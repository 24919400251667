// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import CategoryRow from './CategoryRow';

// Styles
import styles from './styles';

export default withStyles(styles)(CategoryRow);
