// Dependencies
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Helpers
import { sanitizedChefs, compareTextBlobs } from 'lib/helpers/comparisonTool';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const ChefRow = ({ viewableMeals, classes }) => {
  // State methods
  const [hasDifferences, setHasDifferences] = useState(false);

  // Lifecycle methods
  useMemo(() => {
    const checkDifferences =
      viewableMeals.length !== 0 ? compareTextBlobs(sanitizedChefs(viewableMeals)) : false;
    setHasDifferences(checkDifferences);
  }, [viewableMeals]);

  return (
    <TableRow>
      <TableCell
        className={clsx(classes.iconCell, hasDifferences && classes.tableRowGreenBorder)}
      />
      <TableCell className={classes.rowLabel}>Chef</TableCell>
      {viewableMeals.map((meal) => (
        <TableCell key={`chef.${meal.versionId}`} className={clsx(meal.inactive && classes.greyed)}>
          <div className={clsx(classes.chef, hasDifferences && classes.highlight)}>{meal.chef}</div>
        </TableCell>
      ))}
    </TableRow>
  );
};

ChefRow.propTypes = {
  viewableMeals: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default ChefRow;
