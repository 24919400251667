import {
  CIRF_COMPOUND_INGREDIENT_RECIPE,
  CIRF_UPDATE_CIR,
  CIRF_CLEAR_MESSAGES,
  CIRF_UPDATE_CI_STATE,
} from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import {
  fetchCompoundIngredientRecipe,
  updateCompoundIngredientRecipe,
  updateCompoundIngredientState,
} from './services';

export const getCompoundIngredientRecipe = (compoundIngredientRecipeId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: CIRF_COMPOUND_INGREDIENT_RECIPE,
        method: fetchCompoundIngredientRecipe,
        params: { compoundIngredientRecipeId },
      })
    );
  };
};

export const postCompoundIngredientRecipe = (transformedParams) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: CIRF_UPDATE_CIR,
        method: updateCompoundIngredientRecipe,
        params: transformedParams,
      })
    );
  };
};

export const postCompoundIngredientState = (transformedParams) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: CIRF_UPDATE_CI_STATE,
        method: updateCompoundIngredientState,
        params: transformedParams,
      })
    );
  };
};

export const clearSuccessAndErrorMessages = () => {
  return async (dispatch) => {
    dispatch({
      type: CIRF_CLEAR_MESSAGES,
    });
  };
};
