import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';
import CancelFields from './CancelFields';

const mapStateToProps = (state) => ({
  formOptions: state.incidentManager.incident.formOptions,
});

export default connect(mapStateToProps)(withStyles(styles)(CancelFields));
