// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

// Actions
import { setConceptId, getMeals } from 'redux/comparisonTool/concept/actions';

// Components
import ComparisonTool from './ComparisonTool';

// Styles
import styles from './styles';

const mapStateToProps = (state) => ({
  conceptId: state.comparisonTool.concept.id,
  viewableMeals: state.comparisonTool.concept.viewableMeals,
  fetching: state.comparisonTool.concept.fetching,
  allMeals: state.comparisonTool.concept.allMeals,
});
const mapDispatchToProps = {
  onSetConceptId: setConceptId,
  onGetMeals: getMeals,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ComparisonTool));
