// Dependencies
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Helpers
import { mealShape } from 'lib/helpers/comparisonTool/propShapes';

// Components
import { Loading } from 'components/shared';
import VersionTable from './VersionTable';
import SelectVersionsPrompt from './SelectVersionsPrompt';
import ConceptInput from './ConceptInput';

const ComparisonTool = ({
  classes,
  allMeals,
  viewableMeals,
  fetching,
  onSetConceptId,
  onGetMeals,
}) => {
  // Helper methods
  const { urlConceptId } = useParams();

  // Lifecycle methods
  // TODO: Clean up redundant concept id
  useEffect(() => {
    if (urlConceptId !== undefined) {
      onSetConceptId(urlConceptId);
      onGetMeals(urlConceptId);
    }
  }, [onGetMeals, urlConceptId, onSetConceptId]);

  // Rendering
  if (fetching) {
    return <Loading />;
  }

  if (allMeals.length === 0) {
    return <ConceptInput />;
  }

  return (
    <div className={classes.root}>
      {viewableMeals.length !== 0 ? <VersionTable /> : <SelectVersionsPrompt />}
    </div>
  );
};

ComparisonTool.propTypes = {
  classes: PropTypes.object.isRequired,
  viewableMeals: PropTypes.arrayOf(mealShape).isRequired,
  fetching: PropTypes.bool.isRequired,
  onSetConceptId: PropTypes.func.isRequired,
  onGetMeals: PropTypes.func.isRequired,
  allMeals: PropTypes.arrayOf(mealShape).isRequired,
};

export default ComparisonTool;
