// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import IngredientsRow from './IngredientsRow';

// Styles
import styles from './styles';

export default withStyles(styles)(IngredientsRow);
