import { DC_DISPUTES, DC_EVIDENCE } from 'lib/constants';

import { makeApiRequest } from 'lib/api';

import { fetchDisputes, updateEvidence } from './services';

export const getDisputes = () => {
  return async (dispatch) => {
    await dispatch(makeApiRequest({ type: DC_DISPUTES, method: fetchDisputes }));
  };
};

export const sendEvidence = (disputeId, chargeId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: DC_EVIDENCE,
        method: updateEvidence,
        params: { disputeId, chargeId },
      })
    );
  };
};
