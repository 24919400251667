import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { upperFirst } from 'lodash';

import { broadcastScrollSizeToParent } from 'lib/utils';

// Material Components
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Input from '@mui/material/Input';
import DeleteIcon from '@mui/icons-material/Delete';

import Dropdown from '../../shared/Dropdown';

const PhotoCard = ({ classes, photo, formState, handleFormState, index }) => {
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);

  const [photoState, setPhotoState] = useState({
    ...photo,
    index,
    newImage: null,
  });

  useEffect(() => {
    setPhotoState({
      ...photo,
      index,
      newImage: photoState.newImage,
    });
  }, [photo, index, photoState.newImage]);

  // If being used in an iframe, set the width and height
  // so we don't have a double scrollbar situation.
  useEffect(() => {
    broadcastScrollSizeToParent();
  }, []);

  const onCategoryChange = (event) => {
    const { name, value } = event.target;

    const newPhotoState = { ...photoState, [name]: value };
    setPhotoState(newPhotoState);

    const newFormStatePhotos = formState.photos;
    newFormStatePhotos[index] = newPhotoState;

    handleFormState('photos', newFormStatePhotos);
  };

  const onFileChange = (event) => {
    const photoFile = event.target.files[0];
    if (photoFile === undefined || photoFile === null) return;

    const newPhotoState = { ...photoState, fileName: photoFile.name, newImage: photoFile };
    setPhotoState(newPhotoState);

    const newFormStatePhotos = formState.photos;
    newFormStatePhotos[index] = newPhotoState;

    handleFormState('photos', newFormStatePhotos);
  };

  const onDelete = () => {
    const newPhotoState = { ...photoState, deleted: true };
    setPhotoState(newPhotoState);

    const newFormStatePhotos = formState.photos;
    newFormStatePhotos[index] = newPhotoState;

    handleFormState('photos', newFormStatePhotos);
  };

  const selectCategories = useMemo(() => {
    return formOptions.photoCategories.map((category) => ({
      label: category.displayName,
      value: category.category,
      key: category.category,
    }));
  }, [formOptions.photoCategories]);

  const renderImage = () => {
    const src = photoState.newImage ? URL.createObjectURL(photoState.newImage) : photoState.asset;
    return <img src={src} alt={photoState.fileName} className={classes.image} />;
  };

  const renderPhoto = () => {
    const inputId = `contained-button-file-${index}`;

    return (
      <Card className={classes.card} elevation={0}>
        {renderImage()}
        <CardContent className={classes.cardContent}>
          <div className={classes.flexContainer}>
            <p className={classes.fileName}>
              {photoState.fileName ? photoState.fileName : 'No Image Selected'}
            </p>
            <div className={classes.uploadAndDelete}>
              <label htmlFor={inputId}>
                <Input
                  accept="image/*"
                  id={inputId}
                  type="file"
                  className={classes.input}
                  onChange={onFileChange}
                />
                <Button variant="outlined" component="span" className={classes.outlinedButton}>
                  {photo.id ? 'Replace Image' : 'Choose Image'}
                </Button>
              </label>
              <Button
                alt="Delete Photo"
                variant="outlined"
                className={classes.deleteButton}
                onClick={onDelete}
              >
                <DeleteIcon />
              </Button>
            </div>
          </div>
          {photo.id ? (
            <p className={classes.fileName}>Type: {upperFirst(photoState.category)}</p>
          ) : (
            <Dropdown
              onChange={onCategoryChange}
              label="Type"
              name="category"
              value={photo.category}
              required
              options={selectCategories}
            />
          )}
        </CardContent>
      </Card>
    );
  };

  return <>{photo.deleted ? null : renderPhoto()}</>;
};

PhotoCard.propTypes = {
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  photo: PropTypes.object,
};

PhotoCard.defaultProps = {
  photo: null,
};

export default PhotoCard;
