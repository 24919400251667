import {
  GET_QUARTER_REQUEST,
  GET_QUARTER_SUCCESS,
  GET_QUARTER_ERROR,
  CHANGE_QUARTER_REQUEST,
  CHANGE_QUARTER_SUCCESS,
  CHANGE_QUARTER_ERROR,
} from 'lib/constants';

import { getUnixTime } from 'date-fns';

const initialState = {
  currentTime: getUnixTime(new Date()),
  start: null,
  end: null,
  fetching: true,
  error: false,
  errorMessage: '',
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case GET_QUARTER_REQUEST:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case GET_QUARTER_SUCCESS:
      return {
        ...state,
        start: action.response.quarter.start,
        end: action.response.quarter.end,
        fetching: false,
        error: false,
      };
    case GET_QUARTER_ERROR:
      return {
        ...state,
        fetching: false,
        error: true,
        errorMessage: `Error: ${action.error?.message || action.response.message}`,
      };
    case CHANGE_QUARTER_REQUEST:
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case CHANGE_QUARTER_SUCCESS:
      return {
        ...state,
        start: action.response.quarter.start,
        end: action.response.quarter.end,
        fetching: false,
        error: false,
      };
    case CHANGE_QUARTER_ERROR:
      return {
        ...state,
        fetching: false,
        error: true,
        errorMessage: `Error: ${action.response.message}`,
      };
    default:
      return state;
  }
};
