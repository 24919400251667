import { isEmpty, map, every } from 'lodash';

export const splitStringByNewline = (str) => {
  return str.split('\n');
};

export const stripWhiteSpaceArray = (arr) => {
  return map(arr, (str) => str.trim());
};

export const arrayEmpty = (arr) => {
  return isEmpty(arr) || every(arr, (id) => isEmpty(id));
};

export const validIds = (arrOfIds) => {
  return every(arrOfIds, (id) => id.match(/^[0-9]{6}.[0-9]{3}.[0-9]{2}$/));
};
