import { mapKeys, mapValues, isObject, isArray } from 'lodash';

export const mapKeysDeep = (object, callback) => {
  if (isArray(object)) {
    return object.map((innerObj) => mapKeysDeep(innerObj, callback));
  }
  if (isObject(object)) {
    return mapValues(mapKeys(object, callback), (val) => mapKeysDeep(val, callback));
  }
  return object;
};
