import { BACKGROUND_GREY, LIGHT_BLACK } from 'lib/constants';

export default {
  root: {
    margin: '10px 0',
    height: '40px',
    backgroundColor: BACKGROUND_GREY,
  },
  link: {
    padding: '10px',
    height: '100%',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    color: LIGHT_BLACK,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
    width: '150px',
  },
  arrow: {
    color: LIGHT_BLACK,
    fontSize: '14px',
  },
};
