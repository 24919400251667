// Dependencies
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Material
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

// Helpers
import { validSwappableIngredientId } from 'lib/helpers/ingredientSwapper';

const SwappableIdInput = ({ classes, errorMessage, onResetErrorMessage }) => {
  // State
  const [swappableIdInput, setSwappableIdInput] = useState(undefined);
  const [inputError, setInputError] = useState(false);
  const [inputHelperText, setInputHelperText] = useState('');
  const [redirectUrl, setRedirectUrl] = useState(null);

  const validateInput = () => {
    if (!validSwappableIngredientId(swappableIdInput)) {
      setInputError(true);
      setInputHelperText('Please enter a number.');
      return false;
    }

    setInputError(false);
    setInputHelperText('');
    return true;
  };

  // Handler Methods
  const handleFindConcept = () => {
    if (errorMessage) {
      onResetErrorMessage();
    }

    if (validateInput()) {
      setRedirectUrl(`/ingredient-swapper/${swappableIdInput}`);
    }
  };

  const handleConceptInput = (ref) => {
    if (inputError) {
      setInputError(false);
      setInputHelperText('');
    }
    setSwappableIdInput(ref.target.value);
  };

  // Rendering
  const renderRedirect = () => {
    if (redirectUrl) {
      return <Navigate to={redirectUrl} />;
    }
    return null;
  };

  return (
    <div>
      {renderRedirect()}
      {(errorMessage || inputError) && (
        <Alert className={classes.alertBar} variant="outlined" severity="error">
          {errorMessage || inputHelperText}
        </Alert>
      )}
      <Paper className={classes.paperBox} elevation={0}>
        Enter the ID of the ingredient you want to swap.
        <TextField
          id="outlined-basic"
          label="Ingredient ID"
          variant="outlined"
          className={classes.textField}
          onChange={handleConceptInput}
          error={inputError}
        />
        <Button
          onClick={handleFindConcept}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Go
        </Button>
      </Paper>
    </div>
  );
};

SwappableIdInput.propTypes = {
  classes: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  onResetErrorMessage: PropTypes.func.isRequired,
};

SwappableIdInput.defaultProps = {
  errorMessage: null,
};

export default SwappableIdInput;
