import React from 'react';
import { useListContext } from 'react-admin';
import Box from '@mui/material/Box';
import { Loading } from 'components/shared';
import { BarChart } from '@mui/x-charts/BarChart';
import { useAggregatedData } from './CompoundIngredientsQueries';

const series = [
  {
    id: 'active',
    dataKey: 'active',
    label: 'Active',
    highlightScope: { highlighted: 'item' },
    color: '#0FCA7A',
  },
  {
    id: 'shorts_and_replacements',
    dataKey: 'shortsAndReplacements',
    label: 'Shorts And Replacements',
    highlightScope: { highlighted: 'item' },
    color: '#F7A23B',
  },
  {
    id: 'wip',
    dataKey: 'wip',
    label: 'WIP',
    highlightScope: { highlighted: 'item' },
    color: '#F75D5F',
  },
  {
    id: 'needs_approval',
    dataKey: 'needsApproval',
    label: 'Needs Approval',
    highlightScope: { highlighted: 'item' },
    color: '#118ab2',
  },
  {
    id: 'needs_review',
    dataKey: 'needsReview',
    label: 'Needs Review',
    highlightScope: { highlighted: 'item' },
    color: '#325277',
  },
  {
    id: 'legacy',
    dataKey: 'legacy',
    label: 'Legacy',
    highlightScope: { highlighted: 'item' },
    color: '#b5179e',
  },
];

const CompoundIngredientHubBarChart = () => {
  const {
    data: { aggregatedDataByMenu } = {},
    isPending: isPendingAggregatedData,
    isSuccess: isSuccessAggregatedData,
  } = useAggregatedData();
  const { setFilters } = useListContext();

  const dataset = isSuccessAggregatedData
    ? aggregatedDataByMenu.map(({ countDataByMenu, startDate }) => ({
        ...countDataByMenu,
        startDate,
      }))
    : [];

  if (isPendingAggregatedData) {
    return <Loading />;
  }

  return (
    isSuccessAggregatedData && (
      <Box sx={{ flexGrow: 1 }}>
        <BarChart
          dataset={dataset}
          xAxis={[{ scaleType: 'band', dataKey: 'startDate' }]}
          series={series}
          height={400}
          width={1450}
          margin={{ top: 5, bottom: 55, left: 40, right: 10 }}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'bottom', horizontal: 'middle' },
              padding: 0,
            },
          }}
          onItemClick={(_event, data) =>
            setFilters({
              menu_id: aggregatedDataByMenu[data.dataIndex].menuId,
              state: data.seriesId,
            })
          }
          onAxisClick={(_event, data) =>
            setFilters({ menu_id: aggregatedDataByMenu[data.dataIndex].menuId })
          }
        />
      </Box>
    )
  );
};

export default CompoundIngredientHubBarChart;
