import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ELIGIBLE,
  INELIGIBLE,
  NEEDS_TESTING,
  TESTED_ELIGIBLE,
  TESTED_INELIGIBLE,
} from 'lib/constants';

// Material Components
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircle from '@mui/icons-material/CheckCircle';

import Dropdown from '../../shared/Dropdown';

const RetailEligibilityStatus = ({ status, handleFormState, statusState, classes }) => {
  const [openCollapsible, setOpenCollapsible] = useState(false);

  const renderMealToCartonPercentage = () => {
    return status.mealToCartonPercentage ? (
      <li className={classes.text}>
        Fits {status.mealToCartonPercentage}% of this retail packaging
      </li>
    ) : null;
  };

  const createStatuses = () => {
    return [
      { value: 'needs_manual_testing', label: 'Manual testing required' },
      { value: 'manually_tested_eligible', label: 'Passed - meal fits in retail carton' },
      { value: 'manually_tested_ineligible', label: 'Failed - meal does not fit in retail carton' },
    ];
  };

  const [statuses] = useState(createStatuses());

  const renderOutcomeDropdown = () => {
    const statusId = status.id.toString();

    return (
      <Dropdown
        label="Outcome"
        name={statusId}
        onChange={handleFormState}
        value={statusState[statusId]}
        options={statuses}
      />
    );
  };

  const renderIneligible = () => {
    return (
      <ul className={classes.ul}>
        <li className={classes.eligibility}>
          Ineligible - meal does not fit safely in this retail packaging
        </li>
        {renderMealToCartonPercentage()}
        {status.ineligibilityReasons.map((reason, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li className={classes.text} key={`${reason}-${index}`}>
              {reason}
            </li>
          );
        })}
      </ul>
    );
  };

  const renderEligible = () => {
    return (
      <ul className={classes.ul}>
        <li className={classes.eligibility}>
          Eligible - meal fits safely in this retail packaging
        </li>
        {renderMealToCartonPercentage()}
      </ul>
    );
  };

  const renderNeedsManualTesting = () => {
    return (
      <ul className={classes.ul}>
        <li className={classes.eligibility}>
          Needs Manual Testing - meal may not fit safely in this retail packaging
        </li>
        {renderMealToCartonPercentage()}
        {renderOutcomeDropdown()}
      </ul>
    );
  };

  const renderManuallyTestedIneligible = () => {
    return (
      <ul className={classes.ul}>
        <li className={classes.eligibility}>
          Manually Tested Ineligible - meal was manually tested for this retail packaging
        </li>
        {renderMealToCartonPercentage()}
        {renderOutcomeDropdown()}
      </ul>
    );
  };

  const renderManuallyTestedEligible = () => {
    return (
      <ul className={classes.ul}>
        <li className={classes.eligibility}>
          Manually Tested Eligible - meal was manually tested for this retail packaging
        </li>
        {renderMealToCartonPercentage()}
        {renderOutcomeDropdown()}
      </ul>
    );
  };

  const renderStatusDetails = () => {
    switch (status.category) {
      case INELIGIBLE:
        return renderIneligible();
      case ELIGIBLE:
        return renderEligible();
      case NEEDS_TESTING:
        return renderNeedsManualTesting();
      case TESTED_ELIGIBLE:
        return renderManuallyTestedEligible();
      case TESTED_INELIGIBLE:
        return renderManuallyTestedIneligible();
      default:
        return null;
    }
  };

  const renderIcon = () => {
    switch (status.category) {
      case INELIGIBLE:
      case TESTED_INELIGIBLE:
        return <ErrorIcon className={classes.errorIcon} />;
      case NEEDS_TESTING:
        return <WarningIcon className={classes.warningIcon} />;
      case ELIGIBLE:
      case TESTED_ELIGIBLE:
        return <CheckCircle className={classes.successIcon} />;
      default:
        return null;
    }
  };

  return (
    <ul className={classes.ul}>
      <li className={classes.packagingName}>
        {renderIcon()}
        {status.packagingDisplayName}
      </li>
      <Collapse data-testid="collapsible" in={openCollapsible}>
        {renderStatusDetails()}
      </Collapse>
      <Button
        className={classes.showHide}
        name={openCollapsible ? 'Hide Details' : 'Show Details'}
        onClick={() => setOpenCollapsible(!openCollapsible)}
      >
        {openCollapsible ? 'Hide Details' : 'Show Details'}
      </Button>
    </ul>
  );
};

RetailEligibilityStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  status: PropTypes.object.isRequired,
  statusState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
};

export default RetailEligibilityStatus;
