// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import TeamTitle from './TeamTitle';

// Styles
import styles from './styles';

export default withStyles(styles)(TeamTitle);
