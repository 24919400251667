// Dependencies
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Helpers
import { mealShape } from 'lib/helpers/comparisonTool/propShapes';

// Material
import Button from '@mui/material/Button';

import withStyles from '@mui/styles/withStyles';
import styles from './styles';

// Components
import ReplicateVersionModalDialog from '../ReplicateVersionModalDialog/ReplicateVersionModalDialog';

const ReplicateVersionButton = ({ meals, sourceMeal, classes }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        color="primary"
        className={classes.replicateVersionButton}
        onClick={() => {
          setOpen(true);
        }}
      >
        Replicate Version
      </Button>
      <ReplicateVersionModalDialog
        open={open}
        handleClose={handleClose}
        meals={meals}
        sourceMeal={sourceMeal}
      />
    </>
  );
};

ReplicateVersionButton.propTypes = {
  meals: PropTypes.arrayOf(mealShape).isRequired,
  sourceMeal: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReplicateVersionButton);
