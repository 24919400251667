// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import GeographyInput from './GeographyInput';

// Styles
import styles from './styles';

export default withStyles(styles)(GeographyInput);
