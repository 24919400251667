import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { broadcastScrollSizeToParent } from 'lib/utils';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import LockIcon from '@mui/icons-material/Lock';

import SaveChangesButton from '../shared/SaveChangesButton';
import IngredientCard from './IngredientCard';

const ComponentIngredientsTab = ({ classes, dirty, setDirty }) => {
  const { compoundIngredientRecipe } = useSelector(
    (state) => state.compoundIngredientRecipeForm.compoundIngredientRecipe
  );

  const [formState, setFormState] = useState({
    compoundIngredientId: compoundIngredientRecipe.compoundIngredient.id,
    compoundIngredientRecipeAssignments:
      compoundIngredientRecipe.compoundIngredientRecipeAssignments,
  });

  // If being used in an iframe, set the width and height
  // so we don't have a double scrollbar situation.
  useEffect(() => {
    broadcastScrollSizeToParent();
  }, []);

  useEffect(() => {
    setFormState({
      compoundIngredientId: compoundIngredientRecipe.compoundIngredient.id,
      compoundIngredientRecipeAssignments:
        compoundIngredientRecipe.compoundIngredientRecipeAssignments,
    });
  }, [compoundIngredientRecipe]);

  const handleFormState = (event) => {
    if (!dirty) {
      setDirty(true);
    }
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };
  const addCIRAssignment = () => {
    if (formState.compoundIngredientRecipeAssignments.length > 10) {
      // eslint-disable-next-line
      alert('Warning: You have already added at least ten component ingredients');
    }
    const newCIRAState = formState.compoundIngredientRecipeAssignments.concat({
      compoundIngredientRecipeId: compoundIngredientRecipe.id,
    });
    setFormState({ ...formState, compoundIngredientRecipeAssignments: newCIRAState });
  };

  const renderIngredientCards = () => {
    return formState.compoundIngredientRecipeAssignments.map((cirAssignment, index) => {
      const key = `ingredient-assignment-${index}`;
      return cirAssignment.deleted ? null : (
        <Grid
          item
          xs={12}
          key={key}
          className={compoundIngredientRecipe.isLocked && classes.disabledCardContainer}
        >
          <fieldset disabled={compoundIngredientRecipe.isLocked} className={classes.fieldset}>
            <IngredientCard
              classes={classes}
              formState={formState}
              handleFormState={handleFormState}
              index={index}
              cirAssignment={cirAssignment}
            />
          </fieldset>
        </Grid>
      );
    });
  };

  const renderEditsLockedMessage = () => {
    return (
      <Grid item xs={12}>
        <Alert severity="warning" icon={<LockIcon fontSize="small" />} className={classes.alert}>
          {`This compound ingredient is ${compoundIngredientRecipe.compoundIngredient.state} and formulation can not be edited`}
        </Alert>
      </Grid>
    );
  };

  return (
    <>
      {compoundIngredientRecipe.isLocked && renderEditsLockedMessage()}
      {renderIngredientCards()}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          disabled={compoundIngredientRecipe.isLocked}
          className={classes.addIngredientButton}
          onClick={addCIRAssignment}
          startIcon={<AddIcon />}
        >
          Add Ingredient
        </Button>
      </Grid>
      <Grid item xs={9}>
        <SaveChangesButton
          compoundIngredientRecipeParams={formState}
          compoundIngredientRecipeId={compoundIngredientRecipe?.id}
          editsLocked={compoundIngredientRecipe.isLocked}
        />
      </Grid>
      {!formState.compoundIngredientRecipeAssignments.length && (
        <div style={{ minHeight: '500px' }} />
      )}
    </>
  );
};

ComponentIngredientsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  dirty: PropTypes.bool,
  setDirty: PropTypes.func.isRequired,
};

ComponentIngredientsTab.defaultProps = {
  dirty: false,
};

export default ComponentIngredientsTab;
