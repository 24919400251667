import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { PORTION_METHOD, INGREDIENT_PACKAGING_ID, OPS_CATEGORY } from 'lib/constants';

// Material Components
import Grid from '@mui/material/Grid';
import Dropdown from '../Dropdown';

const IngredientDropdowns = ({
  ingredientAssignmentState,
  handleIngredientAssignmentState,
  ingredientSelected,
  onAttributeChange,
  forCIForm,
}) => {
  const { formOptions } = useSelector((state) =>
    forCIForm ? state.compoundIngredientRecipeForm.formOptions : state.mealEditForm.formOptions
  );

  /**
   * Updates the ingredient state with the name of the portion_method selected by the user.
   *
   * The IngredientAssignment model for portion_method and ops_category will not let you save an ID.
   * You cannot change the portion_method to an ID. It must be set to a string.
   * @function handlePortionMethodChange
   */
  const handlePortionMethodChange = (event) => {
    const { value } = event.target;
    handleIngredientAssignmentState(PORTION_METHOD, value);
  };

  const getIngredientPackagingId = () => {
    if (ingredientAssignmentState.ingredientPackagingId) {
      return ingredientAssignmentState.ingredientPackagingId;
    }
    if (ingredientSelected?.ingredientPackagingId) {
      return ingredientSelected?.ingredientPackagingId;
    }
    return ingredientSelected?.packaging;
  };

  /**
   * @function handleOpsCategoryChange
   */
  const handleOpsCategoryChange = (event) => {
    const { value } = event.target;
    handleIngredientAssignmentState(OPS_CATEGORY, value);
  };

  const selectIngredientPackagings = useMemo(() => {
    return formOptions.ingredientPackagings.map((ingredientPackaging) => ({
      label: ingredientPackaging.name,
      value: ingredientPackaging.id,
    }));
  }, [formOptions.ingredientPackagings]);

  const selectPortionMethods = useMemo(() => {
    return formOptions.portionMethods.map((portionMethod) => ({
      label: portionMethod.formattedName,
      value: portionMethod.name,
    }));
  }, [formOptions.portionMethods]);

  const selectOpsCategories = useMemo(() => {
    return formOptions.opsCategories.map((opsCategory) => ({
      label: opsCategory.formattedName,
      value: opsCategory.name,
    }));
  }, [formOptions.opsCategories]);

  return (
    <Grid container direction="row" spacing={2}>
      {!forCIForm && (
        <Grid item xs>
          <Dropdown
            required
            label="Ingredient Packaging"
            name={INGREDIENT_PACKAGING_ID}
            onChange={onAttributeChange}
            value={getIngredientPackagingId()}
            options={selectIngredientPackagings}
          />
        </Grid>
      )}
      <Grid item xs>
        <Dropdown
          required
          label="Portion Method"
          name={PORTION_METHOD}
          onChange={handlePortionMethodChange}
          value={ingredientAssignmentState.portionMethod || ingredientSelected?.portionMethod}
          options={selectPortionMethods}
        />
      </Grid>
      <Grid item xs>
        <Dropdown
          required
          label="Ops Category"
          name={OPS_CATEGORY}
          onChange={handleOpsCategoryChange}
          value={ingredientAssignmentState.opsCategory || ingredientSelected?.opsCategory}
          options={selectOpsCategories}
          data-testid="ops-category-dropdown"
        />
      </Grid>
    </Grid>
  );
};

IngredientDropdowns.propTypes = {
  forCIForm: PropTypes.bool,
  handleIngredientAssignmentState: PropTypes.func.isRequired,
  ingredientAssignmentState: PropTypes.object.isRequired,
  ingredientSelected: PropTypes.object,
  onAttributeChange: PropTypes.func.isRequired,
};

IngredientDropdowns.defaultProps = {
  ingredientSelected: {},
  forCIForm: false,
};

export default IngredientDropdowns;
