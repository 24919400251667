import { APP_API_URI } from 'lib/constants';
import { GET, POST } from 'lib/api';

export const fetchAllMenus = async () => {
  const response = await GET(`${APP_API_URI}/internal/retail/menu_management/get_all_menus`);
  return response.data;
};

export const createNewMenu = async () => {
  const url = `${APP_API_URI}/internal/retail/menu_management/create_menu`;

  const response = await POST(url);

  return response.data;
};
