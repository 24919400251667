// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Helpers
import { mealShape } from 'lib/helpers/comparisonTool/propShapes';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const VersionNotesRow = ({ viewableMeals, classes }) => {
  return (
    <TableRow>
      <TableCell className={classes.iconCell} />
      <TableCell className={classes.rowLabel}>Version Notes</TableCell>
      {viewableMeals.map((meal) => (
        <TableCell
          key={`versionNotes.${meal.versionId}`}
          className={clsx(meal.inactive && classes.greyed)}
        >
          <div className={classes.versionNotes}>{meal.versionNotes}</div>
        </TableCell>
      ))}
    </TableRow>
  );
};

VersionNotesRow.propTypes = {
  viewableMeals: PropTypes.arrayOf(mealShape).isRequired,
  classes: PropTypes.object.isRequired,
};

export default VersionNotesRow;
