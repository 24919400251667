import { HC_GREEN } from 'lib/constants';

export default {
  displayFlex: {
    display: 'flex',
  },
  topPadding: {
    paddingTop: '200px',
  },
  centered: {
    justifyContent: 'center',
  },
  circularColorPrimary: {
    color: HC_GREEN,
  },
};
