import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import {
  ACTIVE,
  MEAL_KIT_AND_RTH_S_AND_R_ONLY,
  R_AND_D_READY,
  RTH_AND_MEAL_KIT,
  RTH_ONLY,
  SCALING_WIP,
  SHORTS_AND_REPLACEMENTS,
} from 'lib/constants';
import { CIRF_URL } from 'redux/compoundIngredientRecipeForm/compoundIngredientRecipe/services';
import clsx from 'clsx';

import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { GET } from 'lib/api';

const IngredientsSearch = ({
  classes,
  compoundIngredientId,
  autocompleteOnChange,
  ingredientSelected,
  includeShortsAndReplacements,
  name,
  label,
}) => {
  const [componentIngredientResults, setComponentIngredientResults] = useState([]);
  const [checked, setChecked] = useState(includeShortsAndReplacements);

  const tableHeader = {
    id: 'ID',
    name: 'Name',
    brandName: 'Brand Name',
    displayName: 'Display Name',
    category: 'Category',
    state: 'State',
    approved: 'Approved',
  };

  const handleIngredientSearch = async (searchTerm, isChecked) => {
    if (searchTerm) {
      setComponentIngredientResults([]);

      const states = [ACTIVE, R_AND_D_READY, SCALING_WIP];
      const eligibilities = [RTH_ONLY, RTH_AND_MEAL_KIT];

      if (isChecked) {
        states.push(SHORTS_AND_REPLACEMENTS);
        eligibilities.push(MEAL_KIT_AND_RTH_S_AND_R_ONLY);
      }
      await GET(`${CIRF_URL}/ingredients_search`, {
        params: {
          term: searchTerm,
          camelcase: true,
          with_states: states,
          with_eligibilities: eligibilities,
          exclude_nested_compound_ingredients: true,
        },
      })
        .then((response) => {
          const results = response.data;
          const filteredResults = results.baseIngredients.filter(
            (result) => result.id !== compoundIngredientId
          );
          setComponentIngredientResults([tableHeader, ...filteredResults]);
        })
        .catch(() => {
          setComponentIngredientResults([]);
        });
    }
  };

  const debouncedHandleIngredientSearch = debounce(handleIngredientSearch, 400);

  const setSearch = (event) => {
    debouncedHandleIngredientSearch(event.target.value, checked);
    return debouncedHandleIngredientSearch.cancel;
  };

  const optionTextStyle = { fontSize: 13, overflowWrap: 'break-word' };

  return (
    <div
      className={clsx(
        classes.ingredientTextSearchBox,
        !!includeShortsAndReplacements && classes.mb_8
      )}
    >
      <Autocomplete
        freeSolo
        name={name}
        options={componentIngredientResults}
        getOptionDisabled={(option) => option.id === 'ID' && option.name === 'Name'}
        getOptionLabel={(option) => option.name || ''}
        onChange={autocompleteOnChange}
        value={ingredientSelected}
        filterOptions={(x) => x}
        renderOption={(props, option) => (
          <li style={{ minWidth: '850px' }} {...props}>
            <Grid container style={{ minWidth: '850px' }} spacing={1}>
              <Grid item xs={1}>
                <Typography style={optionTextStyle}>{option.id}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={optionTextStyle}>{option.name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={optionTextStyle}>{option.brandName}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={optionTextStyle}>{option.displayName}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography style={optionTextStyle}>{option.category}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography style={optionTextStyle}>{option.state}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography style={optionTextStyle}>{option.approved.toString()}</Typography>
              </Grid>
            </Grid>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            {...params}
            onChange={setSearch}
            label={label}
            size="small"
            InputProps={{ ...params.InputProps, style: { fontSize: 14 } }}
            InputLabelProps={{ style: { fontSize: 13 } }}
          />
        )}
      />
      {!includeShortsAndReplacements && (
        <>
          <Checkbox
            name="searchWithShortsAndReplacements"
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
          <span className={classes.label}>Include shorts and replacements</span>
        </>
      )}
    </div>
  );
};

IngredientsSearch.propTypes = {
  classes: PropTypes.object,
  compoundIngredientId: PropTypes.number.isRequired,
  autocompleteOnChange: PropTypes.func.isRequired,
  ingredientSelected: PropTypes.object,
  includeShortsAndReplacements: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

IngredientsSearch.defaultProps = {
  classes: null,
  ingredientSelected: {},
  includeShortsAndReplacements: false,
};

export default IngredientsSearch;
