import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { broadcastScrollSizeToParent } from 'lib/utils';

// Material Components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import Dropdown from '../../shared/Dropdown';
import SaveChangesButton from '../shared/SaveChangesButton';
import SectionHeader from '../shared/SectionHeader';

const MealClassificationTab = ({ classes, dirty, setDirty }) => {
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);
  const { meal } = useSelector((state) => state.mealEditForm.meal);

  const [formState, setFormState] = useState({
    producedBy: meal.producedBy,
  });

  const handleFormState = (event) => {
    if (!dirty) {
      setDirty(true);
    }
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  // If being used in an iframe, set the width and height
  // so we don't have a double scrollbar situation.
  useEffect(() => {
    broadcastScrollSizeToParent();
  }, []);

  const selectProducedBys = useMemo(() => {
    return formOptions.producedBys.map((producedBy) => ({ label: producedBy, value: producedBy }));
  }, [formOptions.producedBys]);

  return (
    <>
      <Grid item xs={6}>
        <Card className={classes.card}>
          <SectionHeader text="Producer" />

          <Dropdown
            required
            label="Produced By"
            name="producedBy"
            onChange={handleFormState}
            value={formState.producedBy}
            options={selectProducedBys}
          />
        </Card>
      </Grid>

      <Grid item xs={12}>
        <SaveChangesButton mealParams={formState} mealId={meal.id} />
      </Grid>
    </>
  );
};

MealClassificationTab.propTypes = {
  classes: PropTypes.object.isRequired,
  dirty: PropTypes.bool,
  setDirty: PropTypes.func.isRequired,
};

MealClassificationTab.defaultProps = {
  dirty: false,
};

export default MealClassificationTab;
