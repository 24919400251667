import { CSV_EXPORT_REQUEST, CSV_EXPORT_SUCCESS, CSV_EXPORT_ERROR } from 'lib/constants';

const initialState = {
  message: '',
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case CSV_EXPORT_REQUEST:
      return {
        message: 'Generating CSV...',
      };
    case CSV_EXPORT_SUCCESS:
      return {
        message: 'CSV generated, you will be emailed shortly.',
      };
    case CSV_EXPORT_ERROR:
      return {
        message: 'CSV generation failed. Try again or contact the tech team.',
      };
    default:
      return state;
  }
};
