import React, { useEffect, useState } from 'react';
import { APP_API_URI } from 'lib/constants';

// Material
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import { Card, CardActions, CardHeader } from '@mui/material';
import { GET } from 'lib/api';

const DownloadShrink = () => {
  const [updatedAt, setUpdatedAt] = useState('');

  useEffect(() => {
    const getLastUpdatedAt = async () => {
      await GET(`${APP_API_URI}/internal/retail/acceptable_shrink/get_last_updated_at`)
        .then((response) => {
          setUpdatedAt(response.data.lastUpdatedAt);
        })
        .catch(() => setUpdatedAt('error pulling info'));
    };
    getLastUpdatedAt();
  }, []);

  return (
    <Card>
      <CardHeader title="Download Acceptable Shrink" subheader={`Last Updated At ${updatedAt}`} />
      <CardActions>
        <Button
          size="large"
          variant="outlined"
          href={`${APP_API_URI}/internal/retail/acceptable_shrink/download_acceptable_shrink`}
        >
          <DownloadIcon />
          Download
        </Button>
      </CardActions>
    </Card>
  );
};

export default DownloadShrink;
