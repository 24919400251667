// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Material
import Chip from '@mui/material/Chip';

import withStyles from '@mui/styles/withStyles';
import styles from './styles';

const DisplayGroupChip = (props) => {
  const { classes, displayGroup } = props;

  const classMap = {
    Standard: classes.standardChip,
    Family: classes.familyChip,
  };

  if (classMap.hasOwnProperty(displayGroup)) {
    const chipClass = classMap[displayGroup];
    return <Chip className={chipClass} label={displayGroup} />;
  }

  return <Chip className={classes.chip} label={displayGroup} />;
};

DisplayGroupChip.propTypes = {
  displayGroup: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DisplayGroupChip);
