// Dependencies
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Lodash
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import flatMapDeep from 'lodash/flatMapDeep';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

// Helpers
import { mealShape } from 'lib/helpers/comparisonTool/propShapes';
import {
  sanitizedIngredients,
  compareTextBlobs,
  setHighlightGroup,
} from 'lib/helpers/comparisonTool';

const IngredientsRow = ({ viewableMeals, classes }) => {
  // State methods
  const [openCollapsible, setOpenCollapsible] = useState(false);
  const [hasDifferences, setDifferences] = useState(false);
  const [highlightIngredientsGroupedByName, setHighlightIngredientsGroupedByName] = useState({});
  const [ingredientNameSuperset, setIngredientNameSuperset] = useState([]);

  const completeIngredientListForMeal = (meal) => {
    return ingredientNameSuperset.map((name) => {
      return meal.ingredients.find((ing) => ing.name === name) || 'not used';
    });
  };

  // Handler methods
  const handleCollapsibleClick = () => {
    setOpenCollapsible(!openCollapsible);
  };

  const checkForDifferences = useCallback(() => {
    if (viewableMeals.length !== 0) {
      const sanitizedIngs = viewableMeals
        .map((meal) => {
          return meal.ingredients;
        })
        .map((ingredients) => {
          return sanitizedIngredients(ingredients);
        });
      return compareTextBlobs(sanitizedIngs);
    }
    return false;
  }, [viewableMeals]);

  useEffect(() => {
    setDifferences(checkForDifferences(viewableMeals));
  }, [setDifferences, checkForDifferences, viewableMeals]);

  useEffect(() => {
    const allIngredients = flatMapDeep(viewableMeals, (meal) => {
      return meal.ingredients;
    });
    const ingredientsGroupedByName = groupBy(allIngredients, (ingredient) => {
      return ingredient.name;
    });

    setHighlightIngredientsGroupedByName(
      mapValues(ingredientsGroupedByName, (ingredientGroup) => {
        const sanitizedIngs = sanitizedIngredients(ingredientGroup);
        return setHighlightGroup(sanitizedIngs, viewableMeals.length);
      })
    );
  }, [viewableMeals]);

  useEffect(() => {
    const allIngredients = flatMapDeep(viewableMeals, (meal) => {
      return meal.ingredients;
    });
    const sortedIngredients = sortBy(allIngredients, [
      (ingredient) => {
        return !ingredient.isProtein;
      },
      'name',
    ]);
    const sortedIngredientNames = sortedIngredients.map((ingredient) => {
      return ingredient.name;
    });

    setIngredientNameSuperset(uniq(sortedIngredientNames));
  }, [viewableMeals]);

  // Rendering methods
  const renderIngredient = (ingredient) => {
    if (ingredient === 'not used') {
      return (
        <div className={clsx(classes.ingredientBlock, classes.ingredientMissing)}>
          <p>No Ingredient</p>
        </div>
      );
    }
    return (
      <div
        className={clsx(
          classes.ingredientBlock,
          highlightIngredientsGroupedByName[ingredient.name] && classes.highlight
        )}
      >
        <div>
          <div>
            <span className={classes.ingredientName}>{`${ingredient.name}`}</span>
            <span className={classes.ingredientQuantity}>
              {`${ingredient.quantity} ${ingredient.measure}`}
            </span>
          </div>
          <div className={classes.ingredientBrandName}>
            {`${ingredient.brandName} ${ingredient.ingredientName}`}
          </div>
        </div>
      </div>
    );
  };

  return (
    <TableRow>
      <TableCell
        className={clsx(classes.iconCell, hasDifferences && classes.tableRowGreenBorder)}
        onClick={handleCollapsibleClick}
      >
        {openCollapsible ? (
          <ExpandMore className={classes.icon} />
        ) : (
          <ChevronRightIcon className={classes.icon} />
        )}
      </TableCell>
      <TableCell className={classes.rowLabel} onClick={handleCollapsibleClick}>
        Base Ingredients
      </TableCell>
      {viewableMeals.map((meal) => (
        <TableCell
          key={`ingredients.${meal.versionId}`}
          className={clsx(classes.expandingCell, meal.inactive && classes.greyed)}
        >
          <div className={classes.listContentSameBlock}>
            {`${meal.ingredients.length} Ingredients`}
          </div>
          <Collapse in={openCollapsible} unmountOnExit className={classes.collapse}>
            {completeIngredientListForMeal(meal).map((ingredient, ingredientIndex) => (
              <div key={`${meal.VersionId}-${(ingredient && ingredient.id) || ingredientIndex}`}>
                {renderIngredient(ingredient)}
              </div>
            ))}
          </Collapse>
        </TableCell>
      ))}
    </TableRow>
  );
};

IngredientsRow.propTypes = {
  viewableMeals: PropTypes.arrayOf(mealShape).isRequired,
  classes: PropTypes.object.isRequired,
};

export default IngredientsRow;
