import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { broadcastScrollSizeToParent } from 'lib/utils';

// Material Components
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Checkboxes from '../../shared/Checkboxes';
import SaveChangesButton from '../shared/SaveChangesButton';
import SectionHeader from '../shared/SectionHeader';

const RecipeCategoriesTab = ({ classes, dirty, setDirty }) => {
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);
  const { meal } = useSelector((state) => state.mealEditForm.meal);

  const [formState, setFormState] = useState({
    recipeCategoryIds: meal.recipeCategoryIds,
  });

  const handleFormState = (event) => {
    if (!dirty) {
      setDirty(true);
    }
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  // If being used in an iframe, set the width and height
  // so we don't have a double scrollbar situation.
  useEffect(() => {
    broadcastScrollSizeToParent();
  }, []);

  return (
    <>
      <Grid item xs={8}>
        <Paper className={classes.paper}>
          <SectionHeader text="Recipe Categories" />
          <Checkboxes
            choices={formOptions.recipeCategories}
            columns={2}
            selectedFormState={formState.recipeCategoryIds}
            selectedFormStateName="recipeCategoryIds"
            handleFormState={handleFormState}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <SaveChangesButton mealParams={formState} mealId={meal.id} />
      </Grid>
    </>
  );
};

RecipeCategoriesTab.propTypes = {
  classes: PropTypes.object.isRequired,
  dirty: PropTypes.bool,
  setDirty: PropTypes.func.isRequired,
};

RecipeCategoriesTab.defaultProps = {
  dirty: false,
};

export default RecipeCategoriesTab;
