import React from 'react';
import { styled } from '@mui/system';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';

import { HC_GREEN } from 'lib/constants';

const CustomFilterInput = (props) => {
  const CustomInputRoot = styled('button')`
    margin: 10px;
    cursor: pointer;
    background-color: transparent;
    outline: 0;
    border: 0;
  `;

  return (
    <CustomInputRoot>
      <Chip
        label={props.labelText}
        variant="outlined"
        sx={{
          width: '80px',
          height: '25px',
          borderRadius: '4px',
          margin: '10px',
          cursor: 'pointer',
          backgroundColor: 'transparent',
          color: HC_GREEN,
          fontSize: '16px',
          fontWeight: '600',
          '& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'normal',
          },
        }}
      />
    </CustomInputRoot>
  );
};

CustomFilterInput.propTypes = {
  labelText: PropTypes.string.isRequired,
};

export default CustomFilterInput;
