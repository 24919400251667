import {
  WHITE,
  LIGHT_BLACK,
  LIGHT_GREY,
  HC_GREEN,
  BORDER_GREY,
  LIGHTEST_GREY,
} from 'lib/constants';

// Row Styles
export const rowLabel = {
  width: '18%',
  color: LIGHT_BLACK,
  fontWeight: '500',
  fontSize: '12px',
  verticalAlign: 'top',
  position: 'sticky',
  left: '48px',
  backgroundColor: WHITE,
  borderRight: `1px solid ${BORDER_GREY}`,
};
export const iconCell = {
  verticalAlign: 'top',
  padding: '12px',
  position: 'sticky',
  left: '0',
  backgroundColor: WHITE,
};
export const icon = {
  display: 'block',
  margin: 'auto',
  '&:hover': {
    cursor: 'pointer',
  },
};
export const listLabel = {
  display: 'inline-block',
  minWidth: '125px',
  maxWidth: '140px',
  color: LIGHT_GREY,
  fontSize: '12px',
  fontWeight: '400',
  textAlign: 'left',
};
export const listContent = {
  display: 'inline-block',
  color: LIGHT_BLACK,
  fontWeight: '400',
  fontSize: '12px',
};
export const listContentSameBlock = {
  color: LIGHT_BLACK,
  fontWeight: '400',
  fontSize: '12px',
};

export const tableRowGreenBorder = {
  borderLeft: `8px solid ${HC_GREEN}`,
};

// Collapsible Styles
export const expandingCell = {
  verticalAlign: 'top',
};
export const collapse = {
  marginTop: '24px',
};

// Column Styles
export const greyed = {
  backgroundColor: LIGHTEST_GREY,
};
