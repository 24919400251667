// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import BeforeYouCookRow from './BeforeYouCookRow';

// Styles
import styles from './styles';

export default withStyles(styles)(BeforeYouCookRow);
