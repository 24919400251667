import React, { useState, useRef } from 'react';
import useJobDetail from 'lib/useJobDetail';

// Material
import Button from '@mui/material/Button';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Alert, Card, CardActions, CardHeader, Snackbar } from '@mui/material';

import { Loading } from 'components/shared';
import { POST } from 'lib/api';
import { APP_API_URI } from 'lib/constants';

const UploadShrink = () => {
  const [uploadJobId, setUploadJobId] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [snackState, setSnackState] = useState({});
  const inputRef = useRef(null);

  const handleCloseSnack = () => {
    setSnackState({});
  };

  const handleJobResult = () => {
    setIsUploading(false);
    setUploadJobId(null);
    setSnackState({ severity: 'success', message: 'File successfully uploaded!' });
  };

  const handleJobRetrying = (detail) => {
    setSnackState({ severity: 'error', message: detail.error });
  };

  const handleJobDead = (detail) => {
    setSnackState({ state: 'error', message: detail.error });
    setIsUploading(false);
    setUploadJobId(null);
  };

  const jobDetail = useJobDetail({
    jobClass: 'Admin::RetailAcceptableShrinkImportWorker',
    jid: uploadJobId,
    onJobResult: handleJobResult,
    onJobRetrying: handleJobRetrying,
    onJobDead: handleJobDead,
  });

  const sendData = async (file) => {
    setUploadJobId(null);
    setIsUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    await POST(
      `${APP_API_URI}/internal/retail/acceptable_shrink/upload_acceptable_shrink`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
      .then((response) => {
        setUploadJobId(response.data.jid);
      })
      .catch((error) => {
        setSnackState({
          severity: 'error',
          message: error.response?.data?.error || error.response.statusText,
        });
        setIsUploading(false);
      });
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files[0];
    if (!fileObj) {
      return;
    }

    sendData(fileObj);
  };

  return (
    <Card>
      <CardHeader title="Upload Acceptable Shrink" />
      <CardActions>
        <input
          data-testid="fileUpload"
          style={{ display: 'none' }}
          ref={inputRef}
          type="file"
          onChange={handleFileChange}
          accept=".csv"
        />
        <Button
          size="large"
          variant="outlined"
          onClick={handleClick}
          disabled={isUploading}
          startIcon={isUploading ? <Loading size={20} topPadding={false} /> : <UploadFileIcon />}
        >
          {jobDetail ? jobDetail.status : 'Choose File'}
        </Button>
      </CardActions>
      <Snackbar open={'severity' in snackState} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert severity={snackState.severity} variant="filled">
          {snackState.message}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default UploadShrink;
