// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Grid from '@mui/material/Grid';

import { centsToDollarString, dollarsToCents } from 'lib/helpers/money';
import MoneyInput from '../MoneyInput';
import MoneyView from '../MoneyView';

const RefundFields = ({
  maxRefundAmountCents,
  classes,
  formState,
  handleSetMoneyAmount,
  refundType,
}) => {
  const maxAmountText = `Max: ${centsToDollarString(maxRefundAmountCents)}`;

  // This helps with alignment of refund fields by forcing a
  // space where helper text would be.
  const nonBreakingSpace = String.fromCharCode(160);

  const validateRefund = (refundAmount) => {
    return dollarsToCents(refundAmount) <= maxRefundAmountCents;
  };

  const handleMoneyInput = (refundAmount) => {
    handleSetMoneyAmount('cashRefundAmount', refundAmount);
  };

  const handleGiftCardMoneyInput = (refundAmount) => {
    handleSetMoneyAmount('giftCardRefundAmount', refundAmount);
  };

  return (
    <>
      {refundType === 'Charge' ? (
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={3}>
            <MoneyInput
              helperText={maxAmountText}
              label={`${refundType} Refund`}
              name="cashRefundAmount"
              onSetFormState={handleMoneyInput}
              onValidate={validateRefund}
            />
          </Grid>

          <div className={classes.mathSymbols}>+</div>
          <Grid item xs={3}>
            <MoneyView
              helperText={nonBreakingSpace}
              label="Tax Refund"
              name="cashRefundTaxAmount"
              value={formState.cashRefundTaxAmount}
            />
          </Grid>

          <div className={classes.mathSymbols}>=</div>
          <Grid item xs={3}>
            <MoneyView
              helperText={nonBreakingSpace}
              label={`Total ${refundType} Refund`}
              name="cashRefundTotalAmount"
              value={formState.cashRefundTotalAmount}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={3}>
            <MoneyInput
              helperText={maxAmountText}
              label={`${refundType} Refund`}
              name="giftCardRefundAmount"
              onSetFormState={handleGiftCardMoneyInput}
              onValidate={validateRefund}
            />
          </Grid>

          <div className={classes.mathSymbols}>+</div>
          <Grid item xs={3}>
            <MoneyView
              helperText={nonBreakingSpace}
              label="Tax Refund"
              name="giftCardRefundTaxAmount"
              value={formState.giftCardRefundTaxAmount}
            />
          </Grid>

          <div className={classes.mathSymbols}>=</div>
          <Grid item xs={3}>
            <MoneyView
              helperText={nonBreakingSpace}
              label={`Total ${refundType} Refund`}
              name="giftCardRefundTotalAmount"
              value={formState.giftCardRefundTotalAmount}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

RefundFields.propTypes = {
  maxRefundAmountCents: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  handleSetMoneyAmount: PropTypes.func.isRequired,
  refundType: PropTypes.string.isRequired,
};

export default RefundFields;
