export default {
  assortmentCard: {
    margin: 'auto',
    marginTop: '33px',
    border: '.02em solid lightgrey',
    fontFamily: 'Roboto',
    '&:nth-child(2)': {
      marginTop: '32px',
    },
  },
};
