import { BORDER_GREY, LIGHT_BLACK, LIGHT_GREY, MEDIUM_GREY } from 'lib/constants';

export default {
  root: {
    padding: '24px',
    marginTop: '24px',
  },
  header: {
    color: LIGHT_BLACK,
    fontWeight: '500',
    fontSize: '16px',
  },
  mealItem: {
    margin: '16px 0 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '&:last-of-type': {
      margin: '16px 0 20px 0',
    },
  },
  mealTitle: {
    color: LIGHT_BLACK,
    fontWeight: '500',
    fontSize: '14px',
  },
  mealInfo: {
    color: MEDIUM_GREY,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '20px',
  },
  mealTotalPrice: {
    color: LIGHT_BLACK,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '16px',
  },
  totals: {
    minWidth: '112px',
  },
  totalFixedWidth: {
    color: LIGHT_BLACK,
    fontWeight: '400',
    fontSize: '14px',
    textAlign: 'right',
    lineHeight: '20px',
    display: 'inline-block',
    minWidth: '56px',
  },
  totalStrikethrough: {
    color: LIGHT_GREY,
    fontWeight: '400',
    fontSize: '14px',
    textAlign: 'right',
    lineHeight: '20px',
    textDecoration: 'line-through',
    display: 'inline-block',
    minWidth: '56px',
  },
  divider: {
    backgroundColor: BORDER_GREY,
    border: 'none',
    height: 1,
    margin: '16px 0 0 0 ',
  },
  chargeItem: {
    margin: '16px 0 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '&:last-of-type > div': {
      color: LIGHT_BLACK,
      fontWeight: '500',
      fontSize: '14px',
    },
  },
  chargeTitle: {
    color: MEDIUM_GREY,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
  },
  charge: {
    color: LIGHT_BLACK,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '5px',
  },
  creditOrRefundItem: {
    margin: '16px 0 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  creditOrRefundTitle: {
    color: MEDIUM_GREY,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
  },
  creditOrRefund: {
    color: LIGHT_BLACK,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '5px',
  },
};
