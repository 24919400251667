import { createContext } from 'react';

const sessionState = {
  employeeId: null,
  featureFlags: {},
  fetching: false,
  loggedIn: false,
  errorMessage: null,
  abilities: {},
};
const SessionContext = createContext(sessionState);

export { SessionContext, sessionState };
