import { CSV_EXPORT } from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import { triggerCsvExport } from './services';

export const handleCsvExport = ({ sheetName, quarterStart, displayGroup }) =>
  makeApiRequest({
    type: CSV_EXPORT,
    method: triggerCsvExport,
    params: { sheetName, quarterStart, displayGroup },
  });
