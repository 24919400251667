import { APP_API_URI } from 'lib/constants';
import { GET, POST } from 'lib/api';

const IM_URL = `${APP_API_URI}/internal/customer_support/incident_manager`;

export const fetchIncidentable = async ({ incidentableId, incidentableType }) => {
  const queryUrl = `${IM_URL}/incidentable`;

  const response = await GET(queryUrl, {
    params: {
      incidentable_id: incidentableId,
      incidentable_type: incidentableType,
    },
  });
  return response.data;
};

export const fetchTaxRefund = async ({ incidentableId, incidentableType, refundCents }) => {
  const response = await GET(`${IM_URL}/cash_tax_refund`, {
    params: {
      incidentable_id: incidentableId,
      incidentable_type: incidentableType,
      cash_refund_cents: refundCents,
    },
  });
  return response.data;
};

export const fetchGiftCardTaxRefund = async ({ incidentableId, incidentableType, refundCents }) => {
  const queryUrl = `${IM_URL}/gift_card_tax_refund`;

  const response = await GET(queryUrl, {
    params: {
      incidentable_id: incidentableId,
      incidentable_type: incidentableType,
      gift_card_refund_cents: refundCents,
    },
  });
  return response.data;
};

export const createCancellation = async (incidentParams) => {
  const response = await POST(`${IM_URL}/cancel_order`, {
    employee_id: incidentParams.employeeId,
    incidentable_id: incidentParams.incidentableId,
    incidentable_type: incidentParams.incidentableType,
    reason: incidentParams.reason,
    other_description: incidentParams.reasonDescription,
  });

  return response.data;
};
