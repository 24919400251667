// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

// Actions
import { setOpenSelectVersions } from 'redux/comparisonTool/selectVersions/actions';

import SelectVersionsButton from './SelectVersionsButton';

import styles from './styles';

const mapStateToProps = (state) => ({
  openSelectVersions: state.comparisonTool.selectVersions.openSelectVersions,
});

const mapDispatchToProps = {
  onSetOpenSelectVersions: setOpenSelectVersions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SelectVersionsButton));
