// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
// Actions
import { getMenu, setRanking } from 'redux/menuManager/menu/actions';
// Components
import Menu from './Menu';
// Styles
import styles from './styles';

const mapStateToProps = (state) => {
  return {
    menuId: state.menuManager.allMenus.selectedMenuId,
    fetching: state.menuManager.menu.fetching,
    locked: state.menuManager.menu.locked,
    inStoreStartsAt: state.menuManager.menu.inStoreStartsAt,
    categories: state.menuManager.menu.categories,
    alternativeRanking: state.menuManager.menu.alternativeRanking,
  };
};

const mapDispatchToProps = {
  onGetMenu: getMenu,
  onSetRanking: setRanking,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Menu));
