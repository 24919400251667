import { WHITE, LIGHT_GREY } from 'lib/constants';

export default {
  tableRow: {
    '&:last-child > td': {
      borderWidth: 0,
    },
  },
  handle: {
    padding: '16px 2px 16px 8px',
    color: LIGHT_GREY,
  },
  rowDropShadow: {
    boxShadow:
      '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    backgroundColor: WHITE,
  },
  actionButtonContainer: {
    display: 'flex',
  },
  visible: {
    visibility: 'visible',
  },
  hidden: {
    visibility: 'hidden',
  },
};
