import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { Container, Divider, MenuItem, TextField, Typography } from '@mui/material';

import { BehavioralDiscountFormContext } from 'contexts/BehavioralDiscountToolContext';
import {
  homechefTemplates,
  tempoTemplates,
  getColorTheme,
  promotionalTemplates,
  bannerTemplates,
} from 'lib/helpers/behavioralDiscountTool/bannerHelper';
import BannerImageUploader from './BannerImageUploader';

const BannerSelector = ({ classes, filename = null, previewImage = null }) => {
  const { formState, actions } = useContext(BehavioralDiscountFormContext);

  const [errorState, setErrorState] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState('');
  const [filteredThemes, setFilteredThemes] = useState([]);
  const imgHelperText = 'Image should be a 192 x 276 .png or .jpg and under 150kb.';
  const promotionalThemes = Object.entries(promotionalTemplates);

  useEffect(() => {
    if (formState.serviceType === 'tempo') {
      setFilteredThemes(Object.entries(tempoTemplates));
    } else {
      setFilteredThemes(Object.entries(homechefTemplates));
    }

    setSelectedTheme(getColorTheme(formState.bannerBackgroundColor));
  }, [formState.serviceType, formState.bannerBackgroundColor]);

  const onThemeChange = (themeName) => {
    const theme = bannerTemplates[themeName];

    actions.setFormState('bannerBackgroundColor', theme.bannerBackgroundColor);
    actions.setFormState('bannerTagTextColor', theme.bannerTagTextColor);
    actions.setFormState('bannerTagBackgroundColor', theme.bannerTagBackgroundColor);
    actions.setFormState('bannerTextColor', theme.bannerTextColor);
  };

  return (
    <Container disableGutters maxWidth={false}>
      <TextField
        fullWidth
        label="Promotion Text"
        variant="outlined"
        value={formState.promotionText}
        inputProps={{ maxLength: 35 }}
        helperText="Max character length: 35"
        onChange={(e) => actions.setFormState('promotionText', e.target.value)}
      />
      <TextField
        fullWidth
        label="Description Text"
        variant="outlined"
        value={formState.description}
        inputProps={{ maxLength: 56 }}
        helperText="Include an * at the end for disclosures. Max character length: 56"
        onChange={(e) => actions.setFormState('description', e.target.value)}
        className={classes.containers}
      />
      <TextField
        fullWidth
        select
        label="Select banner colors"
        margin="normal"
        size="large"
        variant="outlined"
        className={classes.bannerMenu}
        value={selectedTheme}
        onChange={(e) => onThemeChange(e.target.value)}
      >
        <span className={classes.themeHeading}>Default Themes</span>
        {filteredThemes.map(([themeName, theme]) => (
          <MenuItem key={themeName} value={themeName}>
            <div className={classes.menuSwatches}>
              <img src={theme.image} alt={`banner-theme-${themeName}`} />
              <span className={classes.bannerOptions}>{theme.descriptor}</span>
            </div>
          </MenuItem>
        ))}
        {formState.serviceType !== 'tempo' && (
          <span className={classes.themeHeading}>Promotional Themes</span>
        )}
        {formState.serviceType !== 'tempo' &&
          promotionalThemes.map(([themeName, theme]) => (
            <MenuItem key={themeName} value={themeName}>
              <div className={classes.menuSwatches}>
                <img src={theme.image} alt={`banner-theme-${themeName}`} />
                <span className={classes.bannerOptions}>{theme.descriptor}</span>
              </div>
            </MenuItem>
          ))}
      </TextField>
      <div className={classes.containers}>
        <BannerImageUploader
          classes={classes}
          previewImage={previewImage}
          filename={filename}
          setErrorState={setErrorState}
        />
        <Typography className={errorState ? classes.errorText : classes.imgHelperText}>
          {imgHelperText}
        </Typography>
      </div>
      <Divider className={classes.divider} />
      <Typography className={classes.tagHeader}>Upcoming Orders Offer Tag</Typography>
      <TextField
        fullWidth
        label="Offer Tag Copy"
        variant="outlined"
        value={formState.dashboardTagText}
        inputProps={{ maxLength: 20 }}
        helperText="max character length: 20"
        onChange={(e) => actions.setFormState('dashboardTagText', e.target.value)}
        className={classes.containers}
      />
    </Container>
  );
};

BannerSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  filename: PropTypes.string,
  previewImage: PropTypes.string,
};

BannerSelector.defaultProps = {
  filename: null,
  previewImage: null,
};

export default BannerSelector;
