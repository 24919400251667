import { dashCaseToSnakeCase } from 'lib/utils';
import { APP_API_URI } from 'lib/constants';
import { GET } from 'lib/api';

const MG_URL = `${APP_API_URI}/internal/culinary/menugrid`;

export const triggerCsvExport = async ({ sheetName, quarterStart, displayGroup }) => {
  const url = `${MG_URL}/${dashCaseToSnakeCase(
    sheetName
  )}_export?quarter=${quarterStart}&display_group=${displayGroup}`;

  const response = await GET(url);
  return response.data;
};
