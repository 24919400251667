import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Home from '@mui/icons-material/Home';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const AppHeader = ({ classes, position, text }) => {
  return (
    <AppBar position={position} className={classes.root}>
      <Toolbar>
        <Link to="/" aria-label="home" className={classes.home}>
          <Home className={classes.icon} />
        </Link>
        <Typography variant="h6" className={classes.title}>
          {text}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

AppHeader.defaultProps = {
  position: 'sticky',
};

AppHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  position: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default AppHeader;
