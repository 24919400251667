import React from 'react';
import { Admin, Resource, memoryStore } from 'react-admin';
import { APP_API_URI } from 'lib/constants';

import HubLayout from './admin/Layout';
import dataProvider from './admin/dataProvider';

// Resources
import CompoundIngredientDashboard from './admin/Resources/CompoundIngredients/CompoundIngredients';

const HubApp = () => {
  return (
    <Admin
      basename="/hub"
      dataProvider={dataProvider(`${APP_API_URI}/internal/hub`)}
      layout={HubLayout}
      store={memoryStore()}
    >
      <Resource
        name="compound-ingredients"
        list={CompoundIngredientDashboard}
        recordRepresentation="name"
      />
    </Admin>
  );
};

export default HubApp;
