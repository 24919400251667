import {
  MEF_MEAL_REQUEST,
  MEF_MEAL_SUCCESS,
  MEF_MEAL_ERROR,
  MEF_UPDATE_MEAL_REQUEST,
  MEF_UPDATE_MEAL_SUCCESS,
  MEF_UPDATE_MEAL_ERROR,
  MEF_CLEAR_MESSAGES,
} from 'lib/constants';

export const initialState = {
  meal: null,
  fetching: false,
  submitting: false,
  error: false,
  errorMessage: null,
  success: false,
  successMessage: null,
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case MEF_MEAL_REQUEST:
      return {
        ...state,
        error: false,
        fetching: true,
      };
    case MEF_MEAL_SUCCESS:
      return {
        ...state,
        meal: action.response.meal,
        error: false,
        fetching: false,
      };
    case MEF_MEAL_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.error.message,
        fetching: false,
      };
    case MEF_UPDATE_MEAL_REQUEST:
      return {
        ...state,
        error: false,
        success: false,
        submitting: true,
      };
    case MEF_UPDATE_MEAL_SUCCESS:
      return {
        ...state,
        meal: action.response.meal,
        error: false,
        submitting: false,
        success: true,
        successMessage: 'Meal updated!',
      };
    case MEF_UPDATE_MEAL_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.error.message,
        submitting: false,
      };
    case MEF_CLEAR_MESSAGES:
      return {
        ...state,
        error: false,
        success: false,
      };
    default:
      return state;
  }
};
