import { createTheme } from '@mui/material/styles';
import { BACKGROUND_GREY } from 'lib/constants';

import Karla from '../assets/fonts/Karla.ttf';
import LeMondeCourrier from '../assets/fonts/LeMondeCourrierPro.otf';
import RocGrotesk from '../assets/fonts/RocGroteskRegular.otf';
import RocGroteskMedium from '../assets/fonts/RocGroteskMedium.otf';

const theme = createTheme({
  typography: {
    fontFamily: ['Roboto', 'Roboto-Regular', 'Roboto-Medium'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Karla';
          src: url(${Karla}) format('truetype');
        }
        @font-face {
          font-family: 'LeMondeCourrier';
          src: url(${LeMondeCourrier}) format('truetype');
        }
        @font-face {
          font-family: 'RocGrotesk';
          src: url(${RocGrotesk}) format('truetype');
        }
        @font-face {
          font-family: 'RocGroteskMedium';
          src: url(${RocGroteskMedium}) format('truetype');
        }
      `,
    },
  },
  palette: {
    background: {
      default: BACKGROUND_GREY,
    },
    primary: {
      main: '#008600',
    },
    secondary: {
      main: '#404851',
    },
  },
});

export default theme;
