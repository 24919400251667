import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

const TextBox = ({
  classes,
  disabled,
  fullWidth,
  endAdornment,
  errorMessage,
  errors,
  helperText,
  label,
  maxLength,
  multiline,
  name,
  onChange,
  required,
  rows,
  value,
  type,
}) => {
  const [error, setError] = useState({
    error: false,
    message: '',
  });

  const inputProps = () => {
    const attrs = { style: { fontSize: 14 } };

    if (maxLength) {
      attrs.maxLength = maxLength;
    }

    if (endAdornment) {
      attrs.endAdornment = endAdornment;
    }

    return attrs;
  };

  const validateField = (event) => {
    const fieldValue = event.target.value;

    if (required && !fieldValue) {
      setError({
        error: true,
        message: errorMessage,
      });
    }
  };

  const validateLengthAndChange = (event) => {
    const fieldLength = event.target.value.length;

    if (maxLength && fieldLength > maxLength) {
      setError({
        error: true,
        message: `Maximum length is ${maxLength} characters.`,
      });
    } else {
      setError({
        error: false,
        message: '',
      });
    }

    onChange(event);
  };

  useEffect(() => {
    // errors param should be an array of objects with an error `message` as a string
    // Check IngredientCard component for an example
    const foundError = errors?.find((err) => err.message);

    if (foundError) {
      setError({
        error: true,
        message: foundError.message,
      });
    } else {
      setError({
        error: false,
        message: '',
      });
    }
  }, [errors, setError]);

  return (
    <TextField
      className={classes.textInput}
      disabled={disabled}
      error={error.error}
      fullWidth={fullWidth}
      helperText={error.error ? error.message : helperText}
      label={label}
      multiline={multiline}
      name={name}
      onBlur={validateField}
      onChange={validateLengthAndChange}
      required={required}
      rows={multiline ? rows : null}
      size="small"
      value={value}
      variant="outlined"
      type={type}
      InputProps={inputProps()}
      InputLabelProps={{ style: { fontSize: 13 } }}
    />
  );
};

TextBox.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.element,
  errorMessage: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.array,
  type: PropTypes.string,
};

TextBox.defaultProps = {
  disabled: false,
  endAdornment: null,
  errorMessage: 'This field is required.',
  errors: [],
  fullWidth: true,
  helperText: '',
  label: '',
  maxLength: null,
  multiline: false,
  name: '',
  onChange: () => {},
  required: false,
  rows: 4,
  value: '',
  type: 'string',
};

export default TextBox;
