import { connect } from 'react-redux';
import { toggleSnackBar } from 'redux/menuManager/snackBar/actions';
import Snackbar from './Snackbar';

const mapStateToProps = (state) => ({
  open: state.menuManager.snackBar.open,
  message: state.menuManager.snackBar.message,
});

const mapDispatchToProps = {
  onToggleSnackBar: toggleSnackBar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
