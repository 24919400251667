// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import clsx from 'clsx';

// Helpers
import { mealShape } from 'lib/helpers/comparisonTool/propShapes';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const LastUpdatedRow = ({ viewableMeals, classes }) => {
  return (
    <TableRow>
      <TableCell className={classes.iconCell} />
      <TableCell className={classes.rowLabel}>Last Updated</TableCell>
      {viewableMeals.map((meal) => (
        <TableCell
          key={`lastUpdated.${meal.versionId}`}
          className={clsx(meal.inactive && classes.greyed)}
        >
          <div className={classes.listContentSameBlock}>
            {meal.lastUpdatedDate ? format(new Date(meal.lastUpdatedDate), 'M/d/Y h:m') : '---'}
          </div>
          <div className={classes.listContentSameBlock}>{meal.lastUpdatedName}</div>
        </TableCell>
      ))}
    </TableRow>
  );
};

LastUpdatedRow.propTypes = {
  viewableMeals: PropTypes.arrayOf(mealShape).isRequired,
  classes: PropTypes.object.isRequired,
};

export default LastUpdatedRow;
