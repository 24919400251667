// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import PhotoCard from './PhotoCard';

// Styles
import styles from './styles';

export default withStyles(styles)(PhotoCard);
