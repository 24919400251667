import React from 'react';
import PropTypes from 'prop-types';

const SectionHeader = ({ classes, text }) => {
  return <h1 className={classes.header}>{text}</h1>;
};

SectionHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
};

export default SectionHeader;
