import {
  FVC_CHANGE_TYPE_SET_QUANTITY,
  FVC_CHANGE_TYPE_TRANSFER_QUANTITY,
  FVC_CHANGE_TYPE_ADD_SUBTRACT_QUANTITY,
  FVC_CHANGE_TYPE_CHANGE_PRODUCTION_DAY,
  FVC_VOLUME_TYPE_PERCENT,
} from 'lib/constants';

const renderGeography = (geography) => geography.map((g) => g.code).join(' ');
const forGeography = (geography) =>
  geography.length > 0 ? ` for ${renderGeography(geography)}` : '';

const getConfirmationMessage = (changeRequestData) => {
  switch (changeRequestData && changeRequestData.changeType) {
    case FVC_CHANGE_TYPE_SET_QUANTITY:
      return `Set quantity to ${changeRequestData.quantity} for ${
        changeRequestData.meal.title
      } on ${changeRequestData.shipDate} at the ${
        changeRequestData.packingFacility.name
      } facility${forGeography(changeRequestData.geography)}?`;
    case FVC_CHANGE_TYPE_TRANSFER_QUANTITY:
      return `Transfer ${changeRequestData.quantity} units of ${changeRequestData.meal.title} on ${
        changeRequestData.shipDate
      } at the ${changeRequestData.packingFacility.name} facility from ${renderGeography(
        changeRequestData.geographyFrom
      )} to ${renderGeography(changeRequestData.geographyTo)}?`;
    case FVC_CHANGE_TYPE_ADD_SUBTRACT_QUANTITY:
      return `${changeRequestData.selectedOperation.name} ${changeRequestData.quantity}${
        changeRequestData.selectedVolumeType.value === FVC_VOLUME_TYPE_PERCENT ? '%' : ''
      } of ${changeRequestData.meal.title} on ${changeRequestData.shipDate} at the ${
        changeRequestData.packingFacility.name
      } facility${forGeography(changeRequestData.geography)}?`;
    case FVC_CHANGE_TYPE_CHANGE_PRODUCTION_DAY:
      return `Change production day for ${changeRequestData.quantity} units of ${
        changeRequestData.meal.title
      } from ${changeRequestData.shipDateFrom} to ${changeRequestData.shipDateTo} at the ${
        changeRequestData.packingFacility.name
      } facility${forGeography(changeRequestData.geography)}?`;
    default:
      return 'Submit change request?';
  }
};

export default getConfirmationMessage;
