export default {
  productLineCard: {
    borderRadius: 4,
    fontFamily: 'Roboto',
    '&:nth-child(2)': {
      marginTop: '32px',
    },
  },
  tableHeader: {
    display: 'flex',
    fontSize: '20px',
    fontWeight: 500,
    justifyContent: 'space-between',
    padding: '0px 24px 0px 16px',
  },
  categoryTitle: {
    marginRight: '8px',
    color: '#404851',
  },
  subtitle: {
    fontSize: '20px',
    color: '#8C9196',
  },
  columnTitle: {
    whiteSpace: 'nowrap',
    fontWeight: 500,
    fontSize: '14px',
  },
  tableRow: {
    '&:last-child > td': {
      borderWidth: 0,
    },
  },
};
