import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Loading } from 'components/shared';

import getConfirmationMessage from 'lib/helpers/flexibleVolumeChanges/getConfirmationMessage';

const ChangeRequestConfirmationDialog = ({ open, changeRequests, onClose, onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const confirmationActionText =
    changeRequests.length > 1 ? `Submit ${changeRequests.length} Changes` : 'Submit Change';

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await onSubmit();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Volume Change</DialogTitle>
      <DialogContent>
        {isSubmitting ? (
          <Loading topPadding={false} />
        ) : (
          <>
            <DialogContentText component="div">
              <List dense disablePadding>
                {changeRequests.map((changeRequest) => {
                  const confirmationMessage = getConfirmationMessage(changeRequest);
                  return <ListItem key={confirmationMessage}>{confirmationMessage}</ListItem>;
                })}
              </List>
            </DialogContentText>
            <DialogActions>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {confirmationActionText}
              </Button>
            </DialogActions>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

ChangeRequestConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  changeRequests: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ChangeRequestConfirmationDialog;
