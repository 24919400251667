import PropTypes from 'prop-types';

export const disputeShape = PropTypes.shape({
  amount: PropTypes.string,
  reason: PropTypes.string,
  userEmail: PropTypes.string,
  disputedOn: PropTypes.string,
  disputeId: PropTypes.string,
  respondBy: PropTypes.string,
  disputeUrl: PropTypes.string,
  userAdminUrl: PropTypes.string,
  disputeFormUrl: PropTypes.string,
  chargeId: PropTypes.number,
  success: PropTypes.string,
  isEvidenceUpdated: PropTypes.bool,
});
