import { isEqual } from 'lodash';

export const handleMount = (
  baseIngredientSelected,
  ingredientAssignmentState,
  index,
  formState,
  setFormState,
  setIngredientAssignmentState
) => {
  const newIngredientAssignmentState = {
    ingredientPackagingId: baseIngredientSelected.ingredientPackagingId,
    measure: baseIngredientSelected.standardUnit,
    name: baseIngredientSelected.displayName,
    opsCategory: baseIngredientSelected.opsCategory,
    portionMethod: baseIngredientSelected.portionMethod,
    ...ingredientAssignmentState,
    ingredientId: baseIngredientSelected.id,
    largeIngredient: baseIngredientSelected.largeIngredient,
    mustCook: baseIngredientSelected.mustCook,
    pickable: baseIngredientSelected.pickable,
  };

  setIngredientAssignmentState(newIngredientAssignmentState);

  const newFormStateIngredientAssignments = formState.ingredientAssignments;
  newFormStateIngredientAssignments[index] = newIngredientAssignmentState;

  // Ensures `dirty` does not get set to `true` on mount
  setFormState({ ...formState, ingredientAssignments: newFormStateIngredientAssignments });
};

const updateIngredientAssignmentState = (baseIngredientSelected, ingredientAssignmentState) => {
  return {
    ...ingredientAssignmentState,
    ingredientId: baseIngredientSelected.id,
    ingredientPackagingId: baseIngredientSelected.ingredientPackagingId,
    labelRequired: baseIngredientSelected.labelRequired,
    largeIngredient: baseIngredientSelected.largeIngredient,
    measure: baseIngredientSelected.standardUnit,
    mustCook: baseIngredientSelected.mustCook,
    name: baseIngredientSelected.displayName,
    opsCategory: baseIngredientSelected.opsCategory,
    pickable: baseIngredientSelected.pickable,
    portionMethod: baseIngredientSelected.portionMethod,
    usageYield: baseIngredientSelected.usageYield,
  };
};

export const handleNewBaseIngredientSelected = (
  baseIngredientSelected,
  ingredientAssignmentState
) => {
  // In the case where a base ingredient is selected for a new ingredient assignment
  if (isEqual(ingredientAssignmentState, { replacementIngredients: [] })) {
    return updateIngredientAssignmentState(baseIngredientSelected, ingredientAssignmentState);
  }
  // If ingredient assignment already exists and baseIngredientSelected is being swapped
  return {
    ...updateIngredientAssignmentState(baseIngredientSelected, ingredientAssignmentState),
    highTolerance: '',
    lowTolerance: '',
    quantity: ingredientAssignmentState.quantity,
    productionSheetNotes: '',
  };
};

export const handleClearIngredientAssignment = (ingredientAssignmentState) => {
  return {
    id: ingredientAssignmentState.id,
    ingredientId: null,
    quantity: ingredientAssignmentState.quantity,
    replacementIngredients: [],
    newIaId: ingredientAssignmentState.newIaId,
  };
};

export const handleNewIngredientAssignment = (ingredientAssignmentState) => {
  return { replacementIngredients: [], newIaId: ingredientAssignmentState.newIaId };
};
