import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { broadcastScrollSizeToParent } from 'lib/utils';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';

import CompoundIngredientRecipeStep from './CompoundIngredientRecipeStep';
import SectionHeader from '../shared/SectionHeader';
import SaveChangesButton from '../shared/SaveChangesButton';

const PrepInstructionsTab = ({ classes, dirty, setDirty }) => {
  const { compoundIngredientRecipe } = useSelector(
    (state) => state.compoundIngredientRecipeForm.compoundIngredientRecipe
  );

  const [formState, setFormState] = useState({
    compoundIngredientRecipeSteps: compoundIngredientRecipe.compoundIngredientRecipeSteps,
  });

  useEffect(() => {
    setFormState({
      compoundIngredientRecipeSteps: compoundIngredientRecipe.compoundIngredientRecipeSteps,
    });
  }, [compoundIngredientRecipe]);

  const handleFormState = (event) => {
    if (!dirty) {
      setDirty(true);
    }
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  // If being used in an iframe, set the width and height
  // so we don't have a double scrollbar situation.
  useEffect(() => {
    broadcastScrollSizeToParent();
  }, []);

  const renderCompoundIngredientRecipeSteps = () => {
    return formState.compoundIngredientRecipeSteps.map((step, index) => {
      const key = `compound-ingredient-recipe-step-${index}`;
      return (
        <CompoundIngredientRecipeStep
          key={key}
          classes={classes}
          formState={formState}
          handleFormState={handleFormState}
          index={index}
          compoundIngredientRecipeStep={step}
        />
      );
    });
  };

  const addCompoundIngredientRecipeStep = () => {
    const newRecipeStep = {};
    const newRecipeStepsState = formState.compoundIngredientRecipeSteps;
    newRecipeStepsState.push(newRecipeStep);
    setFormState({ ...formState, compoundIngredientRecipeSteps: newRecipeStepsState });
  };

  return (
    <>
      <Grid item xs={8}>
        <Paper className={classes.paper}>
          <SectionHeader text="Prep Instructions" />
          <Grid>{renderCompoundIngredientRecipeSteps()}</Grid>
          <Button
            variant="outlined"
            className={classes.addButton}
            onClick={addCompoundIngredientRecipeStep}
          >
            <AddIcon />
            Step
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={9}>
        <SaveChangesButton
          compoundIngredientRecipeParams={formState}
          compoundIngredientRecipeId={compoundIngredientRecipe?.id}
        />
      </Grid>
    </>
  );
};

PrepInstructionsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  dirty: PropTypes.bool,
  setDirty: PropTypes.func.isRequired,
};

PrepInstructionsTab.defaultProps = {
  dirty: false,
};

export default PrepInstructionsTab;
