import { LIGHT_BLACK, MATERIAL_OUTLINE_GRAY } from 'lib/constants';

export default {
  alert: {
    border: '1px solid orange',
  },
  container: {
    borderTop: `solid 1px ${MATERIAL_OUTLINE_GRAY}`,
    padding: '24px 0px 24px 0px',
    margin: '8px 0px 0px 0px',
  },
  paper: {
    padding: 16,
    marginTop: 24,
    marginLeft: 24,
  },
  tableHeader: {
    paddingLeft: 16,
    paddingBottom: 4,
    paddingTop: 11,
  },
  tabHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: '0.15px',
    color: LIGHT_BLACK,
    padding: '0 0 8px 0',
    margin: 0,
  },
  outputLossHeader: {
    paddingTop: 15,
  },
  tableLabel: {
    textTransform: 'capitalize',
  },
  tableValue: {
    textAlign: 'right',
    fontWeight: 'bold',
  },
};
