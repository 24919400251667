import {
  MM_CREATE_NEW_MENU_REQUEST,
  MM_CREATE_NEW_MENU_SUCCESS,
  MM_CREATE_NEW_MENU_ERROR,
  MM_FETCH_ALL_MENUS_REQUEST,
  MM_FETCH_ALL_MENUS_SUCCESS,
  MM_FETCH_ALL_MENUS_ERROR,
  MM_SET_SELECTED_MENU_ID,
} from 'lib/constants';

export const initialState = {
  fetching: false,
  error: false,
  selectedMenuId: null,
  allMenus: [],
  posting: false,
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case MM_CREATE_NEW_MENU_REQUEST:
      return {
        ...state,
        posting: true,
      };
    case MM_CREATE_NEW_MENU_SUCCESS:
      return {
        ...state,
        posting: false,
        selectedMenuId: action.response.retailMenus[0].id,
        allMenus: action.response.retailMenus,
      };
    case MM_CREATE_NEW_MENU_ERROR:
      return {
        ...state,
        posting: false,
      };
    case MM_FETCH_ALL_MENUS_REQUEST:
      return {
        ...state,
        error: false,
        fetching: true,
      };
    case MM_FETCH_ALL_MENUS_SUCCESS:
      return {
        posting: false,
        fetching: false,
        error: false,
        selectedMenuId: action.response.defaultMenuId,
        allMenus: action.response.retailMenus,
      };
    case MM_FETCH_ALL_MENUS_ERROR:
      return {
        ...state,
        error: true,
        fetching: false,
      };
    case MM_SET_SELECTED_MENU_ID:
      return {
        ...state,
        selectedMenuId: action.payload.menuId,
      };
    default:
      return state;
  }
};
