import { WHITE, BORDER_GREY, LIGHT_RED, LIGHT_BLACK } from 'lib/constants';

export default {
  paperBox: {
    width: '500px',
    top: '200px',
    left: '50%',
    position: 'absolute',
    transform: 'translateX(-50%)',
    backgroundColor: WHITE,
    border: `1px solid ${BORDER_GREY}`,
    borderRadius: '4px',
    padding: '24px 16px',
    color: LIGHT_BLACK,
  },
  alertBar: {
    width: '500px',
    top: '135px',
    left: '50%',
    position: 'absolute',
    transform: 'translateX(-50%)',
    borderRadius: '4px',
    backgroundColor: LIGHT_RED,
  },
  textField: {
    width: '100%',
    marginTop: '18px',
  },
  button: {
    borderRadius: '4px',
    marginTop: '18px',
    float: 'right',
  },
};
