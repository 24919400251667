import {
  FVC_CHANGE_TYPE_SET_QUANTITY,
  FVC_CHANGE_TYPE_TRANSFER_QUANTITY,
  FVC_CHANGE_TYPE_ADD_SUBTRACT_QUANTITY,
  FVC_CHANGE_TYPE_CHANGE_PRODUCTION_DAY,
} from 'lib/constants';

import { format } from 'date-fns';

const abbreviatedChangeType = {
  set_quantity: 'Set quantity',
  transfer_quantity: 'Transfer',
  add_subtract_quantity: 'Add/Subtract',
  change_production_day: 'Change day',
};

const formattedDate = (date) => {
  return format(new Date(date), 'MMM d');
};

const formattedGeography = (geography) => {
  return geography.map((g) => g.code).join(', ');
};

const formattedVolumeType = (request) => {
  return request.selectedVolumeType.value === 'percent' ? '%' : '';
};

const getQueuedChangeDescription = (request) => {
  const title = `${formattedDate(request.shipDate)} ${request.packingFacility.name} ${
    request.meal.title
  }:  ${abbreviatedChangeType[request.changeType]}`;
  switch (request && request.changeType) {
    case FVC_CHANGE_TYPE_SET_QUANTITY:
      return `${title} to ${request.quantity}`;
    case FVC_CHANGE_TYPE_TRANSFER_QUANTITY:
      return `${title} ${request.quantity} from ${formattedGeography(
        request.geographyFrom
      )} to ${formattedGeography(request.geographyTo)}`;
    case FVC_CHANGE_TYPE_ADD_SUBTRACT_QUANTITY:
      return `${title} ${request.quantity}${formattedVolumeType(request)}`;
    case FVC_CHANGE_TYPE_CHANGE_PRODUCTION_DAY:
      return `${request.packingFacility.name} ${request.meal.title}: ${
        abbreviatedChangeType[request.changeType]
      } from ${formattedDate(request.shipDateFrom)} to ${formattedDate(request.shipDateTo)}`;
    default:
      return `${title}`;
  }
};

export default getQueuedChangeDescription;
