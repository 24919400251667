export const pluralize = (count, noun, suffix = 's') => {
  return `${noun}${Math.abs(count) !== 1 ? suffix : ''}`;
};

export const pluralizeWithNumber = (count, noun, suffix = 's') => {
  return `${count} ${noun}${Math.abs(count) !== 1 ? suffix : ''}`;
};

export const trimString = (string, length) => {
  return string.length > length ? `${string.substring(0, length)}...` : string;
};
