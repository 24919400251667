import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { orderByRank } from 'lib/helpers/menuManager/';

const DroppableTableBody = ({
  category,
  onUpdateRanking,
  alternativeRanking,
  retailMenuId,
  children,
}) => {
  const newRanking = useCallback(
    (meal, index) => (alternativeRanking ? meal.ranking : index + 1),
    [alternativeRanking]
  );
  const newAltRanking = useCallback(
    (meal, index) => (alternativeRanking ? index + 1 : meal.alternativeRanking),
    [alternativeRanking]
  );

  const onDragEnd = useCallback(
    (result) => {
      const { destination, source, draggableId } = result;

      const meals = Array.from(orderByRank(category.meals, alternativeRanking));

      if (!destination) {
        return;
      }

      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return;
      }

      const draggedMeal = meals.find((meal) => meal.id === parseInt(draggableId, 10));

      meals.splice(source.index, 1);
      meals.splice(destination.index, 0, draggedMeal);

      const updatedMeals = meals.map((meal, index) => ({
        ...meal,
        ranking: newRanking(meal, index),
        alternativeRanking: newAltRanking(meal, index),
      }));

      const updatedCategory = { ...category, meals: updatedMeals };

      const mealRankings = updatedMeals.map((meal) => ({
        meal_id: meal.id,
        ranking: meal.ranking,
        alternative_ranking: meal.alternativeRanking,
      }));

      onUpdateRanking(retailMenuId, updatedCategory, mealRankings);
    },
    [category, onUpdateRanking, alternativeRanking, retailMenuId, newRanking, newAltRanking]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`${category.id}`}>
        {({ innerRef, droppableProps, placeholder }) => {
          return (
            <tbody key={category.id} ref={innerRef} droppableprops={droppableProps}>
              {children}
              {placeholder}
            </tbody>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

DroppableTableBody.propTypes = {
  category: PropTypes.object.isRequired,
  onUpdateRanking: PropTypes.func.isRequired,
  alternativeRanking: PropTypes.bool.isRequired,
  retailMenuId: PropTypes.number,
  children: PropTypes.array.isRequired,
};

DroppableTableBody.defaultProps = {
  retailMenuId: null,
};

export default DroppableTableBody;
