import { GET } from 'lib/api';
import { APP_API_URI } from 'lib/constants';

const MG_URL = `${APP_API_URI}/internal/culinary/menugrid`;

export const getQuarter = async ({ currentTime }) => {
  const url = `${MG_URL}/quarter?current_time=${currentTime}`;

  const response = await GET(url);
  return response.data;
};

export const getChangeQuarter = async ({ currentQuarter, direction }) => {
  const url = `${MG_URL}/change_quarter?quarter=${currentQuarter}&direction=${direction}`;

  const response = await GET(url);
  return response.data;
};
