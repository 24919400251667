// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import { getMeals } from 'redux/comparisonTool/concept/actions';

// Components
import VersionTable from './VersionTable';

// Styles
import styles from './styles';

const mapStateToProps = (state) => ({
  viewableMeals: state.comparisonTool.concept.viewableMeals,
  activeMeals: state.comparisonTool.concept.activeMeals,
  inactiveMeals: state.comparisonTool.concept.inactiveMeals,
  conceptId: state.comparisonTool.concept.id,
  fetching: state.comparisonTool.concept.fetching,
});

const mapDispatchToProps = {
  onGetMeals: getMeals,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VersionTable));
