import { LIGHT_BLACK, MEDIUM_GREY, HC_GREEN, BLUE, WHITE } from 'lib/constants';

export default {
  root: {
    padding: '24px',
  },
  chip: {
    backgroundColor: BLUE,
    color: WHITE,
    float: 'right',
  },
  header: {
    color: LIGHT_BLACK,
    fontWeight: '500',
    fontSize: '16px',
  },
  item: { margin: '16px 0 0 0' },
  title: {
    color: LIGHT_BLACK,
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
  },
  subtitle: {
    color: MEDIUM_GREY,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
  },
  link: {
    color: HC_GREEN,
    textDecoration: 'none',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '400',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  redText: {
    color: 'red',
  },
};
