import { BORDER_GREY, LIGHT_BLACK, MEDIUM_GREY, HC_GREEN, LIGHT_GREEN, WHITE } from 'lib/constants';

export default {
  root: {
    marginBottom: '24px',
    padding: '24px',
  },
  inner: {
    padding: '16px',
  },
  buttonActive: {
    backgroundColor: LIGHT_GREEN,
    borderColor: HC_GREEN,
    color: HC_GREEN,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    padding: '16px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: LIGHT_GREEN,
    },
  },
  buttonInactive: {
    borderColor: MEDIUM_GREY,
    color: MEDIUM_GREY,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '16px',
    textTransform: 'capitalize',
  },
  buttonSubmit: {
    backgroundColor: HC_GREEN,
    borderColor: HC_GREEN,
    color: WHITE,
    float: 'right',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    margin: '24px 0 0 0 ',
    padding: '16px 48px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: WHITE,
      color: HC_GREEN,
    },
  },
  divider: {
    backgroundColor: BORDER_GREY,
    border: 'none',
    height: 1,
    margin: '24px -24px',
  },
  header: {
    color: LIGHT_BLACK,
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
  },
  headerSpaced: {
    color: LIGHT_BLACK,
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    margin: '24px 0 0 0',
  },
  hintText: {
    color: MEDIUM_GREY,
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  inputLabel: {
    color: MEDIUM_GREY,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  inputPlaceholder: {
    color: MEDIUM_GREY,
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  inputText: {
    color: LIGHT_BLACK,
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  mathSymbols: {
    color: MEDIUM_GREY,
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '24px',
    margin: '0 16px',
    textAlign: 'center',
  },
  textInput: {
    borderColor: MEDIUM_GREY,
    borderRadius: 4,
    margin: '24px 0 0 0',
  },
  alert: {
    marginBottom: '16px',
  },
};
