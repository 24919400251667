import { WHITE } from 'lib/constants';

export default {
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  container: {
    padding: '24px',
    width: '100%',
  },
  modal: {
    bgcolor: WHITE,
    padding: 16,
    position: 'absolute',
    top: '10%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
  },
  viewCompoundLink: {
    color: WHITE,
    paddingLeft: 8,
  },
};
