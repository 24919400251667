const NUM_COLUMNS = 13;

export default {
  root: {
    padding: '0px 6px 6px 6px',
    display: 'flex',
    flexDirection: 'column',
    width: `calc(100% / ${NUM_COLUMNS})`,
  },
  headerItem: {
    padding: '16px 0px 0px 0px',
  },
  list: {
    flexGrow: '1',
    minHeight: '100px',
  },
  addPlaceholder: {
    fontSize: '11px',
    textTransform: 'capitalize',
    fontWeight: 'normal',
  },
};
