import {
  CIRF_COMPOUND_INGREDIENT_RECIPE_REQUEST,
  CIRF_COMPOUND_INGREDIENT_RECIPE_SUCCESS,
  CIRF_COMPOUND_INGREDIENT_RECIPE_ERROR,
  CIRF_UPDATE_CIR_REQUEST,
  CIRF_UPDATE_CIR_SUCCESS,
  CIRF_UPDATE_CIR_ERROR,
  CIRF_UPDATE_CI_STATE_REQUEST,
  CIRF_UPDATE_CI_STATE_SUCCESS,
  CIRF_UPDATE_CI_STATE_ERROR,
  CIRF_CLEAR_MESSAGES,
} from 'lib/constants';

export const initialState = {
  compoundIngredientRecipe: null,
  fetching: false,
  submitting: false,
  error: false,
  errorMessage: null,
  success: false,
  successMessage: null,
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case CIRF_COMPOUND_INGREDIENT_RECIPE_REQUEST:
      return {
        ...state,
        error: false,
        fetching: true,
      };
    case CIRF_COMPOUND_INGREDIENT_RECIPE_SUCCESS:
      return {
        ...state,
        compoundIngredientRecipe: action.response.compoundIngredientRecipe,
        error: false,
        fetching: false,
      };
    case CIRF_COMPOUND_INGREDIENT_RECIPE_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.error.message,
        fetching: false,
      };
    case CIRF_UPDATE_CIR_REQUEST:
      return {
        ...state,
        error: false,
        success: false,
        submitting: true,
      };
    case CIRF_UPDATE_CIR_SUCCESS:
      return {
        ...state,
        compoundIngredientRecipe: action.response.compoundIngredientRecipe,
        error: false,
        submitting: false,
        success: true,
        successMessage: 'Compound Ingredient updated!',
      };
    case CIRF_UPDATE_CIR_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.error.message,
        submitting: false,
      };
    case CIRF_UPDATE_CI_STATE_REQUEST:
      return {
        ...state,
        error: false,
        success: false,
        submitting: true,
      };
    case CIRF_UPDATE_CI_STATE_SUCCESS:
      return {
        ...state,
        compoundIngredientRecipe: action.response.compoundIngredientRecipe,
        error: false,
        submitting: false,
        success: true,
        successMessage: 'Compound Ingredient updated!',
      };
    case CIRF_UPDATE_CI_STATE_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.error.message,
        submitting: false,
      };
    case CIRF_CLEAR_MESSAGES:
      return {
        ...state,
        error: false,
        success: false,
      };
    default:
      return state;
  }
};
