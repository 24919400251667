import { TextField } from '@mui/material';

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { BehavioralDiscountFormContext } from 'contexts/BehavioralDiscountToolContext';

const DisclaimerSection = ({ classes }) => {
  const { formState, actions } = useContext(BehavioralDiscountFormContext);

  return (
    <div className={classes.root}>
      <h6 className={classes.header}>Disclaimer</h6>
      <TextField
        fullWidth
        multiline
        rows={5}
        label="Disclaimer"
        variant="outlined"
        value={formState.disclaimer}
        onChange={(e) => actions.setFormState('disclaimer', e.target.value)}
        helperText="For clarity, please include the offer name with each disclaimer."
        className={classes.containers}
      />
    </div>
  );
};

DisclaimerSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default DisclaimerSection;
