import { SINGLE_MENU_DATA } from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import { getSingleMenuData } from './services';

export const fetchSingleMenuData = ({ displayGroup, menuId }) =>
  makeApiRequest({
    type: SINGLE_MENU_DATA,
    method: getSingleMenuData,
    params: { displayGroup, menuId },
  });
