import React from 'react';
import PropTypes from 'prop-types';

// This element is needed because ListItemIcon expect a React element as its children.
const ListItemVersionId = ({ meal, classes }) => {
  return <div className={classes.listItemIcon}>{meal.versionId}</div>;
};

ListItemVersionId.propTypes = {
  meal: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default ListItemVersionId;
