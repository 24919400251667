import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DELETED, OZ_WEIGHT } from 'lib/constants';

// Material Components
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';

import TextBox from '../../shared/TextBox';
import IngredientFormInputs from '../../shared/IngredientFormInputs/IngredientFormInputs';
import IngredientDropdowns from '../../shared/IngredientDropdowns/IngredientDropdowns';
import IngredientSearch from './IngredientSearch';

const IngredientCard = ({ classes, formState, handleFormState, index, cirAssignment }) => {
  const [componentIngredientSelected, setComponentIngredientSelected] = useState(
    cirAssignment.ingredient
  );
  const [ingredientAssignmentState, setIngredientAssignmentState] = useState(cirAssignment);
  const [isFirstRun, setIsFirstRun] = useState(true);

  const handleCIRAState = (name, value) => {
    const newCIRAState = { ...ingredientAssignmentState, [name]: value };
    setIngredientAssignmentState(newCIRAState);

    const newFormStateCIRAssignments = formState.compoundIngredientRecipeAssignments;
    newFormStateCIRAssignments[index] = newCIRAState;

    handleFormState({
      target: { name: 'compoundIngredientRecipeAssignments', value: newFormStateCIRAssignments },
    });
  };

  useEffect(() => {
    if (!isFirstRun) {
      setIngredientAssignmentState((currentIngredientAssignmentState) => {
        return {
          ...currentIngredientAssignmentState,
          ingredientId: componentIngredientSelected?.id,
          measure:
            componentIngredientSelected?.standardUnit === 'volume'
              ? OZ_WEIGHT
              : componentIngredientSelected?.standardUnit,
          name: componentIngredientSelected?.displayName,
          usageYield: componentIngredientSelected?.usageYield,
        };
      });
    } else {
      setIsFirstRun(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentIngredientSelected]);

  // Updates the CIRA in the formState to use the new ingredient if the ingredient is changed
  useEffect(() => {
    if (!isFirstRun) {
      handleCIRAState('ingredientId', ingredientAssignmentState.ingredientId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingredientAssignmentState.ingredientId]);

  const handleDelete = () => {
    handleCIRAState(DELETED, true);
  };

  const onAttributeChange = (event) => {
    handleCIRAState(event.target.name, event.target.value);
  };

  const onIngredientChange = (_event, values) => {
    setComponentIngredientSelected(values);
  };

  return (
    <Card className={classes.ingredientCard}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={4}>
          <TextBox
            fullWidth
            label="Seq #"
            name="sequenceNumber"
            type="number"
            onChange={onAttributeChange}
            value={ingredientAssignmentState?.sequenceNumber || ''}
          />
        </Grid>
        <Grid item xs={8}>
          <IngredientSearch
            classes={classes}
            compoundIngredientId={formState.compoundIngredientId}
            autocompleteOnChange={onIngredientChange}
            ingredientSelected={componentIngredientSelected}
            name="ingredient"
            label="Ingredient"
          />
        </Grid>
      </Grid>
      <IngredientFormInputs
        ingredientAssignmentState={ingredientAssignmentState}
        ingredientSelected={componentIngredientSelected}
        onAttributeChange={onAttributeChange}
        isCompoundIngredient
      />
      <IngredientDropdowns
        forCIForm
        ingredientAssignmentState={ingredientAssignmentState}
        ingredientSelected={componentIngredientSelected}
        handleIngredientAssignmentState={handleCIRAState}
        onAttributeChange={onAttributeChange}
      />
      <Button
        variant="outlined"
        className={classes.deleteButton}
        startIcon={<DeleteIcon />}
        onClick={handleDelete}
      >
        Remove Ingredient
      </Button>
    </Card>
  );
};

IngredientCard.propTypes = {
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  cirAssignment: PropTypes.object.isRequired,
};

export default IngredientCard;
