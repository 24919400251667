import { connect } from 'react-redux';
import {
  submitAddMeals,
  toggleAddMealsDialog,
  setSelectedMealId,
} from 'redux/menuManager/menu/actions';

import withStyles from '@mui/styles/withStyles';
import AddMealsDialog from './AddMealsDialog';
import styles from './styles';

const mapStateToProps = (state) => ({
  retailMenuId: state.menuManager.menu.id,
  posting: state.menuManager.menu.posting,
  postingFailed: state.menuManager.menu.postingFailed,
  addMealsDialogActiveCategory: state.menuManager.menu.addMealsDialogActiveCategory,
});

const mapDispatchToProps = {
  onSubmitAddMeals: submitAddMeals,
  onToggleAddMealsDialog: toggleAddMealsDialog,
  onSetSelectedMealId: setSelectedMealId,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddMealsDialog));
