// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

// Actions
import { getSwappableIngredient } from 'redux/ingredientSwapper/swappableIngredient/actions';

// Components
import IngredientSwapper from './IngredientSwapper';

import styles from './styles';

const mapStateToProps = (state) => ({
  swappableIngredient: state.ingredientSwapper.swappableIngredient,
});
const mapDispatchToProps = {
  onGetSwappableIngredient: getSwappableIngredient,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IngredientSwapper));
