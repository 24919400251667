import {
  LEMON_100,
  WHITE,
  MEDIUM_RED,
  MEDIUM_GREY,
  BORDER_GREY,
  LIGHTEST_GREY,
  LIGHT_BLACK,
  HC_GREEN,
} from 'lib/constants';

export default {
  text: {
    margin: 0,
    font: 'Roboto',
    weight: 400,
    fontSize: 16,
    letterSpacing: '0.15px',
    lineHeight: '24px',
    color: LIGHT_BLACK,
  },
  paper: {
    padding: 16,
    margin: 12,
    overflow: 'visible',
    maxWidth: '48%',
  },
  ingredientInfo: {
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  ingredientCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: WHITE,
    padding: '16px',
  },
  replacementCardContainer: {
    margin: 10,
    padding: 5,
    backgroundColor: LIGHTEST_GREY,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  replacementCardInfo: {
    flexDirection: 'row',
  },
  compoundPill: {
    marginLeft: 8,
    backgroundColor: LEMON_100,
  },
  ingredientReplacementInfo: {
    backgroundColor: LIGHTEST_GREY,
    padding: 5,
  },
  componentIngredientsHeader: {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '0.875rem',
    color: MEDIUM_GREY,
    marginTop: 0,
    paddingTop: '10px',
  },
  cirasContainer: {
    paddingLeft: '30px',
  },
  ciraCardContainer: {
    padding: '5px',
  },
  replacementCiraCardWrapper: {
    marginLeft: '10px',
    padding: '5px 5px 5px 15px',
    borderLeft: '5px solid',
    borderLeftColor: BORDER_GREY,
    borderTop: '1px solid',
    borderTopColor: BORDER_GREY,
  },
  replacementCiraCardContainer: {
    margin: 10,
    padding: 5,
    backgroundColor: LIGHTEST_GREY,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    paddingTop: '5px',
    marginLeft: '10px',
    marginBottom: '15px',
  },
  smallCaps: {
    fontVariant: 'small-caps',
    margin: 0,
    font: 'Roboto',
    weight: 400,
    fontSize: 12,
    letterSpacing: '0.15px',
    lineHeight: '20px',
    color: LIGHT_BLACK,
  },
  capitalized: {
    textTransform: 'capitalize',
  },
  replacementFormContainer: {
    display: 'flex',
  },
  replacementFormCheckboxContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  addIngredientButton: {
    color: HC_GREEN,
    margin: '0px 0px 16px 24px',
  },
  replacementIngredientButton: {
    color: MEDIUM_GREY,
    marginLeft: '16px',
    minWidth: '15%',
    height: '36px',
  },
  addReplacementCiraButton: {
    color: MEDIUM_GREY,
    marginLeft: '16px',
    minWidth: '10%',
    height: '36px',
    opacity: '1',
  },
  editReplacementCiraButton: {
    color: MEDIUM_GREY,
    marginRight: '16px',
    minWidth: '5%',
    height: '36px',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    margin: '0px 16px',
  },
  cancelButton: {
    color: '#666C73',
    marginLeft: '16px',
  },
  inactiveForm: {
    '& input': {
      backgroundColor: '#F5F5F6',
    },
    '& textarea': {
      backgroundColor: '#F5F5F6',
    },
  },
  inactiveFormTextContainer: {
    margin: '12px 0 0 0',
    minHeight: '40px',
  },
  inactiveFormTextLabel: {
    fontSize: '10px',
    lineHeight: '10px',
    color: '#666C73',
    marginLeft: '14px',
  },
  inactiveFormText: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    marginLeft: '14px',
  },
  tooltip: {
    '& .MuiTooltip-tooltip': {
      color: LIGHTEST_GREY,
      fontSize: '16px',
      display: 'flex',
      maxWidth: 'fit-content',
      zIndex: '5000',
    },
  },
  replacementFormHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: '24px',
  },
  deleteButton: {
    marginRight: 'auto',
    color: MEDIUM_RED,
    backgroundColor: WHITE,
    '&:hover': {
      color: MEDIUM_RED,
      backgroundColor: WHITE,
    },
  },
  modal: {
    padding: 16,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
  },
  deleteReplacementButton: {
    backgroundColor: MEDIUM_RED,
    color: WHITE,
    margin: '0 8px 0 16px',
    '&:hover': {
      color: MEDIUM_RED,
      backgroundColor: WHITE,
    },
  },
};
