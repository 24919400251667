import {
  BORDER_GREY,
  LIGHT_BLACK,
  LIGHT_GREY,
  MEDIUM_GREY,
  HC_GREEN,
  LIGHT_GREEN,
  WHITE,
} from 'lib/constants';
import { iconCell, tableRowGreenBorder, greyed } from 'lib/styles/comparisonTool';

export default {
  tableRowGreenBorder,
  conceptLabel: {
    color: LIGHT_GREY,
    fontWeight: '400',
  },
  conceptId: {
    color: LIGHT_BLACK,
    fontSize: '20px',
    fontWeight: '500',
  },
  title: {
    color: LIGHT_BLACK,
    fontWeight: '500',
    fontSize: '12px',
  },
  subtitle: {
    color: MEDIUM_GREY,
    fontWeight: '400',
    fontSize: '12px',
  },
  id: {
    color: LIGHT_BLACK,
    fontWeight: '500',
    fontSize: '12px',
  },
  iconCell: {
    ...iconCell,
    zIndex: '99',
    backgroundColor: WHITE,
  },
  labelColumn: {
    minWidth: '150px',
    position: 'sticky',
    left: '48px',
    backgroundColor: WHITE,
    zIndex: '99',
    borderRight: `1px solid ${BORDER_GREY}`,
  },
  contentColumn: {
    minWidth: '400px',
    backgroundColor: WHITE,
  },
  link: {
    color: HC_GREEN,
    textDecoration: 'none',
    fontSize: '12px',
    fontWeight: '400',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  highlight: {
    backgroundColor: LIGHT_GREEN,
  },
  selectVersionsButton: {
    fontSize: '12px',
    textTransform: 'capitalize',
  },
  greyed,
};
