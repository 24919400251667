import React from 'react';
import PropTypes from 'prop-types';

// Material Components
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import DisposableItem from './DisposableItem';
import SectionHeader from '../shared/SectionHeader';

const DisposableItems = ({ classes, formState, handleFormState }) => {
  const addDisposableItemAssignment = () => {
    const newItemsState = formState.disposableItemAssignments;
    const assignmentIndexes = newItemsState.map((assignment) => assignment.index);
    const nextIndex = !assignmentIndexes.length ? 0 : Math.max(...assignmentIndexes) + 1;

    newItemsState.push({ index: nextIndex });

    handleFormState({ target: { name: 'disposableItemAssignments', value: newItemsState } });
  };

  const changeDisposableItemAssignment = (assignmentAttributes) => {
    const newAssignmentsState = formState.disposableItemAssignments.map((itemAssignment) => {
      if (itemAssignment.index === assignmentAttributes.index) {
        // eslint-disable-next-line no-param-reassign
        itemAssignment = assignmentAttributes;
      }
      return itemAssignment;
    });

    handleFormState({ target: { name: 'disposableItemAssignments', value: newAssignmentsState } });
  };

  const renderDisposableItemCards = () => {
    return formState.disposableItemAssignments.map((assignment) => {
      if (assignment.deleted) {
        return null;
      }

      return (
        <DisposableItem
          key={assignment.index}
          assignment={assignment}
          classes={classes}
          onChange={changeDisposableItemAssignment}
        />
      );
    });
  };

  return (
    <Paper className={classes.paper}>
      <SectionHeader text="Disposable Items" />
      {renderDisposableItemCards()}
      <Button
        variant="outlined"
        className={classes.greenText}
        onClick={addDisposableItemAssignment}
        startIcon={<AddIcon />}
      >
        Add Item
      </Button>
    </Paper>
  );
};

DisposableItems.propTypes = {
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
};

export default DisposableItems;
