// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

// Actions
import { getIncidentable } from 'redux/incidentManager/incidentable/actions';

// Components
import IncidentManager from './IncidentManager';

import styles from './styles';

const mapStateToProps = (state) => ({
  incidentable: state.incidentManager.incidentable.incidentable,
});

const mapDispatchToProps = {
  onGetIncidentable: getIncidentable,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IncidentManager));
