import { BACKGROUND_GREY, WHITE, MATERIAL_OUTLINE_GRAY } from 'lib/constants';

export default {
  root: {
    backgroundColor: BACKGROUND_GREY,
  },
  container: {
    maxWidth: '1440px',
  },
  paper: {
    backgroundColor: WHITE,
    minHeight: '510px',
  },
  ingredientSelectorBox: {
    paddingTop: '16px',
    borderRight: `solid 1px ${MATERIAL_OUTLINE_GRAY}`,
    minHeight: '510px',
  },
  ingredientMealsBox: {
    paddingTop: '16px',
  },
};
