import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Drawer from '@mui/material/Drawer';

import IfAllowed from 'components/shared/IfAllowed';
import RetailPage from 'components/Retail/Page';

import Adjustments from './Adjustments';
import ChangeRequestForm from './ChangeRequestForm';
import DownloadStoreOrders from './DownloadStoreOrders';

const FlexibleVolumeChanges = ({ classes }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <RetailPage title="Flexible Volume Changes">
      <IfAllowed ability="editRetailFlexibleVolumeChanges">
        <Adjustments onClickAddAdjustment={() => setDrawerOpen(true)} />
      </IfAllowed>
      <IfAllowed ability="downloadRetailStoreOrders">
        <DownloadStoreOrders />
      </IfAllowed>
      <Drawer
        anchor="right"
        variant="persistent"
        open={drawerOpen}
        classes={{ paper: classes.drawerPaper }}
      >
        <ChangeRequestForm onClose={() => setDrawerOpen(false)} />
      </Drawer>
    </RetailPage>
  );
};

FlexibleVolumeChanges.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default FlexibleVolumeChanges;
