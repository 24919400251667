import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { removeMealAndUpdate } from 'redux/menugrid/menus/actions';

import IconButton from '@mui/material/IconButton';

import { truncate } from 'lodash';

const MealInfo = ({ menuSelection, classes }) => {
  const dispatch = useDispatch();

  const handleRemoveMeal = () => {
    dispatch(removeMealAndUpdate(menuSelection));
  };

  return (
    <div className={classes.root}>
      <div>
        <img src={menuSelection.photo} alt={menuSelection.title} className={classes.mealImage} />
        <div className={classes.mealImageHoverText}>
          <span className={classes.mealImageHoverTitle}>
            {menuSelection.mealId} -{' '}
            {truncate(menuSelection.title, {
              length: 40,
              separator: ' ',
            })}
          </span>
        </div>
      </div>
      {menuSelection.menuType === 'staging' && (
        <IconButton
          aria-label="Close"
          className={classes.close}
          onClick={() => handleRemoveMeal()}
          size="large"
        >
          x
        </IconButton>
      )}
    </div>
  );
};

MealInfo.propTypes = {
  menuSelection: PropTypes.shape({
    photo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    locked: PropTypes.bool,
    menuType: PropTypes.string,
    mealId: PropTypes.number.isRequired,
  }).isRequired,
  classes: PropTypes.object.isRequired,
};

export default MealInfo;
