import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import AddMealsErrorMessageSection from 'components/MenuManager/AddMealsErrorMessageSection';

const AddMealsDialog = ({
  retailMenuId,
  retailCategoryId,
  retailCategoryName,
  posting,
  postingFailed,
  onSubmitAddMeals,
  onToggleAddMealsDialog,
  onSetSelectedMealId,
  addMealsDialogActiveCategory,
  classes,
}) => {
  const [culinaryVersions, setCulinaryVersions] = React.useState(false);

  const handleOnToggleAddMealsDialog = () => {
    onToggleAddMealsDialog(retailCategoryId);
  };

  const handleClose = () => {
    setCulinaryVersions(null);
    onToggleAddMealsDialog(null);
  };

  const handleOnAddMealsBlur = (e) => {
    setCulinaryVersions(e.target.value);
  };

  const handleOnSubmitAddMeals = () => {
    onSetSelectedMealId(null);
    onSubmitAddMeals(retailMenuId, retailCategoryId, culinaryVersions);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleOnToggleAddMealsDialog}>
        Add Meals
      </Button>
      <Dialog
        open={addMealsDialogActiveCategory === retailCategoryId}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add meals to {retailCategoryName}</DialogTitle>
        <DialogContent>
          <TextareaAutosize
            autoFocus
            margin="dense"
            placeholder="Culinary Version ID"
            type="text"
            multiline="true"
            minRows="10"
            onBlur={handleOnAddMealsBlur}
            className={
              classes.addMealsTextArea + (postingFailed ? ` ${classes.addMealsPostingFailed}` : '')
            }
          />
        </DialogContent>

        <AddMealsErrorMessageSection />

        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={posting}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleOnSubmitAddMeals}
            disabled={posting}
            className="addMealsSubmit"
          >
            Add Meals
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddMealsDialog.propTypes = {
  retailMenuId: PropTypes.number.isRequired,
  retailCategoryName: PropTypes.string.isRequired,
  retailCategoryId: PropTypes.number.isRequired,
  posting: PropTypes.bool,
  postingFailed: PropTypes.bool,
  onSetSelectedMealId: PropTypes.func.isRequired,
  onSubmitAddMeals: PropTypes.func.isRequired,
  onToggleAddMealsDialog: PropTypes.func.isRequired,
  addMealsDialogActiveCategory: PropTypes.number,
  classes: PropTypes.object,
};

AddMealsDialog.defaultProps = {
  posting: false,
  postingFailed: false,
  addMealsDialogActiveCategory: null,
  classes: {},
};

export default AddMealsDialog;
