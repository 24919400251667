import React from 'react';
import PropTypes from 'prop-types';
import { noMenus, formattedMenu } from 'lib/helpers/comparisonTool';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';

import ListItemVersionId from '../ListItemVersionId';

const MealListItem = ({ meal, classes, checkedMeals, handleCheck }) => {
  const labelId = `checkbox-list-label-${meal.versionId}`;
  return (
    <>
      <ListItem className={classes.listItem}>
        <ListItemIcon>
          <ListItemVersionId meal={meal} classes={classes} />
        </ListItemIcon>
        <ListItemText className={classes.listItemTextBox} id={labelId}>
          <div>
            <div className={classes.listItemText}>{meal.title}</div>
            {noMenus(meal) ? (
              <div className={classes.listItemText}>Unassigned</div>
            ) : (
              <div>
                {meal.menus.map((menu) => (
                  <div key={`${menu.type}-${menu.id}`} className={classes.listItemText}>
                    {formattedMenu(menu)}
                  </div>
                ))}
              </div>
            )}
          </div>
        </ListItemText>
        <ListItemSecondaryAction>
          <Checkbox
            inputProps={{ 'aria-labelledby': labelId }}
            onChange={handleCheck(meal.versionId)}
            checked={checkedMeals.indexOf(meal.versionId) !== -1}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
};

MealListItem.propTypes = {
  meal: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  checkedMeals: PropTypes.array.isRequired,
  handleCheck: PropTypes.func.isRequired,
};

export default MealListItem;
