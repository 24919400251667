import { BORDER_GREY, LIGHT_BLACK, BLUE_100, RED, ORANGE_100, HC_GREEN } from 'lib/constants';

export default {
  container: {
    padding: 24,
  },
  checkbox: {
    margin: 2,
    padding: 2,
  },
  paper: {
    padding: 16,
    overflow: 'visible',
  },
  paperWithBottomMargin: {
    padding: 16,
    marginBottom: 24,
  },
  buttonContainer: {
    padding: 24,
  },
  label: {
    font: 'Roboto',
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: '0.15px',
    color: LIGHT_BLACK,
    marginBottom: 0,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  text: {
    font: 'Roboto',
    weight: 400,
    fontSize: 12,
    letterSpacing: '0.15px',
    lineHeight: '20px',
    color: LIGHT_BLACK,
  },
  hr: {
    color: BORDER_GREY,
    margin: '16px 0',
    padding: 0,
    height: '1px',
  },
  channelCard: {
    padding: 16,
    marginBottom: 24,
    overflow: 'visible',
  },
  eligibility: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: LIGHT_BLACK,
  },
  retailEligibilityContainer: {
    borderTop: 'solid 1px #D8DADC',
    paddingTop: 24,
    marginTop: 24,
  },
  packagingName: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '0.15px',
    color: LIGHT_BLACK,
    display: 'flex',
    alignItems: 'center',
  },
  successIcon: {
    color: HC_GREEN,
    marginRight: '10px',
  },
  warningIcon: {
    color: ORANGE_100,
    marginRight: '10px',
  },
  errorIcon: {
    color: RED,
    width: '20px',
    height: '20px',
    marginRight: '10px',
  },
  showHide: {
    color: BLUE_100,
    margin: '2px 0px',
    padding: 0,
    fontSize: 12,
  },
  ul: {
    listStyleType: 'none',
    padding: 0,
    margin: '4px 0px',
  },
  productTypeCard: {
    padding: 16,
    marginBottom: 24,
    overflow: 'visible',
  },
};
