// Styles

// Dependencies
import { connect } from 'react-redux';

// Actions
import { updateRanking } from 'redux/menuManager/menu/actions';

import DroppableTableBody from './DroppableTableBody';

const mapStateToProps = (state) => {
  return {
    retailMenuId: state.menuManager.allMenus.selectedMenuId,
  };
};

const mapDispatchToProps = {
  onUpdateRanking: updateRanking,
};

export default connect(mapStateToProps, mapDispatchToProps)(DroppableTableBody);
