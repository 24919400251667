import { map } from 'lodash';

export const sanitizedEquipmentItems = (arrayOfEquipmentItems) => {
  return map(arrayOfEquipmentItems, (equipmentItems) =>
    map(equipmentItems, (equipmentItem) => {
      return [equipmentItem];
    })
  );
};

export const setHighlightEquipmentItem = (occurrences, meals) => {
  if (occurrences.length === 0) return false;
  return occurrences.length !== meals.length;
};
