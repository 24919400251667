import { WHITE, BORDER_GREY, BACKGROUND_GREY } from 'lib/constants';

export default {
  root: {
    maxWidth: '100%',
    margin: '16px 10px 10px 10px',
    padding: '0px 8px 8px 8px',
    backgroundColor: BACKGROUND_GREY,
    '& > div': {
      backgroundColor: WHITE,
      border: `1px solid ${BORDER_GREY}`,
      borderRadius: '4px',
      marginBottom: '16px',
    },
  },
  loading: {
    textAlign: 'center',
    padding: '50px',
  },
  csvRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '8px',
    margin: '10px 10px 0px 4px',
  },
};
