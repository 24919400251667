import { MM_TOGGLE_SNACKBAR } from 'lib/constants';

export const initialState = {
  open: false,
  message: null,
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case MM_TOGGLE_SNACKBAR:
      return {
        ...state,
        open: action.open,
        message: action.message,
      };
    default:
      return state;
  }
};
