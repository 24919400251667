import { combineReducers } from 'redux';

import concept from './concept/reducers';
import selectVersions from './selectVersions/reducers';
import mealVersionDataCopier from './mealVersionDataCopier/reducers';

export default combineReducers({
  concept,
  mealVersionDataCopier,
  selectVersions,
});
