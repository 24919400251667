import { APP_API_URI } from 'lib/constants';
import { GET, POST } from 'lib/api';

export const fetchIngredient = async ({ ingredientId, showLiveMeals, showUnassignedMeals }) => {
  const response = await GET(`${APP_API_URI}/internal/culinary/ingredient_swapper/ingredient`, {
    params: {
      ingredient_id: ingredientId,
      show_live_meals: showLiveMeals,
      show_unassigned_meals: showUnassignedMeals,
    },
  });

  return response.data;
};

export const swapIngredient = async ({ swappableIngredientAssignment, targetIngredient }) => {
  const url = `${APP_API_URI}/internal/culinary/ingredient_swapper/swap_ingredient`;

  const response = await POST(url, {
    swappable_ingredient_assignment_id: swappableIngredientAssignment.id,
    target_ingredient_id: targetIngredient.id,
    display_name: targetIngredient.displayName,
    usage_yield: targetIngredient.usageYield,
    quantity: targetIngredient.quantity,
    measure: targetIngredient.measure,
    portion_method: targetIngredient.portionMethod,
    ingredient_packaging_id: targetIngredient.ingredientPackagingId,
  });

  return response.data;
};
