import { BORDER_GREY, MEDIUM_GREY } from 'lib/constants';

export default {
  checkboxLabel: {
    color: MEDIUM_GREY,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
  },
  divider: {
    backgroundColor: BORDER_GREY,
    border: 'none',
    height: 1,
    margin: '24px 0px',
  },
  root: {
    padding: '24px',
  },
};
