export default {
  tableCell: {
    padding: 5,
  },
  dragIconCell: {
    padding: '0px 0px 0px 15px',
  },
  actionButtonCell: {
    padding: '0px 15px 0px 0px',
  },
  actionButtonContainer: {
    display: 'inline-flex',
  },
  hidden: {
    visibility: 'hidden',
  },
  visible: {
    visibility: 'visible',
  },
};
