// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import { mealShape } from 'lib/helpers/comparisonTool/propShapes';

// Material
import Button from '@mui/material/Button';

// Components
import SelectVersions from '../SelectVersions';

const SelectVersionsButton = ({
  activeMeals,
  inactiveMeals,
  openSelectVersions,
  onSetOpenSelectVersions,
  classes,
}) => {
  // Handler methods
  const handleClickOpenSelectVersions = () => {
    onSetOpenSelectVersions(true);
  };

  const handleCloseSelectVerisons = () => {
    onSetOpenSelectVersions(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpenSelectVersions}
        className={classes.selectVersionsButton}
      >
        Select Versions
      </Button>
      <SelectVersions
        activeMeals={activeMeals}
        inactiveMeals={inactiveMeals}
        openSelectVersions={openSelectVersions}
        handleCloseSelectVerisons={handleCloseSelectVerisons}
      />
    </>
  );
};

SelectVersionsButton.propTypes = {
  activeMeals: PropTypes.arrayOf(mealShape).isRequired,
  inactiveMeals: PropTypes.arrayOf(mealShape).isRequired,
  openSelectVersions: PropTypes.bool.isRequired,
  onSetOpenSelectVersions: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default SelectVersionsButton;
