// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import ChefRow from './ChefRow';

// Styles
import styles from './styles';

export default withStyles(styles)(ChefRow);
