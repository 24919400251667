// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import FlexibleVolumeChanges from './FlexibleVolumeChanges';

// Styles
import styles from './styles';

export default withStyles(styles)(FlexibleVolumeChanges);
