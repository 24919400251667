import { GET_QUARTER, CHANGE_QUARTER } from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import { getQuarter, getChangeQuarter } from './services';

export const fetchQuarter = (currentTime) =>
  makeApiRequest({ type: GET_QUARTER, method: getQuarter, params: { currentTime } });

export const changeQuarter = (currentQuarter, direction) =>
  makeApiRequest({
    type: CHANGE_QUARTER,
    method: getChangeQuarter,
    params: { currentQuarter, direction },
  });
