import withStyles from '@mui/styles/withStyles';

import { connect } from 'react-redux';
import AppConfigurationTool from './AppConfigurationTool';
import styles from './styles';
import { getAllAppConfigurations } from '../../redux/appConfigurationTool/actions';

const mapStateToProps = (state) => ({
  applicationConfig: state.appConfigurationTool.applicationConfig.sort((groupA, groupB) => {
    return groupA.groupName.localeCompare(groupB.groupName);
  }),
});

const mapDispatchToProps = {
  onGetAllAppConfigurations: getAllAppConfigurations,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AppConfigurationTool));
