import { LIGHT_BLACK } from 'lib/constants';

export default {
  checkbox: {
    margin: 2,
    padding: 2,
  },
  inputLabel: {
    font: 'Roboto',
    weight: 400,
    fontSize: 14,
    color: LIGHT_BLACK,
  },
};
