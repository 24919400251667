import { LIGHT_GREEN, LIGHT_BLACK, MEDIUM_GREY, LIGHTEST_GREY } from 'lib/constants';

import {
  rowLabel,
  iconCell,
  icon,
  listContentSameBlock,
  expandingCell,
  collapse,
  tableRowGreenBorder,
  greyed,
} from 'lib/styles/comparisonTool';

export default {
  rowLabel,
  iconCell,
  icon,
  listContentSameBlock,
  expandingCell,
  collapse,
  tableRowGreenBorder,
  greyed,
  disposableItem: {
    color: LIGHT_BLACK,
    fontSize: '12px',
    fontWeight: '400',
    padding: '4px 4px',
    margin: '4px 0px',
    '& p': {
      margin: '0px',
    },
  },
  disposableQuantity: {
    color: MEDIUM_GREY,
    marginLeft: '8px',
  },
  disposableItemMissing: {
    backgroundColor: LIGHTEST_GREY,
    color: MEDIUM_GREY,
  },
  highlight: {
    backgroundColor: LIGHT_GREEN,
  },
};
