import { combineReducers } from 'redux';

import csvs from './csvs/reducers';
import menus from './menus/reducers';
import quarter from './quarter/reducers';
import singleMenu from './singleMenu/reducers';

export default combineReducers({
  csvs,
  menus,
  quarter,
  singleMenu,
});
