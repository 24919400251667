import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const RemoveMealDialog = ({
  open,
  onDialogClose,
  onSubmitRemoveMeal,
  storeOrderCount,
  countUnits,
}) => (
  <Dialog open={open} onClose={onDialogClose}>
    <DialogTitle id="form-dialog-title">Remove meal from menu?</DialogTitle>
    {storeOrderCount > 0 && (
      <DialogContent>
        There are {storeOrderCount} pending store orders representing {countUnits} units on this
        meal. These orders will be deleted, and the forecasting team will be alerted.
      </DialogContent>
    )}
    <DialogActions>
      <Button onClick={onDialogClose}>Cancel</Button>
      <Button onClick={onSubmitRemoveMeal} color="primary">
        Remove
      </Button>
    </DialogActions>
  </Dialog>
);

RemoveMealDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  onSubmitRemoveMeal: PropTypes.func.isRequired,
  storeOrderCount: PropTypes.number.isRequired,
  countUnits: PropTypes.number.isRequired,
};

export default RemoveMealDialog;
