// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import PackingFacilitySelector from './PackingFacilitySelector';

// Styles
import styles from './styles';

export default withStyles(styles)(PackingFacilitySelector);
