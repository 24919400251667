// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import WhatYouNeedRow from './WhatYouNeedRow';

// Styles
import styles from './styles';

export default withStyles(styles)(WhatYouNeedRow);
