import React from 'react';

import PropTypes from 'prop-types';

import BannerSelector from './BannerSelector';
import BannerPreview from './BannerPreview';

const BannerSection = ({ classes, previewImage, filename }) => {
  return (
    <div className={classes.root}>
      <h6 className={classes.header}>Banner Display</h6>
      <BannerPreview previewImage={previewImage} />
      <BannerSelector filename={filename} previewImage={previewImage} />
    </div>
  );
};

BannerSection.defaultProps = {
  previewImage: null,
  filename: '',
};

BannerSection.propTypes = {
  classes: PropTypes.object.isRequired,
  previewImage: PropTypes.string,
  filename: PropTypes.string,
};

export default BannerSection;
