import React from 'react';
import PropTypes from 'prop-types';
import useAllowed from 'lib/useAllowed';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import { orderByRank } from 'lib/helpers/menuManager/';
import clsx from 'clsx';
import MealRow from '../MealRow';
import AddMealsDialog from '../../AddMealsDialog';
import DroppableTableBody from '../DroppableTableBody';

const CategoryCard = ({ classes, category, locked, alternativeRanking }) => (
  <Paper
    className={clsx(classes.assortmentCard, alternativeRanking && classes.alternateRankCard)}
    elevation={0}
    variant="outlined"
  >
    <Toolbar className={classes.tableHeader}>
      <div>
        <span className={classes.categoryTitle}>{category.name}</span>
        <span className={classes.subtitle}>{category.mealsCount} meals</span>
      </div>
      {!useAllowed('editRetailMenuManager') || locked ? null : (
        <AddMealsDialog retailCategoryId={category.id} retailCategoryName={category.name} />
      )}
    </Toolbar>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell size="small" />
          <TableCell size="small" className={classes.columnTitle}>
            {alternativeRanking ? 'Alternative Rank' : 'Primary Rank'}
          </TableCell>
          <TableCell size="small" className={classes.columnTitle}>
            Meal
          </TableCell>
          <TableCell size="small" className={classes.columnTitle} align="left">
            Product Type
          </TableCell>
          <TableCell size="small" className={classes.columnTitle} align="left">
            Version ID
          </TableCell>
          <TableCell size="small" className={classes.columnTitle} align="left">
            Meal ID
          </TableCell>
          <TableCell size="small" className={classes.columnTitle}>
            {alternativeRanking ? 'Primary Rank' : 'Alternative Rank'}
          </TableCell>
          <TableCell size="small" />
        </TableRow>
      </TableHead>
      <TableBody
        component={DroppableTableBody}
        category={category}
        alternativeRanking={alternativeRanking}
      >
        {orderByRank(category.meals, alternativeRanking).map((meal, index) => (
          <MealRow key={meal.id} category={category} meal={meal} index={index} />
        ))}
      </TableBody>
    </Table>
  </Paper>
);

CategoryCard.propTypes = {
  classes: PropTypes.object.isRequired,
  locked: PropTypes.bool.isRequired,
  category: PropTypes.object.isRequired,
  alternativeRanking: PropTypes.bool.isRequired,
};

export default CategoryCard;
