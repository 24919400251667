import { HC_GREEN, BORDER_GREY, LIGHT_GREEN } from 'lib/constants';

export default {
  root: {
    position: 'relative',
    margin: '6px 0px 6px 0px',
    minHeight: '60px',
    marginBottom: '0.1875em',
    overflow: 'hidden',
    fontSize: '0.1em',
  },
  mealImage: {
    position: 'absolute',
    left: '0',
    right: '0',
    margin: '0 auto',
    top: '50%',
    transform: 'translateY(-50%)',
    textAlign: 'center',
    fontSize: 10,
    maxWidth: '100%',
    '&:hover': {
      opacity: '0',
    },
  },
  mealImageHoverText: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '0 auto',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: 10,
    maxWidth: '100%',
    color: HC_GREEN,
    backgroundColor: LIGHT_GREEN,
    minHeight: '60px',
    overflow: 'hidden',
    border: `1px solid ${BORDER_GREY}`,
    opacity: '0',
    borderRadius: '4px',
    '&:hover': {
      opacity: '1',
    },
  },
  mealImageHoverTitle: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '6px',
    top: '40%',
    transform: 'translateY(-50%)',
    textAlign: 'center',
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    fontSize: 11,
    width: 10,
    height: 10,
  },
};
