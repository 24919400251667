import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Radio,
  Container,
  Drawer,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

import { BehavioralDiscountFormContext } from 'contexts/BehavioralDiscountToolContext';

const MealBadgeSection = ({ classes }) => {
  const { formState, actions } = useContext(BehavioralDiscountFormContext);

  const [mealBadgeSelectorOpen, setMealBadgeSelectorOpen] = useState(false);
  const [searchMealBadges, setSearchMealBadges] = useState(formState.formData.mealBadges);

  const handleClose = () => [
    setMealBadgeSelectorOpen(false),
    setSearchMealBadges(formState.formData.mealBadges),
  ];

  const handleRemove = () => {
    actions.setFormState('mealBadgeId', null);
  };

  const handleClick = (mealBadgeId) => {
    actions.setFormState('mealBadgeId', mealBadgeId);

    if (mealBadgeId === formState.mealBadgeId) {
      actions.setFormState('mealBadgeId', null);
    }
  };

  const findNameMealBadgeById = (id) => formState.formData.mealBadges.find((mb) => mb.id === id);

  const requestSearch = (searchTerm) => {
    setSearchMealBadges(
      formState.formData.mealBadges.filter((mealBadge) =>
        mealBadge.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  useEffect(() => {
    setSearchMealBadges(formState.formData.mealBadges);
  }, [formState.formData.mealBadges]);

  return (
    <div className={classes.root}>
      <h6 className={classes.header}>Display a Meal Card Badge</h6>
      <Container disableGutters maxWidth={false} className={classes.container}>
        <h6 className={classes.label}>Display a badge on eligible reward meals (optional)</h6>
        {formState.mealBadgeId && formState.formData.mealBadges.length !== 0 && (
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow className={classes.tableRow}>
                  <TableCell
                    component="th"
                    id={`title-${findNameMealBadgeById(formState.mealBadgeId)}`}
                    scope="row"
                    padding="none"
                    className={classes.imageRow}
                  >
                    <img
                      src={findNameMealBadgeById(formState.mealBadgeId).icon.url}
                      alt={findNameMealBadgeById(formState.mealBadgeId).name}
                      className={classes.image}
                    />
                  </TableCell>
                  <TableCell className={classes.nameCell}>
                    <div className={classes.mealBadgeName}>
                      {findNameMealBadgeById(formState.mealBadgeId).name}
                    </div>
                  </TableCell>
                  <TableCell align="right" className={classes.trashIconCell}>
                    <IconButton onClick={() => handleRemove()} size="large">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Container
          className={formState.mealBadgeId ? classes.hidden : classes.visible}
          disableGutters
        >
          <Button
            disabled={
              formState.discountTarget === 'basket' ||
              formState.discountTarget === 'pickable_item' ||
              formState.discountTarget === 'shipping'
            }
            variant="outlined"
            color="primary"
            onClick={() => setMealBadgeSelectorOpen(true)}
            startIcon={<SearchIcon className={classes.searchIcon} />}
          >
            SEARCH ALL BADGES
          </Button>
        </Container>
        <Drawer
          anchor="right"
          open={mealBadgeSelectorOpen}
          classes={{ paper: classes.drawerPaper }}
        >
          <Container key="bd_meal_badge_selector">
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              spacing={0}
              height="100vh"
            >
              <Grid item xs={12} maxHeight="12vh">
                <Box className={classes.titleBar}>
                  Add a Badge
                  <IconButton
                    onClick={() => handleClose()}
                    className={classes.closeBtn}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search..."
                  type="search"
                  onInput={(e) => requestSearch(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} maxHeight="81vh" overflow="hidden" sx={{ overflowY: 'auto' }}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {searchMealBadges.map((mealBadge) => (
                        <TableRow
                          key={mealBadge.id}
                          hover
                          onClick={() => handleClick(mealBadge.id)}
                          role="checkbox"
                          aria-checked={mealBadge.id === formState.mealBadgeId}
                          selected={mealBadge.id === formState.mealBadgeId}
                        >
                          <TableCell padding="checkbox">
                            <Radio checked={mealBadge.id === formState.mealBadgeId} />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={`title-${mealBadge.id}`}
                            scope="row"
                            padding="none"
                          >
                            <img
                              src={mealBadge.icon.url}
                              alt={mealBadge.name}
                              className={classes.image}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={`title-${mealBadge.id}`}
                            scope="row"
                            padding="none"
                          >
                            {mealBadge.name}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="flex-start"
                width="100%"
                minHeight="50px"
                maxHeight="7vh"
              >
                <Button
                  onClick={() => handleClose()}
                  variant="contained"
                  disabled={!formState.mealBadgeId}
                  sx={{ width: '100%', height: '40px', marginTop: '8px' }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Drawer>
      </Container>
    </div>
  );
};

MealBadgeSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default MealBadgeSection;
