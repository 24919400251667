// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import LandingPage from './LandingPage';

// Styles
import styles from './styles';

export default withStyles(styles)(LandingPage);
