// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
// Actions
import { getAllMenus, setSelectedMenuId } from 'redux/menuManager/allMenus/actions';
// Components
import MenuSelector from './MenuSelector';
// Styles
import styles from './styles';

const mapStateToProps = (state) => {
  return {
    fetching: state.menuManager.allMenus.fetching,
    selectedMenuId: state.menuManager.allMenus.selectedMenuId,
    allMenus: state.menuManager.allMenus.allMenus,
  };
};

const mapDispatchToProps = {
  onGetAllMenus: getAllMenus,
  onSetSelectedMenuId: setSelectedMenuId,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuSelector));
