import { LIGHT_BLACK } from 'lib/constants';

export default {
  container: {
    padding: 24,
  },
  card: {
    padding: 16,
    overflow: 'visible',
  },
  h2: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: LIGHT_BLACK,
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: LIGHT_BLACK,
  },
};
