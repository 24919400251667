import { APP_API_URI } from 'lib/constants';
import { POST } from 'lib/api';

export const CT_URL = `${APP_API_URI}/internal/culinary/comparison_tool`;
export const updateMeals = async (param) => {
  const queryUrl = `${CT_URL}/update_meal_version_data`;
  const response = await POST(
    queryUrl,
    {},
    {
      params: {
        source_meal_id: param.sourceMealId,
        target_meal_ids: param.targetMealIds,
      },
    }
  );

  return response.data;
};
