import { useCallback } from 'react';
import debounce from 'lodash/debounce';

export default (fn, wait = 200, maxWait = 350, dependencies = []) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedFunction = useCallback(
    debounce(fn, wait, {
      trailing: true,
      maxWait,
    }),
    dependencies
  );

  return delayedFunction;
};
