// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import Categories from './Categories';

// Styles
import styles from './styles';

export default withStyles(styles)(Categories);
