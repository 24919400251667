import React from 'react';
import Grid from '@mui/material/Grid';
import { Loading } from 'components/shared';
import { useAggregatedData } from './CompoundIngredientsQueries';
import StateCountCard from './StateCountCard';

const StateCountCards = () => {
  const {
    data: aggregatedData,
    isPending: isPendingAggregatedData,
    isSuccess: isSuccessAggregatedData,
  } = useAggregatedData();

  if (isPendingAggregatedData) {
    return <Loading />;
  }

  return (
    isSuccessAggregatedData && (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <StateCountCard title="WIP" value="wip" count={aggregatedData.aggregatedWipCount} />
        </Grid>
        <Grid item xs={4}>
          <StateCountCard
            title="Needs Approval"
            value="needs_approval"
            count={aggregatedData.aggregatedNeedsApprovalCount}
          />
        </Grid>
        <Grid item xs={4}>
          <StateCountCard
            title="Needs Review"
            value="needs_review"
            count={aggregatedData.aggregatedNeedsReviewCount}
          />
        </Grid>
      </Grid>
    )
  );
};

export default StateCountCards;
