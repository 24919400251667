// Dependencies
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

import { Box, Collapse, Container, Grid, IconButton, Typography } from '@mui/material';

import { ChevronRight, ExpandMore } from '@mui/icons-material';

// Components
import MealCard from '../MealCard';
import AddMealsDialog from '../AddMealsDialog';

const StagingArea = ({ menuSelections, stagingGroup, displayGroup, classes }) => {
  const [openStagingSection, setOpenStagingSection] = useState(false);

  const handleStagingSectionClick = () => {
    setOpenStagingSection(!openStagingSection);
  };

  const renderMealCards = () => {
    return menuSelections.map((menuSelection, index) => {
      return (
        <MealCard
          key={menuSelection.id}
          menuSelection={{ ...menuSelection, menuType: 'staging' }}
          index={index}
          displayGroup={displayGroup}
        />
      );
    });
  };

  return (
    <Container className={classes.root}>
      <Grid container alignItems="center" justifyContent="space-between" direction="row">
        <Typography className={classes.title}>
          Staging Area
          <IconButton
            className={classes.iconButton}
            onClick={handleStagingSectionClick}
            size="large"
          >
            {openStagingSection ? (
              <ExpandMore className={classes.icon} />
            ) : (
              <ChevronRight className={classes.icon} />
            )}
          </IconButton>
        </Typography>
        <AddMealsDialog stagingGroup={stagingGroup} displayGroup={displayGroup} />
      </Grid>

      <Collapse in={openStagingSection} unmountOnExit>
        <Droppable droppableId={`staging-${String(stagingGroup.id)}`} direction="horizontal">
          {(provided) => (
            <Box
              className={classes.list}
              data-testid="stagingArea"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {renderMealCards()}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </Collapse>
    </Container>
  );
};

StagingArea.propTypes = {
  menuSelections: PropTypes.array.isRequired,
  stagingGroup: PropTypes.object.isRequired,
  displayGroup: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default StagingArea;
