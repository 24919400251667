import { WHITE, BORDER_GREY, LIGHT_BLACK, HC_GREEN, LIGHT_GREY } from 'lib/constants';

export default {
  root: {
    maxWidth: '100%',
    margin: '16px 10px 10px 10px',
    backgroundColor: WHITE,
    border: `1px solid ${BORDER_GREY}`,
    borderRadius: '4px',
    padding: '8px 8px 24px 8px',
    fontFamily: 'Roboto',
    '& a': {
      color: LIGHT_BLACK,
    },
  },
  loading: {
    textAlign: 'center',
    padding: '50px',
  },
  noMenuError: {
    textAlign: 'center',
  },
  separator: {
    color: LIGHT_GREY,
    margin: '0 12px 0 12px',
  },
  menu: { fontSize: '16px', fontWeight: '500', color: LIGHT_BLACK },
  menuDate: { fontSize: '16px', fontWeight: '500', color: LIGHT_GREY, margin: '0 12px 0 8px' },
  arrowButton: { color: LIGHT_GREY },
  arrow: { width: '14px', height: '14px' },
  adminLink: {
    marginRight: '12px',
    '& a': {
      color: HC_GREEN,
      fontSize: '14px',
      fontWeight: '400',
    },
  },
  displayGroup: {
    textTransform: 'capitalize',
    fontSize: '16px',
    fontWeight: '500',
    color: LIGHT_GREY,
    margin: '0 8px 0 8px',
  },
};
