import {
  FVC_CHANGE_TYPE_SET_QUANTITY,
  FVC_CHANGE_TYPE_TRANSFER_QUANTITY,
  FVC_CHANGE_TYPE_ADD_SUBTRACT_QUANTITY,
  FVC_CHANGE_TYPE_CHANGE_PRODUCTION_DAY,
} from 'lib/constants';

const getChangeRequestParams = (changeRequestData) => {
  switch (changeRequestData && changeRequestData.changeType) {
    case FVC_CHANGE_TYPE_SET_QUANTITY:
      return {
        change_type: changeRequestData.changeType,
        ship_date: changeRequestData.shipDate,
        packing_facility_id: changeRequestData.packingFacility.id,
        geography: changeRequestData.geography,
        meal_id: changeRequestData.meal.id,
        quantity: changeRequestData.quantity,
        balancer: changeRequestData.balancer.value,
        explanation: changeRequestData.explanation,
      };
    case FVC_CHANGE_TYPE_TRANSFER_QUANTITY:
      return {
        change_type: changeRequestData.changeType,
        ship_date: changeRequestData.shipDate,
        packing_facility_id: changeRequestData.packingFacility.id,
        geography_from: changeRequestData.geographyFrom,
        geography_to: changeRequestData.geographyTo,
        meal_id: changeRequestData.meal.id,
        quantity: changeRequestData.quantity,
        balancer: changeRequestData.balancer.value,
        explanation: changeRequestData.explanation,
      };
    case FVC_CHANGE_TYPE_ADD_SUBTRACT_QUANTITY:
      return {
        change_type: changeRequestData.changeType,
        ship_date: changeRequestData.shipDate,
        packing_facility_id: changeRequestData.packingFacility.id,
        geography: changeRequestData.geography,
        meal_id: changeRequestData.meal.id,
        operation: changeRequestData.selectedOperation.value,
        quantity: changeRequestData.quantity,
        volume_type: changeRequestData.selectedVolumeType.value,
        balancer: changeRequestData.balancer.value,
        explanation: changeRequestData.explanation,
      };
    case FVC_CHANGE_TYPE_CHANGE_PRODUCTION_DAY:
      return {
        change_type: changeRequestData.changeType,
        ship_date_from: changeRequestData.shipDateFrom,
        ship_date_to: changeRequestData.shipDateTo,
        packing_facility_id: changeRequestData.packingFacility.id,
        geography: changeRequestData.geography,
        meal_id: changeRequestData.meal.id,
        quantity: changeRequestData.quantity,
        balancer: changeRequestData.balancer.value,
        explanation: changeRequestData.explanation,
      };
    default:
      return null;
  }
};

export default getChangeRequestParams;
