import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';

import CategoryRow from '../CategoryRow';

const CategoryTable = ({ categories, classes, handleEditClick }) => {
  const [showArchived, setShowArchived] = useState(false);

  const filteredCategories = categories.filter((category) => category.status !== 'archived');
  const displayCategories = showArchived ? categories : filteredCategories;
  const handleChange = () => setShowArchived(!showArchived);

  return (
    <Paper className={classes.productLineCard} elevation={0} variant="outlined">
      <Toolbar role="rowgroup" className={classes.tableHeader}>
        <Typography variant="h5">Manage Product Lines</Typography>
        <FormControlLabel
          control={<Switch role="switch" onChange={handleChange} checked={showArchived} />}
          label="Show Archived Product Lines"
        />
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell size="small" />
            <TableCell size="small" className={classes.columnTitle}>
              ID
            </TableCell>
            <TableCell size="small" className={classes.columnTitle} align="left">
              Product Line
            </TableCell>
            <TableCell size="small" className={classes.columnTitle} align="left">
              NetSuite Line of Business Internal ID
            </TableCell>
            <TableCell size="small" className={classes.columnTitle} align="left">
              Status
            </TableCell>
            <TableCell />
            <TableCell size="small" className={classes.columnTitle} align="left">
              Last Updated
            </TableCell>
            <TableCell size="small" />
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {displayCategories &&
            displayCategories.map((category) => (
              <CategoryRow
                key={category.id}
                category={category}
                handleEditClick={handleEditClick}
              />
            ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

CategoryTable.propTypes = {
  categories: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  handleEditClick: PropTypes.func.isRequired,
};

export default CategoryTable;
