import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useAllowed from 'lib/useAllowed';

// Material Components
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import CompactCirasContainer from './CompactCirasContainer';
import { ACTIONS, ReplacementsTabContext } from '../reducer';
import CompactCiraCard from './CompactCiraCard';

const CompactReplacementCard = ({
  classes,
  ingredientAssignment,
  ingredientAssignmentIndex,
  replacementIngredientAssignment,
  replacementIndex,
}) => {
  const { state, dispatch } = useContext(ReplacementsTabContext);
  const { allCardsExpanded, expandedCards, collapsedCards } = state;
  const replacementIsCompound =
    replacementIngredientAssignment.ingredient.compoundIngredientRecipeId != null;

  const [expanded, setExpanded] = useState(
    allCardsExpanded
      ? !collapsedCards.includes(replacementIngredientAssignment.ingredient.id)
      : expandedCards.includes(replacementIngredientAssignment.ingredient.id)
  );

  const canEditReplacements = useAllowed('editReplacements');

  const handleExpandCard = () => {
    dispatch({
      type: ACTIONS.UPDATE_EXPANDED_CARDS,
      payload: {
        expandUpdate: replacementIngredientAssignment.ingredient.id,
      },
    });
  };

  const handleEditReplacement = () => {
    dispatch({
      type: ACTIONS.HANDLE_EDIT_REPLACEMENT,
      payload: {
        selectedState: ingredientAssignment,
        selectedIndex: ingredientAssignmentIndex,
        replacementState: replacementIngredientAssignment,
        replacementIndex,
      },
    });
    if (!expandedCards.includes(replacementIngredientAssignment.ingredient.id)) {
      handleExpandCard();
    }
  };

  useEffect(() => {
    setExpanded(
      allCardsExpanded
        ? !collapsedCards.includes(replacementIngredientAssignment.ingredient.id)
        : expandedCards.includes(replacementIngredientAssignment.ingredient.id)
    );
  }, [
    allCardsExpanded,
    expandedCards,
    collapsedCards,
    replacementIngredientAssignment.ingredient.id,
  ]);

  return (
    <Box elevation={3} className={classes.replacementCiraCardWrapper}>
      <Grid item xs={12} className={classes.replacementCiraCardContainer}>
        <div className={classes.ingredientInfoTopRow}>
          <span className={classes.smallCaps}>REPLACEMENT</span>
          <br />
          <span className={classes.text}>
            <strong>{replacementIngredientAssignment.ingredient?.id}</strong>{' '}
            {replacementIngredientAssignment?.name} - {replacementIngredientAssignment.quantity}{' '}
            {replacementIngredientAssignment.measure}
          </span>
          {replacementIsCompound && <Chip label="COMPOUND" className={classes.compoundPill} />}
          <p className={classes.text}>
            {replacementIngredientAssignment.packingFacilityNames?.length > 0 && (
              <span>{replacementIngredientAssignment.packingFacilityNames.join(', ')}</span>
            )}
          </p>
        </div>
        <div>
          {canEditReplacements && (
            <Button
              variant="outlined"
              className={classes.replacementIngredientButton}
              onClick={() => handleEditReplacement()}
            >
              Edit Replacement
            </Button>
          )}
          {replacementIsCompound && (
            <Button onClick={() => handleExpandCard()}>
              {expanded ? <ExpandLess /> : <ChevronRightIcon />}
            </Button>
          )}
        </div>
      </Grid>
      {replacementIsCompound && (
        <Collapse in={expanded}>
          <CompactCirasContainer
            classes={classes}
            render={() => {
              return replacementIngredientAssignment.compoundIngredientRecipeAssignments?.map(
                (cira, i) => {
                  const key = `cira-ri-${i}`;
                  return cira.deleted ? null : (
                    <CompactCiraCard classes={classes} cira={cira} key={key} ciraIndex={i} />
                  );
                }
              );
            }}
          />
        </Collapse>
      )}
    </Box>
  );
};

CompactReplacementCard.propTypes = {
  classes: PropTypes.object.isRequired,
  ingredientAssignment: PropTypes.object.isRequired,
  ingredientAssignmentIndex: PropTypes.number.isRequired,
  replacementIngredientAssignment: PropTypes.object.isRequired,
  replacementIndex: PropTypes.number.isRequired,
};

export default CompactReplacementCard;
