import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { fetchGridData } from 'redux/menugrid/menus/actions';

const ErrorDialog = ({ displayGroup }) => {
  const dispatch = useDispatch();

  const { updateMenuSelectionOrderError } = useSelector((state) => state.menugrid.menus);
  const quarterStart = useSelector((state) => state.menugrid.quarter.start);

  return (
    <div>
      <Dialog
        open={updateMenuSelectionOrderError}
        onClose={() => {
          dispatch(fetchGridData(quarterStart, displayGroup));
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">An Error Has Occurred!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Menu Grid was unable to save changes to some meals and needs to return to its last saved
            state. Some changes may be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(fetchGridData(quarterStart, displayGroup));
            }}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ErrorDialog.propTypes = {
  displayGroup: PropTypes.string.isRequired,
};

export default ErrorDialog;
