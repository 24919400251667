import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const AddMealsErrorContent = ({ classes }) => {
  const { addMealsErrorMessage } = useSelector((state) => state.menugrid.menus);

  return <div className={classes.addMealsErrorText}>{addMealsErrorMessage}</div>;
};

AddMealsErrorContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default AddMealsErrorContent;
