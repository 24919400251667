import { combineReducers } from 'redux';

import incidentable from './incidentable/reducers';
import incident from './incident/reducers';
import snackBar from './snackBar/reducers';

export default combineReducers({
  incidentable,
  incident,
  snackBar,
});
