import { LIGHT_GREEN } from 'lib/constants';
import {
  rowLabel,
  iconCell,
  listLabel,
  listContent,
  greyed,
  tableRowGreenBorder,
} from 'lib/styles/comparisonTool';

export default {
  rowLabel,
  iconCell,
  listLabel,
  listContent,
  greyed,
  highlight: {
    backgroundColor: LIGHT_GREEN,
  },
  tableRowGreenBorder,
};
