import { BACKGROUND_GREY } from 'lib/constants';

export default {
  container: {
    display: 'flex',
  },
  root: {
    backgroundColor: BACKGROUND_GREY,
    display: 'flex',
  },
};
