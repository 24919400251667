import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { BehavioralDiscountFormContext } from 'contexts/BehavioralDiscountToolContext';

import Box from '@mui/material/Box';

import Tag from './Tag';

const BannerPreview = ({ classes, previewImage = null }) => {
  const { formState } = useContext(BehavioralDiscountFormContext);

  const heading = formState.promotionText || 'Promotion Text';
  const description = formState.description || 'Description Text';
  const { bannerBackgroundColor, bannerTagTextColor, bannerTagBackgroundColor, bannerTextColor } =
    formState;

  return (
    <Box
      className={[
        classes.bannerPreviewContainer,
        formState.serviceType === 'tempo' ? classes.tempoBg : '',
      ]}
    >
      <Box
        className={classes.bannerOuterContainer}
        style={{ backgroundColor: bannerBackgroundColor }}
      >
        <Box className={classes.bannerInnerContainer}>
          <Box className={classes.bannerContentContainer}>
            <Box
              style={{
                color: bannerTagTextColor,
                backgroundColor: bannerTagBackgroundColor,
              }}
              className={`${classes.bannerLabelContainer} ${classes.h7}`}
            >
              <Box
                sx={{
                  fontFamily: 'Karla',
                }}
                className={classes.bannerLabelContentContainer}
              >
                <Box className={classes.bannerLabelIcon}>
                  <Tag color={bannerTagTextColor} />
                </Box>
                <Box style={{ color: bannerTagTextColor }}>OFFER</Box>
              </Box>
            </Box>
            <Box
              className={
                formState.serviceType === 'tempo' ? classes.tempoPromoText : classes.promoText
              }
              style={{ color: bannerTextColor }}
            >
              {heading}
            </Box>
            <Box
              className={
                formState.serviceType === 'tempo'
                  ? classes.tempoDescriptionText
                  : classes.descriptionText
              }
              style={{ color: bannerTextColor }}
            >
              {description}
            </Box>
          </Box>
          {previewImage && (
            <Box className={classes.bannerImageContainer}>
              <img className={classes.bannerImage} src={previewImage} alt="Banner Preview" />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

BannerPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  previewImage: PropTypes.string,
};

BannerPreview.defaultProps = {
  previewImage: null,
};

export default BannerPreview;
