import { HC_GREEN, RED, MEDIUM_GREY, BORDER_GREY, LIGHT_GREEN } from 'lib/constants';

export default {
  container: {
    padding: 24,
  },
  paper: {
    padding: '16px',
    marginBottom: '16px',
  },
  paperFlexContainer: {
    padding: '16px 8px',
    marginBottom: '16px',
  },
  headerWrapper: {
    paddingLeft: '8px',
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexItemCard: {
    paddingRight: '8px',
    paddingLeft: '8px',
  },
  divider: {
    marginTop: '16px',
    marginBottom: '16px',
    backgroundColor: BORDER_GREY,
    height: '1px',
    border: 'none',
  },
  recipeStepDivider: {
    margin: '16px 8px',
    backgroundColor: BORDER_GREY,
    height: '1px',
    border: 'none',
    width: '100%',
  },
  fileName: {
    display: 'inline-block',
    width: '40%',
    color: MEDIUM_GREY,
    overflowWrap: 'break-word',
  },
  input: {
    display: 'none',
  },
  recipeStepCard: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  recipeStepImage: {
    maxWidth: '100%',
    overflowWrap: 'break-word',
  },
  deleteButton: {
    color: RED,
    borderColor: RED,
    marginTop: '16px',
  },
  outlinedButton: {
    color: HC_GREEN,
  },
  addButton: {
    color: HC_GREEN,
    marginTop: '16px',
  },
  imageInformation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uploadAndDelete: {
    minWidth: '180px',
  },
  deleteImageButton: {
    marginLeft: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    minWidth: 0,
    color: MEDIUM_GREY,
  },
  ingredientInfoContainer: {
    backgroundColor: LIGHT_GREEN,
    width: 'max-content',
    padding: '15px',
    borderRadius: '15px',
    borderColor: HC_GREEN,
  },
  ingredientInfoButton: {
    marginTop: '16px',
    marginRight: '20px',
  },
};
