import { WARNING_LIGHT, WARNING_DARK, ERROR_LIGHT, ERROR_DARK } from 'lib/constants';

export default {
  warning: {
    backgroundColor: WARNING_LIGHT,
    borderColor: WARNING_DARK,
  },
  error: {
    backgroundColor: ERROR_LIGHT,
    borderColor: ERROR_DARK,
  },
};
