import { LIGHT_BLACK, LIGHT_GREY, LIGHTEST_GREY, HC_GREEN } from 'lib/constants/styleConstants';

export default {
  drawer: {
    width: 272,
    flexShrink: 0,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 400,
  },
  drawerPaper: {
    marginTop: 64,
    width: 272,
  },
  drawerHeader: {
    padding: '24px 0px 8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  drawerTitle: {
    fontWeight: 500,
    fontSize: '16px',
    paddingLeft: 15,
  },
  listItemMenuId: {
    color: LIGHT_GREY,
  },
  listItemMenuDate: {
    color: LIGHT_BLACK,
    fontWeight: 500,
    marginLeft: '94px',
  },
  listItem: {
    borderLeft: '4px solid transparent',
    color: LIGHT_BLACK,
    padding: '14px 16px',
    '&.Mui-selected': {
      backgroundColor: LIGHTEST_GREY,
      borderLeft: `4px solid ${HC_GREEN}`,
      color: HC_GREEN,
    },
  },
  lockIcon: {
    paddingLeft: 5,
  },
};
