import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';
import IncidentSummary from './IncidentSummary';

const mapStateToProps = (state) => ({
  incidents: state.incidentManager.incident.incidents,
  totalCashRefunds: state.incidentManager.incident.totalCashRefunds,
  totalGiftCardRefunds: state.incidentManager.incident.totalGiftCardRefunds,
  totalCredits: state.incidentManager.incident.totalCredits,
});

export default connect(mapStateToProps)(withStyles(styles)(IncidentSummary));
