import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

const NewMenuButton = ({ posting, onSubmitCreateNewMenu, classes }) => {
  const handleOnSubmitCreateNewMenu = () => {
    onSubmitCreateNewMenu();
  };

  return (
    <div className={classes.newMenuButton}>
      <Button
        variant="outlined"
        onClick={handleOnSubmitCreateNewMenu}
        disabled={posting}
        color="primary"
      >
        New Menu
      </Button>
    </div>
  );
};

NewMenuButton.propTypes = {
  posting: PropTypes.bool.isRequired,
  onSubmitCreateNewMenu: PropTypes.func.isRequired,
  classes: PropTypes.object,
};

NewMenuButton.defaultProps = {
  classes: {},
};

export default NewMenuButton;
