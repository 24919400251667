import React, { Fragment } from 'react';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';

const LoadingMenuSelector = () =>
  [...Array(20)].map((item, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Fragment key={index}>
      <ListItem>
        <Skeleton variant="text" width={130} />
      </ListItem>
      <Divider variant="middle" />
    </Fragment>
  ));

export default LoadingMenuSelector;
