import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Material Components
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import DeleteIcon from '@mui/icons-material/Delete';

import Dropdown from '../../shared/Dropdown';
import TextBox from '../../shared/TextBox';

const DisposableItem = ({ classes, assignment, onChange }) => {
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);

  const handleAssignmentChange = (event) => {
    const { name, value } = event.target;
    const newAssignmentState = { ...assignment, [name]: value };

    onChange(newAssignmentState);
  };

  const handleDelete = () => {
    const newAssignmentState = { ...assignment, deleted: true };

    onChange(newAssignmentState);
  };

  const selectDisposableItems = useMemo(() => {
    return formOptions.disposableItems.map((disposableItem) => ({
      label: disposableItem.name,
      value: disposableItem.id,
    }));
  }, [formOptions.disposableItems]);

  return (
    <Card elevation={0} className={classes.disposableItemCard}>
      <Dropdown
        required
        label="Disposable Item"
        name="disposableItemId"
        onChange={handleAssignmentChange}
        value={assignment.disposableItemId || ''}
        options={selectDisposableItems}
      />

      <TextBox
        helperText="Must be greater than 0"
        label="Quantity"
        name="quantity"
        onChange={handleAssignmentChange}
        required
        value={assignment.quantity}
      />

      <Button
        variant="outlined"
        className={classes.deleteButton}
        onClick={handleDelete}
        startIcon={<DeleteIcon />}
      >
        Remove Item
      </Button>
      <hr className={classes.hr} />
    </Card>
  );
};

DisposableItem.propTypes = {
  classes: PropTypes.object.isRequired,
  assignment: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DisposableItem;
