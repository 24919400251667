import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

const SharedButton = ({
  handleOnClick,
  className,
  buttonText,
  variant,
  color,
  disabled,
  value,
  type,
}) => {
  return (
    <Button
      color={color}
      variant={variant}
      className={className}
      onClick={handleOnClick}
      disabled={disabled}
      value={value}
      type={type}
    >
      {buttonText}
    </Button>
  );
};

SharedButton.propTypes = {
  handleOnClick: PropTypes.func,
  className: PropTypes.string,
  buttonText: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  type: PropTypes.string,
};

SharedButton.defaultProps = {
  className: '',
  handleOnClick: () => {},
  buttonText: '',
  variant: 'text',
  color: 'success',
  disabled: false,
  value: '',
  type: 'button',
};

export default SharedButton;
