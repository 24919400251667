import React from 'react';
import PropTypes from 'prop-types';

// Material Components
import Grid from '@mui/material/Grid';

import SectionHeader from '../shared/SectionHeader';
import TextBox from '../../shared/TextBox';

const OutputLoss = ({ classes, editsLocked, formState, handleFormState }) => {
  return (
    <>
      <div className={classes.outputLossHeader}>
        <SectionHeader text="Output Loss" />
      </div>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={6} style={{ paddingLeft: 0 }}>
          <TextBox
            label="Moisture Loss"
            name="moistureLoss"
            disabled
            endAdornment={<>g</>}
            value={formState.moistureLoss}
          />
        </Grid>
        <Grid item xs={6}>
          <TextBox
            label="Pre-Prep Fat Content"
            name="prePrepFat"
            disabled
            endAdornment={<>g</>}
            value={formState.prePrepFat}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: 0 }}>
          <TextBox
            label="Fat Loss"
            name="fatLoss"
            disabled={editsLocked}
            onChange={handleFormState}
            value={formState.fatLoss}
          />
        </Grid>
      </Grid>
    </>
  );
};

OutputLoss.propTypes = {
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
  editsLocked: PropTypes.bool.isRequired,
};

export default OutputLoss;
