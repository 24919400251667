import { CT_SET_OPEN_SELECT_VERSIONS } from 'lib/constants';

const initialState = {
  openSelectVersions: false,
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case CT_SET_OPEN_SELECT_VERSIONS:
      return {
        ...state,
        openSelectVersions: action.payload.open,
      };
    default:
      return state;
  }
};
