// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import CookingFactsRow from './CookingFactsRow';

// Styles
import styles from './styles';

export default withStyles(styles)(CookingFactsRow);
