import { WHITE } from 'lib/constants';

export default {
  loading: {
    textAlign: 'center',
    padding: '50px',
  },
  versionTable: {
    maxWidth: '98%',
    margin: 'auto',
    marginTop: '18px',
    backgroundColor: WHITE,
    border: '1px solid #D8DADC',
    borderRadius: '4px',
  },
  tableContainer: {
    height: 'calc(100vh - 82px)',
  },
};
