import { LIGHT_BLACK, LIGHT_GREY } from 'lib/constants';
import {
  rowLabel,
  iconCell,
  icon,
  expandingCell,
  collapse,
  greyed,
} from 'lib/styles/comparisonTool';

export default {
  rowLabel,
  iconCell,
  icon,
  expandingCell,
  collapse,
  greyed,
  firstMenuInfo: {
    color: LIGHT_BLACK,
    fontWeight: '400',
    fontSize: '12px',
    display: 'inline',
  },
  collapsibleMenuInfo: {
    color: LIGHT_BLACK,
    fontWeight: '400',
    fontSize: '12px',
    padding: '2px 0px',
  },
  extraMenusLabel: {
    color: LIGHT_GREY,
    fontStyle: 'italic',
    fontWeight: '400',
    fontSize: '12px',
    display: 'inline',
    marginLeft: '8px',
  },
};
