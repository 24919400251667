// Dynamically getting the base API URI to allow for multiple envs to use internal apps with mealhand in the standalone environment
// If the app is loaded inside an iframe
//   -> it will use the host's base URI for all API calls
//   -> otherwise, it uses the hard coded URI
const getApiUri = () => {
  return window.location !== window.parent.location
    ? document.referrer.slice(0, -1) // remove ending "/" to have parity with hardcoded URI
    : process.env.REACT_APP_API_URI;
};

export const APP_API_URI = getApiUri();
