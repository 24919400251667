import { MEDIUM_GREY } from 'lib/constants';

export default {
  textInput: {
    borderColor: MEDIUM_GREY,
    borderRadius: 4,
    margin: '2px 0 0 0',
    fontSize: 12,
  },
};
