import { makeApiRequest } from 'lib/api';
import {
  MM_FETCH_MENU,
  MM_ADD_MEALS,
  MM_TOGGLE_ADD_MEALS_DIALOG,
  MM_SET_SELECTED_MEAL_ID,
  MM_REMOVE_MEAL,
  MM_UPDATE_RANKING,
  MM_POST_RANKING,
  MM_SET_RANKING,
} from 'lib/constants';
import { toggleSnackBar } from 'redux/menuManager/snackBar/actions';
import { createAddMeals, fetchMenu, removeMeal, postRankingUpdates } from './services';

export const getMenu = (menuId) =>
  makeApiRequest({ type: MM_FETCH_MENU, method: fetchMenu, params: { menuId } });

export const setRanking = (alternativeRanking) => {
  return async (dispatch) => {
    dispatch({ type: MM_SET_RANKING, payload: alternativeRanking });
  };
};

export const setSelectedMealId = (mealId) => {
  return async (dispatch) => {
    dispatch({ type: MM_SET_SELECTED_MEAL_ID, payload: { mealId } });
  };
};

export const updateRanking = (retailMenuId, category, mealRankings) => {
  return async (dispatch) => {
    dispatch({ type: MM_UPDATE_RANKING, payload: { category } });
    dispatch(
      makeApiRequest({
        type: MM_POST_RANKING,
        method: postRankingUpdates,
        params: { retailMenuId, mealRankings, retailCategoryId: category.id },
      })
    );
  };
};

export const submitAddMeals = (retailMenuId, retailCategoryId, culinaryVersions) => {
  return async (dispatch) => {
    const response = await dispatch(
      makeApiRequest({
        type: MM_ADD_MEALS,
        method: createAddMeals,
        params: {
          retailMenuId,
          retailCategoryId,
          culinaryVersions,
        },
      })
    );
    if (response) {
      dispatch(toggleSnackBar(true, 'Meals added!'));
    }
  };
};

export const toggleAddMealsDialog = (retailCategoryId) => {
  return async (dispatch) => {
    dispatch({
      type: MM_TOGGLE_ADD_MEALS_DIALOG,
      activeCategory: retailCategoryId,
    });
  };
};

export const submitRemoveMeal = (retailMenuId, categoryId, mealId) => {
  return async (dispatch) => {
    const response = await dispatch(
      makeApiRequest({
        type: MM_REMOVE_MEAL,
        method: removeMeal,
        params: {
          retailMenuId,
          categoryId,
          mealId,
        },
      })
    );

    if (response && response.status === 500) {
      dispatch(toggleSnackBar(true, `${response.error}`));
      dispatch(setSelectedMealId(null));
    }
  };
};
