import { GET, POST } from 'lib/api';
import { APP_API_URI } from 'lib/constants';

export const fetchMenu = async ({ menuId }) => {
  const response = await GET(`${APP_API_URI}/internal/retail/menu_management/get_menu/`, {
    params: {
      retail_menu_id: menuId,
    },
  });
  return response.data;
};

export const createAddMeals = async ({ retailMenuId, retailCategoryId, culinaryVersions }) => {
  const url = `${APP_API_URI}/internal/retail/menu_management/add_meals`;

  const response = await POST(url, {
    retail_menu_id: retailMenuId,
    retail_category_id: retailCategoryId,
    culinary_versions: culinaryVersions.split('\n'),
  });

  return response.data;
};

export const removeMeal = async ({ retailMenuId, categoryId, mealId }) => {
  const url = `${APP_API_URI}/internal/retail/menu_management/remove_meal`;

  const response = await POST(url, {
    retail_menu_id: retailMenuId,
    retail_category_id: categoryId,
    meal_id: mealId,
  });

  return response.data;
};

export const postRankingUpdates = async ({ retailMenuId, retailCategoryId, mealRankings }) => {
  const url = `${APP_API_URI}/internal/retail/menu_management/update_ranking`;
  const response = await POST(url, {
    retail_menu_id: retailMenuId,
    retail_category_id: retailCategoryId,
    meals: mealRankings,
  });

  return response.data;
};
