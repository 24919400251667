import { LIGHT_BLACK, BORDER_GREY, MEDIUM_GREY } from 'lib/constants';

const NUM_COLUMNS = 13;

export default {
  root: {
    padding: '0px',
    maxWidth: '100%',
  },

  title: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: LIGHT_BLACK,
    padding: '6px 0px 6px 12px',
  },

  list: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    borderTop: `1px solid ${BORDER_GREY}`,
    padding: '16px 0px',
    '& > div': {
      padding: '0px 6px',
      margin: '0px',
      width: `calc(100% / ${NUM_COLUMNS})`,
    },
  },

  iconButton: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'transparent',
    },
  },

  icon: {
    display: 'inline-block',
    margin: 'auto',
    color: MEDIUM_GREY,
  },
};
