import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { broadcastScrollSizeToParent } from 'lib/utils';

import Grid from '@mui/material/Grid';

import PackingFacilityCard from './PackingFacilityCard';
import SaveChangesButton from '../shared/SaveChangesButton';

const PackingFacilitiesTab = ({ classes, dirty, setDirty }) => {
  const { formOptions } = useSelector((state) => state.compoundIngredientRecipeForm.formOptions);
  const { compoundIngredientRecipe } = useSelector(
    (state) => state.compoundIngredientRecipeForm.compoundIngredientRecipe
  );
  const [formState, setFormState] = useState({
    packingFacilityIngredientAssignments:
      compoundIngredientRecipe.compoundIngredient.packingFacilityIngredientAssignments,
  });

  const compoundIngredientAttributeParams = {
    compoundIngredientAttributes: {
      id: compoundIngredientRecipe.compoundIngredient.id,
      packingFacilityIngredientAssignmentsAttributes:
        formState.packingFacilityIngredientAssignments,
    },
  };

  useEffect(() => {
    setFormState({
      packingFacilityIngredientAssignments:
        compoundIngredientRecipe.compoundIngredient.packingFacilityIngredientAssignments,
    });
  }, [compoundIngredientRecipe]);

  const handleFormState = (event) => {
    if (!dirty) {
      setDirty(true);
    }
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  // If being used in an iframe, set the width and height
  // so we don't have a double scrollbar situation.
  useEffect(() => {
    broadcastScrollSizeToParent();
  }, []);

  const renderPackingFacilityCards = () => {
    return formOptions.packingFacilities.map((packingFacility) => {
      const key = `packing-facility-${packingFacility.id}`;
      const pfia = formState.packingFacilityIngredientAssignments.find(
        (_pfia) => _pfia?.packingFacilityId === packingFacility.id
      );
      return (
        <Grid item xs={4} key={key}>
          <PackingFacilityCard
            classes={classes}
            formState={formState}
            handleFormState={handleFormState}
            packingFacility={packingFacility}
            pfia={pfia}
          />
        </Grid>
      );
    });
  };

  return (
    <>
      {renderPackingFacilityCards()}
      <Grid item xs={9}>
        <SaveChangesButton
          compoundIngredientRecipeParams={compoundIngredientAttributeParams}
          compoundIngredientRecipeId={compoundIngredientRecipe?.id}
        />
      </Grid>
    </>
  );
};

PackingFacilitiesTab.propTypes = {
  classes: PropTypes.object.isRequired,
  dirty: PropTypes.bool,
  setDirty: PropTypes.func.isRequired,
};

PackingFacilitiesTab.defaultProps = {
  dirty: false,
};

export default PackingFacilitiesTab;
