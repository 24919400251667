// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import BalancerSelector from './BalancerSelector';

// Styles
import styles from './styles';

export default withStyles(styles)(BalancerSelector);
