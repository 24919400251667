import { createContext, React } from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'components/BehavioralDiscountTool/formUtils';

const BehavioralDiscountFormContext = createContext();

const BehavioralDiscountFormProvider = ({ children }) => {
  const { formState, actions } = useFormState();

  return (
    <BehavioralDiscountFormContext.Provider value={{ formState, actions }}>
      {children}
    </BehavioralDiscountFormContext.Provider>
  );
};

BehavioralDiscountFormProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { BehavioralDiscountFormProvider, BehavioralDiscountFormContext };
