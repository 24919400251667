import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { submitCreateNewMenu } from 'redux/menuManager/allMenus/actions';
import styles from './styles';
import NewMenuButton from './NewMenuButton';

const mapStateToProps = (state) => ({
  posting: state.menuManager.menu.posting,
});

const mapDispatchToProps = {
  onSubmitCreateNewMenu: submitCreateNewMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewMenuButton));
