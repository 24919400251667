import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import useAllowed from 'lib/useAllowed';

// Material Components
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import CompactReplacementCiraCard from './CompactReplacementCiraCard';
import CompactCirasContainer from './CompactCirasContainer';

import { ACTIONS, ReplacementsTabContext } from '../reducer';

const CompactCiraCard = ({ classes, cira, ciraIndex }) => {
  const { state, dispatch } = useContext(ReplacementsTabContext);
  const { allCardsExpanded, expandedCards, collapsedCards } = state;
  const { replacementCiras } = state.formState;
  const [expanded, setExpanded] = useState(
    allCardsExpanded
      ? !collapsedCards.includes(cira.ingredient.id)
      : expandedCards.includes(cira.ingredient.id)
  );
  const [replacements, setReplacements] = useState([]);

  const handleExpandCard = () => {
    dispatch({
      type: ACTIONS.UPDATE_EXPANDED_CARDS,
      payload: {
        expandUpdate: cira.ingredient.id,
      },
    });
  };

  const handleAddReplacement = () => {
    dispatch({
      type: ACTIONS.HANDLE_ADD_REPLACEMENT,
      payload: { selectedState: cira, selectedIndex: ciraIndex },
    });
    if (!expandedCards.includes(cira.ingredient.id)) {
      handleExpandCard();
    }
  };

  useEffect(() => {
    setExpanded(
      allCardsExpanded
        ? !collapsedCards.includes(cira.ingredient.id)
        : expandedCards.includes(cira.ingredient.id)
    );
  }, [allCardsExpanded, expandedCards, collapsedCards, cira.ingredient.id]);

  const isCompound = cira.ingredient.compoundIngredientRecipeId != null;

  const numberOfReplacements = replacements.filter(
    (replacement) => !('deleted' in replacement)
  ).length;

  const canEditReplacements = useAllowed('editReplacements');

  useEffect(() => {
    if (replacementCiras?.length > 0) {
      // need to grab the original index from the un-nested replacementCira array
      const indexedReplacementCiras = replacementCiras.map((rc, rcIndex) => {
        const indexedRC = { ...rc, originalIndex: rcIndex };
        return indexedRC;
      });
      const relatedReplacements = indexedReplacementCiras.filter(
        (rc) => rc.originalCiraId === cira.id
      );
      setReplacements(relatedReplacements);
    }
  }, [cira, replacementCiras]);

  const renderReplacementCiraCards = () =>
    replacements?.map((replacementCira, replacementCiraIndex) => {
      const key = `replacement-ingredient-${replacementCiraIndex}`;
      return 'deleted' in replacementCira ? null : (
        <CompactReplacementCiraCard
          key={key}
          classes={classes}
          cira={cira}
          ciraIndex={ciraIndex}
          replacementCira={replacementCira}
          replacementCiraIndex={replacementCira.originalIndex}
          render={() => {
            return replacementCira.compoundIngredientRecipeAssignments?.map((c, i) => {
              const id = `cira-ria-${i}`;
              return c.deleted ? null : (
                <CompactCiraCard classes={classes} cira={c} key={id} ciraIndex={i} />
              );
            });
          }}
        />
      );
    });

  return (
    <Box elevation={3} className={classes.ciraCardContainer}>
      <div className={classes.ingredientInfo}>
        <div>
          <span className={classes.text}>
            <strong>{cira.ingredient?.id}</strong> {cira?.name} - {cira.quantity} {cira.measure}
          </span>
          {isCompound && <Chip label="COMPOUND" className={classes.compoundPill} />}
          {numberOfReplacements > 0 && (
            <p className={classes.text}>
              <span className={classes.ingredientReplacementInfo}>
                + {numberOfReplacements} Replacements
              </span>
            </p>
          )}
        </div>
        <div>
          {canEditReplacements && (
            <Button
              variant="outlined"
              className={classes.addReplacementCiraButton}
              onClick={() => handleAddReplacement()}
              startIcon={<AddIcon />}
            >
              Replacement
            </Button>
          )}
          {(numberOfReplacements > 0 || isCompound) && (
            // <Button onClick={() => setExpanded(!expanded)}>
            <Button onClick={() => handleExpandCard()}>
              {expanded ? <ExpandLess /> : <ChevronRightIcon />}
            </Button>
          )}
        </div>
      </div>
      {numberOfReplacements > 0 && (
        <Collapse in={expanded}>
          <Grid item xs={12} className={classes.replacementsContainer}>
            {renderReplacementCiraCards()}
          </Grid>
        </Collapse>
      )}
      {isCompound && (
        <Collapse in={expanded}>
          <CompactCirasContainer
            classes={classes}
            render={() => {
              return cira.compoundIngredientRecipeAssignments?.map((c, idx) => {
                const key = `cira-ci-${idx}`;
                return c.deleted ? null : (
                  <CompactCiraCard key={key} classes={classes} cira={c} ciraIndex={idx} />
                );
              });
            }}
          />
        </Collapse>
      )}
    </Box>
  );
};

CompactCiraCard.propTypes = {
  classes: PropTypes.object.isRequired,
  cira: PropTypes.object.isRequired,
  ciraIndex: PropTypes.number,
};

CompactCiraCard.defaultProps = {
  ciraIndex: 0,
};

export default CompactCiraCard;
