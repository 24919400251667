import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const TeamTitle = ({ title, classes }) => {
  return (
    <Typography variant="h6" className={classes.root}>
      {title}
    </Typography>
  );
};

TeamTitle.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default TeamTitle;
