import { useQuery } from '@tanstack/react-query';
import { GET } from 'lib/api';
import { APP_API_URI } from 'lib/constants';

const getAggregatedData = async () => {
  const response = await GET(`${APP_API_URI}/internal/hub/aggregated-data`);
  return response.data;
};

export const useAggregatedData = () =>
  useQuery({ queryKey: ['aggregatedData'], queryFn: getAggregatedData });

export const getFilters = async () => {
  const response = await GET(`${APP_API_URI}/internal/hub/filter-options`);
  return response.data.filterOptions;
};

export const useFilters = () => useQuery({ queryKey: ['filters'], queryFn: getFilters });
