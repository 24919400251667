import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

const Loading = ({ size, topPadding, centered, className, classes }) => {
  const topPaddingStyle = topPadding ? classes.topPadding : '';
  const centerStyle = centered ? classes.centered : '';

  // Rendering
  return (
    <div className={`${classes.displayFlex} ${topPaddingStyle} ${centerStyle} ${className}`}>
      <CircularProgress className={classes.circularColorPrimary} size={size} />
    </div>
  );
};

Loading.propTypes = {
  size: PropTypes.number, // pixels
  topPadding: PropTypes.bool,
  centered: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

Loading.defaultProps = {
  size: 40,
  topPadding: true,
  centered: true,
  className: '',
};

export default withStyles(styles)(Loading);
