import { MM_CREATE_NEW_MENU, MM_FETCH_ALL_MENUS, MM_SET_SELECTED_MENU_ID } from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import { toggleSnackBar } from 'redux/menuManager/snackBar/actions';
import { createNewMenu, fetchAllMenus } from './services';

export const getAllMenus = () => {
  return makeApiRequest({ type: MM_FETCH_ALL_MENUS, method: fetchAllMenus });
};

export const setSelectedMenuId = (menuId) => ({
  type: MM_SET_SELECTED_MENU_ID,
  payload: { menuId },
});

export const submitCreateNewMenu = () => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: MM_CREATE_NEW_MENU,
        method: createNewMenu,
      })
    );

    dispatch(toggleSnackBar(true, 'Menu created!'));
  };
};
