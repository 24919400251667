import React from 'react';
import PropTypes from 'prop-types';

// Material Components
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

const CompactCirasContainer = ({ classes, render }) => {
  return (
    <>
      <Divider className={classes.divider} />
      <Grid item xs={12} className={classes.cirasContainer}>
        <p className={classes.componentIngredientsHeader}>COMPONENT INGREDIENTS</p>
        {render()}
      </Grid>
    </>
  );
};

CompactCirasContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
};

export default CompactCirasContainer;
