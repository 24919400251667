import { map, flatMapDeep, uniq } from 'lodash';

export const sanitizedDisposableItems = (arrayOfDisposableItems) => {
  return map(arrayOfDisposableItems, (disposableItems) =>
    map(disposableItems, ({ name, quantity }) => {
      return [name, quantity];
    })
  );
};

const hasQuantityDifferences = (itemName, meals) => {
  const allQuantities = flatMapDeep(meals, (meal) => {
    const matchedItem = meal.disposableItems.find(
      (disposableItem) => disposableItem.name === itemName
    );
    return matchedItem.quantity;
  });
  return uniq(allQuantities).length !== 1;
};

export const setHighlightDisposableItem = (itemName, occurrences, meals) => {
  if (occurrences.length === 0) return false;
  if (occurrences.length !== meals.length) return true; // if any items are listed for at least one but not ALL meals

  return hasQuantityDifferences(itemName, meals);
};
