import { BORDER_GREY, BACKGROUND_GREY } from 'lib/constants';

export default {
  root: {
    position: 'relative',
    margin: '6px 0px 6px 0px',
    minHeight: '60px',
    marginBottom: '0.1875em',
    overflow: 'hidden',
    fontSize: '0.75em',
    border: `1px solid ${BORDER_GREY}`,
    borderRadius: '4px',
    backgroundColor: `${BACKGROUND_GREY}`,
  },
  order: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '0 auto',
    top: '50%',
    transform: 'translateY(-50%)',
    textAlign: 'center',
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    fontSize: 11,
    width: 10,
    height: 10,
  },
};
