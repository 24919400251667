import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import clsx from 'clsx';

const Dropdown = ({
  classes,
  disabled,
  options,
  label,
  name,
  onChange,
  value,
  helperText,
  required,
}) => {
  const [error, setError] = useState({ error: false, message: '' });

  const customStyles = {
    container: (provided) => ({
      ...provided,
      borderRadius: 4,
    }),
    menuList: (provided) => ({
      ...provided,
      fontSize: 13,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 100, // prevent mui textbox label from sitting on top of the popover
    }),
    valueContainer: (provided) => ({
      ...provided,
      fontSize: 13,
    }),
  };

  const currentValue = useMemo(() => {
    const selectedValue = options.filter((option) => option.value === value);
    if (selectedValue.length === 0) {
      return null;
    }
    return selectedValue[0];
  }, [options, value]);

  const getLabel = () => {
    return required ? `${label} *` : `${label}`;
  };

  const getOptions = useMemo(() => {
    const hasNoneOption = options.some((option) => option.label === 'None');
    if (!required && !hasNoneOption) {
      options.unshift({ label: 'None', value: '' });
      return options;
    }
    return options;
  }, [required, options]);

  const renderHelperText = () => {
    if (helperText === null && error.error === false) return null;
    if (error.error === true) {
      return <div className={classes.errorMessage}>{error.message}</div>;
    }
    return <div className={classes.dropdownHelperText}>{helperText}</div>;
  };

  // Creates "pseudo-event" based on the values returned by the onChange
  // and let the calling function handle it
  const handleSelect = (newValue, actionMeta) => {
    const attrName = actionMeta.name;
    const updatedValue = newValue.value;

    onChange({ target: { name: attrName, value: updatedValue } });
  };

  const validateField = (newValue, actionMeta) => {
    if (required && !newValue) {
      setError({
        error: true,
        message: 'This field is required',
      });
    } else {
      setError({
        error: false,
        message: '',
      });
      handleSelect(newValue, actionMeta);
    }
  };

  return (
    <div data-testid={`${name}-dropdown`}>
      <label htmlFor={name}>
        <span
          data-testid={`${name}-current`}
          className={clsx(classes.labelText, disabled && classes.disabledText)}
        >
          {currentValue ? getLabel() : null}
        </span>
        <Select
          id={name}
          data-testid="opsCategory-select"
          styles={customStyles}
          isDisabled={disabled}
          name={name}
          options={getOptions}
          onChange={validateField}
          value={currentValue}
          variant="outlined"
          size="small"
          margin="normal"
          required={required}
          placeholder={getLabel()}
          aria-label={name}
        />
      </label>
      {renderHelperText()}
    </div>
  );
};

Dropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  helperText: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Dropdown.defaultProps = {
  disabled: false,
  label: '',
  name: '',
  helperText: null,
  options: [],
  onChange: () => {},
  required: false,
  value: '',
};

export default Dropdown;
