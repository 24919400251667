// Dependencies
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Droppable } from 'react-beautiful-dnd';
import { Box, Button, Container } from '@mui/material';

import { addPlaceholder } from 'redux/menugrid/menus/actions';

// Components
import MenuHeaderItem from '../MenuHeaderItem';
import MealCard from '../MealCard';

const MenuSelectionsColumn = ({ displayGroup, menu, menuType, classes }) => {
  const { menuSelections } = menu;

  const dispatch = useDispatch();

  const handleAddPlaceholder = (menuId, type) => {
    dispatch(addPlaceholder(menuId, type));
  };

  const renderMealCards = () => {
    return menuSelections.map((menuSelection, index) => {
      return (
        <MealCard
          key={menuSelection.id}
          menuSelection={menuSelection}
          index={index}
          displayGroup={displayGroup}
        />
      );
    });
  };

  return (
    <Container className={classes.root}>
      <div className={classes.headerItem}>
        <MenuHeaderItem displayGroup={displayGroup} menu={menu} key={menu.id} />
      </div>
      <Droppable droppableId={String(menu.id)}>
        {(provided) => (
          <Box
            className={classes.list}
            data-testid="menuSelections"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {renderMealCards()}
            <Button
              variant="text"
              size="small"
              onClick={() => handleAddPlaceholder(menu.id, menuType)}
              className={classes.addPlaceholder}
            >
              Add Placeholder
            </Button>
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </Container>
  );
};

MenuSelectionsColumn.propTypes = {
  displayGroup: PropTypes.string.isRequired,
  menu: PropTypes.object.isRequired,
  menuType: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default MenuSelectionsColumn;
