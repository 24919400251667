import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';

const IngredientsPopper = ({ classes, index, ingredients }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const popperId = open ? `simple-popper-${index}` : undefined;

  const handlePopperClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const renderIcon = () => {
    if (open) {
      return <VisibilityIcon />;
    }
    return <InfoIcon />;
  };

  return (
    <>
      <Button
        aria-describedby={popperId}
        variant="outlined"
        onClick={handlePopperClick}
        className={classes.ingredientInfoButton}
      >
        {renderIcon()} Ingredients
      </Button>
      <Popper anchorEl={anchorEl} id={popperId} placement="right-start" open={open}>
        <TableContainer component={Paper} className={classes.ingredientInfoContainer}>
          <Table sx={{ minWidth: 450 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="right">Measure</TableCell>
                <TableCell align="right">Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ingredients.map((ingredient) => (
                <TableRow key={ingredient.name}>
                  <TableCell align="left">{ingredient.ingredient.name}</TableCell>
                  <TableCell align="right">{ingredient.measure}</TableCell>
                  <TableCell align="right">{ingredient.quantity}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Popper>
    </>
  );
};

IngredientsPopper.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  ingredients: PropTypes.array,
};

IngredientsPopper.defaultProps = {
  ingredients: [],
};

export default IngredientsPopper;
