import { createContext } from 'react';

const getNewReplacementIngredientState = (ia) => ({
  brandName: ia.ingredient.brandName,
  highTolerance: ia.highTolerance,
  ingredient: ia.ingredient,
  ingredientPackagingId: ia.ingredientPackagingId,
  labelRequired: ia.labelRequired,
  lowTolerance: ia.lowTolerance,
  measure: ia.measure,
  name: ia.name,
  opsCategory: ia.opsCategory,
  portionMethod: ia.portionMethod,
  productionSheetNotes: ia.productionSheetNotes,
  packingFacilityIds: [],
  packingFacilityNames: [],
  quantity: ia.quantity,
  readyToEat: false,
  sequenceNumber: ia.sequenceNumber,
  usageYield: ia.usageYield,
});

const getNewReplacementCiraState = (cira) => ({
  compoundIngredientRecipeId: cira.compoundIngredientRecipeId,
  brandName: cira.ingredient.brandName,
  highTolerance: cira.highTolerance,
  ingredient: cira.ingredient,
  ingredientPackagingId: cira.ingredientPackagingId,
  labelRequired: cira.labelRequired,
  lowTolerance: cira.lowTolerance,
  mealWhereUsedAsReplacementId: cira.mealWhereUsedAsReplacementId,
  measure: cira.measure,
  name: cira.name,
  opsCategory: cira.opsCategory,
  originalCiraId: cira.originalCiraId,
  packingFacilityIds: cira.packingFacilityIds,
  packingFacilityNames: cira.packingFacilityNames,
  portionMethod: cira.portionMethod,
  productionSheetNotes: cira.productionSheetNotes,
  quantity: cira.quantity,
  readyToEat: cira.readyToEat,
  sequenceNumber: cira.sequenceNumber,
  usageYield: cira.usageYield,
});

export const ACTIONS = {
  UPDATE_FORM_STATE: 'updateFormState',
  SHOW_REPLACEMENT_FORM: 'showReplacementForm',
  HIDE_REPLACEMENT_FORM: 'hideReplacementForm',
  SHOW_WARNING_MODAL: 'showWarningModal',
  HIDE_WARNING_MODAL: 'hideWarningModal',
  SHOW_VALIDATION_MODAL: 'showValidationModal',
  HIDE_VALIDATION_MODAL: 'hideValidationModal',
  HIDE_WARNING_MODAL_AND_REPLACEMENT_FORM: 'hideWarningModalAndReplacementForm',
  TOGGLE_EXPAND_ALL: 'toggleExpandAll',
  UPDATE_EXPANDED_CARDS: 'updateExpandedCards',
  SET_REPLACEMENTS_ARRAY_STATE: 'setReplacementsArrayState',
  SET_SELECTED_STATE: 'setSelectedState',
  SET_REPLACEMENT_STATE: 'setReplacementState',
  HANDLE_ADD_REPLACEMENT: 'handleAddReplacement',
  HANDLE_EDIT_REPLACEMENT: 'handleEditReplacement',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_FORM_STATE: {
      const { name, value } = action.payload;
      const updatedState = { ...state };
      updatedState.formState[name] = value;
      return updatedState;
    }
    case ACTIONS.SHOW_REPLACEMENT_FORM: {
      return { ...state, showReplacementForm: true };
    }
    case ACTIONS.HIDE_REPLACEMENT_FORM: {
      return {
        ...state,
        showReplacementForm: false,
      };
    }
    case ACTIONS.SHOW_WARNING_MODAL: {
      return { ...state, showWarningModal: true };
    }
    case ACTIONS.HIDE_WARNING_MODAL: {
      return { ...state, showWarningModal: false };
    }
    case ACTIONS.HIDE_WARNING_MODAL_AND_REPLACEMENT_FORM: {
      return {
        ...state,
        showWarningModal: false,
        showReplacementForm: false,
      };
    }
    case ACTIONS.SHOW_VALIDATION_MODAL: {
      return { ...state, showValidationModal: true };
    }
    case ACTIONS.HIDE_VALIDATION_MODAL: {
      return { ...state, showValidationModal: false };
    }
    case ACTIONS.TOGGLE_EXPAND_ALL: {
      return {
        ...state,
        allCardsExpanded: action.payload.value,
        expandedCards: [],
        collapsedCards: [],
      };
    }
    case ACTIONS.UPDATE_EXPANDED_CARDS: {
      const { expandUpdate } = action.payload;
      const updatedExpandedCardsArray = !state.expandedCards.includes(expandUpdate)
        ? [...state.expandedCards, expandUpdate]
        : state.expandedCards.filter((expandCurrent) => expandCurrent !== expandUpdate);
      const updatedCollapsedCardsArray = !state.collapsedCards.includes(expandUpdate)
        ? [...state.collapsedCards, expandUpdate]
        : state.collapsedCards.filter((collapsedCurrent) => collapsedCurrent !== expandUpdate);
      return {
        ...state,
        expandedCards: updatedExpandedCardsArray,
        collapsedCards: updatedCollapsedCardsArray,
      };
    }
    case ACTIONS.SET_SELECTED_STATE: {
      return { ...state, selectedState: action.payload.value };
    }
    case ACTIONS.SET_REPLACEMENT_STATE: {
      return { ...state, replacementState: action.payload.value };
    }
    case ACTIONS.HANDLE_ADD_REPLACEMENT: {
      const { selectedState, selectedIndex } = action.payload;
      return {
        ...state,
        showReplacementForm: true,
        selectedState,
        selectedIndex,
        replacementState: selectedState.ingredient.compoundIngredientRecipeId
          ? getNewReplacementCiraState(selectedState)
          : getNewReplacementIngredientState(selectedState),
      };
    }
    case ACTIONS.HANDLE_EDIT_REPLACEMENT: {
      const { selectedState, selectedIndex, replacementState, replacementIndex } = action.payload;
      return {
        ...state,
        showReplacementForm: true,
        selectedState,
        selectedIndex,
        replacementState,
        replacementIndex,
      };
    }

    default:
      return state;
  }
};

export const ReplacementsTabContext = createContext();
