import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';

import keys from 'lodash/keys';
import { FormControlLabel } from '@mui/material';

const Checkboxes = ({
  classes,
  choices,
  columns,
  selectedFormState,
  selectedFormStateName,
  handleFormState,
  disabledState,
}) => {
  const defaultState = {};
  choices.forEach((choice) => {
    const key = choice.id.toString();
    defaultState[key] = selectedFormState.includes(choice.id);
  });
  const [selectedState, setSelectedState] = useState(defaultState);

  const handleChange = (event) => {
    const newSelectedState = { ...selectedState, [event.target.name]: event.target.checked };
    setSelectedState(newSelectedState);

    const choiceIds = keys(newSelectedState).filter((key) => newSelectedState[key] === true);
    handleFormState({ target: { name: selectedFormStateName, value: choiceIds } });
  };

  const renderChoice = (choice) => {
    const choiceId = choice.id.toString();

    return (
      <Grid item xs={12 / columns} key={choice.id}>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedState[choiceId]}
              className={classes.checkbox}
              disableRipple
              name={choiceId}
              onChange={handleChange}
              disabled={disabledState}
            />
          }
          label={<span className={classes.inputLabel}>{choice.name}</span>}
        />
      </Grid>
    );
  };
  return <Grid container>{choices.map((choice) => renderChoice(choice))}</Grid>;
};

Checkboxes.propTypes = {
  classes: PropTypes.object.isRequired,
  choices: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }))
    .isRequired,
  columns: PropTypes.number,
  selectedFormState: PropTypes.array,
  selectedFormStateName: PropTypes.string.isRequired,
  handleFormState: PropTypes.func.isRequired,
  disabledState: PropTypes.bool,
};

Checkboxes.defaultProps = {
  columns: 1,
  selectedFormState: [],
  disabledState: false,
};

export default Checkboxes;
