import {
  CT_MEALS_REQUEST,
  CT_MEALS_SUCCESS,
  CT_MEALS_ERROR,
  CT_SET_CONCEPT,
  CT_RESET_ERROR_MESSAGE,
  CT_SET_CHECKED_MEALS,
  CT_FILTER_VIEWABLE_MEALS,
} from 'lib/constants';

const initialState = {
  id: null,
  fetching: false,
  error: false,
  errorMessage: null,
  viewableMeals: [],
  activeMeals: [],
  inactiveMeals: [],
  checkedMeals: [],
  allMeals: [],
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case CT_MEALS_REQUEST:
      return {
        ...state,
        error: false,
        fetching: true,
      };
    case CT_MEALS_SUCCESS:
      return {
        ...state,
        viewableMeals: action.response.meals.active,
        activeMeals: action.response.meals.active,
        inactiveMeals: action.response.meals.inactive,
        // sorting is needed to keep filtered meals ordered by run number
        allMeals: action.response.meals.active
          .concat(action.response.meals.inactive)
          .sort((meal1, meal2) => (meal1.versionId > meal2.versionId ? 1 : -1)),
        checkedMeals: action.response.meals.active.map((meal) => meal.versionId),
        error: false,
        fetching: false,
      };
    case CT_MEALS_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.error.message,
        fetching: false,
      };
    case CT_SET_CONCEPT:
      return {
        ...state,
        id: action.payload.conceptId,
      };
    case CT_RESET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: null,
      };
    case CT_SET_CHECKED_MEALS:
      return {
        ...state,
        checkedMeals: action.payload.newCheckedMeals,
      };
    case CT_FILTER_VIEWABLE_MEALS:
      return {
        ...state,
        viewableMeals: state.allMeals.filter((meal) => state.checkedMeals.includes(meal.versionId)),
      };
    default:
      return state;
  }
};
