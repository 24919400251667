import { APP_API_URI } from 'lib/constants';
import { GET } from 'lib/api';

export const fetchMeals = async ({ conceptId }) => {
  const response = await GET(`${APP_API_URI}/internal/culinary/comparison_tool/meals`, {
    params: {
      concept_id: conceptId,
    },
  });

  return response.data;
};
