// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
// Actions
import { setSelectedMealId, submitRemoveMeal } from 'redux/menuManager/menu/actions';
// Components
import DraggableTableRow from './DraggableTableRow';
// Styles
import styles from './styles';

const mapStateToProps = (state) => {
  return {
    locked: state.menuManager.menu.locked,
    selectedMealId: state.menuManager.menu.selectedMealId,
    retailMenuId: state.menuManager.allMenus.selectedMenuId,
    alternativeRanking: state.menuManager.menu.alternativeRanking,
  };
};

const mapDispatchToProps = {
  onSetSelectedMealId: setSelectedMealId,
  onSubmitRemoveMeal: submitRemoveMeal,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DraggableTableRow));
