import {
  DC_DISPUTES_REQUEST,
  DC_DISPUTES_SUCCESS,
  DC_DISPUTES_ERROR,
  DC_EVIDENCE_REQUEST,
  DC_EVIDENCE_SUCCESS,
  DC_EVIDENCE_ERROR,
} from 'lib/constants';

import { updateDisputeStatus } from 'lib/helpers/disputedCharges/modifyDisputes';

const initialState = {
  disputes: null,
  error: false,
  errorMessage: null,
  fetching: false,
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case DC_DISPUTES_REQUEST:
      return {
        ...state,
        error: false,
        fetching: true,
      };
    case DC_DISPUTES_SUCCESS:
      return {
        ...state,
        disputes: action.response.disputes,
        error: false,
        fetching: false,
      };
    case DC_DISPUTES_ERROR:
      return {
        error: true,
        errorMessage: action.error.message,
        fetching: false,
      };
    case DC_EVIDENCE_REQUEST: {
      const newDisputes = updateDisputeStatus(action.params.disputeId, state.disputes, 'sending');

      return {
        ...state,
        disputes: newDisputes,
        error: false,
      };
    }
    case DC_EVIDENCE_SUCCESS: {
      const newDisputes = updateDisputeStatus(action.params.disputeId, state.disputes, 'sent');

      return {
        ...state,
        disputes: newDisputes,
        error: false,
      };
    }
    case DC_EVIDENCE_ERROR: {
      const newDisputes = updateDisputeStatus(action.params.disputeId, state.disputes, 'failed');

      return {
        ...state,
        disputes: newDisputes,
        error: true,
        errorMessage: action.error.message,
      };
    }
    default:
      return state;
  }
};
