import { WHITE, BORDER_GREY, LIGHT_BLACK } from 'lib/constants';

export default {
  text: {
    marginBottom: '16px',
  },
  paperBox: {
    width: '500px',
    top: '200px',
    left: '50%',
    position: 'absolute',
    transform: 'translateX(-50%)',
    backgroundColor: WHITE,
    border: `1px solid ${BORDER_GREY}`,
    borderRadius: '4px',
    padding: '24px 16px',
    color: LIGHT_BLACK,
    textAlign: 'center',
  },
};
