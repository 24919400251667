import React from 'react';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

const GeographyPill = ({ classes, pill, onDelete }) => {
  if (pill.errorMessage !== undefined) {
    return (
      <Tooltip title={pill.errorMessage}>
        <Chip label={pill.code} onDelete={onDelete} className={classes.error} />
      </Tooltip>
    );
  }
  if (pill.warningMessage !== undefined) {
    return (
      <Tooltip title={pill.warningMessage}>
        <Chip label={pill.code} onDelete={onDelete} className={classes.warning} />
      </Tooltip>
    );
  }

  return <Chip onDelete={onDelete} label={pill.code} />;
};

GeographyPill.propTypes = {
  classes: PropTypes.object.isRequired,
  pill: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
};

GeographyPill.defaultProps = {
  onDelete: null,
};

export default GeographyPill;
