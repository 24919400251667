import { BACKGROUND_GREY, LIGHT_GREY, LIGHT_BLACK } from 'lib/constants';

export default {
  cancelButton: {
    color: LIGHT_GREY,
  },
  dialogTitle: {
    color: LIGHT_BLACK,
    backgroundColor: BACKGROUND_GREY,
  },
};
