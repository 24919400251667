import {
  CT_MEALS,
  CT_SET_CONCEPT,
  CT_RESET_ERROR_MESSAGE,
  CT_SET_CHECKED_MEALS,
  CT_FILTER_VIEWABLE_MEALS,
} from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import { fetchMeals } from './services';

export const getMeals = (conceptId) => {
  return async (dispatch) => {
    await dispatch(makeApiRequest({ type: CT_MEALS, method: fetchMeals, params: { conceptId } }));
  };
};

export const setConceptId = (conceptId) => ({
  type: CT_SET_CONCEPT,
  payload: { conceptId },
});

export const resetErrorMessage = () => ({
  type: CT_RESET_ERROR_MESSAGE,
});

export const setCheckedMeals = (newCheckedMeals) => ({
  type: CT_SET_CHECKED_MEALS,
  payload: { newCheckedMeals },
});

export const filterViewableMeals = () => ({
  type: CT_FILTER_VIEWABLE_MEALS,
});
