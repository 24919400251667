// string that is greater than 0 but less than or equal to 1 - requires leading 0 for decimal values
export const validUsageYield = (input) => {
  return input === undefined ? false : input.match(/^(0+\.[0-9]*[1-9][0-9]*|1(\.0+)?)$/);
};

// string that is greater than 0 - requires leading 0 for decimal values less than 1
export const validQuantity = (input) => {
  return input === undefined
    ? false
    : input.match(/^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/);
};

// measure is required for ingredients with a standard unit of "volume"
export const validMeasure = (input, isVolumeUnit) => {
  return isVolumeUnit ? !!input : true;
};
