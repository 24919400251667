// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import NutritionRow from './NutritionRow';

// Styles
import styles from './styles';

export default withStyles(styles)(NutritionRow);
