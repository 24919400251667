// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import AppLink from './AppLink';

// Styles
import styles from './styles';

export default withStyles(styles)(AppLink);
