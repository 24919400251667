import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

const AddIncidentButton = ({ classes, formStates, setFormStates, setButtonHeight }) => {
  const ref = useRef(null);

  const addIncidentForm = () => {
    const formIds = Object.keys(formStates);
    const formIdNumbers = formIds.map((formId) => Number(formId));
    const nextId = Math.max(...formIdNumbers) + 1;

    setFormStates({ ...formStates, [nextId]: {} });
  };

  useEffect(() => {
    if (ref) {
      setButtonHeight(ref.current.clientHeight);
    }
  });

  return (
    <div ref={ref} className={classes.root}>
      <Button className={classes.button} onClick={addIncidentForm} variant="outlined">
        <div className={classes.plusSign}>+</div>
        Add Another Incident
      </Button>
    </div>
  );
};

AddIncidentButton.propTypes = {
  classes: PropTypes.object.isRequired,
  formStates: PropTypes.object.isRequired,
  setFormStates: PropTypes.func.isRequired,
  setButtonHeight: PropTypes.func.isRequired,
};

export default AddIncidentButton;
