// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

// Actions
import { resetErrorMessage } from 'redux/comparisonTool/concept/actions';

// Components
import ConceptInput from './ConceptInput';

// Styles
import styles from './styles';

const mapStateToProps = (state) => ({
  conceptId: state.comparisonTool.concept.id,
  errorMessage: state.comparisonTool.concept.errorMessage,
});
const mapDispatchToProps = {
  onResetErrorMessage: resetErrorMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConceptInput));
