import {
  sameMenuPayload,
  crossMenuPayload,
  stagingGroupPayload,
  crossStagingGroupPayload,
} from './beautifulMenugridDataConstructor';

export const getSameMenuDropChanges = (menu, source, draggableId, destination) => {
  const oldSelection = menu.menuSelections.find(
    (menuSelection) => menuSelection.id === parseInt(draggableId, 10)
  );

  const newSelections = Array.from(menu.menuSelections);
  newSelections.splice(source.index, 1);
  newSelections.splice(destination.index, 0, oldSelection);

  const newMenu = {
    ...menu,
    menuSelections: newSelections,
  };
  const payload = sameMenuPayload(newMenu);

  return {
    repositionedMenus: [newMenu],
    payload,
  };
};

export const getStagingGroupDropChanges = (stagingGroup, source, draggableId, destination) => {
  const oldSelection = stagingGroup.menuSelections.find(
    (stagingGroupSelection) => stagingGroupSelection.id === parseInt(draggableId, 10)
  );

  const newSelections = Array.from(stagingGroup.menuSelections);
  newSelections.splice(source.index, 1);
  newSelections.splice(destination.index, 0, oldSelection);

  const newStagingGroup = {
    ...stagingGroup,
    menuSelections: newSelections,
  };
  const payload = stagingGroupPayload(newStagingGroup);

  return {
    repositionedStaging: [newStagingGroup],
    payload,
  };
};

export const getCrossStagingGroupDropChanges = (
  startMenu,
  endMenu,
  source,
  draggableId,
  destination
) => {
  const oldSelection = startMenu.menuSelections.find(
    (menuSelection) => menuSelection.id === parseInt(draggableId, 10)
  );
  const newMenuSelectionsStart = Array.from(startMenu.menuSelections);
  newMenuSelectionsStart.splice(source.index, 1);
  const newMenuSelectionsFinish = Array.from(endMenu.menuSelections);
  newMenuSelectionsFinish.splice(destination.index, 0, oldSelection);

  const newStartMenu = {
    ...startMenu,
    menuSelections: newMenuSelectionsStart,
  };
  const newEndMenu = {
    ...endMenu,
    menuSelections: newMenuSelectionsFinish,
  };

  const selectionToDestroy = { ...oldSelection, deleted: true };
  const { stagingPayload, menuPayload } = crossStagingGroupPayload(
    newStartMenu,
    newEndMenu,
    selectionToDestroy
  );

  return {
    repositionedMenus: [newStartMenu, newEndMenu],
    stagingPayload,
    menuPayload,
  };
};

export const getCrossMenuDropChanges = (startMenu, endMenu, source, draggableId, destination) => {
  const oldSelection = startMenu.menuSelections.find(
    (menuSelection) => menuSelection.id === parseInt(draggableId, 10)
  );
  const newMenuSelectionsStart = Array.from(startMenu.menuSelections);
  newMenuSelectionsStart.splice(source.index, 1);
  const newMenuSelectionsFinish = Array.from(endMenu.menuSelections);
  newMenuSelectionsFinish.splice(destination.index, 0, oldSelection);

  const newStartMenu = {
    ...startMenu,
    menuSelections: newMenuSelectionsStart,
  };
  const newEndMenu = {
    ...endMenu,
    menuSelections: newMenuSelectionsFinish,
  };

  const selectionToDestroy = { ...oldSelection, deleted: true };
  const payload = crossMenuPayload(newStartMenu, newEndMenu, selectionToDestroy);

  return {
    repositionedMenus: [newStartMenu, newEndMenu],
    payload,
  };
};
