// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import RecipeStepsRow from './RecipeStepsRow';

// Styles
import styles from './styles';

export default withStyles(styles)(RecipeStepsRow);
