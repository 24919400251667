import { LIGHT_BLACK, MEDIUM_RED, MEDIUM_GREY, HC_GREEN, BORDER_GREY, WHITE } from 'lib/constants';

export default {
  container: {
    padding: 24,
  },
  modal: {
    bgcolor: WHITE,
    padding: 16,
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
  },
  disabledCardContainer: {
    pointerEvents: 'none',
    opacity: 0.4,
  },
  ingredientInfoCard: {
    padding: '0px 16px 16px 16px',
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cancelButton: {
    color: '#666C73',
    marginLeft: '16px',
  },
  paper: {
    padding: 16,
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '100%',
  },
  inputLabel: {
    font: 'Roboto',
    weight: 400,
    fontSize: 14,
    lineHeight: '24px',
    color: LIGHT_BLACK,
  },
  whatYouNeed: {
    margin: '0 -16px 0 8px',
    padding: 0,
  },
  ingredientCard: {
    margin: '0 -16px 24px 24px',
    padding: 16,
    marginBottom: 24,
    overflow: 'visible',
  },
  collapsedIngredientCard: {
    padding: '8px 16px',
    margin: '0 -16px 16px 24px',
  },
  ingredientsSectionHeader: {
    margin: '24px -16px 24px 24px',
  },
  deleteButton: {
    margin: '16px 0px',
    padding: '4px 16px',
    color: WHITE,
    backgroundColor: MEDIUM_RED,
    '&:hover': {
      color: MEDIUM_RED,
    },
  },
  replacmentDeleteButton: {
    color: MEDIUM_RED,
  },
  replacementBottomRow: {
    alignItems: 'center',
    paddingTop: '8px',
  },
  greenText: {
    color: HC_GREEN,
  },
  greyText: {
    color: MEDIUM_GREY,
  },
  addIngredientButton: {
    color: HC_GREEN,
    margin: '0px 0px 16px 24px',
  },
  replacementIngredientButton: {
    color: MEDIUM_GREY,
    marginLeft: '16px',
  },
  label: {
    font: 'Roboto',
    weight: 400,
    fontSize: 14,
    letterSpacing: '0.15px',
    color: MEDIUM_GREY,
    marginBottom: 0,
  },
  helperText: {
    color: MEDIUM_GREY,
    fontSize: '12px',
    marginTop: '0px',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  hr: {
    backgroundColor: BORDER_GREY,
    height: '1px',
    border: 'none',
    margin: '24px 0',
  },
  checkbox: {
    margin: 2,
    padding: 2,
  },
  ingredientTextSearchBox: {
    marginTop: '16px',
  },
  fs_14: {
    fontSize: 14,
  },
  mb_0: {
    marginBottom: '0px',
  },
  mb_8: {
    marginBottom: '8px',
  },
  ml_24: {
    marginLeft: '24px',
  },
  pl_8: {
    paddingLeft: '8px',
  },
  pr_0: {
    paddingRight: '0px',
  },
  ai_c: {
    alignItems: 'center',
  },
  textAlignRight: {
    textAlignLast: 'right',
  },
  disposableItemCard: {
    overflow: 'visible',
  },
  tooltip: {
    '& .MuiTooltip-tooltip': {
      display: 'flex',
      maxWidth: '250px',
      fontSize: '12px',
      zIndex: '5000',
    },
  },
};
