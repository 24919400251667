export const FVC_CHANGE_TYPE_SET_QUANTITY = 'set_quantity';
export const FVC_CHANGE_TYPE_TRANSFER_QUANTITY = 'transfer_quantity';
export const FVC_CHANGE_TYPE_ADD_SUBTRACT_QUANTITY = 'add_subtract_quantity';
export const FVC_CHANGE_TYPE_CHANGE_PRODUCTION_DAY = 'change_production_day';
export const FVC_OPERATION_ADD = 'add';
export const FVC_OPERATION_SUBTRACT = 'subtract';
export const FVC_VOLUME_TYPE_QUANTITY = 'quantity';
export const FVC_VOLUME_TYPE_PERCENT = 'percent';
export const FVC_BALANCING_TYPE_RR = 'round_robin';
export const FVC_BALANCING_TYPE_AS = 'acceptable_shrink';

export const FVC_CHANGE_TYPES = [
  {
    name: 'Set Quantity',
    value: FVC_CHANGE_TYPE_SET_QUANTITY,
  },
  {
    name: 'Transfer Quantity',
    value: FVC_CHANGE_TYPE_TRANSFER_QUANTITY,
  },
  {
    name: 'Add / Subtract Quantity',
    value: FVC_CHANGE_TYPE_ADD_SUBTRACT_QUANTITY,
  },
  {
    name: 'Change Production Day',
    value: FVC_CHANGE_TYPE_CHANGE_PRODUCTION_DAY,
  },
];

export const FVC_OPERATION_TYPES = [
  {
    name: 'Add',
    value: FVC_OPERATION_ADD,
  },
  {
    name: 'Subtract',
    value: FVC_OPERATION_SUBTRACT,
  },
];

export const FVC_VOLUME_TYPES = [
  {
    display_name: '%',
    value: FVC_VOLUME_TYPE_PERCENT,
  },
  {
    display_name: 'Qty',
    value: FVC_VOLUME_TYPE_QUANTITY,
  },
];

export const FVC_BALANCING_TYPES = [
  {
    display_name: 'Round Robin',
    value: FVC_BALANCING_TYPE_RR,
  },
  {
    display_name: 'Acceptable Shrink',
    value: FVC_BALANCING_TYPE_AS,
  },
];
export const FVC_BALANCING_TYPES_DEFAULT = FVC_BALANCING_TYPES[0];
