import { map, every, isEmpty } from 'lodash';

export const sanitizedCookingFacts = (arrayOfCookingFacts) => {
  return map(arrayOfCookingFacts, ({ cookWithin, difficultyLevel, prepTime, spiceLevel }) => {
    return [cookWithin, difficultyLevel, prepTime, spiceLevel];
  });
};

const propertySameForEveryCookingFact = (occurences) => {
  const firstCookingFact = occurences[0]; // pull first fact as basis for comparison
  return every(occurences, (cf) => {
    return cf === firstCookingFact;
  });
};

export const isPropertyDifferent = (occurences, property, numMeals) => {
  if (isEmpty(occurences)) return false;
  if (occurences.length !== numMeals) return true; // if one or more of the versions is missing a step

  return !propertySameForEveryCookingFact(occurences, property);
};
