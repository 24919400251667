import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import { mealShape } from 'lib/helpers/comparisonTool/propShapes';

// Material
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

// Components
import MealsList from '../MealsList';

const SelectVersions = ({
  activeMeals,
  inactiveMeals,
  openSelectVersions,
  handleCloseSelectVerisons,
  onFilterViewableMeals,
  classes,
}) => {
  const handleClickDone = () => {
    handleCloseSelectVerisons();
    onFilterViewableMeals();
  };

  return (
    <Dialog
      open={openSelectVersions}
      onClose={handleCloseSelectVerisons}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      data-testid="select-versions-dialog"
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        Select versions to compare
      </DialogTitle>
      <DialogContent>
        <MealsList activeMeals={activeMeals} inactiveMeals={inactiveMeals} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseSelectVerisons} className={classes.cancelButton} size="small">
          Close
        </Button>
        <Button onClick={handleClickDone} color="primary" size="small">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SelectVersions.propTypes = {
  activeMeals: PropTypes.arrayOf(mealShape).isRequired,
  inactiveMeals: PropTypes.arrayOf(mealShape).isRequired,
  openSelectVersions: PropTypes.bool,
  handleCloseSelectVerisons: PropTypes.func.isRequired,
  onFilterViewableMeals: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

SelectVersions.defaultProps = {
  openSelectVersions: false,
};

export default SelectVersions;
