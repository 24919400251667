import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { postMeal, postMealPhotos, postMealRecipeStepsTab } from 'redux/mealEditForm/meal/actions';

import Button from '@mui/material/Button';

const SaveChangesButton = ({ disabled, mealId, mealParams, savePhotos, saveRecipeSteps }) => {
  const isSubmitting = useSelector((state) => state.mealEditForm.meal.submitting);

  const dispatch = useDispatch();

  const postChanges = () => {
    if (savePhotos) {
      dispatch(postMealPhotos({ mealId, mealParams }));
    } else if (saveRecipeSteps) {
      dispatch(postMealRecipeStepsTab({ mealId, mealParams }));
    } else {
      dispatch(postMeal({ mealId, mealParams }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    postChanges();
  };

  return (
    <Button
      color="primary"
      onClick={handleSubmit}
      disabled={disabled || isSubmitting}
      variant="contained"
    >
      Save Changes
    </Button>
  );
};

SaveChangesButton.propTypes = {
  disabled: PropTypes.bool,
  mealId: PropTypes.number.isRequired,
  mealParams: PropTypes.object.isRequired,
  savePhotos: PropTypes.bool,
  saveRecipeSteps: PropTypes.bool,
};

SaveChangesButton.defaultProps = {
  disabled: false,
  savePhotos: false,
  saveRecipeSteps: false,
};

export default SaveChangesButton;
