import { MM_TOGGLE_SNACKBAR } from 'lib/constants';

export const toggleSnackBar = (open, message) => {
  return async (dispatch) => {
    dispatch({
      type: MM_TOGGLE_SNACKBAR,
      open,
      message,
    });
  };
};
