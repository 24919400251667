import { map, every, isEmpty } from 'lodash';

export const sanitizedBeforeYouCook = (arrayOfInstructions) => {
  return map(arrayOfInstructions, (beforeYouCook) =>
    map(beforeYouCook, ({ instruction }) => {
      return [instruction];
    })
  );
};

const propertySameForInstruction = (beforeYouCookGroup) => {
  const firstBeforeYouCook = beforeYouCookGroup[0]; // pull first step as basis for comparison
  return every(beforeYouCookGroup, (byc) => {
    return byc.instruction === firstBeforeYouCook.instruction;
  });
};

export const setHighlightBeforeYouCookGroup = (beforeYouCookGroup, numMeals) => {
  if (isEmpty(beforeYouCookGroup)) return false;
  if (beforeYouCookGroup.length !== numMeals) return true; // if one or more of the versions is missing an instruction

  return !propertySameForInstruction(beforeYouCookGroup);
};
