import {
  rowLabel,
  icon,
  iconCell,
  greyed,
  collapse,
  expandingCell,
  listContentSameBlock,
  tableRowGreenBorder,
} from 'lib/styles/comparisonTool';

import { LIGHT_BLACK, MEDIUM_GREY, LIGHTEST_GREY, LIGHT_GREEN } from 'lib/constants';

export default {
  rowLabel,
  iconCell,
  icon,
  greyed,
  expandingCell,
  collapse,
  listContentSameBlock,
  utensil: {
    color: LIGHT_BLACK,
    fontWeight: '400',
    fontSize: '12px',
  },
  equipmentItem: {
    color: LIGHT_BLACK,
    fontWeight: '400',
    fontSize: '12px',
  },
  equipmentQuantity: {
    color: MEDIUM_GREY,
    marginLeft: '8px',
  },
  equipmentItemMissing: {
    backgroundColor: LIGHTEST_GREY,
    color: MEDIUM_GREY,
  },
  highlight: {
    backgroundColor: LIGHT_GREEN,
  },
  tableRowGreenBorder,
};
