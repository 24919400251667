import React from 'react';
import PropTypes from 'prop-types';

// Components
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const BackToAdmin = ({ url, text, classes }) => {
  return (
    <Paper elevation={0} className={classes.root}>
      <Link href={url} className={classes.link}>
        <ArrowBackIosIcon className={classes.arrow} />
        {text}
      </Link>
    </Paper>
  );
};

BackToAdmin.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default BackToAdmin;
