import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import AppHeader from 'components/shared/AppHeader';
import AlertManager from 'components/shared/AlertManager';

import styles from './styles';

const Page = ({ title, children }) => {
  return (
    <>
      <AppHeader text={title} />
      <Box sx={styles.root}>
        <AlertManager>
          <Link to="/retail" style={styles.retailPath}>
            Retail Dashboard
          </Link>
          <Typography sx={styles.navPath}> / {title}</Typography>
          <Container sx={styles.container}>
            <Stack direction="column" spacing={2} justifyContent="flex-start">
              {children}
            </Stack>
          </Container>
        </AlertManager>
      </Box>
    </>
  );
};

Page.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Page;
