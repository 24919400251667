// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

// Actions
import { filterViewableMeals } from 'redux/comparisonTool/concept/actions';

import SelectVersionsPrompt from './SelectVersionsPrompt';

import styles from './styles';

const mapStateToProps = (state) => ({
  activeMeals: state.comparisonTool.concept.activeMeals,
  inactiveMeals: state.comparisonTool.concept.inactiveMeals,
});

const mapDispatchToProps = {
  onFilterViewableMeals: filterViewableMeals,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SelectVersionsPrompt));
