// Dependencies
import React from 'react';
import PropTypes from 'prop-types';
import round from 'lodash/round';

// Material Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { centsToDollars } from 'lib/helpers/money';
import { formOptionsShape } from 'lib/helpers/incidentManager/propShapes';

import { REMEDATION_CATEGORY_IDS } from 'lib/constants';

const CreditHelperText = ({
  formOptions,
  remediationCategoryId,
  suggestedPartialCredit,
  classes,
}) => {
  const allRemediationCategories = formOptions.userRemediationCategories.concat(
    formOptions.deliveryRemediationCategories
  );
  const remediationCategory = allRemediationCategories.find(
    (category) => category.id === remediationCategoryId
  );

  const partialCreditSuggestion = () => {
    if (!Object.values(REMEDATION_CATEGORY_IDS).includes(remediationCategoryId)) return '';

    const { fiftyPercentCreditCents, twentyFivePercentCreditCents } = suggestedPartialCredit;
    // If one of these remedation categories for a user error are selected, there will be a credit suggestion remediation for the user of either 50% or 25% of the value order.
    if (remediationCategoryId === REMEDATION_CATEGORY_IDS.USER_ERROR_ORDER_UNWANTED_ID) {
      const orderValue = round(centsToDollars(fiftyPercentCreditCents), 2);
      return ` ($${orderValue})`; // 50% of the value of the order
    }

    if (remediationCategoryId === REMEDATION_CATEGORY_IDS.USER_ERROR_ORDER_WANTED_ID) {
      const orderValue = round(centsToDollars(twentyFivePercentCreditCents), 2);
      return ` ($${orderValue})`; // 25% of the value of the order
    }
    return '';
  };

  if (remediationCategory.helperText) {
    return (
      <Alert severity="info" className={classes.alert}>
        <AlertTitle>{remediationCategory.name}</AlertTitle>
        {remediationCategory.helperText}
        {partialCreditSuggestion()}
      </Alert>
    );
  }
  return null;
};

CreditHelperText.propTypes = {
  formOptions: formOptionsShape.isRequired,
  remediationCategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  classes: PropTypes.object.isRequired,
  suggestedPartialCredit: PropTypes.object.isRequired,
};

export default CreditHelperText;
