import { BACKGROUND_GREY } from 'lib/constants';

export default {
  container: {
    padding: 24,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: BACKGROUND_GREY,
    },
  },
};
