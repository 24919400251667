export default {
  assortmentCard: {
    margin: 'auto',
    marginTop: '33px',
    borderRadius: 4,
    fontFamily: 'Roboto',
    '&:nth-child(2)': {
      marginTop: '32px',
    },
  },
  alternateRankCard: {
    width: '912px',
    marginLeft: 0,
  },
  tableHeader: {
    display: 'flex',
    fontSize: '20px',
    fontWeight: 500,
    justifyContent: 'space-between',
    padding: '0px 24px 0px 16px',
  },
  categoryTitle: {
    marginRight: '8px',
    color: '#404851',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '20px',
    color: '#8C9196',
  },
  columnTitle: {
    whiteSpace: 'nowrap',
  },
};
