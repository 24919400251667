import { MEDIUM_GREY, LIGHT_GREY, HC_GREEN } from 'lib/constants';

export default {
  menuId: {
    color: MEDIUM_GREY,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'left',
    marginRight: '6px',
    '&:hover': {
      color: HC_GREEN,
      textDecoration: 'underline',
    },
  },
  endsAtDate: {
    color: LIGHT_GREY,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'left',
  },
};
