import { MEDIUM_GREY, LIGHT_GREY } from 'lib/constants';

export default {
  labelText: {
    color: MEDIUM_GREY,
    fontSize: 10,
    paddingLeft: 12,
  },
  disabledText: {
    color: LIGHT_GREY,
  },
  dropdownHelperText: {
    color: MEDIUM_GREY,
    fontSize: 12,
    paddingLeft: 12,
    paddingBottom: 8,
  },
  errorMessage: {
    color: 'red',
  },
};
