import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import { sortedMenus } from 'lib/helpers/comparisonTool';

// Material
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import withStyles from '@mui/styles/withStyles';
import styles from './styles';

// Components
import DisplayGroupChip from './DisplayGroupChip';

const MealListItem = (props) => {
  const { meal, classes } = props;
  const menus = sortedMenus(meal);

  return (
    <Grid
      container
      className={classes.mealListItem}
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={4}>
        <span className={classes.mealIdText}>{`${meal.id}`}</span>
        {`${meal.versionId}`}
      </Grid>
      <Grid item xs={6}>
        {menus.map((menu) => {
          return (
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              key={`${menu.type}-${menu.id}`}
            >
              <Grid item xs={5}>
                <Box display="flex" justifyContent="flex-end">
                  {`${menu.type} #${menu.id}`}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end">
                  <DisplayGroupChip displayGroup={`${menu.displayGroup}`} />
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

MealListItem.propTypes = {
  meal: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MealListItem, DisplayGroupChip);
