import React from 'react';
import { Button } from '@mui/material';
import { useListContext } from 'react-admin';
import PropTypes from 'prop-types';

const ClearFiltersInput = (props) => {
  const { setFilters } = useListContext();

  return (
    <Button color="primary" onClick={() => setFilters({})}>
      {props.labelText}
    </Button>
  );
};

ClearFiltersInput.propTypes = {
  labelText: PropTypes.string.isRequired,
};

export default ClearFiltersInput;
