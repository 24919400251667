import { useReducer, useMemo } from 'react';
import { initialState, reducer } from './reducer';

export const useFormState = () => {
  const [formState, dispatch] = useReducer(reducer, initialState);

  const setFormState = (field, value) => {
    dispatch({ type: 'SET_FORM_STATE', field, value });
  };

  const setFormData = (field, value) => {
    dispatch({ type: 'SET_FORM_DATA', field, value });
  };

  const setFormValidation = (field, value) => {
    dispatch({ type: 'SET_FORM_VALIDATION', field, value });
  };

  const setFormStateFromBehavioralDiscount = (behavioralDiscount) => {
    dispatch({ type: 'SET_FORM_STATE_FROM_BEHAVIORAL_DISCOUNT', behavioralDiscount });
  };

  const revertForm = (behavioralDiscount) => {
    dispatch({ type: 'REVERT_FORM', behavioralDiscount });
  };

  const resetForm = () => {
    dispatch({ type: 'RESET_FORM' });
  };

  const changeServiceType = (newServiceType) => {
    dispatch({ type: 'CHANGE_SERVICE_TYPE', newServiceType });
  };

  const changeDiscountFrequency = (newFrequency) => {
    dispatch({ type: 'CHANGE_DISCOUNT_FREQUENCY', newFrequency });
  };

  return {
    formState,
    actions: useMemo(() => {
      return {
        changeDiscountFrequency,
        changeServiceType,
        resetForm,
        revertForm,
        setFormData,
        setFormState,
        setFormStateFromBehavioralDiscount,
        setFormValidation,
      };
    }, []),
  };
};
