// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import LastUpdatedRow from './LastUpdatedRow';

// Styles
import styles from './styles';

export default withStyles(styles)(LastUpdatedRow);
