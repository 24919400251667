import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import AddMealsErrorMessageSection from './AddMealsErrorMessageSection';

// Styles
import styles from './styles';

const mapStateToProps = (state) => ({
  errorMessage: state.menuManager.menu.errorMessage,
});

export default connect(mapStateToProps)(withStyles(styles)(AddMealsErrorMessageSection));
