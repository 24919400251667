import {
  CIRF_FORM_OPTIONS_REQUEST,
  CIRF_FORM_OPTIONS_SUCCESS,
  CIRF_FORM_OPTIONS_ERROR,
} from 'lib/constants';

export const initialState = {
  formOptions: null,
  fetching: false,
  error: false,
  errorMessage: null,
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case CIRF_FORM_OPTIONS_REQUEST:
      return {
        ...state,
        error: false,
        fetching: true,
      };
    case CIRF_FORM_OPTIONS_SUCCESS:
      return {
        ...state,
        formOptions: action.response.formOptions,
        error: false,
        fetching: false,
      };
    case CIRF_FORM_OPTIONS_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.error.message,
        fetching: false,
      };
    default:
      return state;
  }
};
