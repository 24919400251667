import { MEDIUM_GREY, HC_GREEN } from 'lib/constants';

export default {
  root: {
    textTransform: 'capitalize',
    backgroundColor: 'transparent',
    color: MEDIUM_GREY,
    border: 'none',
    borderRadius: '4px',
    fontWeight: '500',
    fontSize: '12px',
    padding: '1px 7px 2px',
    '&:hover': {
      color: HC_GREEN,
    },
    '&:focus': {
      outline: 'none',
    },
  },
};
