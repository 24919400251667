import { RED, BORDER_GREY } from 'lib/constants';

export default {
  paper: {
    padding: 16,
  },
  deleteButton: {
    color: RED,
    borderColor: RED,
    marginTop: '16px',
  },
  recipeStepDivider: {
    margin: '16px 8px',
    backgroundColor: BORDER_GREY,
    height: '1px',
    border: 'none',
    width: '100%',
  },
  removeStepContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addButton: {
    marginTop: '16px',
  },
  recipeStepTitle: {
    marginTop: '8px',
  },
};
