import {
  SINGLE_MENU_DATA_REQUEST,
  SINGLE_MENU_DATA_SUCCESS,
  SINGLE_MENU_DATA_ERROR,
} from 'lib/constants';

const initialState = {
  menu: {},
  error: false,
  fetching: true,
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case SINGLE_MENU_DATA_REQUEST:
      return {
        ...state,
        error: false,
        fetching: true,
      };
    case SINGLE_MENU_DATA_SUCCESS:
      return {
        ...state,
        menu: action.response.menu,
        error: false,
        fetching: false,
      };
    case SINGLE_MENU_DATA_ERROR:
      return {
        ...state,
        error: true,
        fetching: false,
      };
    default:
      return state;
  }
};
