import {
  HC_GREEN,
  LIGHT_BLACK,
  LIGHTEST_GREY,
  LIGHT_GREEN,
  LIGHT_GREY,
  MEDIUM_GREY,
  ORANGE_100,
  WHITE,
} from 'lib/constants';

export default {
  chip: {
    minWidth: '76px',
  },
  familyChip: {
    color: WHITE,
    backgroundColor: ORANGE_100,
    minWidth: '76px',
  },
  standardChip: {
    color: WHITE,
    backgroundColor: HC_GREEN,
    minWidth: '76px',
  },
  cancelButton: {
    color: LIGHT_GREY,
    borderColor: LIGHT_GREY,
  },
  dialogTitle: {
    color: LIGHT_BLACK,
  },
  dialogTitleText: {
    display: 'flex',
    justifyContent: 'center',
  },
  mealIdText: {
    fontWeight: '800',
    paddingRight: '10px',
  },
  sourceMealTitle: {
    color: MEDIUM_GREY,
  },
  sourceMealBox: {
    backgroundColor: LIGHTEST_GREY,
    paddingLeft: '8px',
    paddingRight: '8px',
    margin: 0,
    width: '100%',
  },
  sourceMealGridItem: {
    paddingBottom: '10px',
    paddingTop: '0px !important',
  },
  mealListItem: {
    paddingRight: '8px',
  },
  listItemButton: {
    padding: 0,
  },
  mealListContainer: {
    paddingLeft: '0px',
    paddingRight: '0px',
    margin: 0,
  },
  list: {
    width: '98%',
  },
  listItem: {
    '&:nth-of-type(odd)': {
      backgroundColor: LIGHTEST_GREY,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  replicationAccordion: {
    border: `1px solid ${LIGHTEST_GREY}`,
    boxShadow: 'none',
    '&:before': {
      border: `1px solid ${LIGHTEST_GREY}`,
    },
    maxWidth: '420px',
  },
  replicationAccordionHeader: {
    border: `1px solid ${LIGHTEST_GREY}`,
    display: 'flex',
    flexDirection: 'column',
    color: HC_GREEN,
    paddingTop: '4px',
    margin: '0px',
    '&:hover': {
      backgroundColor: LIGHT_GREEN,
    },
    '&:first-child': {
      '&:first-child .MuiAccordionSummary-content': {
        margin: '0px !important',
        padding: '0px',
        justifyContent: 'center',
        flexDirection: 'column',
      },
    },
  },
  replicationAccordionHeaderText: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
  },
  replicationAccordionSubheader: {
    fontSize: '12px',
    color: MEDIUM_GREY,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  replicationAccordionContent: {
    display: 'flex',
    fontSize: '12px',
    justifyContent: 'space-between',
    padding: '8px 12px 4px 12px',
    '&:last-child': {
      boxShadow: 'none',
    },
    '&:first-child svg': {
      color: HC_GREEN,
      marginRight: '3px',
    },
    '&:first-child .MuiGrid-item': {
      paddingTop: '0px',
    },
  },
  replicationListItem: {
    color: MEDIUM_GREY,
    paddingTop: '0px',
  },
};
