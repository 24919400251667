// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Material
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import withStyles from '@mui/styles/withStyles';
import styles from './styles';

const ReplicateFieldsAccordion = ({ classes }) => {
  const replicationFields = [
    'Title',
    'Subtitle',
    'Chef',
    'What You Need',
    'Disposable Items',
    'Before You Cook',
    'Dietary Preferences',
    'Dietary Restrictions',
    'Recipe Steps',
    'Cooking Facts',
    'Equipment',
    'Description',
  ];

  return (
    <Accordion disableGutters className={classes.replicationAccordion}>
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ExpandMoreIcon />}
        className={classes.replicationAccordionHeader}
      >
        <span className={classes.replicationAccordionHeaderText}>Show Replicable Attributes</span>
        <span className={classes.replicationAccordionSubheader}>
          Listed fields will be copied over from source meal version to selected meal versions
        </span>
      </AccordionSummary>
      <AccordionDetails className={classes.replicationAccordionContent}>
        <List>
          <Grid container spacing={2}>
            {replicationFields.map((replicationField, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item xs={6} key={index}>
                  <ListItem className={classes.replicationListItem} disablePadding>
                    <LibraryAddCheckIcon viewBox="0 0 30 24" />
                    <ListItemText primary={replicationField} />
                  </ListItem>
                </Grid>
              );
            })}
          </Grid>
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

ReplicateFieldsAccordion.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReplicateFieldsAccordion);
