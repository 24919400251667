// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import ImagesRow from './ImagesRow';

// Styles
import styles from './styles';

export default withStyles(styles)(ImagesRow);
