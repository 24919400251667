import {
  ISW_SWAPPABLE_INGREDIENT_ERROR,
  ISW_SWAPPABLE_INGREDIENT_REQUEST,
  ISW_SWAPPABLE_INGREDIENT_SUCCESS,
  ISW_SWAPPABLE_INGREDIENT_MEALS_ERROR,
  ISW_SWAPPABLE_INGREDIENT_MEALS_REQUEST,
  ISW_SWAPPABLE_INGREDIENT_MEALS_SUCCESS,
  ISW_SWAP_INGREDIENT_REQUEST,
  ISW_SWAP_INGREDIENT_SUCCESS,
  ISW_SWAP_INGREDIENT_ERROR,
  ISW_RESET_ERROR_MESSAGE,
} from 'lib/constants';

// Helpers
import { updateSwapStatus, insertUpdatedIngredientAssignment } from 'lib/helpers/ingredientSwapper';

const initialState = {
  id: null,
  name: null,
  brandName: null,
  displayName: null,
  standardUnit: null,
  state: null,
  usageYield: null,
  adminIngredientLink: '',
  ingredientAssignments: [],

  error: false,
  errorMessage: null,
  fetching: false,
  loaded: false,
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case ISW_SWAPPABLE_INGREDIENT_REQUEST:
      return {
        ...state,
        error: false,
        fetching: true,
        loaded: false,
      };
    case ISW_SWAPPABLE_INGREDIENT_SUCCESS:
      return {
        ...state,
        id: action.response.ingredient.id,
        name: action.response.ingredient.name,
        brandName: action.response.ingredient.brandName,
        displayName: action.response.ingredient.displayName,
        standardUnit: action.response.ingredient.standardUnit,
        state: action.response.ingredient.state,
        usageYield: action.response.ingredient.usageYield.toString(),
        adminIngredientLink: action.response.ingredient.adminIngredientLink,
        ingredientAssignments: action.response.ingredient.ingredientAssignments,

        error: false,
        fetching: false,
        loaded: true,
      };
    case ISW_SWAPPABLE_INGREDIENT_ERROR:
      return {
        ...state,
        error: true,
        fetching: false,
        loaded: false,
        errorMessage: action.error.message,
      };
    case ISW_SWAPPABLE_INGREDIENT_MEALS_REQUEST:
      return {
        ...state,
        error: false,
      };
    case ISW_SWAPPABLE_INGREDIENT_MEALS_SUCCESS:
      return {
        ...state,
        ingredientAssignments: action.response.ingredient.ingredientAssignments,
        error: false,
      };
    case ISW_SWAPPABLE_INGREDIENT_MEALS_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.error.message,
      };
    case ISW_SWAP_INGREDIENT_REQUEST: {
      const updatedSwapStatusAssignments = updateSwapStatus(
        action.params.swappableIngredientAssignment,
        state.ingredientAssignments,
        'swapping'
      );

      return {
        ...state,
        ingredientAssignments: updatedSwapStatusAssignments,
      };
    }
    case ISW_SWAP_INGREDIENT_SUCCESS: {
      const updatedAssignments = insertUpdatedIngredientAssignment(
        action.response.ingredientAssignment,
        state.ingredientAssignments
      );

      return {
        ...state,
        ingredientAssignments: updatedAssignments,
      };
    }
    case ISW_SWAP_INGREDIENT_ERROR: {
      const updatedErrorAssignments = insertUpdatedIngredientAssignment(
        action.response.ingredientAssignment,
        state.ingredientAssignments
      );

      return {
        ...state,
        ingredientAssignments: updatedErrorAssignments,
        errorMessages: action.response.errorMessages, // not being used on the frontend yet
      };
    }
    case ISW_RESET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: null,
      };
    default:
      return state;
  }
};
