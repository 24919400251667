import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

const RemoveIncidentButton = ({ classes, formId, formStates, setFormStates }) => {
  const removeIncidentForm = () => {
    const newFormStates = formStates;
    delete newFormStates[formId];

    setFormStates({ ...newFormStates });
  };

  if (formId === '0') {
    return null;
  }

  return (
    <Button className={classes.button} onClick={removeIncidentForm}>
      Remove
    </Button>
  );
};

RemoveIncidentButton.propTypes = {
  classes: PropTypes.object.isRequired,
  formId: PropTypes.string.isRequired,
  formStates: PropTypes.object.isRequired,
  setFormStates: PropTypes.func.isRequired,
};

export default RemoveIncidentButton;
