import { LIGHT_BLACK } from 'lib/constants';

export default {
  container: {
    padding: 24,
  },
  paper: {
    padding: 16,
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '100%',
  },
  inputLabel: {
    font: 'Roboto',
    weight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: LIGHT_BLACK,
  },
};
