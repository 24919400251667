// Dependencies
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

// Helpers
import { mealShape } from 'lib/helpers/comparisonTool/propShapes';
import { compareNumberItems } from 'lib/helpers/comparisonTool';

// Components
import PhotoCard from '../PhotoCard';

const ImagesRow = ({ viewableMeals, classes }) => {
  // State methods
  const [openCollapsible, setOpenCollapsible] = useState(false);
  const [hasDifferences, setDifferences] = useState(false);

  // Lifecycle methods
  const checkForDifferences = useCallback(() => {
    if (viewableMeals.length !== 0) {
      return compareNumberItems(
        viewableMeals.map((meal) => {
          return meal.photos;
        })
      );
    }
    return false;
  }, [viewableMeals]);

  useEffect(() => {
    setDifferences(checkForDifferences(viewableMeals));
  }, [setDifferences, checkForDifferences, viewableMeals]);

  // Handler methods
  const handleCollapsibleClick = () => {
    setOpenCollapsible(!openCollapsible);
  };

  return (
    <TableRow>
      <TableCell
        className={clsx(classes.iconCell, hasDifferences && classes.tableRowGreenBorder)}
        onClick={handleCollapsibleClick}
      >
        {openCollapsible ? (
          <ExpandMore className={classes.icon} />
        ) : (
          <ChevronRightIcon className={classes.icon} />
        )}
      </TableCell>
      <TableCell className={classes.rowLabel} onClick={handleCollapsibleClick}>
        Images
      </TableCell>
      {viewableMeals.map((meal) => (
        <TableCell
          key={`images.${meal.versionId}`}
          className={clsx(classes.expandingCell, meal.inactive && classes.greyed)}
        >
          <div className={classes.listContentSameBlock}>{`${meal.photos.length} Images`}</div>
          <Collapse in={openCollapsible} unmountOnExit className={classes.collapse}>
            {meal.photos.map((photo) => (
              <PhotoCard photo={photo} key={`${meal.versionId}-${photo.id}`} />
            ))}
          </Collapse>
        </TableCell>
      ))}
    </TableRow>
  );
};

ImagesRow.propTypes = {
  viewableMeals: PropTypes.arrayOf(mealShape).isRequired,
  classes: PropTypes.object.isRequired,
};

export default ImagesRow;
