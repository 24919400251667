import { LIGHT_BLACK } from 'lib/constants';

export default {
  header: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '0.15px',
    color: LIGHT_BLACK,
    padding: '0 0 8px 0',
    margin: 0,
  },
};
