// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Components
import SearchField from './SearchField';
import SearchResults from './SearchResults';
import AssignmentFields from './AssignmentFields';
import Error from './Error';

const TargetIngredientSelector = ({ targetIngredient, targetIngredientError }) => {
  // Rendering
  return (
    <>
      <SearchField />
      {targetIngredient.loaded && <SearchResults targetIngredient={targetIngredient} />}
      {targetIngredient.loaded && <AssignmentFields targetIngredient={targetIngredient} />}
      {targetIngredientError && <Error />}
    </>
  );
};

TargetIngredientSelector.propTypes = {
  targetIngredient: PropTypes.shape({
    brandName: PropTypes.string,
    displayName: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    standardUnit: PropTypes.string,
    state: PropTypes.string,
    usageYield: PropTypes.string,
    adminIngredientLink: PropTypes.string,
    quantity: PropTypes.string,
    measure: PropTypes.string,
    loaded: PropTypes.bool,
  }),
  targetIngredientError: PropTypes.bool,
};

TargetIngredientSelector.defaultProps = {
  targetIngredient: null,
  targetIngredientError: false,
};

export default TargetIngredientSelector;
