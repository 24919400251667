// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Material Components
import Alert from '@mui/material/Alert';

const Error = ({ classes }) => {
  // Rendering
  return (
    <>
      <hr className={classes.hr} />
      <Alert severity="warning" className={classes.error}>
        No ingredients found with this ID
      </Alert>
    </>
  );
};

Error.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Error;
