// Dependencies
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { mapValues, groupBy, flatMapDeep } from 'lodash';
import parse from 'html-react-parser';
import clsx from 'clsx';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

// Helpers
import { mealShape } from 'lib/helpers/comparisonTool/propShapes';
import {
  sanitizedBeforeYouCook,
  compareTextBlobs,
  setHighlightBeforeYouCookGroup,
} from 'lib/helpers/comparisonTool';

const BeforeYouCookRow = ({ viewableMeals, classes }) => {
  // State methods
  const [openCollapsible, setOpenCollapsible] = useState(false);
  const [hasDifferences, setHasDifferences] = useState(false);
  const [highlightInstructions, setHighlightInstructions] = useState({});

  // Lifecycle methods
  useMemo(() => {
    const checkDifferences =
      viewableMeals.length !== 0
        ? compareTextBlobs(
            sanitizedBeforeYouCook(viewableMeals.map((meal) => meal.beforeYouCookInstructions))
          )
        : false;
    setHasDifferences(checkDifferences);
  }, [viewableMeals]);

  // Group before you cook instructions by id. The 'instruction' field is what needs to be checked
  // Ex. instruction: "Bring {{value}} to a boil in a medium pot" -> if 'value' is different between viewableMeals - highlight
  useEffect(() => {
    const allInstructions = flatMapDeep(viewableMeals, (meal) => {
      return meal.beforeYouCookInstructions;
    });
    const groupedById = groupBy(allInstructions, (beforeYouCook) => {
      return beforeYouCook.id;
    });
    setHighlightInstructions(
      mapValues(groupedById, (idGroup) => {
        return setHighlightBeforeYouCookGroup(idGroup, viewableMeals.length);
      })
    );
  }, [viewableMeals]);

  // Handler methods
  const handleCollapsibleClick = () => {
    setOpenCollapsible(!openCollapsible);
  };

  return (
    <TableRow>
      <TableCell
        className={clsx(classes.iconCell, hasDifferences && classes.tableRowGreenBorder)}
        onClick={handleCollapsibleClick}
      >
        {openCollapsible ? (
          <ExpandMore className={classes.icon} />
        ) : (
          <ChevronRightIcon className={classes.icon} />
        )}
      </TableCell>
      <TableCell className={classes.rowLabel} onClick={handleCollapsibleClick}>
        Before You Cook
      </TableCell>
      {viewableMeals.map((meal) => (
        <TableCell
          key={`beforeYouCook.${meal.versionId}`}
          className={clsx(classes.expandingCell, meal.inactive && classes.greyed)}
        >
          <div
            className={classes.listContentSameBlock}
          >{`${meal.beforeYouCookInstructions.length} Steps`}</div>
          <Collapse in={openCollapsible} unmountOnExit className={classes.collapse}>
            {meal.beforeYouCookInstructions.map((beforeYouCook) => {
              return (
                <div
                  key={`${meal.versionId}-${beforeYouCook.id}`}
                  className={clsx(
                    classes.instruction,
                    highlightInstructions[beforeYouCook.id] && classes.highlight
                  )}
                >
                  {parse(beforeYouCook.instruction)}
                </div>
              );
            })}
          </Collapse>
        </TableCell>
      ))}
    </TableRow>
  );
};

BeforeYouCookRow.propTypes = {
  viewableMeals: PropTypes.arrayOf(mealShape).isRequired,
  classes: PropTypes.object.isRequired,
};

export default BeforeYouCookRow;
