import { LIGHT_BLACK, HC_GREEN } from 'lib/constants';

export default {
  root: {
    margin: '20px 0',
    height: '68px',
    width: '260px',
    '&.bottom-border-green': {
      borderBottom: `4px solid ${HC_GREEN}`,
    },
    '&.bottom-border-blue': {
      borderBottom: '4px solid #303F9F',
    },
    '&.bottom-border-pink': {
      borderBottom: '4px solid #FF4081',
    },
    '&.bottom-border-orange': {
      borderBottom: '4px solid #ED6C02',
    },
    '&.bottom-border-lightBlue': {
      borderBottom: '4px solid #64B6F7',
    },
  },
  link: {
    padding: '10px',
    height: '100%',
    fontSize: '16px',
    color: LIGHT_BLACK,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  arrow: {
    color: HC_GREEN,
  },
};
