import PropTypes from 'prop-types';
import { useContext } from 'react';
import { SessionContext } from 'contexts/sessionContext';

// To show children only if a feature flag is OFF,
// pass whenOff={true}.

const FeatureFlag = ({ flag, whenOff, children }) => {
  const { featureFlags } = useContext(SessionContext);

  const flagOn = featureFlags[flag];
  const desiredCondition = whenOff ? !flagOn : flagOn;

  if (desiredCondition) {
    return children;
  }
  return null;
};

FeatureFlag.propTypes = {
  flag: PropTypes.string.isRequired,
  whenOff: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

FeatureFlag.defaultProps = {
  whenOff: false,
};

export default FeatureFlag;
