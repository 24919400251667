const sanitizedSelections = (selections) => {
  return selections.map((selection) => {
    const { title, photo, id, displayOrder, ...sanitizedSelection } = selection;
    return { ...sanitizedSelection };
  });
};

export const getMenuPayload = (menu, menuType) => {
  if (menu.staging) {
    return {
      id: menu.id,
      staging_group_selections_attributes: sanitizedSelections(menu.menuSelections),
    };
  }

  if (menuType === 'retail') {
    return {
      id: menu.id,
      draft_meal_ids: menu.menuSelections.map((selection) => selection.id),
    };
  }

  return {
    id: menu.id,
    menu_selections_attributes: sanitizedSelections(menu.menuSelections),
  };
};

export const sameMenuPayload = (newMenu) => {
  const newMenuPayload = getMenuPayload(newMenu);
  return [newMenuPayload];
};

export const crossMenuPayload = (startMenu, endMenu, selectionToDestroy) => {
  const fullStartMenu = {
    ...startMenu,
    menuSelections: [...startMenu.menuSelections, selectionToDestroy],
  };
  const startMenuPayload = getMenuPayload(fullStartMenu);
  const endMenuPayload = getMenuPayload(endMenu);

  return [startMenuPayload, endMenuPayload];
};

export const stagingGroupPayload = (newStagingGroup) => {
  return getMenuPayload(newStagingGroup);
};

export const crossStagingGroupPayload = (startMenu, endMenu, selectionToDestroy) => {
  const fullStartMenu = {
    ...startMenu,
    menuSelections: [...startMenu.menuSelections, selectionToDestroy],
  };
  const stagingGroup = [fullStartMenu, endMenu].find((menu) => menu.staging);
  const menu = [fullStartMenu, endMenu].find((m) => !m.staging);
  const stagingPayload = getMenuPayload(stagingGroup);
  const menuPayload = [getMenuPayload(menu)];

  return { stagingPayload, menuPayload };
};

export const removePlaceholderPayload = (menu) => {
  return getMenuPayload(menu);
};

export const removeMealPayload = (menu, selectionToDestroy) => {
  const newSelectionToDestroy = { ...selectionToDestroy, deleted: true };
  const newMenu = {
    ...menu,
    menuSelections: [...menu.menuSelections, newSelectionToDestroy],
  };
  return getMenuPayload(newMenu);
};
