// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import { mealShape } from 'lib/helpers/comparisonTool/propShapes';

// Material
import Paper from '@mui/material/Paper';

import SelectVersionsButton from '../SelectVersionsButton';

const SelectVersionsPrompt = ({ activeMeals, inactiveMeals, classes }) => {
  return (
    <div>
      <Paper className={classes.paperBox} elevation={0}>
        <div className={classes.text}>Please select at least one version to start comparing.</div>
        <SelectVersionsButton activeMeals={activeMeals} inactiveMeals={inactiveMeals} />
      </Paper>
    </div>
  );
};

SelectVersionsPrompt.propTypes = {
  activeMeals: PropTypes.arrayOf(mealShape).isRequired,
  inactiveMeals: PropTypes.arrayOf(mealShape).isRequired,
  classes: PropTypes.object.isRequired,
};

export default SelectVersionsPrompt;
