import isEmpty from 'lodash/isEmpty';

export * from './beforeYouCook';
export * from './chefInfo';
export * from './conceptInput';
export * from './cookingFacts';
export * from './descriptionInfo';
export * from './dietaryInfo';
export * from './disposableItems';
export * from './equipmentItems';
export * from './ingredients';
export * from './menus';
export * from './recipeSteps';
export * from './titleInfo';
export * from './whatYouNeed';

export const compareNumberItems = (arrayOfArrays) => {
  const defaultNum = arrayOfArrays[0].length;
  return arrayOfArrays.some((arr) => {
    return arr.length !== defaultNum;
  });
};

export const compareTextBlobs = (arrayOfArrays) => {
  const defaultTextBlob = arrayOfArrays[0].join('');
  return arrayOfArrays.some((arr) => {
    return arr.join('') !== defaultTextBlob;
  });
};

export const setHighlightGroup = (group, numMeals) => {
  if (isEmpty(group)) return false;
  if (group.length !== numMeals) return true; // if one or more of the versions is missing an object

  return compareTextBlobs(group);
};
