import { LIGHT_BLACK, BACKGROUND_GREY, LIGHT_GREEN } from 'lib/constants';

export default {
  card: {
    maxWidth: '272px',
    margin: '0 0 36px 0',
    position: 'relative',
  },
  cardMedia: {
    minHeight: '181px',
  },
  imageErrorContent: {
    height: '181px',
    lineHeight: '181px',
    backgroundColor: BACKGROUND_GREY,
    color: LIGHT_BLACK,
    textAlign: 'center',
  },
  cardContent: {
    padding: '0px',
    '&:last-child': {
      paddingBottom: '0px',
    },
  },
  cardTitle: {
    color: LIGHT_BLACK,
    textTransform: 'uppercase',
    padding: '4px 16px 4px 16px',
    fontSize: '12px',
    fontWeight: '500',
  },
  cardDescription: {
    color: LIGHT_BLACK,
    fontWeight: '400',
    fontSize: '12px',
    padding: '4px 16px 4px 16px',
    marginBottom: '0px',
    '& p': {
      margin: '0px',
    },
  },
  highlight: {
    backgroundColor: LIGHT_GREEN,
  },
  icon: {
    marginLeft: 'auto',
  },
};
