import { CT_UPDATE_MEAL_VERSION_DATA } from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import { updateMeals } from './services';

export const postUpdateMeals = (sourceMealId, targetMealIds) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: CT_UPDATE_MEAL_VERSION_DATA,
        method: updateMeals,
        params: { sourceMealId, targetMealIds },
      })
    );
  };
};
