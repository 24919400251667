import { LIGHT_GREY, HC_GREEN } from 'lib/constants';

export default {
  root: {
    width: '250px',
    height: '230px',
    margin: '8px 8px 8px 8px',
    lineHeight: '16px',
  },
  media: {
    height: '130px',
  },
  title: {
    fontSize: '12px',
    fontWeight: '500',
    '&:hover': {
      color: HC_GREEN,
      textDecoration: 'underline',
    },
  },
  subtitle: {
    color: LIGHT_GREY,
    fontSize: '12px',
    fontWeight: '400',
  },
};
