import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  PRODUCTION_SHEET_NOTES,
  HIGH_TOLERANCE,
  LOW_TOLERANCE,
  MEASURE,
  QUANTITY,
  USAGE_YIELD,
  VOLUME_MEASUREMENTS,
} from 'lib/constants';

// Material Components
import Grid from '@mui/material/Grid';

import TextBox from '../TextBox';
import Dropdown from '../Dropdown';

const IngredientFormInputs = ({
  ingredientAssignmentState,
  ingredientSelected,
  onAttributeChange,
  isCompoundIngredient,
}) => {
  const selectVolumeMeasurements = useMemo(() => {
    return VOLUME_MEASUREMENTS.map((measurement) => ({ label: measurement, value: measurement }));
  }, []);

  const paddingTop = { paddingTop: ingredientSelected?.standardUnit === 'volume' ? 24 : 16 };

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <TextBox label="Brand" fullWidth value={ingredientSelected?.brandName || ''} disabled />
        </Grid>
        <Grid item xs={6}>
          <TextBox
            label="Ingredient State"
            fullWidth
            value={ingredientSelected?.state || ''}
            disabled
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <TextBox fullWidth label="ID" name="id" disabled value={ingredientSelected?.id || ''} />
        </Grid>
        <Grid item xs={6}>
          <TextBox
            fullWidth
            label="Display Name"
            name="name"
            value={ingredientAssignmentState?.name || ''}
            onChange={onAttributeChange}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={4} style={paddingTop}>
          <TextBox
            fullWidth
            required
            label="Quantity"
            name={QUANTITY}
            type="number"
            onChange={onAttributeChange}
            value={ingredientAssignmentState.quantity}
          />
        </Grid>
        <Grid item xs={4}>
          {isCompoundIngredient && (
            <TextBox
              fullWidth
              label="Measure"
              name={MEASURE}
              disabled
              onChange={onAttributeChange}
              value={ingredientAssignmentState.measure || ingredientSelected?.standardUnit}
            />
          )}
          {!isCompoundIngredient && (
            <>
              {ingredientSelected?.standardUnit === 'volume' ? (
                <Dropdown
                  required
                  label="Measure"
                  name={MEASURE}
                  onChange={onAttributeChange}
                  value={ingredientAssignmentState.measure}
                  options={selectVolumeMeasurements}
                />
              ) : (
                <TextBox
                  fullWidth
                  label="Measure"
                  name={MEASURE}
                  disabled
                  onChange={onAttributeChange}
                  value={ingredientAssignmentState.measure || ingredientSelected?.standardUnit}
                />
              )}
            </>
          )}
        </Grid>
        <Grid item xs={4} style={paddingTop}>
          <TextBox
            fullWidth
            label="Usage Yield"
            name={USAGE_YIELD}
            required
            type="number"
            onChange={onAttributeChange}
            value={ingredientAssignmentState.usageYield || ''}
          />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={12}>
          <TextBox
            fullWidth
            label="Production Sheet Notes"
            name={PRODUCTION_SHEET_NOTES}
            onChange={onAttributeChange}
            value={ingredientAssignmentState.productionSheetNotes || ''}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <TextBox
            fullWidth
            label="Low Tolerance"
            name={LOW_TOLERANCE}
            type="number"
            onChange={onAttributeChange}
            value={ingredientAssignmentState.lowTolerance || ''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextBox
            fullWidth
            label="High Tolerance"
            name={HIGH_TOLERANCE}
            type="number"
            onChange={onAttributeChange}
            value={ingredientAssignmentState.highTolerance || ''}
          />
        </Grid>
      </Grid>
    </>
  );
};

IngredientFormInputs.propTypes = {
  ingredientAssignmentState: PropTypes.object.isRequired,
  ingredientSelected: PropTypes.object,
  onAttributeChange: PropTypes.func.isRequired,
  isCompoundIngredient: PropTypes.bool,
};

IngredientFormInputs.defaultProps = {
  ingredientSelected: {},
  isCompoundIngredient: false,
};

export default IngredientFormInputs;
