import PropTypes from 'prop-types';

import useAllowed from 'lib/useAllowed';

const IfAllowed = ({ ability, children }) => {
  if (useAllowed(ability)) {
    return children;
  }
  return null;
};

IfAllowed.propTypes = {
  ability: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default IfAllowed;
