import {
  rowLabel,
  iconCell,
  listLabel,
  listContent,
  listContentSameBlock,
  greyed,
  tableRowGreenBorder,
} from 'lib/styles/comparisonTool';

import { LIGHT_GREEN } from 'lib/constants';

export default {
  rowLabel,
  iconCell,
  listLabel,
  listContent,
  listContentSameBlock,
  greyed,
  dietaryInfoRow: {
    gridRowGap: '10px',
  },
  dietaryInfoCell: {
    verticalAlign: 'top',
  },
  dietaryPreferencesContainer: {
    paddingTop: '10px',
  },
  dietaryListContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  highlight: {
    backgroundColor: LIGHT_GREEN,
  },
  tableRowGreenBorder,
};
