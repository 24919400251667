import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Button, Card, CardContent, CardMedia, Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { BehavioralDiscountFormContext } from 'contexts/BehavioralDiscountToolContext';
import { calculateFileSize } from 'lib/helpers/behavioralDiscountTool/bannerHelper';

const BannerImageUploader = ({ classes, filename = null, setErrorState, previewImage = null }) => {
  const { actions } = useContext(BehavioralDiscountFormContext);

  const checkFileSize = (file) => Number(calculateFileSize(file.size)) <= 150;
  const imgChecker = (file) => {
    const extension = file.name.split('.').pop().toLowerCase();
    const bool = extension === 'jpg' || extension === 'png' || checkFileSize(file);
    setErrorState(!bool);
    return bool;
  };

  const handleImgUpload = async (e) => {
    if (!e.target.files) return;

    const selectedImage = e.target.files[0];

    if (imgChecker(selectedImage)) {
      actions.setFormState('photoFile', selectedImage);
    }
  };

  const handleRemoveImage = () => {
    actions.setFormState('photo', {});
    actions.setFormState('photoFile', null);
  };

  const UploadImage = (text = 'UPLOAD AN IMAGE') => {
    return (
      <Button component="label" variant="outlined">
        {text}
        <input className={classes.input} type="file" accept="image/*" onChange={handleImgUpload} />
      </Button>
    );
  };

  const RemoveImage = () => {
    return (
      <Card className={classes.imgContainer}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <CardMedia component="img" height="75" image={previewImage} className={classes.img} />
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item>
                <Typography gutterBottom component="div" className={classes.imgFileName}>
                  {filename}
                </Typography>
              </Grid>
              <Grid item xs={12} sm container justifyContent="flex-end" gap={2}>
                {UploadImage('Replace')}
                <IconButton onClick={() => handleRemoveImage()} size="small">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const imageComponent = previewImage ? RemoveImage : UploadImage;

  return imageComponent();
};

BannerImageUploader.propTypes = {
  classes: PropTypes.object.isRequired,
  filename: PropTypes.string,
  setErrorState: PropTypes.func.isRequired,
  previewImage: PropTypes.string,
};

BannerImageUploader.defaultProps = {
  filename: null,
  previewImage: null,
};

export default BannerImageUploader;
