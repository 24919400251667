export default {
  drawerPaper: {
    paddingTop: '84px',
    paddingLeft: '16px',
    paddingRight: '32px',
    maxWidth: '600px',
    boxShadow:
      '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)',
  },
  titleBar: {
    position: 'relative',
    marginTop: '16px',
    fontSize: '20px',
    padding: '16px',
  },
};
