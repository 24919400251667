import { APP_API_URI } from 'lib/constants';
import { GET, POSTFORM, PUTFORM } from 'lib/api';

export const fetchBehavioralDiscount = async (behavioralDiscountId) =>
  GET(`${APP_API_URI}/internal/cx/behavioral_discount/get_behavioral_discount`, {
    params: {
      id: behavioralDiscountId,
    },
  });

export const createBehavioralDiscount = async (behavioralDiscount) =>
  POSTFORM(
    `${APP_API_URI}/internal/cx/behavioral_discount/create_behavioral_discount`,
    behavioralDiscount
  );

export const updateBehavioralDiscount = async (behavioralDiscount) =>
  PUTFORM(
    `${APP_API_URI}/internal/cx/behavioral_discount/update_behavioral_discount`,
    behavioralDiscount
  );

export const fetchFutureMenus = async () =>
  GET(`${APP_API_URI}/internal/cx/behavioral_discount/get_future_menus`);

export const fetchMenuMeals = async (menuId) =>
  GET(`${APP_API_URI}/internal/cx/behavioral_discount/get_menu_meals`, {
    params: { id: menuId },
  });

export const fetchMealBadges = async () =>
  GET(`${APP_API_URI}/internal/cx/behavioral_discount/get_meal_badges`);

export const fetchProductTypes = async () =>
  GET(`${APP_API_URI}/internal/cx/behavioral_discount/get_product_types`);
