import { GET } from 'lib/api';
import { APP_API_URI } from 'lib/constants';

const MG_URL = `${APP_API_URI}/internal/culinary/menugrid`;

export const getSingleMenuData = async ({ displayGroup, menuId }) => {
  const url = `${MG_URL}/menu_data?display_group=${displayGroup}&menu_id=${menuId}`;

  const response = await GET(url);
  return response.data;
};
