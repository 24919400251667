import { rowLabel, iconCell, greyed, tableRowGreenBorder } from 'lib/styles/comparisonTool';
import { LIGHT_BLACK, LIGHT_GREEN } from 'lib/constants';

export default {
  rowLabel,
  iconCell,
  greyed,
  description: {
    color: LIGHT_BLACK,
    fontWeight: '400',
    fontSize: '12px',
  },
  highlight: {
    backgroundColor: LIGHT_GREEN,
  },
  tableRowGreenBorder,
};
