import { DEFAULT_SPACING, LIGHT_GREY, MEDIUM_SPACING, THICK_SPACING } from 'lib/constants';

export default {
  root: {
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: '4px',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    height: '100%',
    marginTop: THICK_SPACING,
    marginBottom: THICK_SPACING,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: THICK_SPACING,
    paddingBottom: THICK_SPACING,
    paddingLeft: DEFAULT_SPACING,
    paddingRight: DEFAULT_SPACING,
    width: '640px',
  },
  header: {
    alignSelf: 'flex-start',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: '400',
    margin: 0,
  },
  serviceType: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  frequencyRadioHelperText: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    opacity: '0.6',
  },
  radioGroupContainer: {
    alignSelf: 'flex-start',
  },
  divider: {
    backgroundColor: LIGHT_GREY,
    height: '1px',
    border: 'none',
    width: '100%',
  },
  halfWidth: {
    width: '50%',
  },
  lessThanHalfWidth: {
    width: '40%',
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    opacity: '60%',
  },
  fullWidth: {
    width: '100%',
  },
  hidden: {
    display: 'none',
    visibility: 'hidden',
  },
  buttonContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
    gap: MEDIUM_SPACING,
  },
  containerStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '0',
  },
  visible: {
    display: 'normal',
    visibility: 'visible',
  },
  recurringFieldsContainer: {
    width: '100%',
    display: 'flex',
    gap: '24px',
    flexWrap: 'wrap',
  },
};
