import { HC_GREEN, WHITE } from 'lib/constants';

export default {
  icon: {
    color: HC_GREEN,
  },
  root: {
    backgroundColor: WHITE,
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 5000,
  },
  title: {
    color: HC_GREEN,
    margin: '0 0 0 10px',
  },
  home: {
    marginTop: '5px',
  },
};
