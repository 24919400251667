import { GET } from 'lib/api';
import { APP_API_URI } from 'lib/constants';

const MEF_URL = `${APP_API_URI}/internal/culinary/meal_edit_form`;

export const fetchFormOptions = async () => {
  const response = await GET(`${MEF_URL}/form_options`);

  return response.data;
};
