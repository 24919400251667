import flatten from 'lodash/flatten';
import map from 'lodash/map';
import uniq from 'lodash/uniq';

export const sanitizedWhatYouNeed = (meals) => {
  return map(meals, (meal) => {
    return flatten([meal.whatYouNeed, meal.saltQuantity, meal.oliveOilQuantity]);
  });
};

const hasSaltQuantityDifferences = (meals) => {
  const allSaltQuantities = map(meals, (meal) => {
    return meal.saltQuantity;
  });
  return uniq(allSaltQuantities).length !== 1;
};

const hasOliveOilQuantityDifferences = (meals) => {
  const allOliveOilQuantities = map(meals, (meal) => {
    return meal.oliveOilQuantity;
  });
  return uniq(allOliveOilQuantities).length !== 1;
};

export const setHighlightWhatYouNeedItem = (whatYouNeedItem, occurrences, meals) => {
  if (occurrences.length === 0) return false;
  if (occurrences.length !== meals.length) return true; // if any items are listed for at least one but not ALL meals

  if (whatYouNeedItem === 'Olive Oil') return hasOliveOilQuantityDifferences(meals);

  if (whatYouNeedItem === 'Salt') return hasSaltQuantityDifferences(meals);

  return false;
};
