// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import RecipeStepCard from './RecipeStepCard';

// Styles
import styles from './styles';

export default withStyles(styles)(RecipeStepCard);
