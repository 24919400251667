import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Material Components
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';

import Dropdown from '../../shared/Dropdown';
import SectionHeader from '../shared/SectionHeader';
import TextBox from '../../shared/TextBox';

const PackingFacilityCard = ({ classes, formState, handleFormState, packingFacility, pfia }) => {
  const { formOptions } = useSelector((state) => state.compoundIngredientRecipeForm.formOptions);
  const [pfiaState, setPfiaState] = useState(pfia);

  const updateFormState = (name, value) => {
    const newPfiaState = { ...pfiaState, [name]: value };
    if (!newPfiaState.packingFacilityId) newPfiaState.packingFacilityId = packingFacility.id;
    setPfiaState(newPfiaState);

    const modifiedPFIAs = formState.packingFacilityIngredientAssignments;
    const pfiaIndex = modifiedPFIAs.findIndex(
      (_pfia) => _pfia.packingFacilityId === packingFacility.id
    );
    // TODO: In the code below, we are looking to see if the PFIA already exists or is new.
    // This will allow us to create new ones if they don't already exist
    // This should be temporary since we will create all of the PFIAs upon initial CI creation and then update their cost info in the callbacks we’ll be adding in #3091
    if (pfiaIndex > -1) {
      modifiedPFIAs[pfiaIndex] = newPfiaState;
    } else {
      modifiedPFIAs.push(newPfiaState);
    }

    handleFormState({
      target: { name: 'packingFacilityIngredientAssignments', value: modifiedPFIAs },
    });
  };

  const handleApprovedCheckbox = () => {
    const newVal = !pfiaState?.approved;
    updateFormState('approved', newVal);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    updateFormState(name, value);
  };

  const selectIngredientPackaging = useMemo(() => {
    return formOptions.ingredientPackagings.map((ingredientPackaging) => ({
      label: ingredientPackaging.name,
      value: ingredientPackaging.id,
    }));
  }, [formOptions.ingredientPackagings]);

  const selectStorageAreas = useMemo(() => {
    return formOptions.storageAreas.map((storageArea) => ({
      label: storageArea.name,
      value: storageArea.id,
    }));
  }, [formOptions.storageAreas]);

  return (
    <Paper className={classes.paper}>
      <Grid item xs={12}>
        <SectionHeader text={`${packingFacility.name}`} />
        <div className={classes.row}>
          <Checkbox
            checked={pfiaState?.approved || false}
            name="approved"
            onChange={handleApprovedCheckbox}
          />
          <span className={classes.label}>Approved</span>
        </div>
        <Dropdown
          label="Ingredient Packaging"
          name="ingredientPackagingId"
          onChange={onChange}
          value={pfiaState?.ingredientPackagingId}
          options={selectIngredientPackaging}
        />
        <Dropdown
          label="Storage Area"
          name="storageAreaId"
          onChange={onChange}
          value={pfiaState?.storageAreaId}
          options={selectStorageAreas}
        />
        <TextBox
          fullWidth
          label="Yield"
          name="yield"
          onChange={onChange}
          value={pfiaState?.yield || ''}
        />
        <TextBox
          fullWidth
          label="Over Production"
          name="overOrder"
          onChange={onChange}
          value={pfiaState?.overOrder || ''}
        />
        <TextBox
          fullWidth
          multiline
          rows={6}
          label="Notes"
          name="notes"
          onChange={onChange}
          value={pfiaState?.notes || ''}
        />
      </Grid>
    </Paper>
  );
};

PackingFacilityCard.propTypes = {
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
  packingFacility: PropTypes.object.isRequired,
  pfia: PropTypes.object,
};

PackingFacilityCard.defaultProps = {
  pfia: null,
};

export default PackingFacilityCard;
