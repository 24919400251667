import {
  IM_INCIDENTABLE_REQUEST,
  IM_INCIDENTABLE_SUCCESS,
  IM_INCIDENTABLE_ERROR,
  IM_TAX_REFUND_REQUEST,
  IM_TAX_REFUND_SUCCESS,
  IM_TAX_REFUND_ERROR,
  IM_CANCEL_REQUEST,
  IM_CANCEL_SUCCESS,
  IM_CANCEL_ERROR,
} from 'lib/constants';

export const initialState = {
  incidentable: null,
  error: false,
  errorMessage: null,
  fetching: false,
  submitting: false,
  success: false,
  successMessage: null,
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case IM_INCIDENTABLE_REQUEST:
      return {
        ...state,
        error: false,
        fetching: true,
      };
    case IM_INCIDENTABLE_SUCCESS:
      return {
        ...state,
        incidentable: action.response.incidentable,
        error: false,
        fetching: false,
      };
    case IM_INCIDENTABLE_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.error.message,
        fetching: false,
      };
    case IM_TAX_REFUND_REQUEST:
      return {
        ...state,
        error: false,
        fetching: true,
      };
    case IM_TAX_REFUND_SUCCESS:
      return {
        ...state,
        error: false,
        fetching: false,
      };
    case IM_TAX_REFUND_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.error.message,
        fetching: false,
      };
    case IM_CANCEL_REQUEST:
      return {
        ...state,
        error: false,
        submitting: true,
        success: false,
      };
    case IM_CANCEL_SUCCESS:
      return {
        ...state,
        error: false,
        submitting: false,
        success: true,
        successMessage: 'Order cancelled!',
      };
    case IM_CANCEL_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.error.message,
        submitting: false,
        success: false,
      };
    default:
      return state;
  }
};
