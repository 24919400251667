import { map } from 'lodash';

export const sanitizedTitleInfo = (meals) => {
  return map(meals, (meal) => {
    return [meal.title, meal.subtitle];
  });
};

export const sanitizedTitles = (meals) => {
  return map(meals, (meal) => {
    return [meal.title];
  });
};

export const sanitizedSubtitles = (meals) => {
  return map(meals, (meal) => {
    return [meal.subtitle];
  });
};
