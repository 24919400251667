import PropTypes from 'prop-types';

export const formOptionsShape = PropTypes.shape({
  cancelReasons: PropTypes.array,
  errorTypes: PropTypes.array,
  userRemediationCategories: PropTypes.array,
  deliveryRemediationCategories: PropTypes.array,
});

export const formStateShape = PropTypes.shape({
  cancel: PropTypes.bool,
  causeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  creditAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string,
  incidentableId: PropTypes.number,
  incidentableType: PropTypes.string,
  incidentType: PropTypes.string,
  ingredientAssignmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ingredientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mealId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reason: PropTypes.string,
  reasonDescription: PropTypes.string,
  cashRefundAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  giftCardRefundAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  remediationCategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  taxRefundAmount: PropTypes.number,
  totalRefundAmount: PropTypes.number,
});

export const incidentableShape = PropTypes.shape({
  id: PropTypes.number,
  email: PropTypes.string,
  deliveryDate: PropTypes.string,
  adminUserLink: PropTypes.string,
  cancellable: PropTypes.bool,
  shipmentSummary: PropTypes.array,
  mealSelections: PropTypes.array,
  receipt: PropTypes.array,
  charges: PropTypes.array,
  creditsAndRefunds: PropTypes.object,
  isShipped: PropTypes.bool,
  pastCreditsAndRefunds: PropTypes.array,
  delayed: PropTypes.bool,
  delayType: PropTypes.string,
});
