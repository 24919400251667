// Dependencies
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

const PhotoCard = ({ classes, photo }) => {
  // State
  const [photoError, setPhotoError] = useState(false);
  const [overlayIsShown, setOverlayIsShown] = useState(false);

  // Handler methods
  const showOverlay = () => {
    setOverlayIsShown(true);
  };

  const hideOverlay = () => {
    setOverlayIsShown(false);
  };

  const showPhotoError = () => {
    setPhotoError(true);
  };

  // Conditionals
  const shouldShowOverlay = !photoError && overlayIsShown;

  return (
    <Card
      className={classes.card}
      key={`${photo.title}-${photo.category}`}
      onMouseEnter={showOverlay}
      onMouseLeave={hideOverlay}
    >
      {photoError ? (
        <div className={classes.photoErrorContent}> Image not found </div>
      ) : (
        <CardMedia
          component="img"
          alt={photo.title}
          image={photo.url}
          title={photo.title}
          className={classes.cardMedia}
          onError={showPhotoError}
        />
      )}
      {shouldShowOverlay && <div className={classes.overlay}>{photo.identifier}</div>}
      <CardContent className={classes.cardContent}>
        <span className={classes.listLabel}>Type</span>
        <span className={classes.listContent}>{photo.category}</span>
      </CardContent>
    </Card>
  );
};

PhotoCard.propTypes = {
  classes: PropTypes.object.isRequired,
  photo: PropTypes.object.isRequired,
};

export default PhotoCard;
