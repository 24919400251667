import {
  GRID_DATA,
  UPDATE_SELECTION_ORDER,
  UPDATE_SELECTION_ORDER_ON_GRID,
  ADD_STAGING_SELECTIONS,
  TOGGLE_ADD_MEALS_DIALOG,
  TOGGLE_SNACK_BAR,
  ADD_PLACEHOLDER,
  REMOVE_PLACEHOLDER,
  REMOVE_MEAL,
} from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import { pluralizeWithNumber } from 'lib/helpers/strings';
import { getRemovePlaceholderChanges } from 'lib/helpers/menugrid/beautifulMenugridPlaceholderManager';
import { removeMealPayload } from 'lib/helpers/menugrid/beautifulMenugridDataConstructor';
import { getGridData, patchSelectionOrder, createStagingSelections } from './services';

export const fetchGridData = (quarter, displayGroup) =>
  makeApiRequest({ type: GRID_DATA, method: getGridData, params: { quarter, displayGroup } });

export const updateGrid = (menus, menuType) => ({
  type: UPDATE_SELECTION_ORDER_ON_GRID,
  payload: { menus, menuType },
});

export const updateApiRequest = ({
  stagingGroup = {},
  menus = [],
  menuType = 'standard',
  displayGroup = '',
}) =>
  makeApiRequest({
    type: UPDATE_SELECTION_ORDER,
    method: patchSelectionOrder,
    params: { stagingGroup, menus, menuType, displayGroup },
  });

export const toggleAddMealsDialog = (toggle) => ({
  type: TOGGLE_ADD_MEALS_DIALOG,
  payload: { toggle },
});

export const toggleSnackBar = (toggle, message) => ({
  type: TOGGLE_SNACK_BAR,
  payload: { toggle, message },
});

export const addStagingSelections = ({
  stagingGroup = {},
  displayGroup = '',
  culinaryVersionIds = [],
}) => {
  return async (dispatch) => {
    const response = await dispatch(
      makeApiRequest({
        type: ADD_STAGING_SELECTIONS,
        method: createStagingSelections,
        params: { stagingGroup, displayGroup, culinaryVersionIds },
      })
    );
    if (response) {
      const numMeals = response.stagingGroupSelections.length;
      const successMessage = `${pluralizeWithNumber(numMeals, 'meal')} added!`;
      dispatch(toggleSnackBar(true, successMessage));
    }
  };
};

export const addPlaceholder = (menuId, menuType) => ({
  type: ADD_PLACEHOLDER,
  payload: { menuId, menuType },
});

export const removeMeal = (menuSelectionId) => ({
  type: REMOVE_MEAL,
  payload: { menuSelectionId },
});

export const removeMealAndUpdate = (menuSelection) => async (dispatch, getState) => {
  dispatch(removeMeal(menuSelection.id));
  const state = getState();

  const stagingMenu = { ...state.menugrid.menus.stagingGroupWithPlaceholders };
  stagingMenu.staging = true;
  dispatch(updateApiRequest({ stagingGroup: removeMealPayload(stagingMenu, menuSelection) }));
};

export const removePlaceholder = (id, menuType) => ({
  type: REMOVE_PLACEHOLDER,
  payload: { id, menuType },
});

export const removePlaceholderAndUpdate = (props) => async (dispatch, getState) => {
  const { id, menuId, menuType, displayGroup } = props;
  const response = await dispatch(removePlaceholder(id, menuType));
  const state = getState();
  if (response) {
    if (menuType === 'staging') {
      const stagingMenu = state.menugrid.menus.stagingGroupWithPlaceholders;
      stagingMenu.staging = true;
      dispatch(
        updateApiRequest({
          stagingGroup: getRemovePlaceholderChanges(stagingMenu),
          displayGroup,
        })
      );
    } else if (menuType === 'retail') {
      const menu = state.menugrid.menus.retailMenusWithPlaceholders.find(
        (singleMenu) => singleMenu.id === menuId
      );
      menu.staging = false;
      dispatch(updateApiRequest({ menus: [getRemovePlaceholderChanges(menu)] }));
    } else {
      const menu = state.menugrid.menus.menusWithPlaceholders.find(
        (singleMenu) => singleMenu.id === menuId
      );
      menu.staging = false;
      dispatch(updateApiRequest({ menus: [getRemovePlaceholderChanges(menu)], displayGroup }));
    }
  }
};
