import {
  BLUEY_BLUE,
  BLUEY_DARK_BLUE,
  CREAM_110,
  GREEN_130,
  GRAPE,
  LEMON_100,
  LIGHT_BLACK,
  LIGHT_GREEN,
  ORANGE_100,
  PAW_PATROL_BLUE,
  PINK_100,
  TEAL,
  TEMPO_LIME,
  TEMPO_BLACK,
  TEMPO_PINK,
  TEMPO_BLUE,
  TEMPO_GREEN_120,
  TEMPO_BLUE_10,
  TEMPO_PURPLE,
  WHITE,
} from 'lib/constants';

import LightGreen from './images/lightGreen.svg';
import Kale from './images/kale.svg';
import Lemon from './images/lemon.svg';
import Grape from './images/grape.svg';
import Teal from './images/teal.svg';
import Lime from './images/tempo/lime.svg';
import Pink from './images/tempo/pink.svg';
import Blue from './images/tempo/blue.svg';
import DarkGreen from './images/tempo/darkGreen.svg';
import Purple from './images/tempo/purple.svg';
import BLUEY from './images/bluey.svg';
import FDFL from './images/fdfl.svg';
import PAWPATROL from './images/pawpatrol.svg';

export const homechefTemplates = {
  lightGreen: {
    bannerBackgroundColor: LIGHT_GREEN,
    bannerTextColor: LIGHT_BLACK,
    bannerTagBackgroundColor: LIGHT_BLACK,
    bannerTagTextColor: LIGHT_GREEN,
    image: LightGreen,
    descriptor: 'Light green with grey text',
  },
  teal: {
    bannerBackgroundColor: TEAL,
    bannerTextColor: LIGHT_BLACK,
    bannerTagBackgroundColor: LIGHT_BLACK,
    bannerTagTextColor: TEAL,
    image: Teal,
    descriptor: 'Teal with grey text',
  },
  lemon: {
    bannerBackgroundColor: LEMON_100,
    bannerTextColor: GREEN_130,
    bannerTagBackgroundColor: GREEN_130,
    bannerTagTextColor: LEMON_100,
    image: Lemon,
    descriptor: 'Lemon with dark green text',
  },
  grape: {
    bannerBackgroundColor: GRAPE,
    bannerTextColor: CREAM_110,
    bannerTagBackgroundColor: CREAM_110,
    bannerTagTextColor: GRAPE,
    image: Grape,
    descriptor: 'Grape with cream text ',
  },
  kale: {
    bannerBackgroundColor: GREEN_130,
    bannerTextColor: CREAM_110,
    bannerTagBackgroundColor: CREAM_110,
    bannerTagTextColor: GREEN_130,
    image: Kale,
    descriptor: 'Dark green with cream text',
  },
};

export const tempoTemplates = {
  lime: {
    bannerBackgroundColor: TEMPO_LIME,
    bannerTextColor: TEMPO_BLACK,
    bannerTagBackgroundColor: TEMPO_BLACK,
    bannerTagTextColor: TEMPO_LIME,
    image: Lime,
    descriptor: 'Lime green with black text',
  },
  pink: {
    bannerBackgroundColor: TEMPO_PINK,
    bannerTextColor: TEMPO_BLACK,
    bannerTagBackgroundColor: TEMPO_BLACK,
    bannerTagTextColor: TEMPO_PINK,
    image: Pink,
    descriptor: 'Pink with black text',
  },
  blue: {
    bannerBackgroundColor: TEMPO_BLUE,
    bannerTextColor: WHITE,
    bannerTagBackgroundColor: WHITE,
    bannerTagTextColor: TEMPO_BLUE,
    image: Blue,
    descriptor: 'Blue with white text',
  },
  darkGreen: {
    bannerBackgroundColor: TEMPO_GREEN_120,
    bannerTextColor: TEMPO_BLUE_10,
    bannerTagBackgroundColor: TEMPO_BLUE_10,
    bannerTagTextColor: TEMPO_GREEN_120,
    image: DarkGreen,
    descriptor: 'Dark green with white text ',
  },
  purple: {
    bannerBackgroundColor: TEMPO_PURPLE,
    bannerTextColor: WHITE,
    bannerTagBackgroundColor: WHITE,
    bannerTagTextColor: TEMPO_PURPLE,
    image: Purple,
    descriptor: 'Purple with white text',
  },
};

export const promotionalTemplates = {
  ffl: {
    bannerBackgroundColor: PINK_100,
    bannerTextColor: LIGHT_BLACK,
    bannerTagBackgroundColor: ORANGE_100,
    bannerTagTextColor: WHITE,
    image: FDFL,
    descriptor: 'Free Dessert for Life',
  },
  bluey: {
    bannerBackgroundColor: BLUEY_BLUE,
    bannerTextColor: BLUEY_DARK_BLUE,
    bannerTagBackgroundColor: BLUEY_DARK_BLUE,
    bannerTagTextColor: BLUEY_BLUE,
    image: BLUEY,
    descriptor: 'Bluey Promo',
  },
  pawpatrol: {
    bannerBackgroundColor: PAW_PATROL_BLUE,
    bannerTextColor: WHITE,
    bannerTagBackgroundColor: WHITE,
    bannerTagTextColor: PAW_PATROL_BLUE,
    image: PAWPATROL,
    descriptor: 'PAW Patrol',
  },
};

export const defaultTemplates = {
  core: homechefTemplates.lightGreen,
  family: homechefTemplates.lightGreen,
  tempo: tempoTemplates.lime,
};

export const bannerTemplates = { ...homechefTemplates, ...tempoTemplates, ...promotionalTemplates };

export const calculateFileSize = (bytes) => (bytes / 1024).toFixed(2);

export const getColorTheme = (backgroundColor) => {
  let selectedTemplate;

  Object.entries(bannerTemplates).forEach(([key, value]) => {
    if (value.bannerBackgroundColor === backgroundColor) {
      selectedTemplate = key;
    }
  });

  return selectedTemplate;
};

export const camelToSnakeCase = (camelCase) => {
  return camelCase.replace(/[A-Z]/g, (match, offset) => {
    return (offset ? '_' : '') + match.toLowerCase();
  });
};
