// Dependencies
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Lodash
import mapValues from 'lodash/mapValues';
import forEach from 'lodash/forEach';
import startCase from 'lodash/startCase';

// Helpers
import { mealShape } from 'lib/helpers/comparisonTool/propShapes';
import {
  compareTextBlobs,
  sanitizedCookingFacts,
  isPropertyDifferent,
} from 'lib/helpers/comparisonTool';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const CookingFactsRow = ({ viewableMeals, classes }) => {
  // State methods
  const [hasDifferences, setHasDifferences] = useState(false);
  const [highlightCookingFacts, setHighlightCookingFacts] = useState({});

  // Lifecycle methods
  useMemo(() => {
    const checkDifferences =
      viewableMeals.length !== 0
        ? compareTextBlobs(sanitizedCookingFacts(viewableMeals.map((meal) => meal.cookingFacts)))
        : false;
    setHasDifferences(checkDifferences);
  }, [viewableMeals]);

  useEffect(() => {
    const allCookingFacts = viewableMeals.map((meal) => meal.cookingFacts);

    const groupedCookingFacts = () => {
      const consolidatedCookingFacts = {
        cookWithin: [],
        difficultyLevel: [],
        prepTime: [],
        spiceLevel: [],
      };
      forEach(allCookingFacts, (cookingFact) => {
        forEach(cookingFact, (value, key) => {
          consolidatedCookingFacts[key].push(value);
        });
      });
      return consolidatedCookingFacts;
    };

    setHighlightCookingFacts(
      mapValues(groupedCookingFacts(), (occurrences, property) => {
        return isPropertyDifferent(occurrences, property, viewableMeals.length);
      })
    );
  }, [viewableMeals]);

  const renderCookingFact = (meal) => {
    return Object.keys(meal.cookingFacts).map((cf) => {
      return (
        <div
          key={`${meal.versionId}-${cf}`}
          className={clsx(classes.cookingFact, highlightCookingFacts[cf] && classes.highlight)}
        >
          <span className={classes.listLabel}>{startCase(cf)}</span>
          <span className={classes.listContent}>{meal.cookingFacts[cf]}</span>
        </div>
      );
    });
  };

  // Render methods
  return (
    <TableRow>
      <TableCell
        className={clsx(classes.iconCell, hasDifferences && classes.tableRowGreenBorder)}
      />
      <TableCell className={classes.rowLabel}>Cooking Facts</TableCell>
      {viewableMeals.map((meal) => (
        <TableCell
          key={`cookingFacts.${meal.versionId}`}
          className={clsx(meal.inactive && classes.greyed)}
        >
          {renderCookingFact(meal)}
        </TableCell>
      ))}
    </TableRow>
  );
};

CookingFactsRow.propTypes = {
  viewableMeals: PropTypes.arrayOf(mealShape).isRequired,
  classes: PropTypes.object.isRequired,
};

export default CookingFactsRow;
