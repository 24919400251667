import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { broadcastScrollSizeToParent } from 'lib/utils';

import Grid from '@mui/material/Grid';

import BasicAttributes from './BasicAttributes';
import BatchYield from './BatchYield';
import DisplayInformation from './DisplayInformation';
import ExtendedAttributes from './ExtendedAttributes';
import SaveChangesButton from '../shared/SaveChangesButton';

const BasicsTab = ({ classes, dirty, setDirty }) => {
  const { formOptions } = useSelector((state) => state.compoundIngredientRecipeForm.formOptions);

  const { compoundIngredientRecipe } = useSelector(
    (state) => state.compoundIngredientRecipeForm.compoundIngredientRecipe
  );

  const compoundIngredient = compoundIngredientRecipe?.compoundIngredient;

  const [formState, setFormState] = useState({
    id: compoundIngredient?.id,
    avgWeight: compoundIngredient?.avgWeight,
    displayName: compoundIngredient?.displayName,
    events: compoundIngredient?.events,
    ingredientClassificationId: compoundIngredient?.ingredientClassificationId,
    ingredientClaimIds: compoundIngredient?.ingredientClaims,
    maximumStorageTemperature:
      compoundIngredient?.maximumStorageTemperature ||
      formOptions.defaults.maximumStorageTemperature,
    minimumStorageTemperature:
      compoundIngredient?.minimumStorageTemperature ||
      formOptions.defaults.minimumStorageTemperature,
    name: compoundIngredient?.name,
    opsCategory: compoundIngredient?.opsCategory,
    packSize: compoundIngredient?.packSize,
    packUnit: compoundIngredient?.packUnit,
    pantryCategoryId: compoundIngredient?.pantryCategoryId,
    perishableDays: compoundIngredient?.perishableDays || formOptions.defaults.perishableDays,
    portionMethod: compoundIngredient?.portionMethod,
    standardUnit: compoundIngredient?.standardUnit,
    state: compoundIngredient?.state,
    usageYield: compoundIngredient?.usageYield,
    volumeToWeight: compoundIngredient?.volumeToWeight,
    otherAssignableVersions: compoundIngredient?.otherAssignableVersions,
  });

  useEffect(() => {
    setFormState((currentFormState) => {
      return {
        ...currentFormState,
        state: compoundIngredient?.state || 'wip',
        events: compoundIngredient?.events,
      };
    });
  }, [compoundIngredient?.state, compoundIngredient?.events]);

  const [recipeFormState, setRecipeFormState] = useState({
    inputAmount: compoundIngredientRecipe?.inputAmount,
    inputWeight: compoundIngredientRecipe?.inputWeight,
    maxOutputPerRun: compoundIngredientRecipe?.maxOutputPerRun,
    minOutputPerRun: compoundIngredientRecipe?.minOutputPerRun,
    outputPerRunMetric: compoundIngredientRecipe?.outputPerRunMetric,
    outputYield: compoundIngredientRecipe?.outputYield,
    standardUnit: compoundIngredientRecipe?.standardUnit,
  });

  const allFormState = { ...recipeFormState, compoundIngredientAttributes: formState };

  const [isFirstRun, setIsFirstRun] = useState(true);

  const handleFormState = (event) => {
    if (!dirty) {
      setDirty(true);
    }
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const removeKeysFromFormState = (keys) => {
    if (!dirty && !isFirstRun) {
      setDirty(true);
    }

    const modifiedFormState = { ...formState };
    keys.forEach((key) => delete modifiedFormState[key]);

    setFormState({ ...modifiedFormState });
    setIsFirstRun(false);
  };

  const handleRecipeFormState = (event) => {
    if (!dirty) {
      setDirty(true);
    }
    const { name, value } = event.target;
    setRecipeFormState({ ...recipeFormState, [name]: value });
  };

  // If being used in an iframe, set the width and height
  // so we don't have a double scrollbar situation.
  useEffect(() => {
    broadcastScrollSizeToParent();
  }, []);

  useEffect(() => {
    setFormState((prevFormState) => {
      return { ...prevFormState, id: compoundIngredient?.id };
    });
  }, [compoundIngredient?.id]);

  useEffect(() => {
    if (formState.standardUnit !== recipeFormState.standardUnit) {
      setRecipeFormState({ ...recipeFormState, standardUnit: formState.standardUnit });
    }
  }, [formState.standardUnit, recipeFormState]);

  useEffect(() => {
    // Ensure that this is always equivalent to the calculation done in mealhand
    const calculateInputAmount = (inputWeight, standardUnit, volumeToWeight) => {
      const volumeToWeightInt = parseFloat(volumeToWeight, 10);
      switch (standardUnit) {
        case 'fluid_oz':
          if (Number.isNaN(volumeToWeightInt)) return 'Volume to weight required';
          // return fluid_oz
          return inputWeight / volumeToWeightInt;
        default:
          return inputWeight;
      }
    };

    setRecipeFormState((prevRecipeFormState) => {
      return {
        ...prevRecipeFormState,
        inputAmount: calculateInputAmount(
          prevRecipeFormState.inputWeight,
          formState.standardUnit,
          formState.volumeToWeight
        ),
      };
    });
  }, [formState.standardUnit, formState.volumeToWeight]);

  return (
    <>
      <Grid item xs={6}>
        <BasicAttributes
          classes={classes}
          formState={formState}
          handleFormState={handleFormState}
          dirty={dirty}
        />
        <ExtendedAttributes
          classes={classes}
          formState={formState}
          handleFormState={handleFormState}
          removeKeysFromFormState={removeKeysFromFormState}
        />
      </Grid>
      <Grid item xs={6}>
        <BatchYield
          classes={classes}
          formState={recipeFormState}
          handleFormState={handleRecipeFormState}
          editsLocked={compoundIngredientRecipe?.isLocked}
        />
        <DisplayInformation
          classes={classes}
          formState={formState}
          handleFormState={handleFormState}
        />
      </Grid>
      <Grid item xs={12}>
        <SaveChangesButton
          compoundIngredientRecipeParams={allFormState}
          compoundIngredientRecipeId={compoundIngredientRecipe?.id}
        />
      </Grid>
    </>
  );
};

BasicsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  dirty: PropTypes.bool,
  setDirty: PropTypes.func.isRequired,
};

BasicsTab.defaultProps = {
  dirty: false,
};

export default BasicsTab;
