import React from 'react';
import { replace, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import {
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  Box,
} from '@mui/material';
import styles from './styles';

const StateActionDialog = ({
  open,
  onClose,
  compoundIngredientDisplayName,
  otherAssignableVersions,
  postStateAction,
  eventType,
}) => {
  const handleRequestApproval = () => {
    postStateAction(eventType);
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box>
        <DialogTitle sx={styles.dialogHeader}>Confirm Compound Ingredient State Change</DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <DialogContentText sx={styles.dialogContentText}>
            You are changing the state of {upperFirst(compoundIngredientDisplayName)}.
          </DialogContentText>
          <DialogContentText sx={styles.dialogContentText}>
            Other versions of this Compound Ingredient are set to Active or Needs Approval.
          </DialogContentText>
          <DialogContentText sx={styles.dialogContentBoldText}>
            All other versions must be changed to Legacy before you can make this change:
          </DialogContentText>
          <DialogContentText sx={styles.dialogContentSubHeader}>Other Versions: </DialogContentText>
          {otherAssignableVersions.map((oav) => {
            return (
              <DialogContentText sx={styles.dialogContentText} key={oav.id}>
                {oav.displayName} | State: {upperFirst(replace(oav.state, '_', ' '))} |{' '}
                {new Date(oav.updatedAt).toDateString()}
              </DialogContentText>
            );
          })}
          <DialogContentText sx={styles.dialogContentBoldText}>
            Would you like to change the state of Compound Ingredient{' '}
            {compoundIngredientDisplayName}, and change all other versions to Legacy?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button onClick={handleCancel} color="error" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleRequestApproval} color="success" variant="outlined">
            Confirm All Changes
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

StateActionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  compoundIngredientDisplayName: PropTypes.string.isRequired,
  otherAssignableVersions: PropTypes.array.isRequired,
  postStateAction: PropTypes.func.isRequired,
  eventType: PropTypes.string.isRequired,
};

export default StateActionDialog;
