import { LIGHT_BLACK } from 'lib/constants';

export default {
  content: {
    paddingTop: '16px',
  },
  title: {
    color: LIGHT_BLACK,
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'left',
    fontWeight: 500,
  },
  titleCell: {
    padding: '4px 12px',
    maxWidth: '200px',
    minWidth: '80px',
  },
  cell: {
    padding: '4px 16px',
  },
  switchLabel: {
    paddingRight: '20px',
  },
};
