import { LIGHT_BLACK, BACKGROUND_GREY } from 'lib/constants';
import { listLabel, listContent } from 'lib/styles/comparisonTool';

export default {
  listLabel,
  listContent,
  card: {
    maxWidth: '272px',
    margin: '0 0 36px 0',
    position: 'relative',
  },
  cardMedia: {
    minHeight: '181px',
  },
  photoErrorContent: {
    height: '181px',
    lineHeight: '181px',
    backgroundColor: BACKGROUND_GREY,
    color: LIGHT_BLACK,
    textAlign: 'center',
  },
  overlay: {
    position: 'absolute',
    color: 'white',
    backgroundColor: 'black',
    opacity: '0.7',
    wordBreak: 'break-all',
    bottom: '60px',
    padding: '4px',
  },
  cardContent: {
    height: '60px',
  },
};
