export default {
  menuItem: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
  loadingIndicator: {
    paddingRight: 20,
  },
};
