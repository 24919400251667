import React from 'react';
import PropTypes from 'prop-types';
import { APP_API_URI } from 'lib/constants';

// Material
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Components
import IfAllowed from 'components/shared/IfAllowed';
import AppHeader from '../../shared/AppHeader';
import AppLink from '../../shared/AppLink';

const LandingPage = ({ classes }) => {
  return (
    <div className={classes.root}>
      <AppHeader text="Retail Apps" />
      <Grid container direction="column" spacing={1} className={classes.container}>
        <Grid item>
          <Typography variant="h6">Business Parameters</Typography>
          <Grid container spacing={2} direction="row" data-link-color="green">
            <Grid item>
              <AppLink text="Routing" externalUrl={`${APP_API_URI}/admin/retail_data_upload`} />
            </Grid>
            <Grid item>
              <AppLink
                text="Skus And Facings"
                externalUrl={`${APP_API_URI}/admin/retail_data_upload`}
              />
            </Grid>
            <Grid item>
              <AppLink text="Fulfiller Manager" url="/retail/fulfillers" />
            </Grid>
            <Grid item>
              <AppLink text="Product Line Manager" url="/retail/product_lines" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="h6">Menus</Typography>
          <Grid container spacing={2} direction="row" data-link-color="blue">
            <IfAllowed ability="viewRetailMenuManager">
              <Grid item>
                <AppLink text="Retail Menu Manager" url="/retail/menu-manager" />
              </Grid>
            </IfAllowed>
            <Grid item>
              <AppLink text="GTINs" externalUrl={`${APP_API_URI}/admin/retail_data_upload`} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="h6">Operations</Typography>
          <Grid container spacing={2} direction="row" data-link-color="orange">
            <Grid item>
              <AppLink
                text="Store Orders"
                externalUrl={`${APP_API_URI}/admin/retail_data_upload`}
              />
            </Grid>
            <IfAllowed ability="viewRetailFlexibleVolumeChanges">
              <Grid item>
                <AppLink text="Volume Changes" url="/retail/volume-changes" />
              </Grid>
            </IfAllowed>
            <Grid item>
              <AppLink
                text="Packet Tracker"
                externalUrl={`${APP_API_URI}/admin/retail_packet_tracker`}
              />
            </Grid>
            <Grid item>
              <AppLink text="Manage Acceptable Shrink" url="/retail/acceptable-shrink" />
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h6">User Documentation</Typography>
            <Grid container spacing={2} direction="row" data-link-color="lightBlue">
              <Grid item>
                <AppLink
                  text="View Documentation"
                  url="https://home-chef-ops-core.atlassian.net/wiki/spaces/HCRT/overview"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

LandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default LandingPage;
