import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Material Components
import Paper from '@mui/material/Paper';

import { pluralizeWithNumber } from 'lib/helpers/strings';

import Dropdown from '../../shared/Dropdown';
import SectionHeader from '../shared/SectionHeader';
import TextBox from '../../shared/TextBox';

const ServingsAndPortions = ({ classes, formState, handleFormState }) => {
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);

  const unitsPerOrder = formState.unitAmount || formState.baseServingSize || 2;
  const pricePerOrder = (formState.price * 2).toFixed(2);
  const pricePerUnit = (pricePerOrder / unitsPerOrder).toFixed(2);

  const selectQuantities = useMemo(() => {
    return formOptions.maxOrderableQuantities.map((quantity) => ({
      label: quantity,
      value: quantity,
    }));
  }, [formOptions.maxOrderableQuantities]);

  const selectUnitAmounts = useMemo(() => {
    return formOptions.unitAmounts.map((amount) => ({
      label: amount,
      value: amount,
    }));
  }, [formOptions.unitAmounts]);

  const selectUnitNames = useMemo(() => {
    return formOptions.unitNames.map((name) => ({ label: name, value: name }));
  }, [formOptions.unitNames]);

  return (
    <>
      <Paper className={classes.paperWithBottomMargin}>
        <SectionHeader text="Price and Servings Breakdown" />
        <p className={classes.text}>
          Each orderable unit will cost{' '}
          <strong data-testid="pricePerOrder">${pricePerOrder}</strong>. It will contain{' '}
          <strong data-testid="unitsPerOrder">
            {pluralizeWithNumber(unitsPerOrder, formState.unitName)}
          </strong>{' '}
          and feed <strong>{formState.baseServingSize}</strong> people.
        </p>
        <p className={classes.text}>
          Customers will see the price as{' '}
          <strong data-testid="pricePerUnit">
            ${pricePerUnit}/{formState.unitName}
          </strong>
          .
        </p>
        <p className={classes.text}>
          The maximum number of units that can be ordered is{' '}
          <strong>{formState.maxOrderableQuantity}</strong>.
        </p>
      </Paper>

      <Paper className={classes.paperWithBottomMargin}>
        <SectionHeader text="Meal Portions" />
        <p className={classes.text}>
          The standard Home Chef eCommerce meal build has a <strong>unit amount</strong> of 2, a{' '}
          <strong>unit name</strong> of &quot;servings,&quot; a <strong>servings per meal</strong>{' '}
          of 2, and a <strong>max orderable quantity</strong> of 4.
        </p>

        <Dropdown
          helperText="Smallest orderable unit of this meal. When customers adds additional units, it will incremement by this number. (1 = 1, 2, 3, 4; 2 = 2, 4, 6, 8; 6 = 6, 12, 18, 24)"
          label="Unit Amount"
          name="unitAmount"
          required
          onChange={handleFormState}
          options={selectUnitAmounts}
          value={formState.unitAmount}
        />

        <Dropdown
          label="Unit Name"
          name="unitName"
          onChange={handleFormState}
          options={selectUnitNames}
          required
          value={formState.unitName}
        />

        <TextBox
          fullWidth
          helperText="Each unit a customer orders will feed this many people. This is also how nutrition per serving will be determined. This is a number between 1 and 99."
          label="Servings Per Meal"
          name="baseServingSize"
          onChange={handleFormState}
          value={formState.baseServingSize}
          type="number"
          required
        />

        <Dropdown
          label="Max Orderable Quantity"
          name="maxOrderableQuantity"
          required
          onChange={handleFormState}
          value={formState.maxOrderableQuantity}
          options={selectQuantities}
        />
      </Paper>
    </>
  );
};

ServingsAndPortions.propTypes = {
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
};

export default ServingsAndPortions;
