import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import { mealShape } from 'lib/helpers/comparisonTool/propShapes';

// Material
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';

// Components
import MealListItem from './MealListItem';

const MealsList = ({ activeMeals, inactiveMeals, checkedMeals, onSetCheckedMeals, classes }) => {
  const handleCheck = (value) => () => {
    const currentIndex = checkedMeals.indexOf(value);
    const newCheckedMeals = [...checkedMeals];

    if (currentIndex === -1) {
      newCheckedMeals.push(value);
    } else {
      newCheckedMeals.splice(currentIndex, 1);
    }

    onSetCheckedMeals(newCheckedMeals);
  };

  const renderActiveMeals = () => {
    return (
      <>
        <ListSubheader className={classes.listSubHeader}>Live And Upcoming Versions</ListSubheader>
        <Divider />
        {activeMeals.map((meal) => {
          return (
            <MealListItem
              meal={meal}
              classes={classes}
              key={`${meal.versionId}-listItem`}
              handleCheck={handleCheck}
              checkedMeals={checkedMeals}
            />
          );
        })}
      </>
    );
  };

  const renderInactiveMeals = () => {
    return (
      <>
        <ListSubheader className={classes.listSubHeader}>
          Unassigned And Past Versions
        </ListSubheader>
        <Divider />
        {inactiveMeals.map((meal) => {
          return (
            <MealListItem
              meal={meal}
              classes={classes}
              key={`${meal.versionId}-listItem`}
              handleCheck={handleCheck}
              checkedMeals={checkedMeals}
            />
          );
        })}
      </>
    );
  };

  return (
    <List>
      {activeMeals.length !== 0 && renderActiveMeals()}
      {inactiveMeals.length !== 0 && renderInactiveMeals()}
    </List>
  );
};

MealsList.propTypes = {
  activeMeals: PropTypes.arrayOf(mealShape).isRequired,
  inactiveMeals: PropTypes.arrayOf(mealShape).isRequired,
  checkedMeals: PropTypes.array.isRequired,
  onSetCheckedMeals: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default MealsList;
