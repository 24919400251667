import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';

const CsvMessage = ({ csvStatus }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (csvStatus !== '') {
      setOpen(true);
    }
  }, [csvStatus]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      onClose={handleClose}
      autoHideDuration={3000}
      message={<span>{csvStatus}</span>}
    />
  );
};

CsvMessage.propTypes = {
  csvStatus: PropTypes.string.isRequired,
};

export default CsvMessage;
