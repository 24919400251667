// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

// Actions
import { getDisputes, sendEvidence } from 'redux/disputedCharges/actions';

// Components
import DisputedCharges from './DisputedCharges';
import styles from './styles';

const mapStateToProps = (state) => ({
  disputes: state.disputedCharges.disputes,
  fetching: state.disputedCharges.fetching,
});
const mapDispatchToProps = {
  onGetDisputes: getDisputes,
  onSendEvidence: sendEvidence,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DisputedCharges));
