import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { broadcastScrollSizeToParent } from 'lib/utils';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import LockIcon from '@mui/icons-material/Lock';

import NutritionTable from './NutritionTable';
import OutputLoss from './OutputLoss';
import SaveChangesButton from '../shared/SaveChangesButton';

const NutritionTab = ({ classes, dirty, setDirty }) => {
  const { compoundIngredientRecipe } = useSelector(
    (state) => state.compoundIngredientRecipeForm.compoundIngredientRecipe
  );

  const compoundIngredient = compoundIngredientRecipe?.compoundIngredient;

  const [formState, setFormState] = useState({
    fatLoss: compoundIngredientRecipe?.fatLoss,
    moistureLoss: compoundIngredientRecipe?.moistureLoss,
    prePrepFat: compoundIngredientRecipe?.prePrepFat,
  });

  const handleFormState = (event) => {
    if (!dirty) {
      setDirty(true);
    }
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  // If being used in an iframe, set the width and height
  // so we don't have a double scrollbar situation.
  useEffect(() => {
    broadcastScrollSizeToParent();
  }, []);

  const renderEditsLockedMessage = () => {
    return (
      <Grid item xs={12}>
        <Alert severity="warning" icon={<LockIcon fontSize="small" />} className={classes.alert}>
          {`This compound ingredient is ${compoundIngredient.state} and formulation can not be edited`}
        </Alert>
      </Grid>
    );
  };

  return (
    <>
      {compoundIngredientRecipe.isLocked && renderEditsLockedMessage()}
      <Paper className={classes.paper}>
        <h1 className={classes.tabHeader}>Nutrition Facts</h1>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <OutputLoss
              classes={classes}
              editsLocked={compoundIngredientRecipe.isLocked}
              formState={formState}
              handleFormState={handleFormState}
            />
          </Grid>
          <Grid item xs={6}>
            <NutritionTable classes={classes} compoundIngredient={compoundIngredient} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SaveChangesButton
            compoundIngredientRecipeParams={formState}
            compoundIngredientRecipeId={compoundIngredientRecipe?.id}
            editsLocked={compoundIngredientRecipe.isLocked}
          />
        </Grid>
      </Paper>
    </>
  );
};

NutritionTab.propTypes = {
  classes: PropTypes.object.isRequired,
  dirty: PropTypes.bool,
  setDirty: PropTypes.func.isRequired,
};

NutritionTab.defaultProps = {
  dirty: false,
};

export default NutritionTab;
