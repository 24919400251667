import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Material Components
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

import { range } from 'lodash';

import Dropdown from '../../shared/Dropdown';
import TextBox from '../../shared/TextBox';

const CompoundIngredientRecipeStep = ({
  classes,
  compoundIngredientRecipeStep,
  formState,
  handleFormState,
  index,
}) => {
  const { formOptions } = useSelector((state) => state.compoundIngredientRecipeForm.formOptions);

  const [recipeStepState, setRecipeStepState] = useState({
    ...compoundIngredientRecipeStep,
    index,
  });

  useEffect(() => {
    setRecipeStepState({
      ...compoundIngredientRecipeStep,
      index,
    });
  }, [compoundIngredientRecipeStep, index]);

  const updateFormState = (name, value) => {
    const newRecipeStepState = { ...recipeStepState, [name]: value };
    setRecipeStepState(newRecipeStepState);

    const newFormStateRecipeSteps = formState.compoundIngredientRecipeSteps;
    newFormStateRecipeSteps[index] = newRecipeStepState;

    handleFormState({
      target: { name: 'compoundIngredientRecipeSteps', value: newFormStateRecipeSteps },
    });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    updateFormState(name, value);
  };

  const onDeleteStep = () => {
    updateFormState('deleted', true);
  };

  const selectStepOptions = useMemo(() => {
    return range(1, 100).map((num) => ({ label: num, value: num }));
  }, []);

  const selectPrepMethods = useMemo(() => {
    return formOptions.prepMethods.map((prepMethod) => ({
      label: prepMethod.name,
      value: prepMethod.id,
    }));
  }, [formOptions.prepMethods]);

  const selectProductionEquipments = useMemo(() => {
    return formOptions.productionEquipments.map((productionEquipment) => ({
      label: productionEquipment.name,
      value: productionEquipment.id,
    }));
  }, [formOptions.productionEquipments]);

  const selectProductionRooms = useMemo(() => {
    return formOptions.productionRooms.map((productionRoom) => ({
      label: productionRoom.formattedName,
      value: productionRoom.name,
    }));
  }, [formOptions.productionRooms]);

  const renderRecipeStep = () => {
    return (
      <Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.recipeStepTitle}>
              <TextBox
                required
                label="Title"
                name="title"
                onChange={onChange}
                value={recipeStepState.title}
              />
            </Grid>
            <Grid item xs={6}>
              <Dropdown
                required
                label="Step"
                name="step"
                onChange={onChange}
                value={recipeStepState.step}
                options={selectStepOptions}
              />
            </Grid>
          </Grid>
          <TextBox
            fullWidth
            multiline
            required
            rows={6}
            label="Description"
            name="description"
            onChange={onChange}
            value={recipeStepState.description}
          />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Dropdown
                label="Production Room"
                name="productionRoom"
                onChange={onChange}
                value={recipeStepState.productionRoom}
                options={selectProductionRooms}
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                label="Prep Method"
                name="prepMethodId"
                onChange={onChange}
                value={recipeStepState.prepMethodId}
                options={selectPrepMethods}
              />
            </Grid>
            <Grid item xs={4}>
              <Dropdown
                label="Production Equipment"
                name="productionEquipmentId"
                onChange={onChange}
                value={recipeStepState.productionEquipmentId}
                options={selectProductionEquipments}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextBox
                label="Prep Time (mins)"
                name="prepTime"
                onChange={onChange}
                value={recipeStepState.prepTime}
              />
            </Grid>
            <Grid item xs={4}>
              <TextBox
                label="Cook Time (mins)"
                name="cookTime"
                onChange={onChange}
                value={recipeStepState.cookTime}
              />
            </Grid>
            <Grid item xs={4}>
              <TextBox
                label="Chill Time (mins)"
                name="chillTime"
                onChange={onChange}
                value={recipeStepState.chillTime}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.removeStepContainer}>
            <Button variant="outlined" className={classes.deleteButton} onClick={onDeleteStep}>
              <DeleteIcon />
              Remove Step
            </Button>
          </Grid>
        </Grid>
        <hr className={classes.recipeStepDivider} />
      </Grid>
    );
  };

  return <>{recipeStepState.deleted ? null : renderRecipeStep()}</>;
};

CompoundIngredientRecipeStep.propTypes = {
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  compoundIngredientRecipeStep: PropTypes.object,
};

CompoundIngredientRecipeStep.defaultProps = {
  compoundIngredientRecipeStep: null,
};

export default CompoundIngredientRecipeStep;
