import React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const MealCardWithInfo = ({ meal, classes }) => {
  return (
    <Card className={classes.root}>
      <CardMedia image={meal.photo} title={meal.title} className={classes.media} />
      <CardContent>
        <a href={meal.url} target="_blank" rel="noreferrer" className={classes.title}>
          {meal.title}
        </a>
        <p className={classes.subtitle}>{meal.subtitle}</p>
      </CardContent>
    </Card>
  );
};

MealCardWithInfo.propTypes = {
  meal: PropTypes.shape({
    photo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  classes: PropTypes.object.isRequired,
};

export default MealCardWithInfo;
