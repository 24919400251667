import {
  GET_ALL_CONFIGURATIONS,
  UPDATE_CONFIGURATION_KEY,
  CREATE_CONFIGURATION_GROUP,
  UPDATE_CONFIGURATION_GROUP,
  REMOVE_CONFIGURATION_GROUP,
  DELETE_CONFIGURATION_KEY,
  CREATE_CONFIGURATION_KEY,
  RESTART_NOW,
} from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import {
  fetchAllAppConfigurations,
  updatingConfigurationKey,
  addConfigurationGroup,
  updateConfigurationGroup,
  deleteConfigurationGroup,
  deletingConfigurationKey,
  creatingConfigurationKey,
  restartingNow,
} from './services';

export const getAllAppConfigurations = () => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: GET_ALL_CONFIGURATIONS,
        method: fetchAllAppConfigurations,
      })
    );
  };
};

export const createConfigurationGroup = (configGroup) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: CREATE_CONFIGURATION_GROUP,
        method: addConfigurationGroup,
        params: { configGroup },
      })
    );
  };
};

export const renameConfigurationGroup = (configGroup) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: UPDATE_CONFIGURATION_GROUP,
        method: updateConfigurationGroup,
        params: { configGroup },
      })
    );
  };
};

export const removeConfigurationGroup = (configGroup) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: REMOVE_CONFIGURATION_GROUP,
        method: deleteConfigurationGroup,
        params: { configGroup },
      })
    );
  };
};

export const updateConfigurationKey = (configKey) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: UPDATE_CONFIGURATION_KEY,
        method: updatingConfigurationKey,
        params: { configKey },
      })
    );
  };
};

export const deleteConfigurationKey = (keyId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: DELETE_CONFIGURATION_KEY,
        method: deletingConfigurationKey,
        params: { keyId },
      })
    );
  };
};

export const createConfigurationKey = (formData) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: CREATE_CONFIGURATION_KEY,
        method: creatingConfigurationKey,
        params: { ...formData },
      })
    );
  };
};

export const restartNow = () => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: RESTART_NOW,
        method: restartingNow,
      })
    );
  };
};
