import React from 'react';
import PropTypes from 'prop-types';

const AddMealsErrorMessageSection = ({ errorMessage, classes }) => {
  return (
    <div>
      <div className={classes.addMealsErrorText}>{errorMessage}</div>
    </div>
  );
};

AddMealsErrorMessageSection.propTypes = {
  errorMessage: PropTypes.string,
  classes: PropTypes.object,
};

AddMealsErrorMessageSection.defaultProps = {
  errorMessage: '',
  classes: {},
};

export default AddMealsErrorMessageSection;
