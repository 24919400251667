import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { Button, Chip, TableCell, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const FulfillerRow = ({ fulfiller, handleEditClick }) => {
  return (
    <TableRow key={fulfiller.id}>
      <TableCell>{fulfiller.id}</TableCell>
      <TableCell>{fulfiller.name}</TableCell>
      <TableCell>
        <Chip label={fulfiller.status} color="success" variant="outlined" />
      </TableCell>
      <TableCell>
        <Button
          variant="outlined"
          onClick={() => handleEditClick(fulfiller)}
          startIcon={<EditIcon />}
        >
          Edit
        </Button>
      </TableCell>
      <TableCell>{format(new Date(fulfiller.updatedAt), 'M/d/Y')}</TableCell>
    </TableRow>
  );
};

FulfillerRow.propTypes = {
  fulfiller: PropTypes.object.isRequired,
  handleEditClick: PropTypes.func.isRequired,
};

export default FulfillerRow;
