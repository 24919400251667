import {
  GET_ALL_CONFIGURATIONS_REQUEST,
  GET_ALL_CONFIGURATIONS_SUCCESS,
  GET_ALL_CONFIGURATIONS_ERROR,
  CREATE_CONFIGURATION_GROUP_REQUEST,
  CREATE_CONFIGURATION_GROUP_SUCCESS,
  CREATE_CONFIGURATION_GROUP_ERROR,
  UPDATE_CONFIGURATION_GROUP_REQUEST,
  UPDATE_CONFIGURATION_GROUP_SUCCESS,
  UPDATE_CONFIGURATION_GROUP_ERROR,
  REMOVE_CONFIGURATION_GROUP_REQUEST,
  REMOVE_CONFIGURATION_GROUP_SUCCESS,
  REMOVE_CONFIGURATION_GROUP_ERROR,
  UPDATE_CONFIGURATION_KEY_REQUEST,
  UPDATE_CONFIGURATION_KEY_SUCCESS,
  UPDATE_CONFIGURATION_KEY_ERROR,
  DELETE_CONFIGURATION_KEY_REQUEST,
  DELETE_CONFIGURATION_KEY_SUCCESS,
  DELETE_CONFIGURATION_KEY_ERROR,
  CREATE_CONFIGURATION_KEY_REQUEST,
  CREATE_CONFIGURATION_KEY_SUCCESS,
  CREATE_CONFIGURATION_KEY_ERROR,
  RESTART_NOW_REQUEST,
  RESTART_NOW_SUCCESS,
  RESTART_NOW_ERROR,
  UPDATE_TAB_INDEX,
  SET_FOCUS_KEY_ID,
  CLEAR_FOCUS_KEY_ID,
  CLOSE_RESTART_DIALOG,
  CLOSE_DEPLOY_LOCK_DIALOG,
  SET_FORM_DATA,
} from 'lib/constants';

export const initialState = {
  error: '',
  fetching: false,
  applicationConfig: [],
  tabIndex: 0,
  focusKeyId: null,
  showRestartDialog: false,
  showDeployLockDialog: false,
  formData: {},
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case GET_ALL_CONFIGURATIONS_REQUEST:
      return {
        ...state,
        fetching: true,
        error: '',
      };
    case GET_ALL_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        applicationConfig: action.response.applicationConfig,
      };
    case GET_ALL_CONFIGURATIONS_ERROR:
      return {
        ...state,
        error: action.response.error,
      };
    case CREATE_CONFIGURATION_GROUP_REQUEST:
      return {
        ...state,
        fetching: true,
        error: '',
      };
    case CREATE_CONFIGURATION_GROUP_SUCCESS: {
      const { configurationGroup } = action.response;
      const applicationConfig = [...state.applicationConfig, configurationGroup];
      const tabIndex = applicationConfig
        .sort((groupA, groupB) => groupA.groupName.localeCompare(groupB.groupName))
        .findIndex((x) => x.groupId === configurationGroup.groupId);

      return {
        ...state,
        fetching: false,
        error: '',
        tabIndex,
        applicationConfig,
      };
    }

    case CREATE_CONFIGURATION_GROUP_ERROR: {
      return {
        ...state,
        fetching: false,
        error: action.response.error,
      };
    }
    case UPDATE_CONFIGURATION_GROUP_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: '',
      };
    }
    case UPDATE_CONFIGURATION_GROUP_SUCCESS: {
      const { configurationGroup } = action.response;
      return {
        ...state,
        fetching: false,
        error: '',
        applicationConfig: state.applicationConfig.map((group) =>
          group.groupId === configurationGroup.groupId
            ? {
                ...group,
                groupName: configurationGroup.groupName,
              }
            : group
        ),
      };
    }
    case UPDATE_CONFIGURATION_GROUP_ERROR: {
      return {
        ...state,
        fetching: false,
        error: action.response.error,
      };
    }

    case REMOVE_CONFIGURATION_GROUP_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: '',
      };
    }
    case REMOVE_CONFIGURATION_GROUP_SUCCESS: {
      const { configGroup } = action.params;
      return {
        ...state,
        fetching: false,
        error: '',
        applicationConfig: [
          ...state.applicationConfig.filter((el) => el.groupId !== configGroup.groupId),
        ],
        tabIndex: 0,
      };
    }
    case REMOVE_CONFIGURATION_GROUP_ERROR: {
      return {
        ...state,
        fetching: false,
        error: action.response.error,
      };
    }

    case UPDATE_CONFIGURATION_KEY_REQUEST:
      return {
        ...state,
        fetching: true,
        error: '',
      };
    case UPDATE_CONFIGURATION_KEY_SUCCESS: {
      const { configurationKey: updatedKey, willRestart: showRestartDialog } = action.response;
      const { [updatedKey.id]: _, ...formData } = state.formData; // clear out unsaved changes for the updated key on success
      return {
        ...state,
        fetching: false,
        error: '',
        applicationConfig: state.applicationConfig.map((group) =>
          group.groupId === updatedKey.groupId
            ? {
                ...group,
                keys: group.keys.map((key) => (key.id === updatedKey.id ? updatedKey : key)),
              }
            : group
        ),
        formData,
        showRestartDialog,
      };
    }
    case UPDATE_CONFIGURATION_KEY_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.response.error,
      };
    case DELETE_CONFIGURATION_KEY_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.response.error,
      };
    case DELETE_CONFIGURATION_KEY_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case DELETE_CONFIGURATION_KEY_SUCCESS: {
      const keyId = parseInt(action.params.keyId, 10);
      return {
        ...state,
        fetching: false,
        applicationConfig: state.applicationConfig.map((group) => ({
          ...group,
          keys: group.keys.filter((key) => key.id !== keyId),
        })),
        showRestartDialog: true,
      };
    }
    case CREATE_CONFIGURATION_KEY_REQUEST:
      return {
        ...state,
        fetching: true,
        error: '',
      };
    case CREATE_CONFIGURATION_KEY_SUCCESS: {
      const { configurationKey } = action.response;
      return {
        ...state,
        fetching: false,
        error: '',
        applicationConfig: state.applicationConfig.map((group) =>
          group.groupId === configurationKey.groupId
            ? {
                ...group,
                keys: [...group.keys, configurationKey],
              }
            : group
        ),
        showRestartDialog: true,
      };
    }
    case CREATE_CONFIGURATION_KEY_ERROR: {
      const response = action;
      return {
        ...state,
        fetching: false,
        error: response.error.message,
      };
    }
    case RESTART_NOW_REQUEST: {
      return state;
    }
    case RESTART_NOW_SUCCESS: {
      return {
        ...state,
        showDeployLockDialog: !action.response.canRestartNow,
      };
    }
    case RESTART_NOW_ERROR: {
      return {
        ...state,
        fetching: false,
        error: action.error.message,
      };
    }
    case UPDATE_TAB_INDEX: {
      return {
        ...state,
        tabIndex: action.newTabIndex,
      };
    }
    case CLOSE_RESTART_DIALOG: {
      return {
        ...state,
        showRestartDialog: false,
      };
    }
    case CLOSE_DEPLOY_LOCK_DIALOG: {
      return {
        ...state,
        showDeployLockDialog: false,
      };
    }
    case SET_FOCUS_KEY_ID:
      return {
        ...state,
        focusKeyId: action.focusKeyId,
      };
    case CLEAR_FOCUS_KEY_ID:
      return {
        ...state,
        focusKeyId: null,
      };
    case SET_FORM_DATA: {
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.unsavedKey.id]: action.unsavedKey,
        },
      };
    }
    default:
      return state;
  }
};
