import { BACKGROUND_GREY, HC_GREEN } from 'lib/constants';

export default {
  root: {
    padding: '32px',
    backgroundColor: BACKGROUND_GREY,
  },
  container: {
    marginTop: '24px',
    marginLeft: '0px',
    padding: '8px',
  },
  retailPath: {
    display: 'inline',
    textDecoration: 'none',
    color: HC_GREEN,
    fontSize: '16px',
    paddingLeft: '8px',
  },
  navPath: {
    display: 'inline',
    fontSize: '16px',
  },
};
