// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import MealSelector from './MealSelector';

// Styles
import styles from './styles';

export default withStyles(styles)(MealSelector);
