// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

// Actions
import { setCheckedMeals } from 'redux/comparisonTool/concept/actions';

import MealsList from './MealsList';

import styles from './styles';

const mapStateToProps = (state) => ({
  checkedMeals: state.comparisonTool.concept.checkedMeals,
});

const mapDispatchToProps = {
  onSetCheckedMeals: setCheckedMeals,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MealsList));
