import { MEF_MEAL, MEF_UPDATE_MEAL, MEF_CLEAR_MESSAGES } from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import { fetchMeal, updateMeal, updateMealPhotos, updateMealRecipeStepsTab } from './services';

export const getMeal = (mealId) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: MEF_MEAL,
        method: fetchMeal,
        params: { mealId },
      })
    );
  };
};

export const postMeal = (transformedParams) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: MEF_UPDATE_MEAL,
        method: updateMeal,
        params: transformedParams,
      })
    );
  };
};

export const postMealPhotos = (formData) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: MEF_UPDATE_MEAL,
        method: updateMealPhotos,
        params: formData,
      })
    );
  };
};

export const postMealRecipeStepsTab = (formData) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: MEF_UPDATE_MEAL,
        method: updateMealRecipeStepsTab,
        params: formData,
      })
    );
  };
};

export const clearSuccessAndErrorMessages = () => {
  return async (dispatch) => {
    dispatch({
      type: MEF_CLEAR_MESSAGES,
    });
  };
};
