import React from 'react';
import PropTypes from 'prop-types';

const Tag = ({ color }) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <title>Tag</title>
      <g fillRule="evenodd">
        <path
          fill={color}
          d="M12.5857864,2 C13.1162194,2 13.6249272,2.21071368 14,2.58578644 L14,2.58578644 L22.2992073,10.885 C23.4623781,12.0551124 23.4623781,13.9448876 22.2971068,15.1171068 L22.2971068,15.1171068 L15.1275,22.2867133 C14.5647952,22.8500445 13.8012277,23.1665733 13.005,23.1665733 C12.2087723,23.1665733 11.4452048,22.8500445 10.8833052,22.2875185 L10.8833052,22.2875185 L2.58661033,14.0004822 C2.2110336,13.6253427 2,13.1162819 2,12.5854452 L2,12.5854452 L2,4 C2,2.8954305 2.8954305,2 4,2 L4,2 Z M12.5857864,4 L4,4 L4,12.5854452 L12.2975,20.8732867 C12.4850683,21.0610637 12.7395908,21.1665733 13.005,21.1665733 C13.2704092,21.1665733 13.5249317,21.0610637 13.7128932,20.8728932 L13.7128932,20.8728932 L20.8807927,13.705 C21.2685163,13.3149625 21.2685163,12.6850375 20.8828932,12.2971068 L20.8828932,12.2971068 L12.5857864,4 Z M7.5,6 C6.67157288,6 6,6.67157288 6,7.5 C6,8.32842712 6.67157288,9 7.5,9 C8.32842712,9 9,8.32842712 9,7.5 C9,6.67157288 8.32842712,6 7.5,6 Z"
        />
      </g>
    </svg>
  );
};

Tag.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Tag;
