export default {
  addMealsTextArea: {
    width: '500px',
    maxWidth: '100%',
  },

  addMealsPostingFailed: {
    border: '1px solid red',
  },
};
