// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

// Components
import Footer from './Footer';
import styles from './styles';

const isSubmitting = (state) => {
  const { incidentable } = state.incidentManager;
  const { incident } = state.incidentManager;
  return incidentable.submitting || incident.submitting;
};

const mapStateToProps = (state) => ({
  submitting: isSubmitting(state),
});

export default connect(mapStateToProps)(withStyles(styles)(Footer));
