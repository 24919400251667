import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';

import FulfillerRow from '../FulfillerRow/FulfillerRow';

const FulfillerTable = ({ classes, fulfillers, handleEditClick }) => {
  const [showArchived, setShowArchived] = useState(false);

  const filteredFulfillers = fulfillers.filter((fulfiller) => fulfiller.status !== 'archived');
  const displayFulfillers = showArchived ? fulfillers : filteredFulfillers;
  const handleChange = () => setShowArchived(!showArchived);

  return (
    <Paper className={classes.productLineCard} elevation={0} variant="outlined">
      <Toolbar role="rowgroup" className={classes.tableHeader}>
        <Typography variant="h5">Manage Fulfillers</Typography>
        <FormControlLabel
          control={<Switch role="switch" onChange={handleChange} checked={showArchived} />}
          label="Show Archived Fulfillers"
        />
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Fulfiller Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
            <TableCell>Last Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {displayFulfillers &&
            displayFulfillers.map((fulfiller) => (
              <FulfillerRow
                key={fulfiller.id}
                fulfiller={fulfiller}
                handleEditClick={handleEditClick}
              />
            ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

FulfillerTable.propTypes = {
  classes: PropTypes.object.isRequired,
  fulfillers: PropTypes.array.isRequired,
  handleEditClick: PropTypes.func.isRequired,
};

export default FulfillerTable;
