import { useEffect, useState } from 'react';
import { GET } from './api';
import { APP_API_URI } from './constants';

const REFRESH_INTERVAL_MSEC = 1000;
const PENDING_STATUSES = ['queued', 'active', 'retrying'];

export default ({
  jobClass,
  jid,
  onJobResult = () => {},
  onJobRetrying = () => {},
  onJobDead = () => {},
  onFetchError = () => {},
  refreshRate = REFRESH_INTERVAL_MSEC,
}) => {
  const [lastResponse, setLastResponse] = useState(null);
  const [jobDetail, setJobDetail] = useState(null);
  const parseJobDetail = (detail) => {
    const parsedJobDetail = detail && { ...detail };
    if (parsedJobDetail && parsedJobDetail.lastTime) {
      parsedJobDetail.lastTime = new Date(parsedJobDetail.lastTime);
    }
    return parsedJobDetail;
  };

  useEffect(() => {
    if (jid === null) {
      setLastResponse(null);
      setJobDetail(null);
      return undefined;
    }

    // Once we have a job id we know the job is queued. We'll initialize the
    // status in the client here, so that there is always a status defined once
    // a job id is passed
    setJobDetail({
      status: 'queued',
      lastTime: new Date().toISOString(),
    });

    let ignore = false;
    const fetchJobStatus = async () => {
      const res = await GET(`${APP_API_URI}/internal/job_detail/get_job_detail`, {
        params: { class: jobClass, jid },
      })
        .then((response) => {
          if (ignore) return undefined;
          setLastResponse(response.data);
          return response;
        })
        .catch((error) => {
          onFetchError(error.response?.data?.error || error.response.statusText);
          return undefined;
        });
      if (!res?.data?.jobDetail || PENDING_STATUSES.includes(res?.data?.jobDetail?.status)) {
        // If the job is still pending, schedule the next refresh
        setTimeout(fetchJobStatus, refreshRate);
      }
    };

    fetchJobStatus();

    return () => {
      // This is called on effect cleanup, when the jid is changed. This allows
      // us to discard responses that initiated with a stale jid after the jid
      // changes
      ignore = true;
    };
  }, [jid]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handle API responses. This handling is done in a separate effect to ensure
  // we're using the current state, and not the state when the fetch function
  // was defined
  useEffect(() => {
    if (lastResponse === null) return;

    const newJobDetail = lastResponse.jobDetail;
    // Jobs sometimes disappear for several seconds when moving from queued
    // to busy. If the job detail is null just ignore that response and
    // we'll try again.
    if (newJobDetail === null) {
      return;
    }

    // Only assign the new job detail object if one of the values has changed
    if (
      jobDetail === null ||
      Object.keys(newJobDetail).some((k) => newJobDetail[k] !== jobDetail[k])
    ) {
      setJobDetail(newJobDetail);
    }
  }, [jobDetail, lastResponse]);

  useEffect(() => {
    switch (jobDetail && jobDetail.status) {
      case 'result':
        onJobResult(jobDetail.result);
        break;
      case 'retrying':
        onJobRetrying(parseJobDetail(jobDetail));
        break;
      case 'dead':
        onJobDead(parseJobDetail(jobDetail));
        break;
      default:
    }
  }, [jobDetail]); // eslint-disable-line react-hooks/exhaustive-deps

  return parseJobDetail(jobDetail);
};
