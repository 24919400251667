import { LIGHTEST_GREY, LIGHT_BLACK, MEDIUM_GREY, LIGHT_GREEN } from 'lib/constants';
import {
  rowLabel,
  iconCell,
  icon,
  listContentSameBlock,
  expandingCell,
  collapse,
  tableRowGreenBorder,
  greyed,
} from 'lib/styles/comparisonTool';

export default {
  rowLabel,
  iconCell,
  icon,
  listContentSameBlock,
  expandingCell,
  collapse,
  tableRowGreenBorder,
  greyed,
  ingredientBlock: {
    marginBottom: '12px',
    minHeight: '46px',
    paddingLeft: '6px',
    display: 'flex',
    alignItems: 'center',
  },
  highlight: {
    backgroundColor: LIGHT_GREEN,
  },
  ingredientMissing: {
    backgroundColor: LIGHTEST_GREY,
    '& > p': {
      margin: '0',
      color: MEDIUM_GREY,
      fontWeight: '400',
      fontSize: '12px',
    },
  },
  ingredientName: {
    color: LIGHT_BLACK,
    fontWeight: '500',
    fontSize: '12px',
  },
  ingredientQuantity: {
    display: 'inline-block',
    color: LIGHT_BLACK,
    fontWeight: '400',
    fontSize: '12px',
    marginLeft: '8px',
  },
  ingredientBrandName: {
    color: MEDIUM_GREY,
    fontWeight: '400',
    fontSize: '12px',
  },
};
