import { LIGHT_BLACK } from 'lib/constants';
import { rowLabel, iconCell, greyed } from 'lib/styles/comparisonTool';

export default {
  rowLabel,
  iconCell,
  greyed,
  versionNotes: {
    color: LIGHT_BLACK,
    fontWeight: '400',
    fontSize: '12px',
  },
};
