import { APP_API_URI } from 'lib/constants';
import { GET } from 'lib/api';

const CIRF_URL = `${APP_API_URI}/internal/culinary/compound_ingredient_recipe_form`;

export const fetchFormOptions = async () => {
  const response = await GET(`${CIRF_URL}/form_options`);

  return response.data;
};
