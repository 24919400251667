import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { filter, includes, map } from 'lodash';
import { DELETED } from 'lib/constants';
import useAllowed from 'lib/useAllowed';
import clsx from 'clsx';

import {
  handleMount,
  handleNewBaseIngredientSelected,
  handleClearIngredientAssignment,
  handleNewIngredientAssignment,
} from 'lib/helpers/mealEditForm/ingredientsTabHelpers';

// Material Components
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';

import TextBox from '../../shared/TextBox';
import IngredientSearch from './IngredientSearch';
import IngredientFormInputs from '../../shared/IngredientFormInputs/IngredientFormInputs';
import IngredientDropdowns from '../../shared/IngredientDropdowns/IngredientDropdowns';
import IngredientCheckboxes from './IngredientCheckboxes';
import ReplacementIngredientCard from './ReplacementIngredientCard';

const IngredientCard = ({
  classes,
  areAnyIngredientsExpanded,
  setAnyIngredientsExpanded,
  expandAllIngredients,
  setExpandAllIngredients,
  formState,
  handleFormState,
  setFormState,
  index,
  ingredientAssignment,
  rthProductType,
}) => {
  const [isFirstRun, setIsFirstRun] = useState(true);
  const [baseIngredientSelected, setBaseIngredientSelected] = useState(
    ingredientAssignment.ingredient
  );

  const [ingredientAssignmentState, setIngredientAssignmentState] = useState(ingredientAssignment);

  const defaultCheckboxState = {
    labelRequired: ingredientAssignment.labelRequired || false,
    readyToEat: ingredientAssignment.readyToEat || false,
    mustCook: ingredientAssignment.mustCook || false,
    pickable: ingredientAssignment.pickable || false,
    largeIngredient: ingredientAssignment.largeIngredient || false,
  };
  const [checkboxState, setCheckboxState] = useState(defaultCheckboxState);
  const [isCardExpanded, setCardExpanded] = useState(areAnyIngredientsExpanded);
  const [modalOpen, setModalOpen] = useState(false);
  const [seqNumErrors, setSeqNumErrors] = useState([]);

  const canEditReplacements = useAllowed('editReplacements');

  const replacementIngredients = ingredientAssignmentState?.replacementIngredients;

  const handleIngredientAssignmentState = (name, value) => {
    const newIngredientAssignmentState = { ...ingredientAssignmentState, [name]: value };
    setIngredientAssignmentState(newIngredientAssignmentState);

    const newFormStateIngredientAssignments = formState.ingredientAssignments;
    newFormStateIngredientAssignments[index] = newIngredientAssignmentState;

    handleFormState({
      target: { name: 'ingredientAssignments', value: newFormStateIngredientAssignments },
    });
  };

  const handleCheckboxChange = (event) => {
    const newCheckboxState = { ...checkboxState, [event.target.name]: event.target.checked };
    setCheckboxState(newCheckboxState);
    handleIngredientAssignmentState(event.target.name, event.target.checked);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleDelete = () => {
    handleCloseModal();
    handleIngredientAssignmentState(DELETED, true);
  };

  const onAttributeChange = (event) => {
    handleIngredientAssignmentState(event.target.name, event.target.value);
  };

  useEffect(() => {
    if (isCardExpanded) {
      setAnyIngredientsExpanded(true);
    }
  }, [isCardExpanded, setAnyIngredientsExpanded]);

  useEffect(() => {
    if (!areAnyIngredientsExpanded) {
      setCardExpanded(false);
      setExpandAllIngredients(false);
    }
  }, [areAnyIngredientsExpanded, setExpandAllIngredients]);

  useEffect(() => {
    if (expandAllIngredients) {
      setCardExpanded(true);
    }
  }, [expandAllIngredients]);

  useEffect(() => {
    let newIngredientAssignmentState = {};

    // on mount
    if (baseIngredientSelected && isFirstRun) {
      handleMount(
        baseIngredientSelected,
        ingredientAssignmentState,
        index,
        formState,
        setFormState,
        setIngredientAssignmentState
      );
      setIsFirstRun(false);
      return;
    }

    // after mount, on setting or changing a baseIngredientSelected
    if (baseIngredientSelected) {
      newIngredientAssignmentState = handleNewBaseIngredientSelected(
        baseIngredientSelected,
        ingredientAssignmentState
      );
    }
    // When the baseIngredientSelected is cleared by the user in order to swap ingredients
    else if (ingredientAssignmentState.id) {
      newIngredientAssignmentState = handleClearIngredientAssignment(ingredientAssignmentState);
    }
    // If it's a completely new ingredient assignment with no ID
    else {
      newIngredientAssignmentState = handleNewIngredientAssignment(ingredientAssignmentState);
    }

    setIngredientAssignmentState(newIngredientAssignmentState);

    const newFormStateIngredientAssignments = formState.ingredientAssignments;
    newFormStateIngredientAssignments[index] = newIngredientAssignmentState;

    handleFormState({
      target: { name: 'ingredientAssignments', value: newFormStateIngredientAssignments },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseIngredientSelected]);

  // Updates the ingredient assignment in the formState to use the new ingredient if the ingredient is swapped for another
  useEffect(() => {
    // Ensuring this only runs after the component is mounted.
    // An issue with checkboxes on ingredient assignments occurs if we don't make sure that this is only run if the ingredient is updated after the component has mounted.
    if (!isFirstRun) {
      handleIngredientAssignmentState('ingredientId', ingredientAssignmentState.ingredientId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingredientAssignmentState.ingredientId]);

  /**
   * Sets the baseIngredientSelected to the ingredient selected by the user.
   * onFirstMount of component,
   * Quantity is set to an empty string. This ensures that the user will add a quantity and thus this will ensure that the form state is up to date.
   * If there is no input for quantity, there will be an error when attempting to save changes.
   * After first mount, when a user swaps ingredient A for ingredient B
   * ingredient B has the same "quantity" as ingredient A. Per the specifications of the stakeholders
   *
   * Autocomplete onChange takes in two params: event and values.
   * In this case values is the value of the selected ingredient.
   * @function onIngredientChange
   */
  const onIngredientChange = (_event, values) => {
    setBaseIngredientSelected(values);
  };

  const addReplacementIngredient = () => {
    const newIngredientAssignmentState = {
      ...ingredientAssignmentState,
      replacementIngredients: [
        ...ingredientAssignmentState.replacementIngredients,
        {
          opsCategory: ingredientAssignmentState.opsCategory,
          portionMethod: ingredientAssignmentState.portionMethod,
          ingredientId: ingredientAssignmentState.ingredientId,
          ingredient: ingredientAssignmentState.ingredient,
          ingredientPackagingId: ingredientAssignmentState.ingredientPackagingId,
          originalIngredientAssignmentId: ingredientAssignmentState.id || null,
          labelRequired: ingredientAssignmentState.labelRequired,
          mealId: ingredientAssignmentState.mealId,
          name: ingredientAssignmentState.name,
          quantity: ingredientAssignmentState.quantity,
          readyToEat: ingredientAssignmentState.readyToEat,
          measure: ingredientAssignmentState.measure,
          usageYield: ingredientAssignmentState.usageYield,
        },
      ],
    };

    setIngredientAssignmentState(newIngredientAssignmentState);

    const newFormStateIngredientAssignments = formState.ingredientAssignments;
    newFormStateIngredientAssignments[index] = newIngredientAssignmentState;

    handleFormState({
      target: { name: 'ingredientAssignments', value: newFormStateIngredientAssignments },
    });
  };

  const renderReplacementIngredientCards = () => {
    return ingredientAssignment.replacementIngredients.map((replacementIngredient, idx) => {
      const key = `replacement-ingredient-${idx}`;
      return replacementIngredient.deleted ? null : (
        <ReplacementIngredientCard
          key={key}
          classes={classes}
          formState={formState}
          handleFormState={handleFormState}
          replacementIngredient={replacementIngredient}
          originalIngredientAssignmentName={baseIngredientSelected?.name}
          ingredientAssignmentIndex={index}
          replacementIngredientIndex={idx}
          rthProductType={rthProductType}
        />
      );
    });
  };

  const renderConfirmRemovalModal = () => {
    return (
      <Modal data-testid="remove-ingredient-modal" open={modalOpen} onClose={handleCloseModal}>
        <Paper className={classes.modal}>
          <h3>
            The changes that you have made to the meal include removing a non-replacement
            ingredient, would you like to proceed?
          </h3>
          <Card variant="outlined" className={classes.ingredientInfoCard}>
            <h3>Ingredient Info:</h3>
            {ingredientAssignmentState.ingredientId && (
              <>
                <div className={classes.listContent}>Name: {ingredientAssignmentState.name}</div>
                <div className={classes.listContent}>
                  ID: {ingredientAssignmentState.ingredientId}
                </div>
                <div className={classes.listContent}>
                  Quantity: {ingredientAssignmentState.quantity}
                </div>
                <div className={classes.listContent}>
                  Measure: {ingredientAssignmentState.measure}
                </div>
              </>
            )}
          </Card>
          <div className={classes.buttonRow}>
            <Button
              color="primary"
              onClick={handleDelete}
              variant="outlined"
              className={classes.deleteButton}
              startIcon={<DeleteIcon />}
            >
              Remove Ingredient
            </Button>
            <Button
              color="secondary"
              onClick={handleCloseModal}
              variant="outlined"
              className={classes.cancelButton}
            >
              Cancel
            </Button>
          </div>
        </Paper>
      </Modal>
    );
  };

  const getDuplicateSeqNums = () => {
    const activeIngedientAssignments = formState.ingredientAssignments.filter((ia) => !ia?.deleted);
    const currentSeqNums = map(activeIngedientAssignments, (ia) => {
      return +ia.sequenceNumber;
    });
    const duplicates = filter(currentSeqNums, (val, i, iteratee) => includes(iteratee, val, i + 1));

    return duplicates;
  };

  useEffect(() => {
    if (isFirstRun) return;

    if (!rthProductType) {
      setSeqNumErrors([]);
      return;
    }
    const seqNumInt = parseInt(ingredientAssignmentState?.sequenceNumber, 10);
    const notUnique = getDuplicateSeqNums().includes(seqNumInt);
    const lessThanOne = seqNumInt < 1;

    const errors = [];
    if (notUnique) errors.push({ message: 'Must be unique' });
    if (lessThanOne) errors.push({ message: 'Must be greater than 0' });

    setSeqNumErrors(errors);
    handleIngredientAssignmentState('hasErrors', errors.length > 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingredientAssignmentState?.sequenceNumber, rthProductType]);

  return isCardExpanded ? (
    <Card className={classes.ingredientCard} data-testid="ingredient-card">
      <Grid container direction="row" spacing={2}>
        <Grid item xs={4}>
          <TextBox
            fullWidth
            required={rthProductType}
            label="Seq #"
            name="sequenceNumber"
            type="number"
            onChange={onAttributeChange}
            value={ingredientAssignmentState?.sequenceNumber || ''}
            errors={seqNumErrors}
          />
        </Grid>
        <Grid item xs={8}>
          <IngredientSearch
            classes={classes}
            autocompleteOnChange={onIngredientChange}
            ingredientSelected={baseIngredientSelected}
            name="ingredient"
            label="Ingredient"
            rthProductType={rthProductType}
          />
        </Grid>
      </Grid>
      <IngredientFormInputs
        ingredientAssignmentState={ingredientAssignmentState}
        ingredientSelected={baseIngredientSelected}
        onAttributeChange={onAttributeChange}
      />
      <IngredientDropdowns
        ingredientAssignmentState={ingredientAssignmentState}
        ingredientSelected={baseIngredientSelected}
        handleIngredientAssignmentState={handleIngredientAssignmentState}
        onAttributeChange={onAttributeChange}
      />
      <IngredientCheckboxes
        classes={classes}
        ingredientAssignmentState={ingredientAssignmentState}
        handleCheckboxChange={handleCheckboxChange}
      />
      <Button
        variant="outlined"
        className={classes.deleteButton}
        startIcon={<DeleteIcon />}
        onClick={() =>
          formState.onAnyMenu && !!baseIngredientSelected ? setModalOpen(true) : handleDelete()
        }
      >
        Remove Ingredient
      </Button>
      <Grid className={canEditReplacements ? '' : classes.disabledCardContainer}>
        {renderReplacementIngredientCards()}
        {renderConfirmRemovalModal()}
        {/* Only allow the addition of replacement ingredients on already existing ingredient assignments */}
        {ingredientAssignmentState.id && (
          <Button
            onClick={canEditReplacements ? addReplacementIngredient : () => {}}
            className={classes.replacementIngredientButton}
            startIcon={<AddIcon />}
          >
            Replacement Ingredient
          </Button>
        )}
      </Grid>
    </Card>
  ) : (
    <Card className={classes.collapsedIngredientCard}>
      <Grid container direction="row" spacing={2} className={classes.ai_c}>
        <Grid item xs={11}>
          <span className={classes.fs_14}>
            {ingredientAssignmentState?.ingredientId} {baseIngredientSelected?.displayName}{' '}
            {ingredientAssignmentState?.quantity} {ingredientAssignmentState?.measure}
          </span>
          {!!replacementIngredients?.length && (
            <p className={clsx(classes.greyText, classes.fs_14, classes.mb_0)}>
              + {replacementIngredients.length}{' '}
              {replacementIngredients.length === 1 ? 'Replacement' : 'Replacements'}
            </p>
          )}
        </Grid>
        <Grid item xs={1} className={classes.textAlignRight}>
          <Button
            onClick={() => {
              setCardExpanded(true);
            }}
            className={classes.pr_0}
            data-testid="expandCardButton"
            startIcon={<AddIcon />}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

IngredientCard.propTypes = {
  classes: PropTypes.object.isRequired,
  areAnyIngredientsExpanded: PropTypes.bool.isRequired,
  setAnyIngredientsExpanded: PropTypes.func.isRequired,
  expandAllIngredients: PropTypes.bool.isRequired,
  setExpandAllIngredients: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  ingredientAssignment: PropTypes.object.isRequired,
  rthProductType: PropTypes.bool.isRequired,
};

export default IngredientCard;
