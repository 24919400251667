import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import MealInfo from '../MealInfo';
import PlaceholderInfo from '../PlaceholderInfo';

const MealCard = ({ menuSelection, index, displayGroup }) => {
  return (
    <Draggable draggableId={String(menuSelection.id)} index={index}>
      {(provided) => (
        <div
          data-testid="mealCard"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {menuSelection.mealId === 0 ? (
            <PlaceholderInfo placeholder={menuSelection} displayGroup={displayGroup} />
          ) : (
            <MealInfo menuSelection={menuSelection} />
          )}
        </div>
      )}
    </Draggable>
  );
};

MealCard.propTypes = {
  menuSelection: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  displayGroup: PropTypes.string.isRequired,
};

export default MealCard;
