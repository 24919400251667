import { APP_API_URI } from 'lib/constants';
import { GET } from 'lib/api';

const MEF_URL = `${APP_API_URI}/internal/culinary/meal_edit_form`;

export const fetchMealAllergenValidation = (params) => {
  const { mealId, replacementIngredientId } = params;

  return GET(`${MEF_URL}/validate_meal_allergens`, {
    params: {
      meal_allergen_params: { meal_id: mealId, replacement_ingredient_id: replacementIngredientId },
    },
  });
};
