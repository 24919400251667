// Dependencies
import React, { useEffect } from 'react';
import { Loading } from 'components/shared';
import PropTypes from 'prop-types';

import { disputeShape } from 'lib/helpers/disputedCharges/propShapes';

// Styling
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

const DisputedCharges = ({ classes, disputes, onGetDisputes, onSendEvidence, fetching }) => {
  const renderUpdateLink = (dispute) => {
    if (dispute.chargeId) {
      return (
        <Link
          component="button"
          onClick={() => onSendEvidence(dispute.disputeId, dispute.chargeId)}
        >
          Update Evidence
        </Link>
      );
    }
    return null;
  };

  const renderEvidenceStatus = (dispute) => {
    if (dispute.isEvidenceUpdated === true) {
      return <CheckIcon color="primary" />;
    }

    switch (dispute.status) {
      case 'sending':
        return <Loading size={20} topPadding={false} centered={false} />;
      case 'sent':
        return <CheckIcon color="primary" />;
      case 'failed':
        return <ErrorIcon color="secondary" />;
      default:
        return renderUpdateLink(dispute);
    }
  };

  useEffect(() => {
    if (disputes === null) {
      onGetDisputes();
    }
  }, [onGetDisputes, disputes]);

  if (fetching || disputes === null) {
    return <Loading />;
  }
  return (
    <div className={classes.container}>
      <TableContainer component={Paper} elevation={0} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Dispute Id</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>User Email</TableCell>
              <TableCell>Disputed On</TableCell>
              <TableCell>Respond By</TableCell>
              <TableCell>Evidence Form</TableCell>
              <TableCell>Update Evidence</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {disputes.map((dispute) => (
              <TableRow key={dispute.disputeId} className={classes.row}>
                <TableCell>
                  <Link href={dispute.disputeUrl} target="_blank" rel="noopener">
                    {dispute.disputeId}
                  </Link>
                </TableCell>
                <TableCell>{dispute.amount}</TableCell>
                <TableCell>{dispute.reason}</TableCell>
                <TableCell>
                  <Link href={dispute.userAdminUrl} target="_blank" rel="noopener">
                    {dispute.userEmail || ''}
                  </Link>
                </TableCell>
                <TableCell>{dispute.disputedOn}</TableCell>
                <TableCell>{dispute.respondBy}</TableCell>
                <TableCell>
                  <Link href={dispute.disputeFormUrl} target="_blank" rel="noopener">
                    Edit Evidence Form
                  </Link>
                </TableCell>
                <TableCell>{renderEvidenceStatus(dispute)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

DisputedCharges.propTypes = {
  classes: PropTypes.object.isRequired,
  disputes: PropTypes.arrayOf(disputeShape),
  onGetDisputes: PropTypes.func.isRequired,
  onSendEvidence: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
};

DisputedCharges.defaultProps = {
  disputes: null,
};

export default DisputedCharges;
