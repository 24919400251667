import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';

import { forLifeText } from 'lib/helpers/behavioralDiscountTool/defaultTextHelper';
import { range } from 'lib/helpers/arrays';
import { BehavioralDiscountFormContext } from 'contexts/BehavioralDiscountToolContext';
import PropTypes from 'prop-types';

const BaseAttributeSection = ({
  classes,
  handleServiceTypeChange,
  handleDiscountFrequencyChange,
  onMenuChange,
  userCount,
}) => {
  const { formState, actions } = useContext(BehavioralDiscountFormContext);
  const [showMaxRedemptions, setShowMaxRedemptions] = useState(false);

  useEffect(() => {
    if (formState.maxRedemptions) {
      setShowMaxRedemptions(true);
    } else {
      setShowMaxRedemptions(false);
    }
  }, [formState.maxRedemptions, actions]);

  const formattedMenu = (endsAt) => {
    return new Date(endsAt).toDateString();
  };

  const maxRedemptionCeiling = () => {
    let maxInput;
    if (formState.durationInWeeks === forLifeText) {
      maxInput = 10;
    } else {
      maxInput = formState.durationInWeeks;
    }
    return maxInput;
  };

  const handleToggleMaxRedemptions = () => {
    if (showMaxRedemptions) {
      actions.setFormState('maxRedemptions', '');
    } else {
      actions.setFormState('maxRedemptions', maxRedemptionCeiling());
    }
  };

  // ensure maxRedemptions does not exceed duration
  const handleDurationChange = (value) => {
    actions.setFormState('durationInWeeks', value);
    if (!showMaxRedemptions || formState.maxRedemptions <= value) return;
    if (value === forLifeText) {
      actions.setFormState('maxRedemptions', 10);
    } else {
      actions.setFormState('maxRedemptions', value);
    }
  };

  return (
    <div className={classes.root}>
      <h5 className={classes.header}>Create a Behavioral Discount</h5>
      <TextField
        fullWidth
        label="Discount Name"
        variant="outlined"
        value={formState.name}
        onChange={(e) => actions.setFormState('name', e.target.value)}
      />
      <Grid item sm={2} xs={4} className={classes.serviceType}>
        <div className={classes.label}>Service</div>
        <ToggleButtonGroup
          fullWidth
          color="primary"
          value={formState.serviceType}
          exclusive
          onChange={(e) => handleServiceTypeChange(e.target.value)}
          aria-label="service"
          size="small"
          sx={{ width: 1 }}
        >
          <ToggleButton value="core" sx={{ width: 1 / 4 }}>
            CORE
          </ToggleButton>
          <ToggleButton value="family" sx={{ width: 1 / 4 }}>
            FAMILY
          </ToggleButton>
          <ToggleButton value="tempo" sx={{ width: 1 / 4 }}>
            TEMPO
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <FormControl className={classes.radioGroupContainer}>
        <FormLabel id="trigger-type-radio-buttons-group-label">Discount Frequency</FormLabel>
        <RadioGroup
          aria-labelledby="trigger-type-radio-buttons-group-label"
          value={formState.discountDuration}
          onChange={(e) => handleDiscountFrequencyChange(e.target.value)}
        >
          <div>
            <FormControlLabel value="menu" control={<Radio />} label="A menu week" />
            <span className={classes.frequencyRadioHelperText}>
              Apply once to a selected menu week.
            </span>
          </div>
          <div>
            <FormControlLabel value="recurring" control={<Radio />} label="Recurring" />
            <span className={classes.frequencyRadioHelperText}>
              Discounts are assigned for a set duration
            </span>
          </div>
        </RadioGroup>
      </FormControl>
      <Divider className={classes.divider} />
      {formState.discountDuration === 'menu' && (
        <div className={classes.fullWidth}>
          <TextField
            fullWidth
            label="Menu"
            disabled={formState.discountDuration !== 'menu'}
            onChange={(e) => onMenuChange(e.target.value)}
            select
            size="large"
            value={formState.menuId || ''}
            variant="outlined"
            className={classes.halfWidth}
          >
            {formState.formData.menus.map((menu) => (
              <MenuItem key={menu.ends_at} value={menu.id}>
                {formattedMenu(menu.ends_at)}
              </MenuItem>
            ))}
          </TextField>
        </div>
      )}
      {formState.discountDuration === 'recurring' && (
        <div className={classes.recurringFieldsContainer}>
          <TextField
            fullWidth
            label="Select the Duration (weeks)"
            select
            size="medium"
            value={formState.durationInWeeks}
            variant="outlined"
            className={classes.lessThanHalfWidth}
            onChange={(e) => handleDurationChange(e.target.value)}
            disabled={userCount > 0}
          >
            {range(1, 13, 1)
              .reverse()
              .concat(forLifeText)
              .map((num) => (
                <MenuItem key={num} value={num}>
                  {num === forLifeText ? num : `${num} weeks`}
                </MenuItem>
              ))}
          </TextField>
          <FormControlLabel
            control={<Switch checked={showMaxRedemptions} onChange={handleToggleMaxRedemptions} />}
            label="Limit the number of redemptions"
          />
          {showMaxRedemptions && (
            <TextField
              fullWidth
              label="Max # of Redemptions Possible"
              select
              size="medium"
              value={formState.maxRedemptions || maxRedemptionCeiling()}
              variant="outlined"
              className={classes.lessThanHalfWidth}
              onChange={(e) => actions.setFormState('maxRedemptions', e.target.value)}
              disabled={userCount > 0}
              required={showMaxRedemptions}
            >
              {range(1, maxRedemptionCeiling(), 1)
                .reverse()
                .map((num) => (
                  <MenuItem key={num} value={num}>
                    {num} times
                  </MenuItem>
                ))}
            </TextField>
          )}
        </div>
      )}
    </div>
  );
};

BaseAttributeSection.propTypes = {
  classes: PropTypes.object.isRequired,
  handleServiceTypeChange: PropTypes.func.isRequired,
  handleDiscountFrequencyChange: PropTypes.func.isRequired,
  onMenuChange: PropTypes.func.isRequired,
  userCount: PropTypes.number.isRequired,
};

export default BaseAttributeSection;
