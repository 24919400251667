import {
  MM_FETCH_MENU_REQUEST,
  MM_FETCH_MENU_SUCCESS,
  MM_FETCH_MENU_ERROR,
  MM_ADD_MEALS_REQUEST,
  MM_ADD_MEALS_SUCCESS,
  MM_ADD_MEALS_ERROR,
  MM_TOGGLE_ADD_MEALS_DIALOG,
  MM_REMOVE_MEAL_REQUEST,
  MM_REMOVE_MEAL_SUCCESS,
  MM_REMOVE_MEAL_ERROR,
  MM_SET_SELECTED_MEAL_ID,
  MM_UPDATE_RANKING,
  MM_POST_RANKING_REQUEST,
  MM_POST_RANKING_SUCCESS,
  MM_POST_RANKING_ERROR,
  MM_SET_RANKING,
} from 'lib/constants';

export const initialState = {
  fetching: false,
  id: null,
  locked: false,
  inStoreStartsAt: null,
  categories: null,
  error: false,
  posting: false,
  postingFailed: false,
  errorMessage: null,
  addMealsDialogActiveCategory: null,
  selectedMealId: null,
  alternativeRanking: false,
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case MM_FETCH_MENU_REQUEST:
      return {
        ...state,
        error: false,
        fetching: true,
      };
    case MM_FETCH_MENU_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: false,
        id: action.response.retailMenu.id,
        locked: action.response.retailMenu.locked,
        inStoreStartsAt: action.response.retailMenu.inStoreStartsAt,
        categories: action.response.retailMenu.categories,
      };
    case MM_FETCH_MENU_ERROR:
      return {
        ...state,
        error: true,
        fetching: false,
      };
    case MM_TOGGLE_ADD_MEALS_DIALOG:
      return {
        ...state,
        addMealsDialogActiveCategory: action.activeCategory,
        errorMessage: null,
      };
    case MM_ADD_MEALS_REQUEST:
      return {
        ...state,
        posting: true,
        postingFailed: false,
        errorMessage: null,
      };
    case MM_ADD_MEALS_SUCCESS:
      return {
        ...state,
        posting: false,
        categories: action.response.retailMenu.categories,
        errorMessage: null,
        addMealsDialogActiveCategory: null,
      };
    case MM_ADD_MEALS_ERROR:
      return {
        ...state,
        posting: false,
        postingFailed: true,
        errorMessage: action.response.error,
      };
    case MM_REMOVE_MEAL_REQUEST:
      return {
        ...state,
        posting: true,
        postingFailed: false,
        errorMessage: null,
      };
    case MM_REMOVE_MEAL_SUCCESS:
      return {
        ...state,
        posting: false,
        categories: action.response.retailMenu.categories,
        errorMessage: null,
      };
    case MM_REMOVE_MEAL_ERROR:
      return {
        ...state,
        posting: false,
        postingFailed: true,
        errorMessage: action.response.error,
      };
    case MM_UPDATE_RANKING: {
      // NEED TO REFACTOR THIS -> Into new Reducer?
      const newCategories = Array.from(state.categories);
      const indexOfCategory = newCategories.findIndex(
        (category) => category.id === parseInt(action.payload.category.id, 10)
      );
      newCategories.splice(indexOfCategory, 1, action.payload.category);
      return {
        ...state,
        categories: newCategories,
      };
    }
    case MM_SET_RANKING:
      return {
        ...state,
        alternativeRanking: action.payload,
      };
    case MM_POST_RANKING_REQUEST:
      return {
        ...state,
        posting: true,
        postingFailed: false,
        errorMessage: null,
      };
    case MM_POST_RANKING_SUCCESS:
      return {
        ...state,
        posting: false,
        categories: action.response.retailMenu.categories,
        errorMessage: null,
      };
    case MM_POST_RANKING_ERROR:
      return {
        ...state,
        error: true,
        fetching: false,
      };
    case MM_SET_SELECTED_MEAL_ID:
      return {
        ...state,
        selectedMealId: action.payload.mealId,
      };
    default:
      return state;
  }
};
