import { combineReducers } from 'redux';

import allMenus from './allMenus/reducers';
import menu from './menu/reducers';
import snackBar from './snackBar/reducers';

export default combineReducers({
  allMenus,
  menu,
  snackBar,
});
