import { IM_FORM_OPTIONS, IM_INCIDENT, IM_INCIDENT_ERROR } from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import { createIncidents, fetchFormOptions } from './services';

export const getFormOptions = (incidentableId, incidentableType) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: IM_FORM_OPTIONS,
        method: fetchFormOptions,
        params: { incidentableId, incidentableType },
      })
    );
  };
};

export const submitIncidents = (formState) => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: IM_INCIDENT,
        method: createIncidents,
        params: formState,
      })
    );
  };
};

export const raiseIncidentError = (message) => {
  return async (dispatch) => {
    dispatch({
      type: IM_INCIDENT_ERROR,
      error: {
        message,
      },
    });
  };
};
