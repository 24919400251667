// Dependencies
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// Material Components
import Paper from '@mui/material/Paper';

import { centsToDollars, dollarsToCents } from 'lib/helpers/money';
import { USER_ERROR } from 'lib/constants';
import { broadcastScrollSizeToParent } from 'lib/utils';
import { formOptionsShape } from 'lib/helpers/incidentManager/propShapes';

// Custom Components
import { Loading } from 'components/shared';
import CancelFields from '../CancelFields';
import CreditFields from './CreditFields';
import DeliveryIncidentFields from './DeliveryIncidentFields';
import FormTypeButton from './FormTypeButton';
import RefundFields from './RefundFields';
import RemoveIncidentButton from '../RemoveIncidentButton';
import UserIncidentFields from './UserIncidentFields';
import CreditHelperText from './CreditHelperText';

const IncidentForm = ({
  classes,
  formOptions,
  incidentable,
  formId,
  formStates,
  onGetGiftCardTaxRefund,
  onGetTaxRefund,
  setFormStates,
  updateFormState,
}) => {
  const [formState, setFormState] = useState({
    causeId: '',
    cashRefundAmount: 0,
    cashRefundTaxAmount: 0,
    cashRefundTotalAmount: 0,
    creditAmount: 0,
    description: '',
    giftCardRefundAmount: 0,
    giftCardRefundTaxAmount: 0,
    giftCardRefundTotalAmount: 0,
    incidentableId: incidentable.id,
    incidentableType: incidentable.type,
    incidentType: USER_ERROR,
    ingredientAssignmentId: '',
    ingredientId: '',
    mealId: '',
    remediationCategoryId: '',
    hasTrackingInfo: incidentable.shipmentSummary[0].trackingNumber !== '',
  });

  // Handlers
  const handleFormState = (event) => {
    const { name, value } = event.target;
    setFormState((f) => ({ ...f, [name]: value }));
  };

  const handleSetMoneyAmount = (key, input) => {
    setFormState({ ...formState, [key]: input });
  };

  const handleTypeChange = (event) => {
    const { value } = event.currentTarget;
    event.persist();

    // persist description and hidden incidentable fields, but
    // wipe categories/causes (they don't apply to both types)
    setFormState({
      ...formState,
      incidentType: value,
      remediationCategoryId: '',
      causeId: '',
    });
  };

  const handleTaxRefund = useCallback(async () => {
    const { taxRefundCents, totalRefundCents } = await onGetTaxRefund(
      incidentable.id,
      incidentable.type,
      dollarsToCents(formState.cashRefundAmount)
    );

    const convertedTaxRefund = centsToDollars(taxRefundCents);
    const convertedTotalRefund = centsToDollars(totalRefundCents);

    if (
      formState.cashRefundTaxAmount !== convertedTaxRefund ||
      formState.cashRefundTotalAmount !== convertedTotalRefund
    ) {
      setFormState((prevState) => ({
        ...prevState,
        cashRefundTaxAmount: convertedTaxRefund,
        cashRefundTotalAmount: convertedTotalRefund,
      }));
    }
  }, [
    formState.cashRefundAmount,
    formState.cashRefundTaxAmount,
    formState.cashRefundTotalAmount,
    incidentable,
    onGetTaxRefund,
    setFormState,
  ]);

  const handleGiftCardTaxRefund = useCallback(async () => {
    const { giftCardRefundTaxCents, totalRefundCents } = await onGetGiftCardTaxRefund(
      incidentable.id,
      incidentable.type,
      dollarsToCents(formState.giftCardRefundAmount)
    );

    const convertedTaxRefund = centsToDollars(giftCardRefundTaxCents);
    const convertedTotalRefund = centsToDollars(totalRefundCents);

    if (
      formState.giftCardRefundTaxAmount !== convertedTaxRefund ||
      formState.giftCardRefundTotalAmount !== convertedTotalRefund
    ) {
      setFormState((prevState) => ({
        ...prevState,
        giftCardRefundTaxAmount: convertedTaxRefund,
        giftCardRefundTotalAmount: convertedTotalRefund,
      }));
    }
  }, [
    formState.giftCardRefundAmount,
    formState.giftCardRefundTaxAmount,
    formState.giftCardRefundTotalAmount,
    incidentable.id,
    incidentable.type,
    onGetGiftCardTaxRefund,
  ]);

  // Calculate tax and total refund when refund amount changes
  useEffect(() => {
    if (formState.cashRefundAmount > 0) {
      handleTaxRefund();
    }
  }, [formState.cashRefundAmount, handleTaxRefund]);

  useEffect(() => {
    if (formState.giftCardRefundAmount > 0) {
      handleGiftCardTaxRefund();
    }
  }, [formState.giftCardRefundAmount, handleGiftCardTaxRefund]);

  useEffect(() => {
    if (formStates[formId] !== formState) {
      updateFormState(formId, formState);
    }
  }, [formState, formId, formStates, updateFormState]);

  // If being used in an iframe, set the width and height
  // so we don't have a double scrollbar situation.
  useEffect(() => {
    broadcastScrollSizeToParent();
  });

  // Rendering
  if (formOptions.errorTypes === null) {
    return <Loading />;
  }

  return (
    <div>
      <Paper className={classes.root} variant="outlined">
        <FormTypeButton
          classes={classes}
          incidentType={formState.incidentType}
          handleTypeChange={handleTypeChange}
          disableDeliveryError={!incidentable.isShipped}
        />
        <RemoveIncidentButton
          formId={formId}
          formStates={formStates}
          setFormStates={setFormStates}
        />

        {formState.incidentType === USER_ERROR ? (
          <UserIncidentFields
            formOptions={formOptions}
            formState={formState}
            handleFormState={handleFormState}
          />
        ) : (
          <DeliveryIncidentFields
            formOptions={formOptions}
            formState={formState}
            setFormState={setFormState}
            handleFormState={handleFormState}
            incidentable={incidentable}
          />
        )}

        <hr className={classes.divider} />
        {formState.remediationCategoryId && (
          <CreditHelperText
            formOptions={formOptions}
            remediationCategoryId={formState.remediationCategoryId}
            suggestedPartialCredit={incidentable.creditsAndRefunds.suggestedPartialCredit}
          />
        )}
        <CreditFields
          incidentable={incidentable}
          formState={formState}
          handleSetMoneyAmount={handleSetMoneyAmount}
          formOptions={formOptions}
        />

        <div className={classes.headerSpaced}>Refund</div>

        <RefundFields
          maxRefundAmountCents={incidentable.creditsAndRefunds.maxGiftCardRefundAmountCents}
          formState={formState}
          handleSetMoneyAmount={handleSetMoneyAmount}
          formOptions={formOptions}
          refundType="Gift Card"
        />
        <RefundFields
          maxRefundAmountCents={incidentable.creditsAndRefunds.maxRefundAmountCents}
          formState={formState}
          handleSetMoneyAmount={handleSetMoneyAmount}
          formOptions={formOptions}
          refundType="Charge"
        />
      </Paper>

      {incidentable.cancellable ? (
        <CancelFields
          formState={formState}
          handleFormState={handleFormState}
          formOptions={formOptions}
        />
      ) : null}
    </div>
  );
};

IncidentForm.propTypes = {
  incidentable: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  formId: PropTypes.string.isRequired,
  formOptions: formOptionsShape.isRequired,
  formStates: PropTypes.object.isRequired,
  onGetGiftCardTaxRefund: PropTypes.func.isRequired,
  onGetTaxRefund: PropTypes.func.isRequired,
  setFormStates: PropTypes.func,
  updateFormState: PropTypes.func.isRequired,
};

IncidentForm.defaultProps = {
  setFormStates: () => {},
};

export default IncidentForm;
