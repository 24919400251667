import { HC_GREEN } from 'lib/constants';

export default {
  cell: { padding: '4px 16px' },
  titleCell: { padding: '4px 12px', maxWidth: '230px' },
  link: {
    color: HC_GREEN,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  linkList: {
    listStyleType: 'none',
    paddingInlineStart: '0',
  },
  erroredCell: {
    borderBottom: 'none',
  },
  errorlessRow: {
    padding: 0,
    borderBottom: 'none',
  },
  errorRow: {
    padding: '4px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
};
