import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleSnackBar } from 'redux/menugrid/menus/actions';

import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SnackBar = () => {
  const dispatch = useDispatch();

  const { openSnackBar, snackBarMessage } = useSelector((state) => state.menugrid.menus);

  const EMPTY_MESSAGE = '';

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(toggleSnackBar(false, EMPTY_MESSAGE));
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnackBar}
        autoHideDuration={3500}
        onClose={handleClose}
        message={snackBarMessage}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
};

export default SnackBar;
