import { HC_GREEN, BORDER_GREY, MEDIUM_GREY } from 'lib/constants';

export default {
  container: {
    padding: '24px',
  },
  photosContainer: {
    padding: '16px',
    marginBottom: '24px',
    overflow: 'visible',
  },
  image: {
    marginTop: '16px',
    width: '100%',
  },
  input: {
    display: 'none',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uploadAndDelete: {
    minWidth: '180px',
  },
  deleteButton: {
    marginLeft: '8px',
    paddingLeft: '4px',
    paddingRight: '4px',
    minWidth: 0,
    color: MEDIUM_GREY,
  },
  outlinedButton: {
    color: HC_GREEN,
  },
  hr: {
    backgroundColor: BORDER_GREY,
    height: '1px',
    border: 'none',
    marginBottom: '16px',
  },
  cardContent: {
    padding: '16px 0 0 0',
  },
  card: {
    overflow: 'visible',
  },
  fileName: {
    font: 'Roboto',
    weight: 400,
    fontSize: 14,
    lineHeight: '24px',
    color: MEDIUM_GREY,
    overflowWrap: 'break-word',
    width: '50%',
  },
};
