import { LIGHT_BLACK, MEDIUM_GREY, LIGHTEST_GREY } from 'lib/constants';

export default {
  root: {
    padding: '24px',
  },
  header: {
    color: LIGHT_BLACK,
    fontWeight: '500',
    fontSize: '16px',
  },
  item: { margin: '16px 0 0 0' },
  lgBold: {
    color: LIGHT_BLACK,
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
  },
  lgLeftMargin: {
    color: MEDIUM_GREY,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    margin: '0 0 0 16px',
  },
  lgBoldLeftMargin: {
    color: LIGHT_BLACK,
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    margin: '0 0 0 16px',
  },
  normal: {
    color: MEDIUM_GREY,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '20px',
  },
  greyHr: {
    border: `1px solid ${LIGHTEST_GREY}`,
  },
  footer: {
    padding: '24px',
    backgroundColor: LIGHTEST_GREY,
    display: 'grid',
    borderTop: 'none',
  },
  gridItem: {
    width: '50%',
  },
};
