// Dependencies
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Lodash
import flatMapDeep from 'lodash/flatMapDeep';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import map from 'lodash/map';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

// Helpers
import {
  sanitizedWhatYouNeed,
  compareTextBlobs,
  setHighlightWhatYouNeedItem,
} from 'lib/helpers/comparisonTool';

const WhatYouNeedRow = ({ viewableMeals, classes }) => {
  // State methods
  const [openCollapsible, setOpenCollapsible] = useState(false);
  const [hasDifferences, setDifferences] = useState(false);
  const [highlightWhatYouNeedItems, setHighlightWhatYouNeedItems] = useState({});
  const [whatYouNeedSuperset, setWhatYouNeedSuperset] = useState({});

  // Lifecycle methods
  const checkForDifferences = useCallback(() => {
    if (viewableMeals.length !== 0) {
      return compareTextBlobs(sanitizedWhatYouNeed(viewableMeals));
    }
    return false;
  }, [viewableMeals]);

  useEffect(() => {
    setDifferences(checkForDifferences(viewableMeals));
  }, [setDifferences, checkForDifferences, viewableMeals]);

  useEffect(() => {
    const allWhatYouNeed = groupBy(
      flatMapDeep(viewableMeals, (meal) => {
        return meal.whatYouNeed;
      })
    );

    setWhatYouNeedSuperset(keys(allWhatYouNeed));

    setHighlightWhatYouNeedItems(
      mapValues(allWhatYouNeed, (occurrences, whatYouNeedItem) => {
        return setHighlightWhatYouNeedItem(whatYouNeedItem, occurrences, viewableMeals);
      })
    );
  }, [viewableMeals]);

  // Handler methods
  const handleCollapsibleClick = () => {
    setOpenCollapsible(!openCollapsible);
  };

  // Render methods
  const renderAdditionalItemInfo = (item, meal) => {
    let quantity;

    if (item === 'Salt') {
      quantity = meal.saltQuantity;
    }

    if (item === 'Olive Oil') {
      quantity = meal.oliveOilQuantity;
    }

    if (quantity) {
      return <span className={clsx(classes.whatYouNeedQuantity)}>{quantity} tsp</span>;
    }

    return null;
  };

  const renderWhatYouNeedItem = (item, meal) => {
    if (meal.whatYouNeed.find((mealItem) => mealItem === item)) {
      return (
        <div
          key={`${meal.versionId}-${item}`}
          className={clsx(
            classes.whatYouNeedItem,
            highlightWhatYouNeedItems[item] && classes.highlight
          )}
        >
          {item} {renderAdditionalItemInfo(item, meal)}
        </div>
      );
    }

    return (
      <div
        key={`${meal.versionId}-${item}`}
        className={clsx(classes.whatYouNeedItem, classes.whatYouNeedItemMissing)}
      >
        No Item
      </div>
    );
  };

  return (
    <TableRow>
      <TableCell
        className={clsx(classes.iconCell, hasDifferences && classes.tableRowGreenBorder)}
        onClick={handleCollapsibleClick}
      >
        {openCollapsible ? (
          <ExpandMore className={classes.icon} />
        ) : (
          <ChevronRightIcon className={classes.icon} />
        )}
      </TableCell>
      <TableCell className={classes.rowLabel} onClick={handleCollapsibleClick}>
        What You Need
      </TableCell>
      {viewableMeals.map((meal) => (
        <TableCell
          key={`whatYouNeed.${meal.versionId}`}
          className={clsx(classes.expandingCell, meal.inactive && classes.greyed)}
        >
          <div className={classes.listContentSameBlock}>{`${meal.whatYouNeed.length} Items`}</div>
          <Collapse in={openCollapsible} unmountOnExit className={classes.collapse}>
            {map(whatYouNeedSuperset, (item) => {
              return renderWhatYouNeedItem(item, meal);
            })}
          </Collapse>
        </TableCell>
      ))}
    </TableRow>
  );
};

WhatYouNeedRow.propTypes = {
  viewableMeals: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default WhatYouNeedRow;
