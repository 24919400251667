import { HC_GREEN, LIGHT_GREY } from 'lib/constants';

export default {
  containers: {
    marginTop: '24px',
  },
  bannerMenu: {
    width: '50%',
    marginTop: '24px',
  },
  bannerOptions: {
    paddingLeft: '8px',
  },
  menuSwatches: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  input: {
    display: 'none',
  },
  outlinedButton: {
    color: HC_GREEN,
  },
  img: {
    borderRadius: '4px',
  },
  imgHelperText: {
    marginTop: '12px',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    opacity: '0.6',
  },
  imgFileName: {
    paddingTop: '6px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: '#000000',
  },
  imgContainer: {
    maxWidth: '100%',
    backgroundColor: '#FAFAFA',
  },
  deleteIcon: {
    paddingLeft: '5px',
  },
  errorText: {
    paddingTop: '6px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    opacity: '0.6',
    color: '#F44336',
  },
  divider: {
    backgroundColor: LIGHT_GREY,
    height: '1px',
    border: 'none',
    width: '100%',
    marginTop: '24px',
    marginBottom: '24px',
  },
  tagHeader: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.15px',
    color: '#000000',
    marginBottom: '8px',
  },
  themeHeading: {
    paddingLeft: '16px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '0.17px',
    textAlign: 'left',
    opacity: '0.6',
  },
};
