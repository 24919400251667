export default {
  loadingIndicator: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 20,
    '& .MuiCircularProgress-root': {
      margin: 'auto 0',
    },
  },
  geographyField: {
    '& .MuiInputBase-root': {
      flexWrap: 'wrap',
      paddingRight: 40,
    },
  },
};
