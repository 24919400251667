import { DELETE, GET, PATCH, POST } from 'lib/api';
import { APP_API_URI } from 'lib/constants';

export const fetchAllAppConfigurations = async () => {
  const url = `${APP_API_URI}/internal/application_configurations`;
  const response = await GET(url);

  return response.data;
};

export const addConfigurationGroup = async (params) => {
  const { configGroup } = params;
  const url = `${APP_API_URI}/internal/application_configurations/group`;
  const response = await POST(url, configGroup);

  return response.data;
};

export const updateConfigurationGroup = async (params) => {
  const { configGroup } = params;
  const url = `${APP_API_URI}/internal/application_configurations/group/${configGroup.id}`;
  const response = await PATCH(url, configGroup);

  return response.data;
};

export const deleteConfigurationGroup = async (params) => {
  const { configGroup } = params;
  const url = `${APP_API_URI}/internal/application_configurations/group/${configGroup.groupId}`;
  const response = await DELETE(url, configGroup);

  return response.data;
};

export const updatingConfigurationKey = async (params) => {
  const { configKey } = params;
  const url = `${APP_API_URI}/internal/application_configurations/keys/${configKey.id}`;
  const response = await PATCH(url, {
    key: configKey.key,
    configuration_group_id: configKey.groupId,
    name: configKey.name,
    configuration_values_attributes: configKey.values,
  });

  return response.data;
};

export const deletingConfigurationKey = async (params) => {
  const { keyId } = params;
  const url = `${APP_API_URI}/internal/application_configurations/keys/${keyId}`;
  const response = await DELETE(url);

  return response.data;
};

export const creatingConfigurationKey = async (params) => {
  const { name, key, type, groupId } = params;
  const url = `${APP_API_URI}/internal/application_configurations/keys`;
  const response = await POST(url, {
    name,
    key,
    type,
    configuration_group_id: groupId,
  });

  return response.data;
};

export const restartingNow = async () => {
  const url = `${APP_API_URI}/internal/application_configurations/restart_now`;
  const response = await POST(url);

  return response.data;
};
