import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { broadcastScrollSizeToParent } from 'lib/utils';

// Material Components
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import DisposableItems from './DisposableItems';
import SaveChangesButton from '../shared/SaveChangesButton';
import SectionHeader from '../shared/SectionHeader';
import WhatYouNeed from './WhatYouNeed';
import IngredientCard from './IngredientCard';

const IngredientsTab = ({ classes, dirty, setDirty }) => {
  const { meal } = useSelector((state) => state.mealEditForm.meal);

  const initialDisposableItemAssignments = useMemo(
    () =>
      meal.disposableItemAssignments.map((assignment, index) => {
        // eslint-disable-next-line no-param-reassign
        assignment.index = index;
        return assignment;
      }),
    [meal.disposableItemAssignments]
  );

  const [formState, setFormState] = useState({
    disposableItemAssignments: initialDisposableItemAssignments,
    oliveOilQuantity: meal.oliveOilQuantity,
    saltQuantity: meal.saltQuantity,
    whatYouNeed: meal.whatYouNeed,
    ingredientAssignments: meal.ingredientAssignments,
    onAnyMenu: meal.onAnyMenu,
  });

  const [areAnyIngredientsExpanded, setAnyIngredientsExpanded] = useState(true);
  const [expandAllIngredients, setExpandAllIngredients] = useState(false);

  useEffect(() => {
    setFormState({
      disposableItemAssignments: initialDisposableItemAssignments,
      oliveOilQuantity: meal.oliveOilQuantity,
      saltQuantity: meal.saltQuantity,
      whatYouNeed: meal.whatYouNeed,
      ingredientAssignments: meal.ingredientAssignments,
      onAnyMenu: meal.onAnyMenu,
    });
  }, [meal, initialDisposableItemAssignments]);

  const handleFormState = (event) => {
    if (!dirty) {
      setDirty(true);
    }
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const addIngredientAssignment = () => {
    if (formState.ingredientAssignments.length > 10) {
      // eslint-disable-next-line
      alert('Warning: You have already added at least ten base ingredients');
    }
    const newIngredientAssignmentState = formState.ingredientAssignments.concat({
      replacementIngredients: [],
      newIaId: uuid(),
    });
    setFormState({ ...formState, ingredientAssignments: newIngredientAssignmentState });
  };

  const existingErrors = () => {
    return formState.ingredientAssignments.some((ia) => !ia?.deleted && ia?.hasErrors);
  };

  // Returns the form state without the `hasErrors` attribute on the ingredient assignments
  const finalFormState = () => ({
    ...formState,
    ingredientAssignments: formState.ingredientAssignments.map(
      ({ hasErrors, newIaId, ...rest }) => rest
    ),
  });

  const renderIngredientCards = () => {
    return formState.ingredientAssignments.map((ingredientAssignment, index) => {
      return ingredientAssignment.deleted ? null : (
        <Grid
          item
          xs={12}
          key={
            ingredientAssignment.newIaId ? ingredientAssignment.newIaId : ingredientAssignment.id
          }
        >
          <IngredientCard
            classes={classes}
            areAnyIngredientsExpanded={areAnyIngredientsExpanded}
            setAnyIngredientsExpanded={setAnyIngredientsExpanded}
            expandAllIngredients={expandAllIngredients}
            setExpandAllIngredients={setExpandAllIngredients}
            formState={formState}
            handleFormState={handleFormState}
            setFormState={setFormState}
            index={index}
            ingredientAssignment={ingredientAssignment}
            rthProductType={meal.rthProductType}
          />
        </Grid>
      );
    });
  };

  // If being used in an iframe, set the width and height
  // so we don't have a double scrollbar situation.
  useEffect(() => {
    broadcastScrollSizeToParent();
  }, []);

  return (
    <>
      <Grid container direction="row" spacing={2} className={classes.whatYouNeed}>
        <Grid item xs={6}>
          <WhatYouNeed
            classes={classes}
            dirty={dirty}
            formState={formState}
            handleFormState={handleFormState}
            setFormState={setFormState}
            setDirty={setDirty}
          />
        </Grid>
        <Grid item xs={6}>
          <DisposableItems
            classes={classes}
            formState={formState}
            handleFormState={handleFormState}
          />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid container direction="row" className={classes.ingredientsSectionHeader}>
          <Grid item xs={10}>
            <SectionHeader text="Ingredients" />
          </Grid>
          <Grid item xs={2} className={classes.textAlignRight}>
            <Button
              data-testid="expandCollapseButton"
              className={classes.greenText}
              onClick={() => {
                if (areAnyIngredientsExpanded) {
                  setAnyIngredientsExpanded(false);
                } else {
                  setExpandAllIngredients(true);
                }
              }}
            >
              {areAnyIngredientsExpanded ? 'Collapse All' : 'Expand All'}
            </Button>
          </Grid>
        </Grid>
        {renderIngredientCards()}
        <Grid item xs={12}>
          <Button
            variant="outlined"
            className={classes.addIngredientButton}
            onClick={addIngredientAssignment}
            startIcon={<AddIcon />}
          >
            Add Ingredient
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.ml_24}>
          <SaveChangesButton
            disabled={existingErrors()}
            mealParams={finalFormState()}
            mealId={meal.id}
          />
        </Grid>
      </Grid>
    </>
  );
};

IngredientsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  dirty: PropTypes.bool,
  setDirty: PropTypes.func.isRequired,
};

IngredientsTab.defaultProps = {
  dirty: false,
};

export default IngredientsTab;
