import React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';

const Adjustments = ({ onClickAddAdjustment }) => {
  return (
    <Card>
      <CardHeader title="Adjustments" />
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickAddAdjustment}
          startIcon={<AddIcon />}
        >
          Add Adjustment
        </Button>
      </CardActions>
    </Card>
  );
};

Adjustments.propTypes = {
  onClickAddAdjustment: PropTypes.func.isRequired,
};

export default Adjustments;
