import { LIGHT_BLACK, LIGHT_BLUE, LIGHTEST_BLUE, WHITE } from 'lib/constants';

export default {
  bodyWrapper: {
    margin: 0,
    padding: '21px 10px',
    backgroundColor: WHITE,
  },
  root: {
    fontFamily: 'Roboto',
    color: LIGHT_BLACK,
  },
  actionBox: {
    display: 'flex',
    alignItems: 'right',
    flexBasis: 'auto',
    justifyContent: 'flex-end',
    flexShrink: 0,
  },
  summary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '2%',
    padding: '5px 0 15px 6px',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  key: {
    flexBasis: '35%',
  },
  valueContainer: {
    padding: '0 12px 0 4px',
  },
  value: {
    marginBottom: '50px',
    marginTop: '10px',
  },
  productionLabel: {
    fontWeight: 'bold',
  },
  greenInput: {
    input: { color: 'green' },
  },
  rightButton: {
    alignSelf: 'end',
    marginLeft: 'auto',
    fontWeight: 'bold',
    fontSize: '110%',
  },
  break: {
    flexBasis: '45%',
  },
  dialog: {
    width: '500px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  dialogInput: {
    marginY: '15px',
    width: '350px',
  },
  addKey: {
    float: 'right',
    paddingY: '20px',
  },
  input: {
    height: '1em',
  },
  deleteDialogText: {
    paddingBottom: '20px',
  },
  keyContainer: {
    backgroundColor: LIGHTEST_BLUE,
    legend: {
      fontWeight: 'bold',
    },
    paddingY: '5px',
    margin: '10px 0',
    border: '1px solid #999',
  },
  topDialog: {
    '& .MuiDialog-container': {
      alignItems: 'flex-start',
    },
  },
  highlight: {
    backgroundColor: LIGHT_BLUE,
  },
  highlightFade: {
    backgroundColor: LIGHTEST_BLUE,
    transition: 'background 5.0s linear',
  },
  legend: {
    display: 'flex',
    alignItems: 'center',
  },
  editIcon: {
    margin: '0 0 0.5vh .5vh',
  },
  keyNameInput: {
    width: '300px',
    verticalAlign: 'middle',
  },
};
