import { map, every, isEmpty } from 'lodash';

export const sanitizedRecipeSteps = (arrayOfRecipeSteps) => {
  return map(arrayOfRecipeSteps, (recipeSteps) =>
    map(recipeSteps, ({ title, description }) => {
      return [title, description];
    })
  );
};

const propertySameForEveryRecipeStep = (recipeStepsGroup, property) => {
  const firstRecipeStep = recipeStepsGroup[0]; // pull first step as basis for comparison
  return every(recipeStepsGroup, (rs) => {
    return rs[property] === firstRecipeStep[property];
  });
};

export const setHighlightPropertyForGroup = ({ recipeStepsGroup, property, numMeals }) => {
  if (isEmpty(recipeStepsGroup)) return false;
  if (recipeStepsGroup.length !== numMeals) return true; // if one or more of the versions is missing a step

  return !propertySameForEveryRecipeStep(recipeStepsGroup, property);
};
