import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Material Components
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

import { sortBy } from 'lodash';

import Dropdown from '../../shared/Dropdown';
import TextBox from '../../shared/TextBox';

const BeforeYouCookInstruction = ({ classes, instruction, formState, handleFormState, index }) => {
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);

  const [instructionState, setInstructionState] = useState({
    ...instruction,
    index,
  });

  useEffect(() => {
    setInstructionState({
      ...instruction,
      index,
    });
  }, [instruction, index]);

  const updateFormState = (attribute, value) => {
    const newInstructionState = { ...instructionState, [attribute]: value };
    setInstructionState(newInstructionState);

    const newFormStateInstructions = formState.beforeYouCookInstructions;
    newFormStateInstructions[index] = newInstructionState;

    handleFormState('beforeYouCookInstructions', newFormStateInstructions);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    updateFormState(name, value);
  };

  const onDelete = () => {
    updateFormState('deleted', true);
  };

  const selectInstructionOptions = useMemo(() => {
    const sortedInstructions = sortBy(
      formOptions.beforeYouCookInstructions,
      (instructionOption) => instructionOption.name
    );

    return sortedInstructions.map((instructionOption) => ({
      label: instructionOption.name,
      value: instructionOption.id,
    }));
  }, [formOptions.beforeYouCookInstructions]);

  const renderInstruction = () => {
    return (
      <Grid item xs={6} className={classes.flexItemCard}>
        <Dropdown
          label="Before You Cook Instruction"
          name="beforeYouCookInstructionId"
          value={instruction.beforeYouCookInstructionId}
          onChange={onChange}
          options={selectInstructionOptions}
          required
        />
        <TextBox
          fullWidth
          label="Value"
          name="value"
          helperText="This will replace {{value}} with your input if the instruction contains {{value}}"
          onChange={onChange}
          value={instruction.value}
        />
        <TextBox
          fullWidth
          required
          label="Sequence Number"
          name="sequenceNumber"
          type="number"
          onChange={onChange}
          value={instruction.sequenceNumber}
        />
        <Button variant="outlined" className={classes.deleteButton} onClick={onDelete}>
          <DeleteIcon className={classes.deleteIcon} />
          Remove Step
        </Button>
        <hr className={classes.divider} />
      </Grid>
    );
  };

  return <>{instruction.deleted ? null : renderInstruction()}</>;
};

BeforeYouCookInstruction.propTypes = {
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  instruction: PropTypes.object,
};

BeforeYouCookInstruction.defaultProps = {
  instruction: null,
};

export default BeforeYouCookInstruction;
