// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

// Actions
import {
  setUsageYield,
  setQuantity,
  setSwapability,
  setMeasure,
  setPortionMethod,
  setIngredientPackagingId,
} from 'redux/ingredientSwapper/targetIngredient/actions';

// Components
import AssignmentFields from './AssignmentFields';

import styles from './styles';

const mapStateToProps = (state) => ({
  targetIngredient: state.ingredientSwapper.targetIngredient,
});
const mapDispatchToProps = {
  onSetUsageYield: setUsageYield,
  onSetQuantity: setQuantity,
  onSetMeasure: setMeasure,
  onSetSwapability: setSwapability,
  onSetPortionMethod: setPortionMethod,
  onSetIngredientPackagingId: setIngredientPackagingId,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AssignmentFields));
