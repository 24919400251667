import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Material Components
import Paper from '@mui/material/Paper';

import Checkboxes from '../../shared/Checkboxes';
import Dropdown from '../../shared/Dropdown';
import SectionHeader from '../shared/SectionHeader';

const AddonsAndOptins = ({ classes, formState, handleFormState }) => {
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);

  const selectAddons = useMemo(() => {
    return formOptions.mealAddons.map((addon) => ({
      label: addon.name,
      value: addon.id,
      key: addon.id,
    }));
  }, [formOptions.mealAddons]);

  return (
    <Paper className={classes.paperWithBottomMargin}>
      <SectionHeader text="Addons and Opt-ins" />

      <Dropdown
        label="Meal Addon"
        name="mealAddonId"
        onChange={handleFormState}
        value={formState.mealAddonId || ''}
        options={selectAddons}
      />

      <hr className={classes.hr} />

      <Checkboxes
        choices={formOptions.optIns}
        selectedFormState={formState.optInIds}
        selectedFormStateName="optInIds"
        handleFormState={handleFormState}
      />
    </Paper>
  );
};

AddonsAndOptins.propTypes = {
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
};

export default AddonsAndOptins;
