import { MATERIAL_OUTLINE_GRAY, LIGHT_BLACK, MEDIUM_GREY } from 'lib/constants';

export default {
  hr: {
    borderTop: `solid 1px ${MATERIAL_OUTLINE_GRAY}`,
    width: '97%',
    margin: '16px auto',
  },
  name: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '32px',
    textAlign: 'left',
    color: LIGHT_BLACK,
    margin: 0,
  },
  brandName: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'left',
    color: MEDIUM_GREY,
    marginTop: '4px',
  },
  details: { maxWidth: '320px' },
  detailTitle: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'left',
    color: LIGHT_BLACK,
    margin: 0,
  },
  detailValue: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'left',
    color: MEDIUM_GREY,
    marginTop: '4px',
  },
};
