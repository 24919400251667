import { FVC_CHANGE_TYPE_CHANGE_PRODUCTION_DAY, FVC_BALANCING_TYPES_DEFAULT } from 'lib/constants';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

import GeographyInput from '../GeographyInput';
import MealSelector from '../MealSelector';
import PackingFacilitySelector from '../PackingFacilitySelector';
import BalancerSelector from '../BalancerSelector';

const ChangeProductionDay = ({ value, onChange }) => {
  const [shipDateFrom, setShipDateFrom] = useState(value?.shipDateFrom);
  const [shipDateTo, setShipDateTo] = useState(value?.shipDateTo);
  const [packingFacility, setPackingFacility] = useState(value?.packingFacility);
  const [geography, setGeography] = useState(value?.geography || []);
  const [meal, setMeal] = useState(value?.meal);
  const [quantity, setQuantity] = useState(value?.quantity);
  const [balancer, setBalancer] = useState(value?.balancer || FVC_BALANCING_TYPES_DEFAULT);
  const [explanation, setExplanation] = useState(value?.explanation || '');

  const geographyError = geography.some((g) => g.errorMessage);

  useEffect(() => {
    const isFormReady =
      shipDateFrom &&
      shipDateTo &&
      packingFacility &&
      meal &&
      balancer &&
      explanation &&
      quantity !== null &&
      !geographyError;
    const changeRequestData = isFormReady
      ? {
          changeType: FVC_CHANGE_TYPE_CHANGE_PRODUCTION_DAY,
          shipDateFrom,
          shipDateTo,
          packingFacility,
          geography,
          meal,
          quantity,
          balancer,
          explanation,
        }
      : value;
    onChange(changeRequestData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shipDateFrom,
    shipDateTo,
    packingFacility,
    geography,
    meal,
    quantity,
    balancer,
    explanation,
    geographyError,
    onChange,
  ]);

  useEffect(() => {
    setShipDateFrom(value?.shipDateFrom);
    setShipDateTo(value?.shipDateTo);
    setPackingFacility(value?.packingFacility);
    setGeography(value?.geography || []);
    setMeal(value?.meal);
    setQuantity(value?.quantity);
    setBalancer(value?.balancer || FVC_BALANCING_TYPES_DEFAULT);
    setExplanation(value?.explanation || '');
  }, [value]);

  const handleQuantityChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setQuantity(parseInt(e.target.value, 10));
    }
  };

  return (
    <>
      <TextField
        id="shipDateFrom"
        label="From Production Day"
        type="date"
        value={shipDateFrom || ''}
        onChange={(e) => setShipDateFrom(e.target.value)}
        fullWidth
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="shipDateTo"
        label="To Production Day"
        type="date"
        value={shipDateTo || ''}
        onChange={(e) => setShipDateTo(e.target.value)}
        fullWidth
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <PackingFacilitySelector
        id="packingFacility"
        value={packingFacility}
        onChange={setPackingFacility}
      />
      <MealSelector
        id="meal"
        packingFacility={packingFacility}
        shipDate={shipDateFrom}
        geography={geography}
        value={meal}
        onChange={setMeal}
      />
      <GeographyInput
        id="geography"
        packingFacility={packingFacility}
        shipDate={shipDateFrom}
        meal={meal}
        value={geography}
        onChange={setGeography}
      />
      <Divider />
      <TextField
        id="quantity"
        label="Quantity"
        type="text"
        value={quantity ?? ''}
        onChange={(e) => handleQuantityChange(e)}
        variant="outlined"
        fullWidth
      />
      <BalancerSelector id="balancer" value={balancer} onChange={setBalancer} />
      <Divider />
      <TextField
        id="explanation"
        label="Explanation"
        value={explanation}
        onChange={(e) => setExplanation(e.target.value)}
        multiline
        rows={4}
        variant="outlined"
        fullWidth
      />
    </>
  );
};

ChangeProductionDay.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

ChangeProductionDay.defaultProps = {
  value: null,
};

export default ChangeProductionDay;
