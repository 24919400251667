import { BACKGROUND_GREY } from 'lib/constants';

export default {
  root: {
    backgroundColor: BACKGROUND_GREY,
    paddingBottom: '32px',
  },
  csvRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '8px',
    margin: '10px 10px 0px 4px',
  },
};
