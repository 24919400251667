import React from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import styles from './styles';
import Button from './shared/Button';

const DeleteKeyDialog = ({ handleConfirm, onClose, open, configKey }) => {
  return (
    <Dialog open={open} onClose={onClose} sx={styles.topDialog}>
      <Box sx={styles.dialog}>
        <DialogTitle>Delete Key</DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <Box sx={styles.deleteDialogText}>
            <b> Name:</b> {configKey.name}
          </Box>
          <Box sx={styles.deleteDialogText}>
            <b> Key:</b> {configKey.key}
          </Box>
          <Typography>
            Deleting a key is permanent and cannot be undone. This will remove the key from all
            environments. Are you sure you want to delete this key?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button handleOnClick={onClose} buttonText="Cancel" color="secondary" />
          <Button handleOnClick={handleConfirm} buttonText="Confirm" color="error" />
        </DialogActions>
      </Box>
    </Dialog>
  );
};

DeleteKeyDialog.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  configKey: PropTypes.object.isRequired,
};

export default DeleteKeyDialog;
