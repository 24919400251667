import { MEF_FORM_OPTIONS } from 'lib/constants';
import { makeApiRequest } from 'lib/api';
import { fetchFormOptions } from './services';

export const getFormOptions = () => {
  return async (dispatch) => {
    await dispatch(
      makeApiRequest({
        type: MEF_FORM_OPTIONS,
        method: fetchFormOptions,
      })
    );
  };
};
