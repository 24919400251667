import React from 'react';

import IfAllowed from 'components/shared/IfAllowed';
import RetailPage from 'components/Retail/Page';

import DownloadShrink from './DownloadShrink';
import UploadShrink from './UploadShrink';

const AcceptableShrink = () => {
  return (
    <RetailPage title="Acceptable Shrink">
      <DownloadShrink />
      <IfAllowed ability="editRetailAcceptableShrink">
        <UploadShrink />
      </IfAllowed>
    </RetailPage>
  );
};

export default AcceptableShrink;
