import {
  rowLabel,
  iconCell,
  icon,
  listContentSameBlock,
  listContent,
  expandingCell,
  collapse,
  tableRowGreenBorder,
  greyed,
} from 'lib/styles/comparisonTool';

export default {
  rowLabel,
  iconCell,
  icon,
  listContent,
  listContentSameBlock,
  expandingCell,
  collapse,
  tableRowGreenBorder,
  greyed,
};
