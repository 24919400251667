export const numberToDollarString = (number) => {
  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const trimEmptyCents = (string) => string.replace('.00', '');

export const centsToDollarString = (cents) => {
  const notNullCents = cents || 0;
  const dollarString = `${numberToDollarString(notNullCents / 100)}`;
  return trimEmptyCents(dollarString);
};

export const centsToDollars = (cents) => {
  const notNullCents = Number(cents || 0);
  return notNullCents / 100;
};

export const dollarsToCents = (dollars) => {
  const notNullDollars = Number(dollars || 0);

  // rounding to deal with imprecision in float math
  return Math.round(notNullDollars * 100);
};

export const dollarStringToNumber = (stringish) => {
  let converted;

  if (String(stringish.includes('$'))) {
    converted = Number(stringish.slice(1));
  } else {
    converted = Number(stringish);
  }

  return converted;
};
