import { RED, WHITE } from 'lib/constants';

export default {
  button: {
    color: RED,
    float: 'right',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    margin: '8px',
    textAlign: 'right',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: WHITE,
    },
  },
};
