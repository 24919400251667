// Colors
export const WHITE = '#FFFFFF';
export const BACKGROUND_GREY = '#f8f9fb';
export const HC_GREEN = '#008600';
export const LIGHT_BLACK = '#404851';
export const MEDIUM_GREY = '#666C73';
export const LIGHT_GREY = '#8C9196';
export const LIGHT_BLUE = '#C5D3FF';
export const LIGHTEST_BLUE = '#F7F9FF';
export const LIGHTEST_GREY = '#F5F5F6';
export const LIGHT_GREEN = '#E6F3E6';
export const BORDER_GREY = '#D8DADC';
export const COLLAPSED_RECIPE_CARD_HEIGHT = '71px';
export const LIGHT_RED = '#FDF3F4';
export const MEDIUM_RED = '#F44336';
export const RED = '#D92236';
export const MATERIAL_OUTLINE_GRAY = 'rgba(0, 0, 0, 0.12)';
export const BLUE_100 = '#2A7DE1';
export const BLUE = '#2196F3';
export const ORANGE_100 = '#F07B00';
export const LEMON_100 = '#FFDE6F';
export const TEAL = '#C6EEE4';
export const GREEN_130 = '#004400';
export const GRAPE = '#6F425F';
export const CREAM_110 = '#FAF0E8';
export const PINK_100 = '#FEE0E0';
export const BLUEY_BLUE = '#8CC4EB';
export const BLUEY_DARK_BLUE = '#303775';
export const PAW_PATROL_BLUE = '#005C8F';
export const TEMPO_LIME = '#C0E235';
export const TEMPO_BLACK = '#2B2B2B';
export const TEMPO_PINK = '#FFD6C9';
export const TEMPO_BLUE = '#1071EA';
export const TEMPO_GREEN_120 = '#006028';
export const TEMPO_BLUE_10 = '#F6FBFF';
export const TEMPO_PURPLE = '#8D52DE';

export const SUCCESS = '#66bb6a';
export const SUCCESS_LIGHT = '#81c784';
export const SUCCESS_DARK = '#388e3c';
export const WARNING = '#ffa726';
export const WARNING_LIGHT = '#ffb74d';
export const WARNING_DARK = '#f57c00';
export const ERROR = '#f44336';
export const ERROR_LIGHT = '#e57373';
export const ERROR_DARK = '#d32f2f';

// Spacing
export const MEDIUM_SPACING = 12;
export const DEFAULT_SPACING = 16;
export const THICK_SPACING = 24;
