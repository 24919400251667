// Dependencies
import React, { useState, useMemo, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Helpers
import { mealShape } from 'lib/helpers/comparisonTool/propShapes';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import IfAllowed from 'components/shared/IfAllowed';

// Helpers
import {
  sanitizedTitleInfo,
  sanitizedTitles,
  sanitizedSubtitles,
  compareTextBlobs,
} from 'lib/helpers/comparisonTool';
import { SessionContext } from 'contexts/sessionContext';

// Components
import SelectVersionsButton from '../SelectVersionsButton';
import ReplicateVersionButton from '../ReplicateVersionButton/ReplicateVersionButton';

const TitleRow = ({ viewableMeals, activeMeals, inactiveMeals, conceptId, classes }) => {
  const { featureFlags } = useContext(SessionContext);
  // eslint-disable-next-line no-unused-vars
  const mealVersionDataCopierTesting = featureFlags.meal_version_data_copier_testing;

  // State methods
  const [hasDifferences, setHasDifferences] = useState(false);
  const [highlightTitle, setHighlightTitle] = useState({});
  const [highlightSubtitle, setHighlightSubtitle] = useState({});

  // Lifecycle methods
  useMemo(() => {
    const checkDifferences =
      viewableMeals.length !== 0 ? compareTextBlobs(sanitizedTitleInfo(viewableMeals)) : false;
    setHasDifferences(checkDifferences);
  }, [viewableMeals]);

  useEffect(() => {
    setHighlightTitle(compareTextBlobs(sanitizedTitles(viewableMeals)));
    setHighlightSubtitle(compareTextBlobs(sanitizedSubtitles(viewableMeals)));
  }, [viewableMeals]);

  return (
    <TableRow>
      <TableCell
        className={clsx(classes.iconCell, hasDifferences && classes.tableRowGreenBorder)}
      />
      <TableCell className={classes.labelColumn}>
        <div className={classes.conceptLabel}>Concept</div>
        <div className={classes.conceptId}>{conceptId}</div>
        <div className={classes.selectConcept}>
          <SelectVersionsButton activeMeals={activeMeals} inactiveMeals={inactiveMeals} />
        </div>
      </TableCell>
      {viewableMeals.map((meal) => (
        <TableCell
          key={`title.${meal.versionId}`}
          className={clsx(classes.contentColumn, meal.inactive && classes.greyed)}
        >
          <div className={clsx(classes.title, highlightTitle && classes.highlight)}>
            {meal.title}
          </div>
          <div className={clsx(classes.subtitle, highlightSubtitle && classes.highlight)}>
            {meal.subtitle}
          </div>
          <div>
            <a href={meal.adminUrl} className={classes.link}>
              {meal.versionId}
            </a>
          </div>
          <div className={classes.id}>{meal.id}</div>
          {mealVersionDataCopierTesting && viewableMeals.length > 1 && (
            <IfAllowed ability="viewReplicateVersionButton">
              <ReplicateVersionButton sourceMeal={meal} meals={viewableMeals} />
            </IfAllowed>
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};

TitleRow.propTypes = {
  viewableMeals: PropTypes.arrayOf(mealShape).isRequired,
  activeMeals: PropTypes.arrayOf(mealShape).isRequired,
  inactiveMeals: PropTypes.arrayOf(mealShape).isRequired,
  conceptId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default TitleRow;
