export const DELETED = 'deleted';
export const PACKING_FACILITY_IDS = 'packingFacilityIds';
export const PACKING_FACILITY_NAMES = 'packingFacilityNames';
export const INGREDIENT_PACKAGING_ID = 'ingredientPackagingId';
export const PORTION_METHOD = 'portionMethod';
export const OPS_CATEGORY = 'opsCategory';
export const PRODUCTION_SHEET_NOTES = 'productionSheetNotes';
export const HIGH_TOLERANCE = 'highTolerance';
export const LOW_TOLERANCE = 'lowTolerance';
export const USAGE_YIELD = 'usageYield';
export const MEASURE = 'measure';
export const QUANTITY = 'quantity';
export const OZ_WEIGHT = 'oz_weight';
export const VOLUME_MEASUREMENTS = ['cup', 'tbsp', 'tsp'];
export const DISABLED_CHECKBOXES = ['pickable', 'mustCook', 'largeIngredient'];
