import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { format, fromUnixTime } from 'date-fns';

const MenuHeaderItem = ({ displayGroup, menu, classes }) => {
  return (
    <Link to={`/menugrid/${displayGroup}/${menu.id}`}>
      <span className={classes.menuId}>{menu.id}</span>
      <span className={classes.endsAtDate}>
        {format(fromUnixTime(menu.endsAt), 'LLL')} {format(fromUnixTime(menu.endsAt), 'd')}
      </span>
    </Link>
  );
};

MenuHeaderItem.propTypes = {
  displayGroup: PropTypes.string.isRequired,
  menu: PropTypes.shape({
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    endsAt: PropTypes.number.isRequired,
  }).isRequired,
  classes: PropTypes.object.isRequired,
};

export default MenuHeaderItem;
