import { BACKGROUND_GREY, LIGHT_GREY, HC_GREEN } from 'lib/constants';

export default {
  button: {
    backgroundColor: BACKGROUND_GREY,
    borderColor: LIGHT_GREY,
    color: HC_GREEN,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    padding: '16px 24px',
    textTransform: 'capitalize',
  },
  plusSign: {
    color: HC_GREEN,
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '24px',
    margin: '0 16px 0 0',
    textAlign: 'center',
  },
  root: {
    textAlign: 'right',
  },
};
