// Dependencies
import { connect } from 'react-redux';

// Components
import TargetIngredientSelector from './TargetIngredientSelector';

const mapStateToProps = (state) => ({
  targetIngredient: state.ingredientSwapper.targetIngredient,
  targetIngredientError: state.ingredientSwapper.targetIngredient.error,
});

export default connect(mapStateToProps, null)(TargetIngredientSelector);
