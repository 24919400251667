// Dependencies
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

// Actions
import { filterViewableMeals } from 'redux/comparisonTool/concept/actions';

import SelectVersions from './SelectVersions';

import styles from './styles';

const mapDispatchToProps = {
  onFilterViewableMeals: filterViewableMeals,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(SelectVersions));
