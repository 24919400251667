import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

const ReplacementsByFacilityValidationDialog = ({ errors, handleErrors }) => {
  const openDialog = !isEmpty(errors);

  const handleClick = () => {
    handleErrors({});
  };

  const renderErrorMessage = () => {
    const {
      assignedCompoundIngredient,
      selectedIngredient,
      duplicatedPackingFacilitiesForReplacement,
    } = errors;
    const listFormatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
    const formattedDuplicatedPackingFacilitiesForReplacement = listFormatter.format(
      duplicatedPackingFacilitiesForReplacement
    );

    return assignedCompoundIngredient.id !== selectedIngredient.id ? (
      <DialogContentText>
        Creating a replacement for this component at{' '}
        <b>{formattedDuplicatedPackingFacilitiesForReplacement}</b> will NOT be active since the
        whole compound ingredient is already being replaced in{' '}
        {duplicatedPackingFacilitiesForReplacement.length > 1
          ? 'these facilities'
          : 'this facility'}
        .
      </DialogContentText>
    ) : (
      <DialogContentText>
        Creating a replacement for this Compound Ingredient at{' '}
        <b>{formattedDuplicatedPackingFacilitiesForReplacement}</b> will nullify existing
        replacements for the <b>{selectedIngredient.name}</b> please remove the replacement at the
        component level.
      </DialogContentText>
    );
  };

  return (
    openDialog && (
      <Dialog open={openDialog} onClose={handleClick}>
        <DialogTitle>Invalid Replacement for Same Facility</DialogTitle>
        <DialogContent>{renderErrorMessage()}</DialogContent>
        <DialogActions>
          <Button onClick={handleClick} variant="contained" color="warning">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};

ReplacementsByFacilityValidationDialog.propTypes = {
  errors: PropTypes.object.isRequired,
  handleErrors: PropTypes.func.isRequired,
};

export default ReplacementsByFacilityValidationDialog;
