import { mapKeysDeep } from 'lib/helpers/objects';
import { snakeCase } from 'lodash';
import { APP_API_URI } from 'lib/constants';
import { GET, PATCH, POST } from 'lib/api';

const MG_URL = `${APP_API_URI}/internal/culinary/menugrid`;

export const getGridData = async ({ quarter, displayGroup }) => {
  const url = `${MG_URL}/grid_data?quarter=${quarter}&display_group=${displayGroup}`;

  const response = await GET(url);
  return response.data;
};

export const patchSelectionOrder = async ({ stagingGroup, menus, displayGroup }) => {
  const url = `${MG_URL}/update_menu_selections`;

  const transformedStagingGroup = mapKeysDeep(stagingGroup, (_value, key) => {
    return snakeCase(key);
  });

  const transformedMenus = mapKeysDeep(menus, (_value, key) => {
    return snakeCase(key);
  });

  const response = await PATCH(url, {
    staging_group: transformedStagingGroup,
    menus: transformedMenus,
    display_group: displayGroup,
  });
  return response.data;
};

export const createStagingSelections = async ({
  stagingGroup,
  displayGroup,
  culinaryVersionIds,
}) => {
  const url = `${MG_URL}/create_staging_group_selections`;

  const response = await POST(url, {
    id: stagingGroup.id,
    display_group: displayGroup,
    data: { culinary_version_ids: culinaryVersionIds },
  });
  return response.data;
};
