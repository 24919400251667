import {
  CT_UPDATE_MEAL_VERSION_DATA_REQUEST,
  CT_UPDATE_MEAL_VERSION_DATA_SUCCESS,
  CT_UPDATE_MEAL_VERSION_DATA_ERROR,
} from 'lib/constants';

const initialState = {
  submitting: false,
  success: false,
  successMessage: null,
  error: false,
  errorMessage: null,
};

export default (state = initialState, action = { type: null }) => {
  switch (action.type) {
    case CT_UPDATE_MEAL_VERSION_DATA_REQUEST:
      return {
        ...state,
        submitting: true,
        success: false,
        successMessage: null,
        error: false,
        errorMessage: null,
      };
    case CT_UPDATE_MEAL_VERSION_DATA_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true,
        successMessage: 'Meals updated!',
        error: false,
      };
    case CT_UPDATE_MEAL_VERSION_DATA_ERROR:
      return {
        ...state,
        submitting: false,
        error: true,
        errorMessage: action.error.message,
      };
    default:
      return state;
  }
};
