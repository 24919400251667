import { RED, HC_GREEN, MEDIUM_GREY, LIGHT_GREY } from 'lib/constants';

export default {
  alert: {
    border: '1px solid orange',
  },
  addIngredientButton: {
    color: HC_GREEN,
    marginBottom: '16px',
  },
  deleteButton: {
    color: RED,
    borderColor: RED,
    marginTop: '16px',
  },
  disabledCardContainer: {
    pointerEvents: 'none',
    opacity: 0.4,
  },
  fieldset: {
    border: 'none',
  },
  ingredientCard: {
    padding: 16,
    marginBottom: 24,
    overflow: 'visible',
  },
  ingredientTextSearchBox: {
    marginTop: '16px',
  },
  label: {
    font: 'Roboto',
    weight: 400,
    fontSize: 14,
    letterSpacing: '0.15px',
    color: MEDIUM_GREY,
    marginBottom: 0,
  },
  disabledText: {
    color: LIGHT_GREY,
  },
  mb_8: {
    marginBottom: '8px',
  },
};
