// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Material
import TextField from '@mui/material/TextField';

// Custom Components
import NumberFormatter from '../NumberFormatter';

// Example: https://material-ui.com/components/text-fields/#FormattedInputs.js

const MoneyView = ({ children, classes, helperText, label, name, value }) => {
  return (
    <TextField
      className={classes.textInput}
      disabled
      focused={value >= 0}
      helperText={helperText}
      label={label}
      name={name}
      value={value}
      variant="outlined"
      InputProps={{ inputComponent: NumberFormatter }}
    >
      {children}
    </TextField>
  );
};

MoneyView.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  classes: PropTypes.object.isRequired,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MoneyView.defaultProps = {
  children: [],
  helperText: '',
  label: '',
  name: '',
  value: '',
};

export default MoneyView;
