// Dependencies
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Helpers
import { mealShape } from 'lib/helpers/comparisonTool/propShapes';
import { postUpdateMeals } from 'redux/comparisonTool/mealVersionDataCopier/actions';

// Material
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import withStyles from '@mui/styles/withStyles';
import styles from './styles';

// Components
import MealList from './MealList';
import MealListItem from './MealListItem';
import ReplicateFieldsAccordion from './ReplicateFieldsAccordion';

const ReplicateVersionModalDialog = ({ meals, sourceMeal, open, handleClose, classes }) => {
  const [checked, setChecked] = React.useState([]);
  const eligibleMeals = () => meals.filter((meal) => meal !== sourceMeal);

  const dispatch = useDispatch();

  const postChanges = (sourceMealId, targetMealIds) => {
    dispatch(postUpdateMeals(sourceMealId, targetMealIds));
  };

  const handleCancel = () => {
    setChecked([]);
    handleClose();
  };

  const handleClickSave = () => {
    const targetMealIds = checked.map((meal) => meal.id);
    postChanges(sourceMeal.id, targetMealIds);
    handleCancel();
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheck = (meal) => {
    return checked.indexOf(meal) !== -1;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      data-testid="replicate-version-dialog"
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        <div className={classes.dialogTitleText}>What versions would you like to update?</div>
        <ReplicateFieldsAccordion />
      </DialogTitle>
      <DialogContent>
        <Grid container className={classes.sourceMealBox} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="overline" display="block" className={classes.sourceMealTitle}>
              Copying From
            </Typography>
          </Grid>
          <Grid item xs className={classes.sourceMealGridItem}>
            <MealListItem meal={sourceMeal} />
          </Grid>
        </Grid>
        <Grid container className={classes.mealListContainer} spacing={1}>
          <MealList handleCheck={handleCheck} handleToggle={handleToggle} meals={eligibleMeals()} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          variant="outlined"
          className={classes.cancelButton}
          size="small"
        >
          Cancel
        </Button>
        <Button onClick={handleClickSave} variant="contained" color="primary" size="small">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ReplicateVersionModalDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  meals: PropTypes.arrayOf(mealShape).isRequired,
  sourceMeal: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ReplicateVersionModalDialog);
