import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { postCompoundIngredientRecipe } from 'redux/compoundIngredientRecipeForm/actions';

import Button from '@mui/material/Button';

const SaveChangesButton = ({
  compoundIngredientRecipeId,
  compoundIngredientRecipeParams,
  editsLocked,
}) => {
  const isSubmitting = useSelector(
    (state) => state.compoundIngredientRecipeForm.compoundIngredientRecipe.submitting
  );

  const dispatch = useDispatch();

  const postChanges = () => {
    dispatch(
      postCompoundIngredientRecipe({ compoundIngredientRecipeId, compoundIngredientRecipeParams })
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    postChanges();
  };

  return (
    <Button
      color="primary"
      onClick={handleSubmit}
      disabled={isSubmitting || editsLocked}
      variant="contained"
    >
      Save Changes
    </Button>
  );
};

SaveChangesButton.propTypes = {
  compoundIngredientRecipeId: PropTypes.number,
  compoundIngredientRecipeParams: PropTypes.object.isRequired,
  editsLocked: PropTypes.bool,
};

SaveChangesButton.defaultProps = {
  compoundIngredientRecipeId: null,
  editsLocked: false,
};

export default SaveChangesButton;
