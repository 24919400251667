import React from 'react';
import { ListBase } from 'react-admin';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import StateCountCards from './StateCountCards';
import CompoundIngredientHubBarChart from './CompoundIngredientHubBarChart';
import CompoundIngredientsList from './CompoundIngredientsList';

const queryClient = new QueryClient();

const CompoundIngredientDashboard = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ListBase>
        <Container>
          <Stack spacing={2} alignItems="center">
            <StateCountCards />
            <CompoundIngredientHubBarChart />
            <CompoundIngredientsList />
          </Stack>
        </Container>
      </ListBase>
    </QueryClientProvider>
  );
};

export default CompoundIngredientDashboard;
