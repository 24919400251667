import { HC_GREEN, LIGHT_GREEN } from 'lib/constants';

export default {
  packingFacilityButton: {
    '&.Mui-selected': {
      color: HC_GREEN,
      backgroundColor: LIGHT_GREEN,
    },
  },
};
