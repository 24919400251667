import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

// alpha order
import appConfigurationTool from './appConfigurationTool/reducers';
import disputedCharges from './disputedCharges/reducers';
import comparisonTool from './comparisonTool/reducers';
import compoundIngredientRecipeForm from './compoundIngredientRecipeForm/reducers';
import incidentManager from './incidentManager/reducers';
import ingredientSwapper from './ingredientSwapper/reducers';
import mealEditForm from './mealEditForm/reducers';
import menuManager from './menuManager/reducers';
import menugrid from './menugrid/reducers';

export const allReducers = combineReducers({
  appConfigurationTool,
  comparisonTool,
  compoundIngredientRecipeForm,
  disputedCharges,
  incidentManager,
  ingredientSwapper,
  mealEditForm,
  menuManager,
  menugrid,
});

export default createStore(allReducers, composeWithDevTools(applyMiddleware(thunk)));
