// Dependencies
import withStyles from '@mui/styles/withStyles';

// Components
import ClassificationRow from './ClassificationRow';

// Styles
import styles from './styles';

export default withStyles(styles)(ClassificationRow);
