import { LIGHT_BLACK } from 'lib/constants';

export default {
  listItem: {
    alignItems: 'baseline',
  },
  listItemTextBox: {
    marginLeft: '40px',
  },
  listSubHeader: {
    color: LIGHT_BLACK,
  },
  listItemIcon: {
    color: LIGHT_BLACK,
  },
  listItemText: {
    color: LIGHT_BLACK,
    fontSize: '14px',
  },
};
