export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  card: {
    padding: 16,
    overflow: 'visible',
  },
  buttonContainer: {
    padding: '24px 0',
  },
};
