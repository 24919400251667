import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import DraggableTableRow from '../DraggableTableRow';

const MealRow = ({ classes, category, meal, index }) => {
  return (
    <TableRow
      key={meal.id}
      category={category}
      meal={meal}
      index={index}
      component={DraggableTableRow}
      styles={classes}
      className={classes.backgroundColor}
    />
  );
};

MealRow.propTypes = {
  classes: PropTypes.object.isRequired,
  meal: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  index: PropTypes.number,
};

MealRow.defaultProps = {
  index: null,
};

export default MealRow;
