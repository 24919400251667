import isEmpty from 'lodash/isEmpty';

export const findAssignedCompoundIngredientBeingReplaced = (
  ingredientAssignments,
  replacementCiras,
  selectedCompoundIngredientRecipeId
) => {
  const ingredientAssignmentWithReplacement = ingredientAssignments.find((ingredientAssignment) => {
    const isCompoundWithReplacements =
      !isEmpty(ingredientAssignment.compoundIngredientRecipeAssignments) &&
      !isEmpty(ingredientAssignment.replacementIngredients);
    const hasReplacementCiras = !isEmpty(
      replacementCiras.filter(
        (replacementCira) =>
          replacementCira.compoundIngredientRecipeId === selectedCompoundIngredientRecipeId
      )
    );
    const hasReplacementAttempt =
      ingredientAssignment.ingredient.compoundIngredientRecipeId ===
      selectedCompoundIngredientRecipeId;

    return (isCompoundWithReplacements || hasReplacementCiras) && hasReplacementAttempt;
  });

  return ingredientAssignmentWithReplacement;
};

export const findPackingFacilitiesFromIaReplacement = (ingredientAssignment) =>
  ingredientAssignment.replacementIngredients
    .filter((replacementIngredient) => !replacementIngredient.deleted)
    .flatMap((replacementIngredient) => replacementIngredient.packingFacilityNames);

export const findPackingFacilitiesFromCiraReplacement = (
  replacementCiras,
  selectedCompoundIngredientRecipeId
) =>
  replacementCiras
    .filter((replacementCira) => {
      return (
        replacementCira.compoundIngredientRecipeId === selectedCompoundIngredientRecipeId &&
        !replacementCira.deleted
      );
    })
    .flatMap((replacementCira) => replacementCira.packingFacilityNames);

export const findDuplicatedFacilitiesForReplacement = ({
  replacementState,
  selectedState,
  assignedCompoundIngredientRequiresValidation,
  packingFacilitiesFromCiraReplacement,
  packingFacilitiesFromIaReplacement,
}) =>
  replacementState.packingFacilityNames.filter((packingFacilityName) => {
    let matchedFacility;

    if (
      selectedState.ingredient.id === assignedCompoundIngredientRequiresValidation.ingredient.id
    ) {
      matchedFacility = packingFacilitiesFromCiraReplacement.includes(packingFacilityName);
    } else {
      matchedFacility = packingFacilitiesFromIaReplacement.includes(packingFacilityName);
    }
    return matchedFacility;
  });
