import useDebounce from 'lib/useDebounce';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

import { Loading } from 'components/shared';
import GeographyPill from 'components/FlexibleVolumeChanges/GeographyInput/GeographyPill';
import { GET } from 'lib/api';
import { APP_API_URI } from 'lib/constants';

const GeographyInput = ({
  classes,
  id,
  label,
  value,
  onChange,
  packingFacility,
  shipDate,
  meal,
}) => {
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [parserResponse, setParserResponse] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const quantityMessage =
    parserResponse &&
    parserResponse.total_quantity !== null &&
    `Total Quantity: ${parserResponse.total_quantity},  Minimum Quantity: ${parserResponse.minimum_quantity}`;

  const fullInputText = useMemo(
    () => [...value.map((g) => g.code), inputText].join(' '),
    [value, inputText]
  );

  const debouncedParseGeography = useDebounce(async (params, shouldIgnore) => {
    setIsLoading(true);
    await GET(`${APP_API_URI}/internal/retail/flexible_volume_changes/parse_geography`, {
      params,
    })
      .then((response) => {
        if (shouldIgnore()) return;
        // Instead of handling this response here, we're storing it into React
        // state. In the async context here we don't have the current props of
        // the element which may have changed during the time of the API call.
        setParserResponse(response.data);
        setErrorMessage(null);
      })
      .catch((error) => setErrorMessage(error.response?.data?.error || error.response.statusText))
      .finally(() => {
        setIsLoading(false);
      });
  });

  useEffect(() => {
    let ignore = false;

    const parseParams =
      packingFacility && shipDate && meal
        ? {
            packing_facility_id: packingFacility.id,
            ship_date: shipDate,
            meal_id: meal.id,
            geography_input_text: fullInputText,
          }
        : {
            geography_input_text: fullInputText,
          };
    debouncedParseGeography(parseParams, () => ignore);

    return () => {
      // This is called on effect cleanup, when any of the parameters are
      // changed. This allows us to discard responses that initiated with old
      // params when a new request is now waiting
      ignore = true;
    };
  }, [packingFacility, shipDate, meal, fullInputText, debouncedParseGeography]);

  useEffect(() => {
    if (parserResponse === null) return;

    const newPills = Object.fromEntries(value.map((g) => [g.code, g]));
    let newInputText = inputText;

    parserResponse.results.forEach((result) => {
      if (inputFocused && result.errorMessage !== undefined) {
        // If the result is an error, and the text field is currently focused,
        // we'll skip pillifying that result and let them keep typing.
        return;
      }

      newInputText = newInputText.replace(new RegExp(`\\b${result.code}\\b`, 'gi'), '');

      // Update the pills array if the token was replaced, or if that pill
      // already existed and is being updated
      if (newInputText !== inputText || newPills[result.code] !== undefined) {
        newPills[result.code] = result;
      }
    });

    if (newInputText !== inputText) {
      setInputText(newInputText.trimStart());
    }
    onChange(Object.values(newPills));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parserResponse, inputFocused]);

  const handlePillDeleted = (pill) => {
    const newValue = value.filter((geography) => geography.code !== pill.code);
    onChange(newValue);
  };

  const handleBackspace = () => {
    if (inputText === '' && value.length > 0) {
      handlePillDeleted(value.at(-1));
    }
  };

  return (
    <TextField
      id={id}
      label={label}
      value={inputText}
      onChange={(e) => setInputText(e.target.value)}
      onKeyDown={(e) => {
        if (e.keyCode === 8) handleBackspace();
      }}
      onFocus={() => setInputFocused(true)}
      onBlur={() => setInputFocused(false)}
      multiline
      className={classes.geographyField}
      rows={1}
      variant="outlined"
      fullWidth
      error={!!errorMessage}
      helperText={errorMessage || quantityMessage}
      InputProps={{
        startAdornment: value.map((pill) => (
          <GeographyPill key={pill.code} pill={pill} onDelete={() => handlePillDeleted(pill)} />
        )),
        endAdornment: isLoading ? (
          <Loading
            size={20}
            topPadding={false}
            centered={false}
            className={classes.loadingIndicator}
          />
        ) : undefined,
      }}
    />
  );
};

GeographyInput.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  packingFacility: PropTypes.object,
  shipDate: PropTypes.string,
  meal: PropTypes.object,
  label: PropTypes.string,
  id: PropTypes.string,
};

GeographyInput.defaultProps = {
  packingFacility: null,
  shipDate: null,
  meal: null,
  label: 'Geography',
  id: 'geography',
};

export default GeographyInput;
