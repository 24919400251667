import {
  IM_FORM_OPTIONS_REQUEST,
  IM_FORM_OPTIONS_SUCCESS,
  IM_FORM_OPTIONS_ERROR,
  IM_INCIDENT_REQUEST,
  IM_INCIDENT_SUCCESS,
  IM_INCIDENT_ERROR,
} from 'lib/constants';

export const initialState = {
  error: false,
  errorMessage: null,
  fetching: false,
  formOptions: {
    cancelReasons: null,
    errorTypes: null,
    userRemediationCategories: null,
    deliveryRemediationCategories: null,
  },
  incident: null,
  submitting: false,
  success: false,
  successMessage: null,
  incidents: [],
  totalCashRefunds: null,
  totalGiftCardRefunds: null,
  totalCredits: null,
};

export default (state = initialState, action = { type: null }) => {
  // action.response.statusText captures 500 "internal server error"
  const errorMessage = action.response ? action.response.error || action.response.statusText : null;

  switch (action.type) {
    case IM_FORM_OPTIONS_REQUEST:
      return {
        ...state,
        error: false,
        fetching: true,
      };
    case IM_FORM_OPTIONS_SUCCESS:
      return {
        ...state,
        formOptions: action.response.formOptions,
        error: false,
        fetching: false,
      };
    case IM_FORM_OPTIONS_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.error.message,
        fetching: false,
      };

    case IM_INCIDENT_REQUEST:
      return {
        ...state,
        error: false,
        submitting: true,
        success: false,
        successMessage: null,
      };
    case IM_INCIDENT_SUCCESS:
      // Creating an incident may succeed, but the accompanying
      // cancelation may fail. So there may be an error message
      // on success, present in the response.
      return {
        ...state,
        error: !!errorMessage,
        errorMessage,
        incident: action.response.incident,
        submitting: false,
        success: true,
        successMessage: 'Incident created!',
        incidents: action.response.incidents,
        totalCashRefunds: action.response.totalCashRefunds,
        totalGiftCardRefunds: action.response.totalGiftCardRefunds,
        totalCredits: action.response.totalCredits,
      };
    case IM_INCIDENT_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.error.message,
        submitting: false,
        success: false,
        successMessage: null,
      };
    default:
      return state;
  }
};
