// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Material Components
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { USER_ERROR, DELIVERY_ERROR } from 'lib/constants';

const FormTypeButton = ({ classes, incidentType, handleTypeChange, disableDeliveryError }) => {
  const isDeliveryError = incidentType === DELIVERY_ERROR;
  const isUserError = incidentType === USER_ERROR;

  const { buttonActive, buttonInactive } = classes;
  const deliveryButtonClass = isDeliveryError ? buttonActive : buttonInactive;
  const userButtonClass = isUserError ? buttonActive : buttonInactive;

  return (
    <ButtonGroup className={classes.buttonGroup}>
      <Button
        name="incidentType"
        value={DELIVERY_ERROR}
        className={deliveryButtonClass}
        onClick={handleTypeChange}
        disabled={disableDeliveryError}
      >
        Delivery Error
      </Button>
      <Button
        name="incidentType"
        value={USER_ERROR}
        className={userButtonClass}
        onClick={handleTypeChange}
      >
        User Error
      </Button>
    </ButtonGroup>
  );
};

FormTypeButton.propTypes = {
  incidentType: PropTypes.string.isRequired,
  handleTypeChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disableDeliveryError: PropTypes.bool,
};

FormTypeButton.defaultProps = {
  disableDeliveryError: false,
};

export default FormTypeButton;
