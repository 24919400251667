import { mapKeysDeep } from 'lib/helpers/objects';
import { snakeCase } from 'lodash';
import { APP_API_URI } from 'lib/constants';
import { GET, POST, PUT } from 'lib/api';

export const CIRF_URL = `${APP_API_URI}/internal/culinary/compound_ingredient_recipe_form`;

export const fetchCompoundIngredientRecipe = async ({ compoundIngredientRecipeId }) => {
  const queryUrl = `${CIRF_URL}/compound_ingredient_recipe`;

  const response = await GET(queryUrl, {
    params: {
      compound_ingredient_recipe_id: compoundIngredientRecipeId,
    },
  });

  return response.data;
};

export const updateCompoundIngredientRecipe = async ({
  compoundIngredientRecipeId,
  compoundIngredientRecipeParams,
}) => {
  const queryUrlParams = compoundIngredientRecipeId
    ? { compound_ingredient_recipe_id: compoundIngredientRecipeId }
    : {};

  const queryUrl = `${CIRF_URL}/compound_ingredient_recipe`;

  const transformedParams = mapKeysDeep(compoundIngredientRecipeParams, (_value, key) => {
    return snakeCase(key);
  });

  const response = await POST(queryUrl, transformedParams, { params: queryUrlParams });

  return response.data;
};

export const updateCompoundIngredientState = async ({ compoundIngredientId, eventType }) => {
  const queryUrl = `${CIRF_URL}/update_compound_ingredient_state`;

  const transformedParams = {
    compound_ingredient: {
      id: compoundIngredientId,
      event_type: eventType,
    },
  };

  const response = await PUT(queryUrl, transformedParams);

  return response.data;
};
