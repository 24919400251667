export const updateSwapStatus = (ingredientAssignment, allAssignments, newStatus) => {
  const iaIndex = allAssignments.findIndex(
    (assignment) => assignment.id === ingredientAssignment.id
  );
  const updatedIngredientAssignment = { ...allAssignments[iaIndex], swapStatus: newStatus };

  const updatedAssignments = [
    ...allAssignments.slice(0, iaIndex),
    updatedIngredientAssignment,
    ...allAssignments.slice(iaIndex + 1),
  ];

  return updatedAssignments;
};

export const insertUpdatedIngredientAssignment = (ingredientAssignment, allAssignments) => {
  const iaIndex = allAssignments.findIndex(
    (assignment) => assignment.id === ingredientAssignment.id
  );

  const updatedAssignments = [
    ...allAssignments.slice(0, iaIndex),
    ingredientAssignment,
    ...allAssignments.slice(iaIndex + 1),
  ];

  return updatedAssignments;
};
