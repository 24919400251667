import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Material Components
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import SectionHeader from '../shared/SectionHeader';
import Dropdown from '../../shared/Dropdown';
import TextBox from '../../shared/TextBox';

const BatchYield = ({ classes, formState, handleFormState, editsLocked }) => {
  const { formOptions } = useSelector((state) => state.compoundIngredientRecipeForm.formOptions);

  const volumeHelperTxt =
    formState.standardUnit === 'volume'
      ? 'Since the standard unit is set to volume, the input amount is shown in tbsp.'
      : '';

  const outputYieldHelperTxt = editsLocked
    ? 'Cannot be edited for active or legacy compound ingredients'
    : null;

  const selectOutputPerRunMetrics = useMemo(() => {
    return formOptions.outputPerRunMetrics.map((metric) => ({
      label: metric.formattedName,
      value: metric.name,
    }));
  }, [formOptions.outputPerRunMetrics]);

  return (
    <Paper className={classes.paperWithBottomMargin}>
      <SectionHeader text="Batch Yield" />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextBox
            disabled
            label="Input Amount"
            helperText={`Input amount will be calculated based on ingredient weight, volume to weight, and standard unit. ${volumeHelperTxt}`}
            name="inputAmount"
            value={formState.inputAmount}
          />
        </Grid>
        <Grid item xs={6}>
          <TextBox
            disabled
            label="Standard Unit"
            helperText="Standard unit is set in Extended Attributes and displayed here for reference."
            name="standardUnit"
            value={formState.standardUnit}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} className={classes.outputYieldGridItem}>
          <TextBox
            label="Output Yield"
            name="outputYield"
            onChange={handleFormState}
            disabled={editsLocked}
            helperText={outputYieldHelperTxt}
            required
            type="number"
            endAdornment={<>%</>}
            value={formState.outputYield}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            helperText="Used to measure recipe's output in batches (e.g. sheet tray)."
            label="Output per Run Metric"
            name="outputPerRunMetric"
            onChange={handleFormState}
            options={selectOutputPerRunMetrics}
            value={formState.outputPerRunMetric}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextBox
            label="Min Output per Run"
            helperText="Smallest number of batches produced by the recipe as written (e.g. 2 sheet trays)."
            name="minOutputPerRun"
            value={formState.minOutputPerRun || ''}
            onChange={handleFormState}
          />
        </Grid>
        <Grid item xs={6}>
          <TextBox
            label="Max Output per Run"
            helperText="Largest number of batches produced by the recipe as written (e.g. 5 sheet trays)."
            name="maxOutputPerRun"
            value={formState.maxOutputPerRun || ''}
            onChange={handleFormState}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

BatchYield.propTypes = {
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
  editsLocked: PropTypes.bool,
};

BatchYield.defaultProps = {
  editsLocked: false,
};

export default BatchYield;
