import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  CONFIG_GROUP_CREATE_ACTION,
  CONFIG_GROUP_RENAME_ACTION,
  CONFIG_GROUP_DELETE_ACTION,
} from 'lib/constants';
import { Container, Autocomplete, TextField, createFilterOptions, Alert } from '@mui/material';
import CreateKeyDialog from './CreateKeyDialog';
import styles from './styles';
import AppConfigKey from './AppConfigKey';
import Button from './shared/Button';
import ConfigGroupActionDialog from './ConfigGroupActionDialog';

const AppConfigGroup = ({ classes, configGroup, handleSearchSelect }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [action, setAction] = React.useState(CONFIG_GROUP_CREATE_ACTION);
  const [showCreateKeyDialog, setShowCreateKeyDialog] = useState(false);
  const { applicationConfig } = useSelector((state) => state.appConfigurationTool);

  const autoCompleteOptions = applicationConfig
    .map((appConfigGroup, tabIndex) => {
      return appConfigGroup.keys.map((key) => ({
        label: `${key.name} | ${key.key}`,
        id: key.id,
        groupName: appConfigGroup.groupName,
        tabIndex,
      }));
    })
    .flat();

  const OPTIONS_LIMIT = 20;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const isOptionEqualToValue = (option, value) => {
    return option.id === value.id;
  };

  const handleGroupCreate = () => {
    setOpenModal(!openModal);
    setAction(CONFIG_GROUP_CREATE_ACTION);
  };

  const handleGroupDelete = () => {
    setOpenModal(true);
    setAction(CONFIG_GROUP_DELETE_ACTION);
  };

  const handleGroupRename = () => {
    setOpenModal(true);
    setAction(CONFIG_GROUP_RENAME_ACTION);
  };

  const handleAddKey = () => {
    setShowCreateKeyDialog(true);
  };

  return (
    <Container maxWidth="false">
      {openModal && (
        <ConfigGroupActionDialog
          configGroup={configGroup}
          openModal={openModal}
          setOpenModal={setOpenModal}
          action={action}
        />
      )}
      <Container className={classes.actionBox} maxWidth="false" disableGutters>
        <Button handleOnClick={handleGroupCreate} buttonText="New Group" />
        <Button handleOnClick={handleGroupRename} buttonText="Rename Group" />
        <Button
          handleOnClick={handleGroupDelete}
          buttonText="Delete Group"
          color="error"
          disabled={configGroup.keys.length > 0}
        />
        <Autocomplete
          blurOnSelect
          clearOnBlur
          filterOptions={filterOptions}
          groupBy={(option) => option.groupName}
          handleHomeEndKeys
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={handleSearchSelect}
          options={autoCompleteOptions}
          renderInput={(params) => <TextField {...params} label="Quick Search" />}
          sx={{ width: '50%' }}
          value={null}
        />
        <Button handleOnClick={handleAddKey} buttonText="New Key" className={classes.rightButton} />
      </Container>
      {configGroup.keys.length > 0 ? (
        configGroup.keys
          .sort((keyA, keyB) => keyA.name.localeCompare(keyB.name))
          .map((configKey) => <AppConfigKey key={configKey.id} configKey={configKey} />)
      ) : (
        <Alert severity="info">{configGroup.groupName} is empty.</Alert>
      )}
      {showCreateKeyDialog && (
        <CreateKeyDialog
          open={showCreateKeyDialog}
          onClose={() => setShowCreateKeyDialog(false)}
          configGroup={configGroup}
          configGroupOptions={applicationConfig.map((config) => ({
            label: config.groupName,
            value: config.groupId,
          }))}
        />
      )}
    </Container>
  );
};

AppConfigGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  configGroup: PropTypes.object.isRequired,
  handleSearchSelect: PropTypes.func.isRequired,
};

export default withStyles(styles)(AppConfigGroup);
