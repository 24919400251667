import { parseISO, format, getUnixTime, fromUnixTime, compareDesc } from 'date-fns';

export const sortedMenus = (meal) => {
  return meal.menus.sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)));
};

export const mostRecentMenu = (meal) => {
  return sortedMenus(meal)[0];
};

export const extraMenus = (meal) => {
  return sortedMenus(meal).slice(1);
};

export const noMenus = (meal) => {
  return meal.menus === undefined || meal.menus.length === 0;
};

export const formattedMenu = (menu) => {
  return `${menu.type} #${menu.id} - ${format(
    fromUnixTime(getUnixTime(parseISO(menu.date))),
    'PP'
  )}`;
};
