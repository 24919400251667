import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Material Components
import Paper from '@mui/material/Paper';

import SectionHeader from '../shared/SectionHeader';
import TextBox from '../../shared/TextBox';
import Checkboxes from '../../shared/Checkboxes';

const DisplayInformation = ({ classes, formState, handleFormState }) => {
  const { formOptions } = useSelector((state) => state.compoundIngredientRecipeForm.formOptions);

  return (
    <Paper className={classes.paper}>
      <SectionHeader text="Display Information" />
      <TextBox
        fullWidth
        label="Display Name"
        name="displayName"
        onChange={handleFormState}
        required
        value={formState.displayName}
      />
      <div className={classes.claimsContainer}>
        <SectionHeader text="Ingredient Claims" />
        <Checkboxes
          choices={formOptions.ingredientClaims}
          columns={3}
          selectedFormState={formState.ingredientClaimIds}
          selectedFormStateName="ingredientClaims"
          handleFormState={handleFormState}
        />
      </div>
    </Paper>
  );
};

DisplayInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  handleFormState: PropTypes.func.isRequired,
};

export default DisplayInformation;
