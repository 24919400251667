export default {
  root: {
    marginTop: 20,
    marginLeft: 0,
    marginBottom: 100,
  },
  alertBar: {
    marginBottom: 10,
    border: '1px solid orange',
  },
  header: {
    margin: 'auto',
    display: 'flex',
  },
  headerTitle: {
    fontSize: '20px',
    fontWeight: 500,
    padding: '0px 8px 0px 0px',
    fontFamily: 'Roboto',
    color: '#404851',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '20px',
    color: '#8C9196',
  },
  tabs: {
    marginTop: 33,
  },
  tabsDivider: {
    width: '100%',
    position: 'absolute',
    left: 0,
  },
};
