import { WHITE, LIGHT_BLACK, BACKGROUND_GREY } from 'lib/constants';

export default {
  root: {
    backgroundColor: BACKGROUND_GREY,
    fontFamily: 'Roboto',
  },
  appBar: {
    backgroundColor: WHITE,
  },
  appBarTitle: {
    color: LIGHT_BLACK,
  },
  container: {
    marginTop: '50px',
    marginLeft: '20px',
  },
};
