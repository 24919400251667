const validateKey = (key) => {
  const errors = [];

  if (!key) errors.push('Key is required.');
  if (key.length < 4) errors.push('Key must be at least 4 characters.');
  if (key.match(/[^a-zA-Z0-9_]/)) errors.push('Key must be alphanumeric or an underscore.');
  if (key.match(/^_+|_+$/g)) errors.push('Key cannot start or end with an underscore.');
  if (key.match(/^[0-9]+/)) errors.push('Key cannot start with a number.');

  return errors.join(' ');
};

export default validateKey;
