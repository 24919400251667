import useJobDetail from 'lib/useJobDetail';
import React, { useState } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { Loading } from 'components/shared';
import { useAddAlert } from 'components/shared/AlertManager';

import { POST } from 'lib/api';
import { APP_API_URI } from 'lib/constants';
import MenuWeekSelector from '../MenuWeekSelector';

const DownloadStoreOrders = () => {
  const addAlert = useAddAlert();
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [downloadJobId, setDownloadJobId] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleJobResult = (result) => {
    window.location = result.download_url;
    setIsDownloading(false);
    setDownloadJobId(null);
  };

  const handleJobRetrying = (detail) => {
    addAlert(detail.error);
  };

  const handleJobDead = (detail) => {
    addAlert(detail.error);
    setIsDownloading(false);
    setDownloadJobId(null);
  };

  const jobDetail = useJobDetail({
    jobClass: 'Admin::Csvs::RetailStoreOrdersForMenuJob',
    jid: downloadJobId,
    onJobResult: handleJobResult,
    onJobRetrying: handleJobRetrying,
    onJobDead: handleJobDead,
    onFetchError: addAlert,
  });

  const handleDownloadRetailStoreOrders = async () => {
    setDownloadJobId(null);
    setIsDownloading(true);
    await POST(
      `${APP_API_URI}/internal/retail/flexible_volume_changes/download_retail_store_orders`,
      {
        retail_menu_id: selectedMenu.id,
      }
    )
      .then((response) => {
        setDownloadJobId(response.data.jid);
      })
      .catch((error) => {
        addAlert(error.response?.data?.error || error.response.statusText);
      });
  };

  return (
    <Card>
      <CardContent sx={{ paddingBottom: 0 }}>
        <MenuWeekSelector value={selectedMenu} onChange={setSelectedMenu} />
      </CardContent>
      <CardHeader
        title="Download Store Orders"
        subheader="Download the current store orders for the selected menu week"
        action={
          <Button
            variant="outlined"
            color="primary"
            disabled={!selectedMenu || isDownloading}
            onClick={handleDownloadRetailStoreOrders}
            startIcon={
              isDownloading ? <Loading size={20} topPadding={false} /> : <CloudDownloadIcon />
            }
          >
            {jobDetail ? jobDetail.status : 'Download'}
          </Button>
        }
      />
    </Card>
  );
};

export default DownloadStoreOrders;
