import { LIGHT_BLACK, LIGHT_GREY, MEDIUM_GREY, HC_GREEN, BORDER_GREY } from 'lib/constants';

export default {
  root: {
    display: 'flex',
    flexDirection: 'row',
    color: '#000',
    backgroundColor: '#fff',
    boxShadow: 'none',
    padding: '12px 12px 0px 12px',
    borderBottom: `1px solid ${BORDER_GREY}`,
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: '500',
    textAlign: 'left',
    color: LIGHT_BLACK,
    marginRight: '4%',
    paddingTop: '4px',
  },
  quarterDate: {
    color: LIGHT_GREY,
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    textAligh: 'left',
    marginRight: '24px',
    padding: '10px',
  },
  arrowsContainer: {
    flexGrow: '1',
  },
  arrow: {
    color: MEDIUM_GREY,
    width: '14px',
    height: '14px',
  },
  tab: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    textTransform: 'capitalize',
    color: LIGHT_BLACK,
    '&:hover': {
      color: HC_GREEN,
    },
    '&:focus': {
      color: HC_GREEN,
    },
  },
  selectedTab: {
    opacity: '1',
    color: HC_GREEN,
  },
  indicator: {
    height: 5,
    backgroundColor: HC_GREEN,
    borderRadius: '2.5px 2.5px 0 0',
  },
};
