import { GET, POST } from 'lib/api';
import { APP_API_URI } from 'lib/constants';

export const fetchDisputes = async () => {
  const response = await GET(`${APP_API_URI}/internal/customer_support/dispute_manager/disputes`);
  return response.data;
};

export const updateEvidence = async ({ disputeId, chargeId }) => {
  const response = await POST(
    `${APP_API_URI}/internal/customer_support/dispute_manager/send_evidence`,
    {
      dispute_id: disputeId,
      charge_id: chargeId,
    }
  );
  return response.data;
};
