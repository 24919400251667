import { MEDIUM_SPACING, THICK_SPACING } from 'lib/constants';

export default {
  saveOrCancelSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: THICK_SPACING,
    width: '640px',
  },
  buttonContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
    gap: MEDIUM_SPACING,
  },
};
