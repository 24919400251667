import {
  rowLabel,
  iconCell,
  icon,
  listContentSameBlock,
  expandingCell,
  collapse,
  tableRowGreenBorder,
  greyed,
} from 'lib/styles/comparisonTool';

export default {
  rowLabel,
  iconCell,
  icon,
  listContentSameBlock,
  expandingCell,
  collapse,
  tableRowGreenBorder,
  greyed,
  ingredientBlock: {
    marginBottom: '12px',
  },
};
